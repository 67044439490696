import React from "react";
import logo from "../assets/images/Logo.png";
import { useState, useEffect } from "react";
import Select from "react-select";
import "./CheckBox.css";
import bcrypt from "bcryptjs";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";

export const Affiliate_SignUp = () => {
  const [countries, setCountries] = useState([]);
  const [currentForm, setCurrentForm] = useState(1);
  const navigate = useNavigate();
  const [accountname, setAccountname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [prefferedLanguage, setPrefferedLanguage] = useState("");
  const [hearaboutclickdealer, setHearaboutclickdealer] = useState("");
  const [refferedbyclickdealer, setRefferedbyclickdealer] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const validateConfirmPasswordStrength = (password) => {
    const strongConfirmPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&\-+_=])[A-Za-z\d@$!%*?&\-+_=]{8,}$/;
    if (!strongConfirmPasswordRegex.test(password)) {
      setConfirmPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  const isFormValid = () => {
    return (
      accountname !== "" &&
      firstname !== "" &&
      lastname !== "" &&
      email !== "" &&
      phone !== "" &&
      prefferedLanguage !== "" &&
      hearaboutclickdealer !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      error === "" &&
      lastnameError === "" &&
      emailError === "" &&
      errorMessage === "" &&
      confirmPasswordError === "" &&
      passwordError === "" &&
      password == confirmPassword &&
      nameError === ""
    );
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validateConfirmPasswordStrength(newConfirmPassword);
  };

  const [passwordError, setPasswordError] = useState("");
  const validatePasswordStrength = (password) => {
    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&\-+_=])[A-Za-z\d@$!%*?&\-+_=]{8,}$/;
    if (!strongPasswordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setPasswordError("");
    }
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePasswordStrength(newPassword);
  };

  const [refferd, serReffered] = useState("null");

  const [linkedinisChecked, setLinkedinisChecked] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const validateDateOfBirth = (dob) => {
    const today = new Date();
    if (!dob) {
      return "Date of Birth is required.";
    }

    if (dob > today) {
      return "Date of Birth cannot be in the future.";
    }

    const age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    if (
      age < 18 ||
      (age === 18 &&
        (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)))
    ) {
      return "You must be at least 18 years old.";
    }
    return "";
  };

  const handleChange = (date) => {
    setDateOfBirth(date);
    const error = validateDateOfBirth(date);
    setErrorMessage(error);
  };

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\+\d{7,15}$/;
    return phoneRegex.test(value);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (validatePhoneNumber(value)) {
      setError("");
    } else {
      setError("Please enter a valid phone number");
    }
    setPhone(value);
  };

  const [nameError, setNameError] = useState("");
  const validateFirstName = (value) => {
    // Regex for validating name (alphabetic characters only)
    const nameRegex = /^[A-Za-z]+$/;
    return nameRegex.test(value);
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    if (validateFirstName(value)) {
      setNameError("");
    } else {
      setNameError("Please enter a valid name (alphabetic characters only).");
    }
    setFirstname(value);
  };

  const [lastnameError, setLastnameError] = useState("");
  const validateName = (value) => {
    // Regex for validating name (alphabetic characters only)
    const nameRegex = /^[A-Za-z]+$/;
    return nameRegex.test(value);
  };
  const handleLastnameChange = (e) => {
    const value = e.target.value;
    if (validateName(value)) {
      setLastnameError("");
    } else {
      setLastnameError(
        "Please enter a valid name (alphabetic characters only)."
      );
    }
    setLastname(value);
  };

  const [emailError, setEmailError] = useState("");
  const validateEmail = (value) => {
    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (validateEmail(value)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
    setEmail(value);
  };

  const options = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "french", label: "French" },
  ];

  const handlePrefferedLanguageChange = (selectedOption) => {
    setPrefferedLanguage(selectedOption);
    // console.log(Option selected:, selectedOption);
  };

  //  -----------------  Form 2  ----------------------//

  const [selectedCountry, setSelectedCountry] = useState({});

  const [companyurl, setCompanyurl] = useState("");
  const [companyurlError, setCompanyurlError] = useState("");
  const validateCompanyUrl = (value) => {
    // Simple regex for URL validation
    const urlRegex =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlRegex.test(value);
  };
  const handleCompanyUrlChange = (e) => {
    const value = e.target.value;
    if (validateCompanyUrl(value)) {
      setCompanyurlError("");
    } else {
      setCompanyurlError("Please enter a valid URL.");
    }
    setCompanyurl(value);
  };

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const validateCity = (value) => {
    // Regex for validating city name (alphabetic characters and spaces only)
    const cityRegex = /^[A-Za-z\s]+$/;
    return cityRegex.test(value);
  };
  const handleCityChange = (e) => {
    const value = e.target.value;
    if (validateCity(value)) {
      setCityError("");
    } else {
      setCityError(
        "Please enter a valid city name (alphabetic characters only)."
      );
    }
    setCity(value);
  };

  const [linkedin_facebook_url, setLinkedin_facebook_url] = useState("");
  const [linkedinFacebookUrlError, setLinkedinFacebookUrlError] = useState("");
  const validateSocialMediaUrl = (value) => {
    // Simple regex for URL validation
    const urlRegex =
      /^(https?:\/\/)?(www\.)?(linkedin\.com\/.*|facebook\.com\/.*)$/;
    return urlRegex.test(value);
  };
  const handleLinkedinFacebookUrlChange = (e) => {
    const value = e.target.value;
    if (validateSocialMediaUrl(value)) {
      setLinkedinFacebookUrlError("");
    } else {
      setLinkedinFacebookUrlError(
        "Please enter a valid LinkedIn or Facebook URL."
      );
    }
    setLinkedin_facebook_url(value);
  };

  const [postalcode, setPostalcode] = useState("");
  const [postalcodeError, setPostalcodeError] = useState("");
  const validatePostalcode = (value) => {
    // Regex for validating postal code (adjust according to country format)
    const postalcodeRegex = /^[A-Za-z0-9\s-]{3,10}$/;
    return postalcodeRegex.test(value);
  };
  const handlePostalcodeChange = (e) => {
    const value = e.target.value;
    if (validatePostalcode(value)) {
      setPostalcodeError("");
    } else {
      setPostalcodeError("Please enter a valid postal code.");
    }
    setPostalcode(value);
  };

  const [socialaccount, setSocialaccount] = useState("");
  const [address, setAddress] = useState("");
  const [im_Nickname, setIm_Nickname] = useState("");

  const optionsIM = [
    { value: "Skype", label: "Skype" },
    { value: "QQ", label: "QQ" },
    { value: "Telegram", label: "Telegram" },
    { value: "Whatsapp", label: "Whatsapp" },
    { value: "Signal", label: "Signal" },
  ];

  const topVerticals = [
    { value: "E-commerce", label: "E-commerce" },
    { value: "Health & Wellness", label: "Health & Wellness" },
    { value: "Finance", label: "Finance" },
    { value: "Travel", label: "Travel" },
    { value: "Education", label: "Education" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Technology", label: "Technology" },
    { value: "Fashion", label: "Fashion" },
    { value: "Food & Beverage", label: "Food & Beverage" },
    { value: "Gaming", label: "Gaming" },
  ];

  const handleSocialAccountChange = (selectedOption) => {
    setSocialaccount(selectedOption);
    // console.log(Option selected:, selectedOption);
  };

  const isForm2Valid = () => {
    return (
      selectedCountry !== "" &&
      companyurl !== "" &&
      city !== "" &&
      postalcode !== "" &&
      linkedin_facebook_url !== "" &&
      socialaccount !== "" &&
      address !== "" &&
      im_Nickname !== "" &&
      companyurlError === "" &&
      cityError === "" &&
      linkedinFacebookUrlError === "" &&
      postalcodeError === ""
    );
  };

  //  -----------------  Form 3  ----------------------//

  // ---------------- Radio Button ----------------- //
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    event.preventDefault();
    setSpecialization(event.target.value);
  };

  const handleMonthlyRevenueChange = (event) => {
    setMonthlyRevenue(event.target.value);
  };

  const [terms, setTerms] = useState(0);

  const handleTermsChange = (event) => {
    if (event.target.checked) {
      setTerms(1);
    } else {
      setTerms(0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(Specialization);
    console.log(prefferedNiche);
    console.log(topVertical);
    console.log(specific_offers_or_verticals);
    console.log(currentCPA);
    console.log(monthlyRevenue);
    console.log(traffic_types_promotional_use);
    console.log(promoteCountries);
    console.log(companyInfo);
    console.log(terms);
    console.log(newsletter);
  };

  const [Specialization, setSpecialization] = useState("");
  const [prefferedNiche, setPrefferedNiche] = useState([]);
  const [topVertical, setTopVertical] = useState("");
  const [specific_offers_or_verticals, setSpecific_offers_or_verticals] =
    useState("");

  const [currentCPA, setcurrentCPA] = useState();
  const [cpaError, setCpaError] = useState("");
  const validateCpa = (value) => {
    // Check if the value is a positive number
    const cpaRegex = /^\d+(\.\d{1,2})?$/; // Allows integers and decimals up to two places
    return cpaRegex.test(value);
  };
  const handleCpaChange = (e) => {
    const value = e.target.value;
    if (validateCpa(value)) {
      setCpaError("");
    } else {
      setCpaError("Please enter a valid CPA value.");
    }
    setcurrentCPA(value);
  };

  const [monthlyRevenue, setMonthlyRevenue] = useState("");
  const [traffic_types_promotional_use, setTraffic_types_promotional_use] =
    useState([]);

  const [promoteCountries, setPromoteCountries] = useState("");
  const [promoteCountriesError, setPromoteCountriesError] = useState("");
  const validateCountries = (value) => {
    // Regex for validating comma-separated country names
    const countriesRegex = /^([a-zA-Z\s]+,)*[a-zA-Z\s]+$/;
    return countriesRegex.test(value);
  };
  const handlePromoteCountriesChange = (e) => {
    const value = e.target.value;
    if (validateCountries(value)) {
      setPromoteCountriesError("");
    } else {
      setPromoteCountriesError(
        "Please enter a valid list of countries separated by commas."
      );
    }
    setPromoteCountries(value);
  };

  const [companyInfo, setCompanyInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState();

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  // htmlFor Preffered Niche

  const handle_prefferedNiche_Change = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setPrefferedNiche([...prefferedNiche, value]);
    } else {
      setPrefferedNiche(prefferedNiche.filter((option) => option !== value));
    }
  };

  const handleRadioChange = (event) => {
    setMonthlyRevenue(event.target.value);
  };

  const handle_Traffic_Type_Change = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setTraffic_types_promotional_use([
        ...traffic_types_promotional_use,
        value,
      ]);
    } else {
      setTraffic_types_promotional_use(
        traffic_types_promotional_use.filter((option) => option !== value)
      );
    }
  };

  const [newsletter, setNewsletter] = useState(0);

  const handlePromoChange = (event) => {
    if (event.target.checked) {
      setNewsletter(1);
    } else {
      setNewsletter(0);
    }
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);

  const isForm3Valid = () => {
    return (
      Specialization !== "" &&
      Array.isArray(prefferedNiche) &&
      prefferedNiche.length > 0 &&
      topVertical !== "" &&
      specific_offers_or_verticals !== "" &&
      currentCPA !== "" &&
      monthlyRevenue !== "" &&
      Array.isArray(traffic_types_promotional_use) &&
      traffic_types_promotional_use.length > 0 &&
      promoteCountries !== "" &&
      companyInfo !== "" &&
      terms !== 0 &&
      promoteCountriesError === "" &&
      cpaError === ""
    );
  };

  const handleSubmitFirstForm = (e) => {
    e.preventDefault();
    console.log("First Form Submitted ");
    setCurrentForm(2);
  };

  const handleSubmitSecondForm = (e) => {
    e.preventDefault();
    console.log("Second Form passed");
    //   console.log(selectedOptions)
    setCurrentForm(3);
  };

  const handleFirstBack = (e) => {
    e.preventDefault();
    setCurrentForm(1);
  };

  const handleSecondBack = (e) => {
    e.preventDefault();
    setCurrentForm(2);
  };

  function generateUniqueCode(length = 5) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const { uniqueKey } = useParams();

  const handleThirdSubmit = async (e) => {
    e.preventDefault();

    let key = uniqueKey || "a";

    console.log("My key", key);
    console.log("Entered 3rd Form");
    var result_Selected_Preffered_Niche_String = arrayToString(prefferedNiche);
    var result_Traffic_types_String = arrayToString(
      traffic_types_promotional_use
    );
    const hashedPassword = await bcrypt.hash(password, 7);
    const refCode = generateUniqueCode();

    const response = await fetch(
      "https://apidealer.devcir.co/api/affiliate-registrations",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accountname: accountname,
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone,
          dateofbirth: dateOfBirth,
          prefferedLanguage: prefferedLanguage.value,
          hearaboutclickdealer: hearaboutclickdealer,
          refferedbyclickdealer: refferd,
          password: hashedPassword,
          selectedCountry: selectedCountry.label,
          companyurl: companyurl,
          city: city,
          linkedin_facebook_url: linkedin_facebook_url,
          postalcode: postalcode,
          socialaccount: socialaccount.value,
          address: address,
          im_Nickname: im_Nickname,
          Specialization: Specialization,
          prefferedNiche: result_Selected_Preffered_Niche_String,
          topVertical: topVertical.label,
          specific_offers_or_verticals: specific_offers_or_verticals,
          currentCPA: parseInt(currentCPA),
          monthlyRevenue: monthlyRevenue,
          traffic_types_promotional_use: result_Traffic_types_String,
          promoteCountries: promoteCountries,
          companyInfo: companyInfo,
          selectedOptions: terms,
          Ref_code: refCode,
          Referrer: key,
        }),
      }
    );

    try {
      if (response.ok) {
        const data = await response.json();
        console.log("Registration response:", data);

        const userId = data.data.id;
        if (!userId) {
          console.error("User ID not found in registration response");
          alert(
            "Registration successful, but user ID is missing. Unable to create payout."
          );
          return;
        }

        const payload2 = {
          user_id: userId,
          user_field: "Affiliate",
        };

        console.log("Payout payload:", payload2);

        const payoutResponse = await fetch(
          "https://apidealer.devcir.co/api/payouts",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload2),
          }
        );

        console.log("Payout response status:", payoutResponse.status);

        if (payoutResponse.ok) {
          const payoutData = await payoutResponse.json();
          console.log("Payout response data:", payoutData);
          alert("Successfully Registered and Payout Data Saved");
        } else {
          const errorData = await payoutResponse.json();
          console.error("Error in Payout API response:", errorData);
          alert(
            "Registration Successful, but an error occurred while saving Payout data"
          );
          console.error(
            "Error in Payout API response:",
            await payoutResponse.json()
          );
        }

        navigate("/");
      } else {
        const errorData = await response.json();
        console.error("Error in Registration API response:", errorData);
        alert(
          `Error Occurred While Registering: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error in request:", error);
      alert(`Error Occurred: ${error.message}`);
    }
  };

  function arrayToString(array) {
    return array.join(", ");
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div>
          <img
            src={logo}
            alt="Logo"
            className="mt-4"
            height={"240px"}
            width={"240px"}
          />
        </div>
        <div className="mt-14">
          <label className="flex text-[32px] font-bold ml-[320px] text-white">
            Publisher Sign Up
          </label>
          <hr className="ml-56 w-[472px] h-1 bg-[#f442bc]" />
          <p className="mt-14 ml-32 text-[#d6d3d3] text-[16px] font-medium">
            We are one of the most well-established, multi-national agencies
            htmlFor top advertisers and brands.
          </p>
          <p className="ml-14 text-[#d6d3d3] text-[16px] font-medium ">
            We've developed relationships with major publishers and media buyers
            to ensure quality traffic volume that converts.{" "}
          </p>
          <p className="ml-[360px] text-[#d6d3d3] text-[16px] font-medium">
            Join to grow and succeed online!
          </p>
        </div>
      </div>
      {currentForm === 1 && (
        <div>
          <form className="Fmt-8" onSubmit={handleSubmitFirstForm}>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                  placeholder=" "
                  value={accountname}
                  onChange={(e) => setAccountname(e.target.value)}
                  required
                />

                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Account Name
                </label>
              </div>

              <div className="relative z-0 w-5/12 group ">
                {/* <input type="text" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer" placeholder=" " value={phone}
                                    onChange={(e) => setPhone(e.target.value)} /> */}
                <input
                  type="text"
                  name="floating_phone"
                  id="floating_phone"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    error
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={phone}
                  onChange={handlePhoneChange}
                />
                <label
                  htmlFor="floating_phone"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    error
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Phone Number
                </label>
                {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 mb-5 group">
                <input
                  type="text"
                  name="floating_firstname"
                  id="floating_firstname"
                  className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    nameError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  required
                  value={firstname}
                  onChange={handleNameChange}
                />
                <label
                  htmlFor="floating_firstname"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    nameError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  First Name
                </label>
                {nameError && (
                  <p className="text-red-500 text-xs mt-1">{nameError}</p>
                )}
              </div>

              <div className="relative z-10 w-5/12 mb-5 group">
                <div className="relative z-10">
                  <Select
                    className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10"
                    required
                    value={prefferedLanguage}
                    options={options}
                    onChange={handlePrefferedLanguageChange}
                  />
                </div>
                <label className="absolute text-lg text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] ml-2">
                  Preferred language
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 group">
                <input
                  type="text"
                  name="floating_lastname"
                  id="floating_lastname"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    lastnameError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={lastname}
                  onChange={handleLastnameChange}
                />

                <label
                  htmlFor="floating_lastname"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    lastnameError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Last Name
                </label>
                {lastnameError && (
                  <p className="text-red-500 text-xs mt-1">{lastnameError}</p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                  placeholder=" "
                  value={hearaboutclickdealer}
                  onChange={(e) => setHearaboutclickdealer(e.target.value)}
                />

                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  How did you hear about CronoNetwork?
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="email"
                  name="floating_email"
                  id="floating_email"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    emailError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={email}
                  onChange={handleEmailChange}
                />
                <label
                  htmlFor="floating_email"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    emailError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Email
                </label>
                {emailError && (
                  <p className="text-red-500 text-xs mt-1">{emailError}</p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <label className="text-sm font-bold text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0]">
                  Were you referred by CronoNetwork AM or an active CronoNetwork
                  publisher?
                </label>
                <br />
                <input
                  type="radio"
                  value="yes"
                  checked={refferedbyclickdealer === "yes"}
                  onChange={() => setRefferedbyclickdealer("yes")}
                />
                <label className="text-[#d6d3d3] ml-2 font-medium -mt-[1.5px]">
                  Yes
                </label>
                <input
                  type="radio"
                  className="ml-5"
                  value="no"
                  checked={refferedbyclickdealer === "no"}
                  onChange={() => setRefferedbyclickdealer("no")}
                />
                <label className="text-[#d6d3d3] ml-2 font-medium -mt-[1.5px]">
                  No
                </label>
                {refferedbyclickdealer === "yes" && (
                  <div className="relative z-0 w-full group ">
                    <input
                      type="text"
                      name="floating_email"
                      id="floating_email"
                      className="block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                      placeholder=" "
                      onChange={(e) => serReffered(e.target.value)}
                    />

                    <label
                      htmlFor="floating_email"
                      className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      please Specify
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 mb-3 group">
                <DatePicker
                  selected={dateOfBirth}
                  onChange={handleChange}
                  dateFormat="yyyy-MM-dd"
                  className={`block py-2.5 px-0 w-full placeholder:text-[#f442bc] text-sm text-white font-semibold bg-transparent border-0 border-b-2 ${
                    errorMessage ? "border-red-500" : "border-gray-400"
                  } appearance-none focus:outline-none focus:ring-0 ${
                    errorMessage
                      ? "focus:border-red-500"
                      : "focus:border-[#f442bc]"
                  } peer`}
                  placeholderText="Date Of Birth"
                />
                {errorMessage && (
                  <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group">
                <input
                  type="password"
                  name="floating_password"
                  id="floating_password"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 ${
                    passwordError ? "border-red-500" : "border-gray-400"
                  } appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer`}
                  placeholder=" "
                  value={password}
                  onChange={handlePasswordChange}
                />
                <label
                  htmlFor="floating_password"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Password
                </label>
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-end w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 mb-3 group">
                <input
                  type="password"
                  name="floating_password"
                  id="floating_password"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 ${
                    confirmPasswordError ? "border-red-500" : "border-gray-400"
                  } appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer`}
                  placeholder=" "
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <label
                  htmlFor="floating_password"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Confirm Password
                </label>
                {confirmPasswordError && (
                  <p className="text-red-500 text-sm mt-1">
                    {confirmPasswordError}
                  </p>
                )}
                {confirmPassword !== password && (
                  <p className="text-red-500 text-sm mt-1">
                    Passwords do not match
                  </p>
                )}
              </div>
            </div>
            {isFormValid() && (
              <div className="flex flex-col justify-end w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
                <button
                  type="submit"
                  className="w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                  onSubmit={handleSubmitFirstForm}
                >
                  Next
                </button>
              </div>
            )}
          </form>
          <p className="mt-10 ml-64 mb-14 text-[14px] font-medium text-[#d6d3d3]">
            Already Have An Account?{" "}
            <span
              onClick={() => {
                navigate("/");
              }}
              className="cursor-pointer text-[#f442bc]"
            >
              Login Here
            </span>
          </p>
        </div>
      )}
      {currentForm === 2 && (
        <div>
          <form className="mt-8 mb-11" onSubmit={handleSubmitFirstForm}>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <div className="relative z-10 w-5/12 mb-5 group">
                <Select
                  className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10"
                  required
                  options={countries}
                  value={selectedCountry}
                  onChange={(selectedOption) =>
                    setSelectedCountry(selectedOption)
                  }
                />
                <label className="absolute text-lg text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] ml-2">
                  Country
                </label>
              </div>
              <div className="relative z-0 w-5/12 mb-5 group">
                <input
                  type="text"
                  name="floating_companyurl"
                  id="floating_companyurl"
                  className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    companyurlError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={companyurl}
                  onChange={handleCompanyUrlChange}
                />
                <label
                  htmlFor="floating_companyurl"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    companyurlError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Your Company URL
                </label>
                {companyurlError && (
                  <p className="text-red-500 text-xs mt-1">{companyurlError}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_city"
                  id="floating_city"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    cityError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={city}
                  onChange={handleCityChange}
                />
                <label
                  htmlFor="floating_city"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    cityError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  City
                </label>
                {cityError && (
                  <p className="text-red-500 text-xs mt-1">{cityError}</p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="text"
                  name="floating_linkedin_facebook_url"
                  id="floating_linkedin_facebook_url"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    linkedinFacebookUrlError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={linkedin_facebook_url}
                  onChange={handleLinkedinFacebookUrlChange}
                />
                <label
                  htmlFor="floating_linkedin_facebook_url"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    linkedinFacebookUrlError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Your LinkedIn / Facebook URL
                </label>
                {linkedinFacebookUrlError && (
                  <p className="text-red-500 text-xs mt-1">
                    {linkedinFacebookUrlError}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 ml-auto mr-auto sm:flex-row mt-14">
              <div className="relative z-0 w-5/12 mb-5 group">
                <input
                  type="text"
                  name="floating_postalcode"
                  id="floating_postalcode"
                  className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    postalcodeError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={postalcode}
                  onChange={handlePostalcodeChange}
                />
                <label
                  htmlFor="floating_postalcode"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    postalcodeError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Zip / Postal Code
                </label>
                {postalcodeError && (
                  <p className="text-red-500 text-xs mt-1">{postalcodeError}</p>
                )}
              </div>

              <div className="relative z-10 w-5/12 mb-5 group">
                <Select
                  className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10"
                  required
                  value={socialaccount}
                  options={optionsIM}
                  onChange={handleSocialAccountChange}
                />
                <label className="absolute text-lg text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] ml-2">
                  Preffered IM Service
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                  placeholder=" "
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />

                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Address
                </label>
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                  placeholder=" "
                  value={im_Nickname}
                  onChange={(e) => setIm_Nickname(e.target.value)}
                  required
                />

                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  IM Nickname
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <button
                type="submit"
                className="w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                onClick={handleFirstBack}
              >
                Back
              </button>
              {isForm2Valid() && (
                <button
                  type="submit"
                  onClick={handleSubmitSecondForm}
                  className="w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </div>
      )}
      {currentForm === 3 && (
        <div>
          <form className="mt-8 mb-11">
            <div className="w-7/12 ml-auto mr-auto font-semibold tracking-wide mt-14">
              <label className="text-lg font-bold text-[#f442bc]">
                Specialization
              </label>
            </div>
            <div className="flex flex-row flex-wrap justify-between w-7/12 mt-4 ml-auto mr-auto">
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="Blogger/Fan Page Owner"
                  checked={Specialization === "Blogger/Fan Page Owner"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 font-semibold text-white">
                  Blogger/Fan Page Owner
                </span>
              </label>
              <label className="inline-flex items-center mt-3 ">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="Webiste/App Owner"
                  checked={Specialization === "Webiste/App Owner"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 font-semibold text-white">
                  Webiste/App Owner
                </span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="Affiliate/Media Buyer"
                  checked={Specialization === "Affiliate/Media Buyer"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 font-semibold text-white">
                  Affiliate/Media Buyer
                </span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="Media Buying Agency"
                  checked={Specialization === "Media Buying Agency"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 font-semibold text-white">
                  Media Buying Agency
                </span>
              </label>
              <label className="inline-flex items-center mt-3 ">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="Ad Network"
                  checked={Specialization === "Ad Network"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 font-semibold text-white">
                  Ad Network
                </span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="Affiliate Network"
                  checked={Specialization === "Affiliate Network"}
                  onChange={handleOptionChange}
                />
                <span className="ml-2 font-semibold text-white">
                  Affiliate Network
                </span>
              </label>
            </div>
            <div className="w-7/12 ml-auto mr-auto font-semibold tracking-wide mt-14">
              <label className="text-lg font-bold text-[#f442bc]">
                Please choose your preferred niche
              </label>
            </div>
            <div className="w-7/12 mt-4 ml-auto mr-auto font-semibold tracking-wide">
              <div className="container">
                <ul className="flex flex-row flex-wrap space-x-2 ks-cboxtags justify-evenly">
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxOnee"
                      value="Binary/Bizopp"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxOnee">Binary/Bizopp</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTwoo"
                      value="Mobile Content"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTwoo">Mobile Content</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxThreee"
                      value="Education"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxThreee">Education</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxFourr"
                      value="Download"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxFourr">Download</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxFivee"
                      value="Financial"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxFivee">Financial</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxSixx"
                      value="Other"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxSixx">Other</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxSeven"
                      value="Mobile Apps"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxSeven">Mobile Apps</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxEight"
                      value="Social Networking"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxEight">Social Networking</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxNine"
                      value="Ecommerce"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxNine">Ecommerce</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTen"
                      value="Travel"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTen">Travel</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxEleven"
                      value="Gaming"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxEleven">Gaming</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTwelve"
                      value="iGaming"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTwelve">iGaming</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxThirteen"
                      value="SmartLink"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxThirteen">SmartLink</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxFourteen"
                      value="Vouchers/Leadgen"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxFourteen">Vouchers/Leadgen</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxFifteen"
                      value="Financial-"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxFifteen">Financial-</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxSixteen"
                      value="Forex"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxSixteen">Forex</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxSeventeen"
                      value="Health"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxSeventeen">Health</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxEightteen"
                      value="Legal"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxEightteen">Legal</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxNineteen"
                      value="Survey"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxNineteen">Survey</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTwenty"
                      value="WH leadgen"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTwenty"> WH leadgen</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTwentyone"
                      value="Pay-Per-Call"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTwentyone">Pay-Per-Call</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTwentytwo"
                      value="Insurance"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTwentytwo">Insurance</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxTwentythree"
                      value="Home Improvement"
                      onChange={handle_prefferedNiche_Change}
                    />
                    <label htmlFor="checkboxTwentythree">
                      Home Improvement
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-7/12 ml-auto mr-auto font-semibold tracking-wide mt-14">
              <label className="text-lg font-bold text-[#f442bc]">
                Please pick your top vertical
              </label>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-2 ml-auto mr-auto sm:flex-row">
              <div className="relative z-10 w-full mb-5 group">
                <Select
                  className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10"
                  required
                  options={topVerticals}
                  value={topVertical}
                  onChange={(selectedOption) => setTopVertical(selectedOption)}
                />
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                  placeholder=" "
                  value={specific_offers_or_verticals}
                  onChange={(e) =>
                    setSpecific_offers_or_verticals(e.target.value)
                  }
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-base font-semibold text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Are there any specific offers or verticals you plan to launch
                  with CronoNetwork?
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 ml-auto mr-auto sm:flex-row mt-7">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  name="floating_cpa"
                  id="floating_cpa"
                  className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    cpaError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={currentCPA}
                  required
                  onChange={handleCpaChange}
                />
                <label
                  htmlFor="floating_cpa"
                  className={`peer-focus:font-medium absolute text-base font-semibold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    cpaError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  What CPA do you currently work with?
                </label>
                {cpaError && (
                  <p className="text-red-500 text-xs mt-1">{cpaError}</p>
                )}
              </div>
            </div>
            <div className="w-7/12 ml-auto mr-auto font-semibold tracking-wide mt-14">
              <label className="text-lg font-bold text-[#f442bc]">
                How much monthly revenue do you generate with other networks?
              </label>
            </div>
            <div className="flex flex-row flex-wrap justify-between w-7/12 mt-4 ml-auto mr-auto">
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="1K"
                  checked={monthlyRevenue === "1K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">1K</span>
              </label>
              <label className="inline-flex items-center mt-3 ">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="1K - 2K"
                  checked={monthlyRevenue === "1K - 2K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">1K - 2K</span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="3K - 5K"
                  checked={monthlyRevenue === "3K - 5K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">3K - 5K</span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="10K - 25K"
                  checked={monthlyRevenue === "10K - 25K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">10K - 25K</span>
              </label>
              <label className="inline-flex items-center mt-3 ">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="25K - 50K"
                  checked={monthlyRevenue === "25K - 50K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">25K - 50K</span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="50K - 100K"
                  checked={monthlyRevenue === "50K - 100K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">
                  50K - 100K
                </span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="w-4 h-4 text-blue-600 form-radio"
                  name="radio"
                  value="100K"
                  checked={monthlyRevenue === "100K"}
                  onChange={handleMonthlyRevenueChange}
                />
                <span className="ml-2 font-semibold text-white">100K</span>
              </label>
            </div>
            <div className="w-7/12 ml-auto mr-auto font-semibold tracking-wide mt-14">
              <label className="text-lg font-bold text-[#f442bc]">
                What traffic types do you generally use For promotional
                purposes?
              </label>
            </div>
            <div className="w-7/12 mt-4 ml-auto mr-auto font-semibold tracking-wide">
              <div className="container">
                <ul className="flex flex-row flex-wrap space-x-2 ks-cboxtags justify-evenly">
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxA"
                      value="Display/Banner"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxA">Display/Banner</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxB"
                      value="Native"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxB">Native</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxC"
                      value="Social"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxC">Social(FB, VK, etc)</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxD"
                      value="Pop/Direct"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxD">Pop/Direct</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxE"
                      value="Search/PPC"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxE">Search/PPC</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxF"
                      value="SEO/SEM"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxF">SEO/SEM</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxG"
                      value="Review Site/Blog"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxG">Review Site/Blog</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxH"
                      value="Email"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxH">Email</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxI"
                      value="Contextual"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxI">Contextual</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxJ"
                      value="Incent"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxJ">Incent</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxK"
                      value="Video"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxK">Video</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="checkboxL"
                      value="Other"
                      onChange={handle_Traffic_Type_Change}
                    />
                    <label htmlFor="checkboxL">Other</label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-16 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_promote_countries"
                  id="floating_promote_countries"
                  className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    promoteCountriesError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={promoteCountries}
                  required
                  onChange={handlePromoteCountriesChange}
                />
                <label
                  htmlFor="floating_promote_countries"
                  className={`peer-focus:font-medium absolute text-base font-semibold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    promoteCountriesError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  What are the top 3 countries you promote offers in?
                </label>
                {promoteCountriesError && (
                  <p className="text-red-500 text-xs mt-1">
                    {promoteCountriesError}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-between w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer"
                  placeholder=" "
                  value={companyInfo}
                  required
                  onChange={(e) => setCompanyInfo(e.target.value)}
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-base font-semibold text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Please include any other information that CronoNetwork should
                  know about you or your company
                </label>
              </div>
            </div>
            <div className="flex flex-col w-7/12 mt-10 ml-auto mr-auto sm:flex-row">
              <input
                type="checkbox"
                className=""
                value={terms}
                onChange={handleTermsChange}
              />
              <p className="ml-2 text-sm font-semibold text-white">
                By checking this box, I confirm that I have read, understand and
                agree to the{" "}
                <span className="text-blue-500 underline cursor-pointer">
                  Terms of Agreement
                </span>{" "}
                and{" "}
                <span className="text-blue-500 underline cursor-pointer">
                  Privacy Policy
                </span>
              </p>
            </div>
            <div className="flex flex-col w-7/12 mt-5 ml-auto mr-auto sm:flex-row">
              <input
                type="checkbox"
                className=""
                value={newsletter}
                onChange={handlePromoChange}
              />
              <p className="ml-2 text-sm font-semibold text-white">
                I agree to receive promo emails & newsletters from CronoNetwork
              </p>
            </div>
            <div className="flex flex-col w-7/12 mt-5 ml-auto mr-auto sm:flex-row">
              <button
                type="submit"
                className="mt-7 w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                onClick={handleSecondBack}
              >
                Back
              </button>

              {isForm3Valid() && (
                <button
                  type="submit"
                  className="mt-7 ml-auto w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                  onClick={handleThirdSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Affiliate_SignUp;
