import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Sidebar from './UserDashboard/Sidebar';
import TopBar from './UserDashboard/TopBar';
import { useParams } from "react-router-dom"; // If you are using React Router for URL parameters

const CampaignDetail = ({ userId, userFields }) => {
    const { offerId } = useParams(); // Extract offerId from the URL (if using React Router)
    const [offerData, setOfferData] = useState(null);

    // Fetch data from API based on offerId
    useEffect(() => {
        fetch(`https://apidealer.devcir.co/api/request-offers/${offerId}`) // Use the dynamic offerId
            .then((response) => response.json())
            .then((data) => setOfferData(data))
            .catch((error) => console.error("Error fetching data:", error));
    }, [offerId]); // Re-fetch data if offerId changes

    if (!offerData) {
        return <div className="text-center text-gray-600">Loading...</div>;
    }

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>



            <Sidebar />

            <div className="sm:ml-64 bg-white min-h-screen mt-7 rounded-tl-3xl">
                <TopBar />
                <div className="w-full mx-auto p-4 sm:p-6 font-sans text-gray-600">
                    {/* Header */}
                    <div className="mb-4">
                        <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">GENERAL INFORMATION</h2>
                    </div>

                    {/* Content Section */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        {/* Image Section */}
                        <div className="bg-gray-100 p-4 rounded-lg">
                            <img
                                src={offerData.imageUrl} // Display the image from the API
                                alt="Campaign Visual"
                                className="w-full rounded-lg"
                            />
                        </div>

                        {/* Info Section */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                            {/* Left Column */}
                            <div className="space-y-2">
                                <p className="break-words whitespace-normal"><span className="font-semibold">Price format:</span> {offerData.price_format}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Vertical:</span> {offerData.vertical}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Flow:</span> {offerData.flow}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Platforms:</span> {offerData.platform}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Tracking type:</span> {offerData.tracking_type}</p>
                            </div>

                            {/* Right Column */}
                            <div className="space-y-2">
                                <p className="break-words whitespace-normal"><span className="font-semibold">Media types allowed:</span> {offerData.media_types_allowed}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Restricted media types:</span> {offerData.media_types_restricted}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Target demo:</span> {offerData.age}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">3G/Wi-Fi:</span> {offerData["3g_wifi"]}</p>
                                <p className="break-words whitespace-normal"><span className="font-semibold">Adult traffic:</span> {offerData.adult_traffic}</p>
                            </div>
                        </div>
                    </div>

                    {/* Flags Section */}
                    <div className="mb-6">
                        <h3 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">Allowed countries:</h3>
                        <div className="flex flex-wrap gap-2">
                            {/* Display country flags or country names */}
                            {offerData.country.split(",").map((country, index) => (
                                <div key={index} className="border px-2 py-1 rounded-md text-xs sm:text-sm">
                                    {country}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Description Section */}
                    <div className="p-4 bg-gray-50 rounded-lg border">
                        <p><span className="font-semibold">Description:</span> {offerData.info_share}</p>
                        <p><span className="font-semibold">Available for:</span> {offerData.platform}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(CampaignDetail);