import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import Chart from "chart.js";
import axios from 'axios';
import Arrow from '../../../assets/images/Arrow 1.svg'
import Arrows from '../../../assets/images/Arrow 7.svg'
import OfferIcn from '../../../assets/images/image 17.svg'
import settingIcn from '../../../assets/images/image 55.svg'
import categIcn from '../../../assets/images/image 56.svg'

const RotationSummary = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;


    const [showlessdata, setShowlessdata] = useState(true);
    const [newOffers, setNewOffers] = useState([]);
    const [filterOpen, setFilterOpen] = useState(true);

    const [filters, setFilters] = useState({
        country: '',
        platform: '',
        vertical: '',
        priceFormat: '',
        payout: '',
        flow: '',
        trackingType: '',
        mediaTypesAllowed: '',
        mediaTypesRestricted: '',
        wifi: '',
        incentivizedPromotion: '',
        postLeadsViaAPI: '',
    });


    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };


    const [columnVisibility, setColumnVisibility] = useState({
        Rotations: true,
        Clicks: true,
        TotalClicks: true,
        Consversions: true,
        Events: true,
        Revenue: true,
        CR: true,
        Epc: true,
    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://apidealer.devcir.co/api/rotation-summary');
                const data = response.data;
                setNewOffers(response.data);

                // Prepare data for the chart
                const labels = data.map(item => item.Rotations);
                const clicksData = data.map(item => item.Clicks);
                const totalClicksData = data.map(item => item.Revenue);

                const config = {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Revenue',
                                backgroundColor: '#3182ce',
                                borderColor: '#3182ce',
                                data: clicksData,
                                fill: false,
                            },
                            {
                                label: 'Rotations',
                                fill: false,
                                backgroundColor: '#ed64a6',
                                borderColor: '#ed64a6',
                                data: totalClicksData,
                            },
                        ],
                    },
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        title: {
                            display: false,
                            text: 'Daily Summary Charts',
                            fontColor: 'black',
                        },
                        legend: {
                            labels: {
                                fontColor: 'black',
                            },
                            align: 'end',
                            position: 'bottom',
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true,
                        },
                        scales: {
                            xAxes: [
                                {
                                    ticks: {
                                        fontColor: 'black',
                                    },
                                    display: true,
                                    scaleLabel: {
                                        display: false,
                                        labelString: 'Date',
                                        fontColor: 'black',
                                    },
                                    gridLines: {
                                        display: false,
                                        borderDash: [2],
                                        borderDashOffset: [2],
                                        color: 'rgba(33, 37, 41, 0.3)',
                                        zeroLineColor: 'rgba(0, 0, 0, 0)',
                                        zeroLineBorderDash: [2],
                                        zeroLineBorderDashOffset: [2],
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        fontColor: 'black',
                                    },
                                    display: true,
                                    scaleLabel: {
                                        display: false,
                                        labelString: 'Value',
                                        fontColor: 'black',
                                    },
                                    gridLines: {
                                        borderDash: [3],
                                        borderDashOffset: [3],
                                        drawBorder: false,
                                        color: 'black',
                                        zeroLineColor: 'black',
                                        zeroLineBorderDash: [2],
                                        zeroLineBorderDashOffset: [2],
                                    },
                                },
                            ],
                        },
                    },
                };

                var ctx = document.getElementById('line-chart').getContext('2d');
                new Chart(ctx, config);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [country, setCountry] = useState('');
    const [platform, setPlatform] = useState('');
    const [vertical, setVertical] = useState('');
    const [priceFormat, setPriceFormat] = useState('');
    const [status, setStatus] = useState('');
    const [minPayout, setMinPayout] = useState('');
    const [maxPayout, setMaxPayout] = useState('');
    const [shownBy, setShownBy] = useState('');

    const countryOptions = ['USA', 'Canada', 'UK', 'Australia'];
    const platformOptions = ['Windows', 'Mac', 'Linux', 'Mobile'];
    const verticalOptions = ['Tech', 'Finance', 'Health', 'Education'];
    const priceFormatOptions = ['CPC', 'CPM', 'CPA'];
    const statusOptions = ['Active', 'Inactive', 'Pending'];

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            
            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
                <TopBar />
                <div className='mt-6 bg-white flex justify-between'>
                    <label className='text-[24px] text-[#000000] font-semibold tracking-wider ml-5'>ROTATION SUMMARY</label>
                    <button onClick={() => { setFilterOpen(!filterOpen) }} className='bg-[#EFEFEF] w-24 h-6 rounded-full mr-5 flex flex-row space-x-1 shadow-gray-400 shadow-md'>
                        <img src={Arrow} alt="" className='mt-[6px] ml-6' />
                        <span className="text-black text-[14px] font-semibold">{(!filterOpen) ? "Filter" : "Hide"}</span>
                    </button>
                </div>

                {/* {showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-4 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Rotations:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600'>Apply</button>
                    </div>
                )} */}

                {filterOpen && (
                    <div className="flex items-center w-full justify-center mt-5 mb-10">
                        <div className="flex flex-col p-4 w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
                            <div className="flex flex-row space-x-14 ml-16 mb-4 mt-10">
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Country</label>
                                    <select
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">Select</option>
                                        {countryOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Platform</label>
                                    <select
                                        value={platform}
                                        onChange={(e) => setPlatform(e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {platformOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Vertical</label>
                                    <select
                                        value={vertical}
                                        onChange={(e) => setVertical(e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {verticalOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row space-x-14 ml-12 mt-8 mb-4">
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Price Format</label>
                                    <select
                                        value={priceFormat}
                                        onChange={(e) => setPriceFormat(e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {priceFormatOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Status</label>
                                    <select
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {statusOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Payout</label>
                                    <div className="flex space-x-2">
                                        <input
                                            type="number"
                                            placeholder="Min"
                                            value={minPayout}
                                            onChange={(e) => setMinPayout(e.target.value)}
                                            className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                                        />
                                        <input
                                            type="number"
                                            placeholder="Max"
                                            value={maxPayout}
                                            onChange={(e) => setMaxPayout(e.target.value)}
                                            className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3 ml-12 mt-8">
                                <label htmlFor="myVerticals" className="text-gray-700">My Verticals only</label>
                                <input type="checkbox" id="myVerticals" className="mr-2" />
                            </div>
                            <div className="flex justify-end space-x-4 mt-8 mb-8 mr-10">
                                <button className="bg-[#58165C66] flex space-x-2 bg-opacity-40 text-xs text-[#FFFFFF] p-2 items-center rounded-md w-32 h-10 shadow-gray-400 shadow-md">
                                    <img src={Arrows} alt="" />
                                    <h1>Extended Search</h1>
                                </button>
                                <button className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">Apply</button>
                                <button className="bg-[#315EFF] bg-opacity-40 text-[#0B31FB] p-2 items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">Clear</button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex items-center w-full justify-center mt-5 mb-10">
                    <div className="flex flex-col p-4 w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
                        <div className="rounded-t mb-0 px-14 py-3 bg-transparent">
                            <div className="flex flex-wrap items-center">
                                <div className="w-full max-w-full flex-grow flex-1">
                                    <h6 className="uppercase text-Gray-100 mb-1 text-xs text-black font-semibold">
                                        Overview
                                    </h6>
                                    <h2 className="text-black text-xl font-semibold">Report</h2>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 flex-auto">
                            {/* Chart */}
                            <div className="h-56">
                                <canvas id="line-chart"></canvas>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}


                <div className="flex items-center w-full justify-center mt-5 mb-10">
                    <div className="flex flex-col w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-14">
                        <div className="mx-auto w-full">
                            <div className="flex justify-between items-center mb-4 mt-4 border-b-2 border-gray-200">
                                <div className="flex flex-row space-x-2 ml-5 mb-2">
                                    <img src={OfferIcn} alt="Icn" width={'40px'} height={'40px'} />
                                    <h1 className="text-2xl font-bold text-[#000000] text-[20px] mt-2">Offers</h1>
                                </div>
                                <div className="flex items-center space-x-4 mr-5 mb-2">
                                    <img src={settingIcn} alt="" width={'34px'} height={'34px'} />
                                    <img src={categIcn} alt="" width={'34px'} height={'34px'} />
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="border-t-[3px] border-[#58165C] rounded-full h-9 w-40 px-2 py-1 border-b-2 border-b-black"
                                            placeholder="Search"
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <svg className="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="text-xs w-11/12 ml-11 mb-8">
                                <colgroup>
                                    <col className="text-[10px]" style={{ display: columnVisibility.Rotations ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Visits ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.TotalVisits ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Clicks ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.TotalClicks ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Consversions ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Events ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Revenue ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.CR ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Epc ? 'table-cell' : 'none' }} />
                                </colgroup>

                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.Rotations && <th className="p-[2px] w-28 bg-[#58165C66] border-r-[2px] border-[#681d64] text-black rounded-l-full">Rotations</th>}
                                        {columnVisibility.Clicks && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">Clicks</th>}
                                        {columnVisibility.TotalClicks && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">Total Clicks</th>}
                                        {columnVisibility.Consversions && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">Conversions</th>}
                                        {columnVisibility.Events && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">Events</th>}
                                        {columnVisibility.Revenue && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">Revenue, €</th>}
                                        {columnVisibility.CR && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">CR (Unique/Total),%</th>}
                                        {columnVisibility.Epc && <th className="p-[2px] bg-[#58165C66] text-black rounded-r-full">EPC (Unique/Total),€</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {newOffers.map((offer) => (
                                        <tr
                                            key={offer.id}
                                            className="text-center shadow-xl rounded-r-xl rounded-l-xl h-20"
                                        >
                                            {columnVisibility.Rotations && <td className="p-3 text-black border-r-2 border-gray-200">{offer.Rotations}</td>}
                                            {columnVisibility.Clicks && <td className="p-3 text-black border-r-2 border-gray-200">{offer.Clicks}</td>}
                                            {columnVisibility.TotalClicks && <td className="p-3 text-black border-r-2 border-gray-200">{offer.TotalClicks}</td>}
                                            {columnVisibility.Consversions && <td className="p-3 text-black border-r-2 border-gray-200">{offer.Conversions}</td>}
                                            {columnVisibility.Events && <td className="p-3 text-black border-r-2 border-gray-200">{offer.Events}</td>}
                                            {columnVisibility.Revenue && <td className="p-3 text-black border-r-2 border-gray-200">{`$ ${offer.Revenue}`}</td>}
                                            {columnVisibility.CR && <td className="p-3 text-black border-r-2 border-gray-200">{`${offer.CR} %`}</td>}
                                            {columnVisibility.Epc && <td className="p-3 text-black border-r-2 border-gray-200">{`$ ${offer.Epc}`}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(RotationSummary)