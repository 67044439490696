import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';

const Dataget = () => {
  const [requestOffers, setRequestOffers] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [userNames, setUserNames] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://apidealer.devcir.co/api/request-offers');
        // const response = await fetch('https://apidealer.devcir.co/api/request-offers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredData = data.filter(item => item.status === "Pending");
        setRequestOffers(filteredData);
        fetchUserNames(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const fetchUserNames = async (offers) => {
    try {
      const responses = await Promise.all(
        offers.map(offer => {
          if (offer.userField === "Agency") {
            return axios.get(`https://apidealer.devcir.co/api/agency-registrations/${offer.userid}`);
          } else if (offer.userField === "Affiliate") {
            return axios.get(`https://apidealer.devcir.co/api/affiliate-registrations/${offer.userid}`);
          }
          return Promise.resolve(null); 
        })
      );
      const userNamesData = {};
      responses.forEach((response, index) => {
        if (response) {
          userNamesData[offers[index].userid] = response.data.firstname; 
        } else {
          userNamesData[offers[index].userid] = "Unknown";
        }
      });
      setUserNames(userNamesData);
      console.log(userNames)
    } catch (error) {
      console.error('Error fetching user names:', error);
    }
  };

  const handleStatusChange = async () => {
    console.log(newStatus)
    console.log(selectedOfferId)
    try {
      await axios.put(`https://apidealer.devcir.co/api/request-offers/${selectedOfferId}/update-status`, { status: newStatus });
      // await axios.put(`https://apidealer.devcir.co/api/request-offers/${selectedOfferId}/update-status`, { status: newStatus });
      setRequestOffers(prevOffers => {
        return prevOffers.map(offer => {
          if (offer.id === selectedOfferId) {
            return { ...offer, status: newStatus };
          }
          return offer;
        });
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleRowClick = (selectedOption, offer) => {
    setSelectedOfferId(offer.id);
    setNewStatus(selectedOption.label);
  };

  const statusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" }
  ];

  return (
    <div>
      <h1 className='flex justify-center text-5xl font-semibold uppercase mt-2 mb-5'>Requested Offers</h1>
      <table className="text-xs w-11/12 ml-11 mb-8">
        <thead className="">
          <tr className="text-center">
            <th className="p-[4px] border-r-[2px] bg-slate-400 text-white rounded-l-full">Campaign Name</th>
            <th className="p-[4px] border-r-[2px] bg-slate-400 text-white">User Name</th>
            <th className="p-[4px] border-r-[2px] bg-slate-400 text-white">User Id</th>
            <th className="p-[4px] border-r-[2px] bg-slate-400 text-white">User Field</th>
            <th className="p-[4px] border-r-[2px] bg-slate-400 text-white">Status</th>
            <th className="p-[4px] border-r-[2px] bg-slate-400 text-white">Change Status</th>
            <th className="p-[4px] bg-slate-400 text-white rounded-r-full">Update</th>
          </tr>
        </thead>
        <tbody>
          {requestOffers.map((offer) => (
            <tr key={offer.id} className="text-center shadow-xl rounded-r-xl rounded-l-xl h-20">
              <td className="p-3 text-white border-r-2 border-gray-200">{offer.campaign_name}</td>
              <td className="p-3 text-white border-r-2 border-gray-200">{userNames[offer.userid]}</td>
              <td className="p-3 text-white border-r-2 border-gray-200">{offer.userid}</td>
              <td className="p-3 text-white border-r-2 border-gray-200">{offer.userField}</td>
              <td className="p-3 text-white border-r-2 border-gray-200">{offer.status}</td>
              <td className="p-3 text-white border-r-2 border-gray-200">
                <Select
                  options={statusOptions}
                  onChange={(selectedOption) => handleRowClick(selectedOption, offer)}
                  value={statusOptions.find(option => option.label === offer.status)}
                  className='text-black'
                />
              </td>
              <td>
                <button onClick={handleStatusChange} className='text-black bg-blue-500 p-2 rounded-md hover:text-white hover:bg-blue-700 font-bold'>Update Status</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dataget;
