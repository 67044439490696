import React from "react";
import logo from "../assets/images/Logo.png";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// Becrypting the password //

import bcrypt from "bcryptjs";

export const Agency_SignUp = () => {
  const navigate = useNavigate();

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const [countries, setCountries] = useState([]);

  const [firstname, setFirstname] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const validateFirstname = (value) => {
    // Regex for validating a name (only letters and spaces allowed)
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(value);
  };
  const handleFirstnameChange = (e) => {
    const value = e.target.value;
    if (validateFirstname(value)) {
      setFirstnameError("");
    } else {
      setFirstnameError(
        "Please enter a valid first name (letters and spaces only)."
      );
    }
    setFirstname(value);
  };

  const [selectedCountry, setSelectedCountry] = useState({});

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const validateLastName = (value) => {
    // Regex for validating a name (only letters and spaces allowed)
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(value);
  };
  const handleLastNameChange = (e) => {
    const value = e.target.value;
    if (validateLastName(value)) {
      setLastNameError("");
    } else {
      setLastNameError(
        "Please enter a valid last name (letters and spaces only)."
      );
    }
    setLastName(value);
  };

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const validateCity = (value) => {
    // Regex for validating a city name (only letters and spaces allowed)
    const cityRegex = /^[a-zA-Z\s]+$/;
    return cityRegex.test(value);
  };
  const handleCityChange = (e) => {
    const value = e.target.value;
    if (validateCity(value)) {
      setCityError("");
    } else {
      setCityError("Please enter a valid city name (letters and spaces only).");
    }
    setCity(value);
  };

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const validateCompanyName = (value) => {
    // Regex for validating a company name (letters, numbers, and spaces allowed)
    const companyNameRegex = /^[a-zA-Z0-9\s]+$/;
    return companyNameRegex.test(value);
  };
  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    if (validateCompanyName(value)) {
      setCompanyNameError("");
    } else {
      setCompanyNameError(
        "Please enter a valid company name (letters, numbers, and spaces only)."
      );
    }
    setCompanyName(value);
  };

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const validateAddress = (value) => {
    // Regex for validating an address (letters, numbers, spaces, commas, periods, and hyphens allowed)
    const addressRegex = /^[a-zA-Z0-9\s,.-]+$/;
    return addressRegex.test(value);
  };
  const handleAddressChange = (e) => {
    const value = e.target.value;
    if (validateAddress(value)) {
      setAddressError("");
    } else {
      setAddressError(
        "Please enter a valid address (letters, numbers, spaces, commas, periods, and hyphens only)."
      );
    }
    setAddress(value);
  };

  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyWebsiteError, setCompanyWebsiteError] = useState("");
  const validateCompanyWebsite = (value) => {
    // Regex for validating a URL
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    return urlRegex.test(value);
  };
  const handleCompanyWebsiteChange = (e) => {
    const value = e.target.value;
    if (validateCompanyWebsite(value)) {
      setCompanyWebsiteError("");
    } else {
      setCompanyWebsiteError("Please enter a valid website URL.");
    }
    setCompanyWebsite(value);
  };

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const validatePostalCode = (value) => {
    // Simple regex for validating a postal code (adjust as needed)
    const postalCodeRegex = /^[A-Za-z0-9\s-]{3,10}$/;
    return postalCodeRegex.test(value);
  };
  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    if (validatePostalCode(value)) {
      setPostalCodeError("");
    } else {
      setPostalCodeError("Please enter a valid postal code.");
    }
    setPostalCode(value);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const validateEmail = (value) => {
    // Simple regex for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (validateEmail(value)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
    setEmail(value);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\+\d{7,15}$/;
    return phoneRegex.test(value);
  };
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (validatePhoneNumber(value)) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError("Please enter a valid phone number.");
    }
    setPhoneNumber(value);
  };

  const [linkedinURL, setLinkedinURL] = useState("");
  const [linkedinURLError, setLinkedinURLError] = useState("");
  const validateLinkedinURL = (value) => {
    // Regex to validate LinkedIn URL format
    const urlRegex = /^(https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/.*$/;
    return urlRegex.test(value);
  };
  const handleLinkedinURLChange = (e) => {
    const value = e.target.value;
    if (validateLinkedinURL(value)) {
      setLinkedinURLError("");
    } else {
      setLinkedinURLError("Please enter a valid LinkedIn URL.");
    }
    setLinkedinURL(value);
  };

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const validateConfirmPasswordStrength = (password) => {
    const strongConfirmPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&\-+_=])[A-Za-z\d@$!%*?&\-+_=]{8,}$/;
    if (!strongConfirmPasswordRegex.test(password)) {
      setConfirmPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setConfirmPasswordError("");
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validateConfirmPasswordStrength(newConfirmPassword);
  };

  const [passwordError, setPasswordError] = useState("");
  const validatePasswordStrength = (password) => {
    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&\-+_=])[A-Za-z\d@$!%*?&\-+_=]{8,}$/;
    if (!strongPasswordRegex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setPasswordError("");
    }
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePasswordStrength(newPassword);
  };

  const [showSecondForm, setShowSecondForm] = useState(false);
  const [type, setType] = useState("");

  const handleRadioChange = (event) => {
    setType(event.target.value);
  };

  // Form 2 Working

  const [selectedNiche, setSelectedNiche] = useState("");
  const [topVertical, setTopVertical] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [selectedTrafficType, setSelectedTrafficType] = useState("");
  const [otherMarketingCompanies, setOtherMarketingCompanies] = useState("");
  const [selectedBudget, setSelectedBudget] = useState(0);
  const [otherInformation, setOtherInformation] = useState("");
  const [agreementChecked, setAgreementChecked] = useState(false);

  const isFormValid = () => {
    // Check that all required fields are filled and the agreement checkbox is checked
    return (
      selectedNiche !== "" &&
      topVertical !== "" &&
      productUrl !== "" &&
      selectedTrafficType !== "" &&
      otherMarketingCompanies !== "" &&
      selectedBudget !== 0 &&
      otherInformation !== "" &&
      agreementChecked
    );
  };

  const handle_Data_form2 = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      console.log("Form is valid and ready for submission");
    } else {
      console.log("Please fill all the fields and agree to the terms.");
    }
  };

  const handleCheckboxChange = () => {
    setAgreementChecked(!agreementChecked);
  };

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);

  useEffect(() => {
    const fieldsFilled =
      firstname !== "" &&
      lastName !== "" &&
      type !== "" &&
      city !== "" &&
      companyName !== "" &&
      address !== "" &&
      companyWebsite !== "" &&
      postalCode !== "" &&
      email !== "" &&
      phoneNumber !== "" &&
      linkedinURL !== "" &&
      confirmPassword !== "" &&
      password !== "" &&
      lastNameError === "" &&
      cityError === "" &&
      companyNameError === "" &&
      addressError === "" &&
      companyWebsiteError === "" &&
      postalCodeError === "" &&
      emailError === "" &&
      phoneNumberError === "" &&
      linkedinURLError === "" &&
      password == confirmPassword &&
      firstnameError === "";

    setAllFieldsFilled(fieldsFilled);
  }, [
    firstname,
    lastName,
    city,
    companyName,
    address,
    companyWebsite,
    postalCode,
    email,
    phoneNumber,
    linkedinURL,
    password,
    confirmPassword,
  ]);

  const handleSubmitFirstForm = async (e) => {
    e.preventDefault();
    setShowSecondForm(true);

    console.log("First Form Submitted Successfully");
  };

  const handleSubmitSecondForm = (e) => {
    e.preventDefault();
    // Handle submission of second form here
  };

  const handleBack = (e) => {
    e.preventDefault();
    setShowSecondForm(false);
  };

  function generateUniqueCode(length = 5) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const { uniqueKey } = useParams();

  // const handle_Data = async (e) => {

  //     e.preventDefault();

  //     let key = uniqueKey || "a";

  //     const hashedPassword = await bcrypt.hash(password, 7);
  //     const refCode = generateUniqueCode();

  //     const response = await fetch('https://apidealer.devcir.co/api/agency_registrations', {
  //         // const response = await fetch('https://apidealer.devcir.co/api/agency_registrations', {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({

  //             firstname: firstname,
  //             lastname: lastName,
  //             type: type,
  //             country: selectedCountry.label,
  //             city: city,
  //             company_name: companyName,
  //             address: address,
  //             company_website: companyWebsite,
  //             postal_code: postalCode,
  //             email: email,
  //             phone_number: phoneNumber,
  //             linkedin_url: linkedinURL,
  //             password: hashedPassword,
  //             selected_niche: selectedNiche,
  //             top_vertical: topVertical,
  //             product_url: productUrl,
  //             selected_traffic_type: selectedTrafficType,
  //             other_marketing_companies: otherMarketingCompanies,
  //             selected_budget: selectedBudget,
  //             other_information: otherInformation,
  //             code: refCode,
  //             referral: key,
  //         }),
  //     });

  //     if (!response.ok) {
  //         console.error(`Server responded with status ${response.status}: ${response.statusText}`);
  //         return;
  //     }

  //     try {
  //         alert("Successfully Registered");
  //         const data = await response.json();
  //         console.log(data);
  //         navigate('/AgencyLogin');
  //     } catch (error) {
  //         console.error('Error parsing server response:', error);
  //         alert("Error Occured While Registering");
  //     }

  // }

  const handle_Data = async (e) => {
    e.preventDefault();

    let key = uniqueKey || "a";

    const hashedPassword = await bcrypt.hash(password, 7);
    const refCode = generateUniqueCode();

    const response = await fetch(
      "https://apidealer.devcir.co/api/agency_registrations",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastName,
          type: type,
          country: selectedCountry.label,
          city: city,
          company_name: companyName,
          address: address,
          company_website: companyWebsite,
          postal_code: postalCode,
          email: email,
          phone_number: phoneNumber,
          linkedin_url: linkedinURL,
          password: hashedPassword,
          selected_niche: selectedNiche,
          top_vertical: topVertical,
          product_url: productUrl,
          selected_traffic_type: selectedTrafficType,
          other_marketing_companies: otherMarketingCompanies,
          selected_budget: selectedBudget,
          other_information: otherInformation,
          code: refCode,
          referral: key,
        }),
      }
    );

    try {
      if (response.ok) {
        const data = await response.json();
        console.log("Registration response:", data);

        const userId = data.registration.id;
        if (!userId) {
          console.error("User ID not found in registration response");
          alert(
            "Registration successful, but user ID is missing. Unable to create payout."
          );
          return;
        }

        const payload2 = {
          user_id: userId,
          user_field: "Agency",
        };

        console.log("Payout payload:", payload2);

        const payoutResponse = await fetch(
          "https://apidealer.devcir.co/api/payouts",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload2),
          }
        );

        console.log("Payout response status:", payoutResponse.status);

        if (payoutResponse.ok) {
          const payoutData = await payoutResponse.json();
          console.log("Payout response data:", payoutData);
          alert("Successfully Registered and Payout Data Saved");
        } else {
          const errorData = await payoutResponse.json();
          console.error("Error in Payout API response:", errorData);
          alert(
            "Registration Successful, but an error occurred while saving Payout data"
          );
          console.error(
            "Error in Payout API response:",
            await payoutResponse.json()
          );
        }

        navigate("/");
      } else {
        const errorData = await response.json();
        console.error("Error in Registration API response:", errorData);
        alert(
          `Error Occurred While Registering: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error in request:", error);
      alert(`Error Occurred: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col mt-4 mb-4">
      <div className="flex flex-row">
        <div>
          <img
            src={logo}
            alt="Logo"
            className="mt-4"
            height={"240px"}
            width={"240px"}
          />
        </div>
        <div className="mt-14">
          <label className="flex text-[32px] font-bold ml-60 text-white">
            Advertiser And Agency Sign Up
          </label>
          <hr className="ml-56 w-[465px] h-1 bg-[#f442bc]" />
          <p className="mt-14 ml-32 text-[#d6d3d3] text-[16px] font-medium">
            We are one of the most well-established, multi-national agencies for
            top advertisers and brands.
          </p>
          <p className="ml-14 text-[#d6d3d3] text-[16px] font-medium ">
            We've developed relationships with major publishers and media buyers
            to ensure quality traffic volume that converts.{" "}
          </p>
          <p className="ml-[360px] text-[#d6d3d3] text-[16px] font-medium">
            Join to grow and succeed online!
          </p>
        </div>
      </div>
      {!showSecondForm ? (
        <div>
          <form className="mt-8" onSubmit={handleSubmitFirstForm}>
            {/* <-- RADIO BUTTONS -->  */}
            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-evenly mt-10">
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="form-radio h-4 w-4"
                  name="radio"
                  value="advertiser"
                  checked={type === "advertiser"}
                  onChange={handleRadioChange}
                />
                <span className="ml-2 text-[#f442bc] font-semibold">
                  Advertiser
                </span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="form-radio h-4 w-4"
                  name="radio"
                  value="agency"
                  checked={type === "agency"}
                  onChange={handleRadioChange}
                />
                <span className="ml-2 text-[#f442bc] font-semibold">
                  Agency
                </span>
              </label>
              <label className="inline-flex items-center mt-3">
                <input
                  type="radio"
                  className="form-radio h-4 w-4"
                  name="radio"
                  value="network"
                  checked={type === "network"}
                  onChange={handleRadioChange}
                />
                <span className="ml-2 text-[#f442bc] font-semibold">
                  Network
                </span>
              </label>
            </div>
            <br />
            {/* <-- INPUT FIELDS -->  */}
            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
              <div className="relative z-0 w-5/12 mb-5 group">
                <input
                  type="text"
                  name="floating_firstName"
                  id="floating_firstName"
                  className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-white font-semibold focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    firstnameError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={firstname}
                  required
                  onChange={handleFirstnameChange}
                />
                <label
                  htmlFor="floating_firstName"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    firstnameError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  First Name
                </label>
                {firstnameError && (
                  <p className="text-red-500 text-xs mt-1">{firstnameError}</p>
                )}
              </div>

              <div className="relative z-10 w-5/12 mb-5 group">
                <Select
                  className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10"
                  required
                  options={countries}
                  value={selectedCountry}
                  onChange={(selectedOption) =>
                    setSelectedCountry(selectedOption)
                  }
                />
                <label className="absolute text-lg text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] ml-2">
                  Country
                </label>
              </div>
            </div>

            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-4">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_LastName"
                  id="floating_LastName"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    lastNameError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={lastName}
                  required
                  onChange={handleLastNameChange}
                />
                <label
                  htmlFor="floating_LastName"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    lastNameError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Last Name
                </label>
                {lastNameError && (
                  <p className="text-red-500 text-xs mt-1">{lastNameError}</p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="text"
                  name="floating_City"
                  id="floating_City"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    cityError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={city}
                  required
                  onChange={handleCityChange}
                />
                <label
                  htmlFor="floating_City"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    cityError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  City
                </label>
                {cityError && (
                  <p className="text-red-500 text-xs mt-1">{cityError}</p>
                )}
              </div>
            </div>

            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_CompanyName"
                  id="floating_CompanyName"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    companyNameError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={companyName}
                  required
                  onChange={handleCompanyNameChange}
                />
                <label
                  htmlFor="floating_CompanyName"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    companyNameError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Company Name
                </label>
                {companyNameError && (
                  <p className="text-red-500 text-xs mt-1">
                    {companyNameError}
                  </p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="text"
                  name="floating_address"
                  id="floating_address"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    addressError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={address}
                  required
                  onChange={handleAddressChange}
                />
                <label
                  htmlFor="floating_address"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    addressError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Address
                </label>
                {addressError && (
                  <p className="text-red-500 text-xs mt-1">{addressError}</p>
                )}
              </div>
            </div>

            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_website"
                  id="floating_website"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    companyWebsiteError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={companyWebsite}
                  required
                  onChange={handleCompanyWebsiteChange}
                />
                <label
                  htmlFor="floating_website"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    companyWebsiteError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Company Website
                </label>
                {companyWebsiteError && (
                  <p className="text-red-500 text-xs mt-1">
                    {companyWebsiteError}
                  </p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="text"
                  name="floating_PostalCode"
                  id="floating_PostalCode"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 ${
                    postalCodeError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  } peer`}
                  placeholder=" "
                  value={postalCode}
                  required
                  onChange={handlePostalCodeChange}
                />
                <label
                  htmlFor="floating_PostalCode"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    postalCodeError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  ZIP / Postal Code
                </label>
                {postalCodeError && (
                  <p className="text-red-500 text-xs mt-1">{postalCodeError}</p>
                )}
              </div>
            </div>

            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="email"
                  name="floating_email"
                  id="floating_email"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    emailError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                <label
                  htmlFor="floating_email"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    emailError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Email Address
                </label>
                {emailError && (
                  <p className="text-red-500 text-xs mt-1">{emailError}</p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="text"
                  name="floating_phoneNumber"
                  id="floating_phoneNumber"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    phoneNumberError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={phoneNumber}
                  required
                  onChange={handlePhoneNumberChange}
                />
                <label
                  htmlFor="floating_phoneNumber"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    phoneNumberError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Phone Number
                </label>
                {phoneNumberError && (
                  <p className="text-red-500 text-xs mt-1">
                    {phoneNumberError}
                  </p>
                )}
              </div>
            </div>

            <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
              <div className="relative z-0 w-5/12 group ">
                <input
                  type="text"
                  name="floating_linkedin"
                  id="floating_linkedin"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    linkedinURLError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={linkedinURL}
                  required
                  onChange={handleLinkedinURLChange}
                />
                <label
                  htmlFor="floating_linkedin"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    linkedinURLError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  LinkedIn URL
                </label>
                {linkedinURLError && (
                  <p className="text-red-500 text-xs mt-1">
                    {linkedinURLError}
                  </p>
                )}
              </div>

              <div className="relative z-0 w-5/12 mb-3 group ">
                <input
                  type="password"
                  name="floating_password"
                  id="floating_password"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer ${
                    passwordError
                      ? "border-red-500 focus:border-red-500"
                      : "border-gray-400 focus:border-[#f442bc]"
                  }`}
                  placeholder=" "
                  value={password}
                  required
                  onChange={handlePasswordChange}
                />
                <label
                  htmlFor="floating_password"
                  className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${
                    passwordError
                      ? "text-red-500 peer-focus:text-red-500"
                      : "text-[#f442bc] peer-focus:text-[#f442bc]"
                  }`}
                >
                  Password
                </label>
                {passwordError && (
                  <p className="text-red-500 text-xs mt-1">{passwordError}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col justify-end w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
              <div className="relative z-0 w-5/12 mb-3 group">
                <input
                  type="password"
                  name="floating_password"
                  id="floating_password"
                  className={`block py-2.5 px-0 w-full text-sm text-white font-semibold bg-transparent border-0 border-b-2 ${
                    confirmPasswordError ? "border-red-500" : "border-gray-400"
                  } appearance-none focus:outline-none focus:ring-0 focus:border-[#f442bc] peer`}
                  placeholder=" "
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <label
                  htmlFor="floating_password"
                  className="peer-focus:font-medium absolute text-sm text-[#f442bc] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#f442bc] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Confirm Password
                </label>
                {confirmPasswordError && (
                  <p className="text-red-500 text-sm mt-1">
                    {confirmPasswordError}
                  </p>
                )}
                {confirmPassword !== password && (
                  <p className="text-red-500 text-sm mt-1">
                    Passwords do not match
                  </p>
                )}
              </div>
            </div>
            <br />

            {allFieldsFilled && (
              <div className="flex flex-col justify-end w-7/12 mt-4 ml-auto mr-auto sm:flex-row">
                <button
                  type="submit"
                  className="ml-[760px] w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                >
                  Next
                </button>
              </div>
            )}
          </form>
          <p className="mt-10 ml-64 mb-14 text-[14px] font-medium text-[#d6d3d3]">
            Already Have An Account?{" "}
            <span className="cursor-pointer text-[#f442bc]">Sign Up Here</span>
          </p>
        </div>
      ) : (
        <div className="flex w-[800px] ml-80 mt-14 font-semibold tracking-wide">
          <form className="flex flex-col mb-10" onSubmit={handle_Data}>
            <label className="font-bold text-[#f442bc]">
              What market niche do(es) your product(s)/service(s) belong to?
            </label>
            <select
              name="cars"
              id="cars"
              className="mt-5 w-[800px] bg-transparent text-gray-400 border-b-2 border-gray-500 font-semibold outline-none"
              onChange={(e) => setSelectedNiche(e.target.value)}
              value={selectedNiche}
            >
              <option value="" className="font-semibold text-black">
                Select Niche
              </option>
              <option
                value="Freelance Services"
                className="font-semibold text-black"
              >
                Freelance Services
              </option>
              <option
                value="Subscription Boxes"
                className="font-semibold text-black"
              >
                Subscription Boxes
              </option>
              <option
                value="Gaming Accessories"
                className="font-semibold text-black"
              >
                Gaming Accessories
              </option>
              <option
                value="Online Courses"
                className="font-semibold text-black"
              >
                Online Courses
              </option>
            </select>
            <label className="flex font-bold text-[#f442bc] mt-7">
              Please pick your top Vetical
            </label>
            <input
              className="mt-5 font-semibold text-white bg-transparent border-b-2 border-gray-500 outline-none"
              placeholder="Enter the Top Vertical"
              onChange={(e) => setTopVertical(e.target.value)}
              value={topVertical}
            />
            <label className="flex font-bold text-[#f442bc] mt-7">
              What is the name of the product(s) you would like us to promote
              for you?
            </label>
            <input
              className="mt-5 font-semibold text-white bg-transparent border-b-2 border-gray-500 outline-none"
              placeholder="Please Provide A Sample URL"
              onChange={(e) => setProductUrl(e.target.value)}
              value={productUrl}
            />
            <label className="flex font-bold text-[#f442bc] mt-7">
              What type of traffic are you looking for?
            </label>
            <select
              name="cars"
              id="cars"
              className="mt-5 w-[800px] bg-transparent text-[#d6d3d3] border-b-2 border-gray-500 font-semibold outline-none"
              onChange={(e) => setSelectedTrafficType(e.target.value)}
              value={selectedTrafficType}
            >
              <option value="" className="font-semibold text-black">
                Select Type(s)
              </option>
              <option
                value="Social Media Traffic"
                className="font-semibold text-black"
              >
                Social Media Traffic
              </option>
              <option
                value="Referral Traffic"
                className="font-semibold text-black"
              >
                Referral Traffic
              </option>
              <option
                value="Video Advertising Traffic"
                className="font-semibold text-black"
              >
                Video Advertising Traffic
              </option>
            </select>
            <label className="flex font-bold text-[#f442bc] mt-7">
              What other marketing companies are you buying traffic from?
            </label>
            <input
              className="mt-5 font-semibold bg-transparent text-white border-b-2 border-gray-500 outline-none"
              placeholder="Enter Companies Name"
              onChange={(e) => setOtherMarketingCompanies(e.target.value)}
              value={otherMarketingCompanies}
            />
            <label className="flex font-bold text-[#f442bc] mt-7">
              What budgets do you plan to spend with us?{" "}
            </label>
            <select
              name="cars"
              id="cars"
              className="mt-5 w-[800px] bg-transparent text-gray-400 border-b-2 border-gray-500 font-semibold outline-none"
              onChange={(e) => setSelectedBudget(e.target.value)}
              value={selectedBudget}
            >
              <option value={null} className="font-semibold text-black">
                Select Budgets
              </option>
              <option value={10000} className="font-semibold text-black">
                10,000
              </option>
              <option value={40000} className="font-semibold text-black">
                40,000
              </option>
              <option value={100000} className="font-semibold text-black">
                100,000
              </option>
            </select>
            <label className="flex font-bold text-[#f442bc] mt-7">
              Feel free to include any other information you want to share
            </label>
            <input
              className="mt-5 font-semibold text-white bg-transparent border-b-2 border-gray-500 outline-none"
              placeholder="Enter Other Information"
              onChange={(e) => setOtherInformation(e.target.value)}
              value={otherInformation}
            />
            <div className="flex flex-row mt-7 justify-evenly">
              <input
                type="checkbox"
                className=""
                onChange={handleCheckboxChange}
                checked={agreementChecked}
              />
              <p className="text-sm text-[#d6d3d3]">
                By checking this box, I confirm that I have read, understand and
                agree to the{" "}
                <span className="underline cursor-pointer">
                  Terms of Agreement
                </span>{" "}
                and{" "}
                <span className="underline cursor-pointer">Privacy Policy</span>
              </p>
            </div>
            <div className="flex mt-6">
              <button
                type="submit"
                className="mt-7 w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                onClick={handleBack}
              >
                Back
              </button>

              
              {isFormValid() && (
                <button
                  type="submit"
                  className="mt-7 ml-auto w-[130px] h-[50px] bg-[#230B4D] text-center text-white text-[20px] font-semibold rounded-lg tracking-widest"
                  onClick={handle_Data}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Agency_SignUp;
