import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import axios from 'axios';
import { connect } from 'react-redux';
import Arrow from '../../assets/images/Arrow 1.svg'
import Arrows from '../../assets/images/Arrow 7.svg'
import OfferIcn from '../../assets/images/image 17.svg'
import settingIcn from '../../assets/images/image 55.svg'
import categIcn from '../../assets/images/image 56.svg'

const OffersApplied = ({ userId, userFields }) => {

    const [requestOffers, setRequestOffers] = useState([]);
    const [userNames, setUserNames] = useState({});
    const [linkData, setLinkData] = useState([]);
    const [campaignNames, setCampaignNames] = useState({});
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [offerDetails, setOfferDetails] = useState({});
    const [filterOpen, setFilterOpen] = useState(true);

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const user_id = parsedData.id;
    const user_field = parsedData.setUserFields.name;

    useEffect(() => {
        const fetchLinkData = async () => {
            try {
                const response = await axios.get('https://apidealer.devcir.co/api/links');
                // const response = await axios.get('https://apidealer.devcir.co/api/links');
                console.log(response);
                setLinkData(response.data);
            } catch (error) {
                console.error('Error fetching link data:', error);
            }
        };
        fetchLinkData()
    }, []);

    // using this 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://apidealer.devcir.co/api/applied-offers/${user_id}/${user_field}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log("Main data ",data);
                const filteredData = data.filter(item => item.status === "Approved" && item.admin_approval === "Approved");
                setRequestOffers(filteredData);
                fetchUserNames(data);
                fetchCampaignNames(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData()
    }, []);

    const fetchUserNames = async (offers) => {
        try {
            const responses = await Promise.all(
                offers.map(offer => {
                    if (offer.userField == "Agency") {
                        return axios.get(`https://apidealer.devcir.co/api/agency-registrations/${offer.userid}`);

                    } else if (offer.userField == "Affiliate") {
                        return axios.get(`https://apidealer.devcir.co/api/affiliate-registrations/${offer.userid}`);
                    }
                    return Promise.resolve(null);
                })
            );
            const userNamesData = {};
            responses.forEach((response, index) => {
                if (response) {
                    userNamesData[offers[index].userid] = response.data.firstname;
                } else {
                    userNamesData[offers[index].userid] = "Unknown";
                }
            });
            setUserNames(userNamesData);
        } catch (error) {
            console.error('Error fetching user names:', error);
        }
    };

    const fetchCampaignNames = async (offers) => {
        try {
            const responses = await Promise.all(
                offers.map(offer => axios.get(`https://apidealer.devcir.co/api/request-offers/${offer.item_id}`))
            );
            console.log(responses);
            const campaignNamesData = {};
            responses.forEach((response, index) => {
                if (response && response.data) {
                    campaignNamesData[offers[index].id] = response.data.campaign_name;
                } else {
                    campaignNamesData[offers[index].id] = "Unknown";
                }
            });
            setCampaignNames(campaignNamesData);
        } catch (error) {
            console.error('Error fetching campaign names:', error);
        }
    };

    const handleDetailsClick = async (offer) => {
        setSelectedOffer(offer);
        if (offer.status == 'Approved') {
            const matchingLink = linkData.find(link => link.applied_id == offer.id);
            if (matchingLink) {
                try {
                    const countResponse = await axios.get(`https://apidealer.devcir.co/api/prices/count/${matchingLink.id}`);
                    // const countResponse = await axios.get(`https://apidealer.devcir.co/api/prices/count/${matchingLink.id}`);
                    console.log(countResponse)

                    const priceTableResponse = await axios.get(`https://apidealer.devcir.co/api/prices/${matchingLink.id}`);
                    // // const priceTableResponse = await axios.get(`https://apidealer.devcir.co/api/prices/${matchingLink.id}`);
                    console.log(priceTableResponse)

                    setOfferDetails({
                        count: countResponse.data.data,
                        priceTable: priceTableResponse.data.data
                    });
                    console.log(offerDetails)
                } catch (error) {
                    console.error('Error fetching offer details:', error);
                    setOfferDetails(null);
                }
            } else {
                setOfferDetails(null);
            }
        } else {
            setOfferDetails(null)
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };


    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };


    const [filters, setFilters] = useState({
        country: '',
        platform: '',
        vertical: '',
        price_format: '',
        payout: '',
        flow: '',
        trackingType: '',
        mediaTypesAllowed: '',
        mediaTypesRestricted: '',
        wifi: '',
        incentivizedPromotion: '',
        postLeadsViaAPI: '',
        targetDemo: '',
        age: '',
        adultTraffic: '',
        creativePreapproval: '',
        carrier: '',
        status: '',
        loyaltyProg: ''
    });


    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    const [country, setCountry] = useState('');
    const [minPayout, setMinPayout] = useState('');
    const [maxPayout, setMaxPayout] = useState('');
    const countryOptions = [
        'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria',
        'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan',
        'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia',
        'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo, Democratic Republic of the', 'Congo, Republic of the',
        'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador',
        'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France',
        'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau',
        'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland',
        'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Korea, North', 'Korea, South',
        'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein',
        'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania',
        'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar',
        'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Macedonia', 'Norway',
        'Oman', 'Pakistan', 'Palau', 'Palestine', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland',
        'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino',
        'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands',
        'Somalia', 'South Africa', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria',
        'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey',
        'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu',
        'Vatican City', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
    ];
    const platformOptions = ['Desktop', 'Mobile', 'Tablet'];
    const verticalOptions = ['Astrology, Clairvoyance',
        'Binary options', 'Bingo', 'Business Opportunity', 'Brain Health', 'Bathroom', 'Casual Dating', 'CBD', 'Car insurance', 'Credit Repair', 'Download', 'Diet & Weight Loss', 'Debt Consolidation', 'Debt Relief', 'Education', 'Ecommerce', 'Financial', 'Financial-', 'Forex', 'Finance (Pay-Per-Call)', 'Gaming', 'Home Improvement', 'Hair Loss', 'GamingHealth insurance', 'Home Improvement (Pay-Per-Call)', 'iGaming', 'Insurance (other)', 'Insurance (Pay-Per-Call)', 'Irresponsible', 'Life insurance', 'Loans', 'Legal services (home improvement)', 'Mobile app, Social Networking', 'Mainstream dating', 'Mobile content', 'Mobile app, Download', 'mobile content, Carrier Billing', 'Male Enhancement', 'Muscle Gain', 'Mass Tort', 'Nutra (Other)', 'Penny Auction', 'Other', 'Push Subscriptions', 'Property insurance', 'Pest control', 'Personal Injury', 'Real Estate', 'Roofing', 'Real estate insurance', 'Refinance/Mortgages', 'Residential services', 'Reverse Mortgage', 'Streaming', 'Sweepstakes', 'Smartlink', 'Sale', 'Skincare', 'Solar', 'Services', 'Survey', 'Student Debt', 'Training vertical', 'Travel', 'Training', 'Travel (Pay-Per-Call)', 'Vouchers/Leadgen', 'Windows',
        '_Gaming'
    ];
    const priceFormatOptions = ['CPA', 'RevShare'];

    const statusOptions = ['Approved', 'Pending'];

    const flowOptionsLabels = [
        "CPC",
        "CPI",
        "CPI (APK)",
        "CPE",
        "CPL (SOI/Email Submit)",
        "CPL (DOI)",
        "CPS (Straight Sale)",
        "CPS (First-Time Deposit)",
        "CPS (Cash on Delivery)",
        "Free Trial",
        "CC Submit",
        "DCB (1-click flow)",
        "DCB (2-click flow)",
        "Pin submit (MO flow)",
        "Pin submit (MT flow)",
        "Pin submit (Click2Sms)",
        "Pay-per-call",
        "Dynamic (Smartlink)",
        "Click2Call"
    ];
    const LoyaltyOptions = ['Yes', 'No'];
    const [extendedSearch, setExtendedSearch] = useState(false);

    const toggleExtendedSearch = () => {
        setExtendedSearch(!extendedSearch);
    };

    const filteredOffers1 = requestOffers.filter(offer => {
        return Object.entries(filters).every(([key, value]) => {
            if (value !== '') {
                const regex = new RegExp(value, 'i');
                return regex.test(offer[key]);
            }
            return true;
        }) && (
                (minPayout === '' || parseFloat(offer.payout) >= parseFloat(minPayout)) &&
                (maxPayout === '' || parseFloat(offer.payout) <= parseFloat(maxPayout))
            );
    });




    const filteredOffers = requestOffers.filter((offer) =>
        campaignNames[offer.id]?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
                <TopBar />
                <div className='mt-6 bg-white flex justify-between'>
                    <label className='text-[24px] text-[#000000] font-semibold tracking-wider ml-5'>Pending Offers</label>
                    <button onClick={() => { setFilterOpen(!filterOpen) }} className='bg-[#EFEFEF] w-24 h-6 rounded-full mr-5 flex flex-row space-x-1 shadow-gray-400 shadow-md'>
                        <img src={Arrow} alt="" className='mt-[6px] ml-6' />
                        <span className="text-black text-[14px] font-semibold">{(!filterOpen) ? "Filter" : "Hide"}</span>
                    </button>
                </div>

                {filterOpen && (
                    <div className="flex items-center w-full justify-center mt-5 mb-10">
                        <div className="flex flex-col p-4 w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
                            <div className="flex flex-row space-x-14 ml-16 mb-4 mt-10">
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Country</label>
                                    <select
                                        value={filters.country}
                                        onChange={(e) => handleFilterChange('country', e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value={country}>Select</option>
                                        {countryOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Platform</label>
                                    <select
                                        value={filters.platform}
                                        onChange={(e) => handleFilterChange('platform', e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {platformOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Vertical</label>
                                    <select
                                        value={filters.vertical}
                                        onChange={(e) => handleFilterChange('vertical', e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {verticalOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row space-x-14 ml-12 mt-8 mb-4">
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Price Format</label>
                                    <select
                                        value={filters.price_format}
                                        onChange={(e) => handleFilterChange('price_format', e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {priceFormatOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Status</label>
                                    <select
                                        value={filters.status}
                                        onChange={(e) => handleFilterChange('status', e.target.value)}
                                        className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                    >
                                        <option value="">All</option>
                                        {statusOptions.map((option) => (
                                            <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <label className="font-semibold text-[#1F282E] text-[13px]">Payout</label>
                                    <div className="flex space-x-2">
                                        <input
                                            type="number"
                                            placeholder="Min"
                                            value={minPayout}
                                            onChange={(e) => setMinPayout(e.target.value)}
                                            className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                                        />
                                        <input
                                            type="number"
                                            placeholder="Max"
                                            value={maxPayout}
                                            onChange={(e) => setMaxPayout(e.target.value)}
                                            className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                                        />
                                    </div>
                                </div>
                            </div>
                            {extendedSearch && (
                                <div className="flex flex-row space-x-16 ml-16 mt-8 mb-4">
                                    <div className='flex items-center space-x-2'>
                                        <label className="font-semibold text-[#1F282E] text-[13px]">Carrier</label>
                                        <div className="flex space-x-2">
                                            <input
                                                type="text"
                                                placeholder="Carrier"
                                                value={filters.carrier}
                                                onChange={(e) => handleFilterChange('carrier', e.target.value)}
                                                className="p-2 border-none bg-[#F5F5F5] w-44 rounded-md text-black"
                                            />
                                        </div>
                                    </div>
                                    <div className='flex items-center space-x-2'>
                                        <label className="font-semibold text-[#1F282E] text-[13px]">flow</label>
                                        <select
                                            value={filters.flow}
                                            onChange={(e) => handleFilterChange('flow', e.target.value)}
                                            className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                        >
                                            <option value="">All</option>
                                            {flowOptionsLabels.map((option) => (
                                                <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='flex items-center space-x-2'>
                                        <label className="font-semibold text-[#1F282E] text-[13px]">Loyalty Program</label>
                                        <select
                                            value={filters.loyalty}
                                            onChange={(e) => handleFilterChange('loyalty', e.target.value)}
                                            className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                                        >
                                            <option value="">All</option>
                                            {LoyaltyOptions.map((option) => (
                                                <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>{option}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-end space-x-4 mt-8 mb-8 mr-10">
                                <button
                                    onClick={toggleExtendedSearch}
                                    className="bg-[#58165C66] flex space-x-2 bg-opacity-40 text-xs text-[#FFFFFF] p-2 items-center rounded-md w-32 h-10 shadow-gray-400 shadow-md"
                                >
                                    <img src={Arrows} alt="" />
                                    <h1>Extended Search</h1>
                                </button>
                                <button className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">Apply</button>
                                <button className="bg-[#315EFF] bg-opacity-40 text-[#0B31FB] p-2 items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">Clear</button>
                            </div>
                        </div>
                    </div>
                )}






                <div className="flex items-center w-full justify-center mt-5 mb-10">
                    <div className="flex flex-col w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-14">
                        <div className="mx-auto w-full">
                            <div className="flex justify-between items-center mb-4 mt-4 border-b-2 border-gray-200">
                                <div className="flex flex-row space-x-2 ml-5 mb-2">
                                    <img src={OfferIcn} alt="Icn" width={'40px'} height={'40px'} className="ml-2" />
                                    <h1 className="text-2xl font-bold text-[#000000] text-[20px] mt-2">Applied Offers</h1>
                                </div>
                                <div className="flex items-center space-x-4 mr-5 mb-2">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="border-t-[3px] border-[#58165C] rounded-full h-9 w-40 px-2 py-1 border-b-2 border-b-black text-[#58165C] font-semibold"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <svg className="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <table className="text-xs w-11/12 ml-6 mb-8">
                                <thead>
                                    <tr className="text-center">
                                        <th className="p-[2px] w-28 bg-[#58165C66] border-r-[2px] border-[#681d64] text-black rounded-l-full">Campaign Name</th>
                                        <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">Status</th>
                                        <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">URL</th>
                                        <th className="p-[7px] bg-[#58165C66] text-black rounded-r-full">Link Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredOffers1.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" className="mt-2 text-center p-4 text-2xl font-bold text-gray-500">No data available</td>
                                        </tr>
                                    ) : (
                                        filteredOffers1.map((offer) => {
                                            const matchingLink = linkData.find(link => link.applied_id == offer.id);
                                            console.log("dataaa", filteredOffers1);
                                            return (
                                                <tr key={offer.id} className="text-center p-1 shadow-xl mb-3 rounded-r-xl rounded-l-xl">
                                                    <td className="p-1 text-black border-r-2 border-gray-200">{campaignNames[offer.id]}</td>
                                                    <td className="p-1 text-black border-r-2 border-gray-200">{offer.status}</td>
                                                    <td className="p-1 text-black border-r-2 border-gray-200">
                                                        {offer.status == 'Approved' && matchingLink ? `https://apidealer.devcir.co/api/link/${matchingLink.url}` : ''}
                                                    </td>
                                                    <td className="p-1 text-black">
                                                        {offer.status == 'Approved' &&  matchingLink ? matchingLink.status : ''}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>





                        </div>
                        {selectedOffer && (
                            <div className="mt-4 p-4 bg-gray-800">
                                <h2 className="text-xl">Offer Details</h2>
                                <p><strong>Campaign Name:</strong> {campaignNames[selectedOffer.id]}</p>
                                <p><strong>Status:</strong> {selectedOffer.status}</p>
                                <p><strong>URL:</strong> <span>https://apidealer.devcir.co/api/link/</span>{selectedOffer.status == 'Approved' && linkData.find(link => link.applied_id == selectedOffer.id)?.url}</p>
                                <p><strong>Applied Offers Status:</strong> {selectedOffer.status == 'Approved' && linkData.find(link => link.applied_id == selectedOffer.id)?.status}</p>
                                {offerDetails.priceTable && (
                                    <div className="mt-2">
                                        <h3 className="text-lg">Price Table Details:</h3>
                                        <table className="min-w-full bg-gray-700 rounded">
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="p-2 text-white">Amount</th>
                                                    <th className="p-2 text-white">Date Created</th>
                                                    <th className="p-2 text-white">IP Address</th>
                                                    <th className="p-2 text-white">Country</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {offerDetails.priceTable.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="p-2 text-white">{item.amount}</td>
                                                        <td className="p-2 text-white">{formatDate(item.created_at)}</td>
                                                        <td className="p-2 text-white">{item.ip}</td>
                                                        <td className="p-2 text-white">{item.country}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(OffersApplied);
