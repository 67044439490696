import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import { connect } from "react-redux";
import axios from "axios";
import Arrow from "../../assets/images/Arrow 1.svg";
import Arrows from "../../assets/images/Arrow 7.svg";
import OfferIcn from "../../assets/images/image 17.svg";
import settingIcn from "../../assets/images/image 55.svg";
import categIcn from "../../assets/images/image 56.svg";
import {
  FaDesktop,
  FaApple,
  FaAndroid,
  FaTabletAlt,
  FaEllipsisH,
  FaWindows,
  FaMobile,
} from "react-icons/fa";

const RecentlyViewed = ({ userId, userFields }) => {
  // const recentid = userId
  // const field = userFields.name
  const [requestOffers, setRequestOffers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [filterOpen, setFilterOpen] = useState(true);

  const [selectedOffer, setSelectedOffer] = useState(null);

  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);
  const recentid = parsedData.id;
  const field = parsedData.setUserFields.name;

  const handleRowClick = (offer) => {
    setSelectedOffer(offer);
  };

  const closeModal = () => {
    setSelectedOffer(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch(`https://apidealer.devcir.co/api/recent-views/${recentid}/${field}`);
        const response = await fetch(
          `https://apidealer.devcir.co/api/recent-views/${recentid}/${field}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const sortedData = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        // Extracting IDs from sortedData
        const ids = sortedData.map((item) => item.itemId);

        // Fetching data for each ID
        const requestData = await Promise.all(
          ids.map(async (id) => {
            // const response = await fetch(`https://apidealer.devcir.co/api/request-offers/${id}`);
            const response = await fetch(
              `https://apidealer.devcir.co/api/request-offers/${id}`
            );
            if (!response.ok) {
              throw new Error(`Failed to fetch data for ID: ${id}`);
            }
            return response.json();
          })
        );

        // Storing fetched data
        setRequestOffers(requestData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
    priceFormat: true,
    payout: true,
    country: true,
    vertical: true,
    platform: true,
    loyalty: true,
    flow: false,
    blacklistedCountry: false,
    restrictedMediaTypes: false,
    status: false,
    tracking_type: false,
    age: false,
    gender: false,
    media_types_allowed: false,
    creative_preapproval: false,
    incentivized_promotion: false,
    post_leads_via_api: false,
    wifi: false,
  });

  const toggleColumnVisibility = (columnName) => {
    setColumnVisibility({
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName],
    });
  };

  const handleApplySubmit = async (e, itemId, userID, Name, field) => {
    e.preventDefault();
    console.log(itemId);
    console.log(recentid);
    try {
      const response = await axios.post(
        "https://apidealer.devcir.co/api/applied_offers",
        {
          // const response = await axios.post('https://apidealer.devcir.co/api/applied_offers', {
          item_id: itemId,
          user_id: recentid,
          user_field: userFields.name,
          userOfferId: userID,
          userOfferField: field,
          offer_Name: Name,
        }
      );
      console.log("Offer created:", response.data);
      alert("Applied Successfully!!");
      closeModal();
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const [country, setCountry] = useState("");
  const [minPayout, setMinPayout] = useState("");
  const [maxPayout, setMaxPayout] = useState("");

  const countryOptions = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const platformOptions = ["Desktop", "Mobile", "Tablet"];

  const verticalOptions = [
    "Astrology, Clairvoyance",
    "Binary options",
    "Bingo",
    "Business Opportunity",
    "Brain Health",
    "Bathroom",
    "Casual Dating",
    "CBD",
    "Car insurance",
    "Credit Repair",
    "Download",
    "Diet & Weight Loss",
    "Debt Consolidation",
    "Debt Relief",
    "Education",
    "Ecommerce",
    "Financial",
    "Financial-",
    "Forex",
    "Finance (Pay-Per-Call)",
    "Gaming",
    "Home Improvement",
    "Hair Loss",
    "GamingHealth insurance",
    "Home Improvement (Pay-Per-Call)",
    "iGaming",
    "Insurance (other)",
    "Insurance (Pay-Per-Call)",
    "Irresponsible",
    "Life insurance",
    "Loans",
    "Legal services (home improvement)",
    "Mobile app, Social Networking",
    "Mainstream dating",
    "Mobile content",
    "Mobile app, Download",
    "mobile content, Carrier Billing",
    "Male Enhancement",
    "Muscle Gain",
    "Mass Tort",
    "Nutra (Other)",
    "Penny Auction",
    "Other",
    "Push Subscriptions",
    "Property insurance",
    "Pest control",
    "Personal Injury",
    "Real Estate",
    "Roofing",
    "Real estate insurance",
    "Refinance/Mortgages",
    "Residential services",
    "Reverse Mortgage",
    "Streaming",
    "Sweepstakes",
    "Smartlink",
    "Sale",
    "Skincare",
    "Solar",
    "Services",
    "Survey",
    "Student Debt",
    "Training vertical",
    "Travel",
    "Training",
    "Travel (Pay-Per-Call)",
    "Vouchers/Leadgen",
    "Windows",
    "_Gaming",
  ];

  const priceFormatOptions = ["CPA", "RevShare"];

  const statusOptions = ["Approved", "Pending"];

  const flowOptionsLabels = [
    "CPC",
    "CPI",
    "CPI (APK)",
    "CPE",
    "CPL (SOI/Email Submit)",
    "CPL (DOI)",
    "CPS (Straight Sale)",
    "CPS (First-Time Deposit)",
    "CPS (Cash on Delivery)",
    "Free Trial",
    "CC Submit",
    "DCB (1-click flow)",
    "DCB (2-click flow)",
    "Pin submit (MO flow)",
    "Pin submit (MT flow)",
    "Pin submit (Click2Sms)",
    "Pay-per-call",
    "Dynamic (Smartlink)",
    "Click2Call",
  ];

  const LoyaltyOptions = ["Yes", "No"];

  const [filters, setFilters] = useState({
    country: "",
    platform: "",
    vertical: "",
    price_format: "",
    payout: "",
    flow: "",
    trackingType: "",
    mediaTypesAllowed: "",
    mediaTypesRestricted: "",
    wifi: "",
    incentivizedPromotion: "",
    postLeadsViaAPI: "",
    targetDemo: "",
    age: "",
    adultTraffic: "",
    creativePreapproval: "",
    carrier: "",
    status: "",
    loyaltyProg: "",
  });

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const filteredOffers = requestOffers.filter((offer) => {
    return (
      Object.entries(filters).every(([key, value]) => {
        if (value !== "") {
          const regex = new RegExp(value, "i");
          return regex.test(offer[key]);
        }
        return true;
      }) &&
      (minPayout === "" || parseFloat(offer.payout) >= parseFloat(minPayout)) &&
      (maxPayout === "" || parseFloat(offer.payout) <= parseFloat(maxPayout))
    );
  });

  const [extendedSearch, setExtendedSearch] = useState(false);

  const toggleExtendedSearch = () => {
    setExtendedSearch(!extendedSearch);
  };

  const icons = {
    "Desktop (pc)": <FaWindows />,
    "Desktop (mac)": <FaApple />,
    "Mobile (android)": <FaAndroid />,
    "Mobile (ios)": <FaApple />,
    "Mobile (other)": <FaEllipsisH />,
    "Tablet (android)": <FaTabletAlt />,
    "Tablet (ios)": <FaApple />,
    "Tablet (other)": <FaEllipsisH />,
  };

  const groupedIcons = {
    Desktop: <FaDesktop />,
    Mobile: <FaMobile />,
    Tablet: <FaTabletAlt />,
  };

  const groupPlatforms = (platforms) => {
    if (!platforms) return {};
    const platformGroups = {
      Desktop: [],
      Mobile: [],
      Tablet: [],
    };
    platforms.forEach((platform) => {
      if (platform.includes("Desktop")) {
        platformGroups.Desktop.push(platform);
      } else if (platform.includes("Mobile")) {
        platformGroups.Mobile.push(platform);
      } else if (platform.includes("Tablet")) {
        platformGroups.Tablet.push(platform);
      }
    });
    return platformGroups;
  };

  const renderPlatformGroup = (platforms) => {
    return (
      <>
        {platforms.map((platform, index) => (
          <span key={index} className="mr-2">
            {icons[platform] || platform}
          </span>
        ))}
      </>
    );
  };

  const renderPlatformIcons = (platforms) => {
    const platformGroups = groupPlatforms(platforms);
    return (
      <div className="flex flex-col space-y-2">
        {platformGroups.Desktop.length > 0 && (
          <div className="flex space-x-2">
            <span>{groupedIcons.Desktop}</span>:
            {renderPlatformGroup(platformGroups.Desktop)}
          </div>
        )}
        {platformGroups.Mobile.length > 0 && (
          <div className="flex space-x-2">
            <span>{groupedIcons.Mobile}</span>:
            {renderPlatformGroup(platformGroups.Mobile)}
          </div>
        )}
        {platformGroups.Tablet.length > 0 && (
          <div className="flex space-x-2">
            <span>{groupedIcons.Tablet}</span>:
            {renderPlatformGroup(platformGroups.Tablet)}
          </div>
        )}
      </div>
    );
  };

  const countryFlags = {
    Afghanistan: "af",
    Albania: "al",
    Algeria: "dz",
    Andorra: "ad",
    Angola: "ao",
    "Antigua & Barbuda": "ag",
    Argentina: "ar",
    Armenia: "am",
    Australia: "au",
    Austria: "at",
    Azerbaijan: "az",
    Bahamas: "bs",
    Bahrain: "bh",
    Bangladesh: "bd",
    Barbados: "bb",
    Belarus: "by",
    Belgium: "be",
    Belize: "bz",
    Benin: "bj",
    Bhutan: "bt",
    Bolivia: "bo",
    "Bosnia & Herzegovina": "ba",
    Botswana: "bw",
    Brazil: "br",
    Brunei: "bn",
    Bulgaria: "bg",
    "Burkina Faso": "bf",
    Burundi: "bi",
    "Cabo Verde": "cv",
    Cambodia: "kh",
    Cameroon: "cm",
    Canada: "ca",
    "Central African Republic": "cf",
    Chad: "td",
    Chile: "cl",
    China: "cn",
    Colombia: "co",
    Comoros: "km",
    Congo: "cg",
    "Costa Rica": "cr",
    Croatia: "hr",
    Cuba: "cu",
    Cyprus: "cy",
    "Czech Republic": "cz",
    Denmark: "dk",
    Djibouti: "dj",
    Dominica: "dm",
    "Dominican Republic": "do",
    Ecuador: "ec",
    Egypt: "eg",
    "El Salvador": "sv",
    "Equatorial Guinea": "gq",
    Eritrea: "er",
    Estonia: "ee",
    Eswatini: "sz",
    Ethiopia: "et",
    Fiji: "fj",
    Finland: "fi",
    France: "fr",
    Gabon: "ga",
    Gambia: "gm",
    Georgia: "ge",
    Germany: "de",
    Ghana: "gh",
    Greece: "gr",
    Grenada: "gd",
    Guatemala: "gt",
    Guinea: "gn",
    "Guinea-Bissau": "gw",
    Guyana: "gy",
    Haiti: "ht",
    Honduras: "hn",
    Hungary: "hu",
    Iceland: "is",
    India: "in",
    Indonesia: "id",
    Iran: "ir",
    Iraq: "iq",
    Ireland: "ie",
    Israel: "il",
    Italy: "it",
    Jamaica: "jm",
    Japan: "jp",
    Jordan: "jo",
    Kazakhstan: "kz",
    Kenya: "ke",
    Kiribati: "ki",
    Kosovo: "xk",
    Kuwait: "kw",
    Kyrgyzstan: "kg",
    Laos: "la",
    Latvia: "lv",
    Lebanon: "lb",
    Lesotho: "ls",
    Liberia: "lr",
    Libya: "ly",
    Liechtenstein: "li",
    Lithuania: "lt",
    Luxembourg: "lu",
    Madagascar: "mg",
    Malawi: "mw",
    Malaysia: "my",
    Maldives: "mv",
    Mali: "ml",
    Malta: "mt",
    "Marshall Islands": "mh",
    Mauritania: "mr",
    Mauritius: "mu",
    Mexico: "mx",
    Micronesia: "fm",
    Moldova: "md",
    Monaco: "mc",
    Mongolia: "mn",
    Montenegro: "me",
    Morocco: "ma",
    Mozambique: "mz",
    Myanmar: "mm",
    Namibia: "na",
    Nauru: "nr",
    Nepal: "np",
    Netherlands: "nl",
    "New Zealand": "nz",
    Nicaragua: "ni",
    Niger: "ne",
    Nigeria: "ng",
    "North Korea": "kp",
    "North Macedonia": "mk",
    Norway: "no",
    Oman: "om",
    Pakistan: "pk",
    Palau: "pw",
    Palestine: "ps",
    Panama: "pa",
    "Papua New Guinea": "pg",
    Paraguay: "py",
    Peru: "pe",
    Philippines: "ph",
    Poland: "pl",
    Portugal: "pt",
    Qatar: "qa",
    Romania: "ro",
    Russia: "ru",
    Rwanda: "rw",
    Samoa: "ws",
    "San Marino": "sm",
    "Sao Tome & Principe": "st",
    "Saudi Arabia": "sa",
    Senegal: "sn",
    Serbia: "rs",
    Seychelles: "sc",
    "Sierra Leone": "sl",
    Singapore: "sg",
    Slovakia: "sk",
    Slovenia: "si",
    "Solomon Islands": "sb",
    Somalia: "so",
    "South Africa": "za",
    "South Korea": "kr",
    "South Sudan": "ss",
    Spain: "es",
    "Sri Lanka": "lk",
    "St. Kitts & Nevis": "kn",
    "St. Lucia": "lc",
    "St. Vincent & Grenadines": "vc",
    Sudan: "sd",
    Suriname: "sr",
    Sweden: "se",
    Switzerland: "ch",
    Syria: "sy",
    Taiwan: "tw",
    Tajikistan: "tj",
    Tanzania: "tz",
    Thailand: "th",
    "Timor-Leste": "tl",
    Togo: "tg",
    Tonga: "to",
    "Trinidad & Tobago": "tt",
    Tunisia: "tn",
    Turkey: "tr",
    Turkmenistan: "tm",
    Tuvalu: "tv",
    Uganda: "ug",
    Ukraine: "ua",
    "United Arab Emirates": "ae",
    "United Kingdom": "gb",
    "United States": "us",
    Uruguay: "uy",
    Uzbekistan: "uz",
    Vanuatu: "vu",
    "Vatican City": "va",
    Venezuela: "ve",
    Vietnam: "vn",
    Yemen: "ye",
    Zambia: "zm",
    Zimbabwe: "zw",
  };

  const getCountryFlagCode = (country) => {
    return countryFlags[country] || null;
  };

  const mapCountryFlags = (countriesString) => {
    if (!countriesString) return [];
    const countries = countriesString
      .split(",")
      .map((country) => country.trim());
    return countries.map((country) => {
      const code = getCountryFlagCode(country);
      return code ? `https://flagcdn.com/w20/${code}.png` : null;
    });
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (rowId) => {
    setHoveredRow(rowId);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchOffers = filteredOffers.filter((offer) =>
    offer.campaign_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="ml-2">
      <button
        data-drawer-target="sidebar-multi-level-sidebar"
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <Sidebar />

      <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
        <TopBar />
        <div className="mt-6 bg-white flex justify-between">
          <label className="text-[24px] text-[#000000] font-semibold tracking-wider ml-5">
            RECENTLY VIEWED OFFERS
          </label>
          <button
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
            className="bg-[#EFEFEF] w-24 h-6 rounded-full mr-5 flex flex-row space-x-1 shadow-gray-400 shadow-md"
          >
            <img src={Arrow} alt="" className="mt-[6px] ml-6" />
            <span className="text-black text-[14px] font-semibold">
              {!filterOpen ? "Filter" : "Hide"}
            </span>
          </button>
        </div>
        {/* <div className="mt-6 bg-white">
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}

        {filterOpen && (
          <div className="flex items-center w-full justify-center mt-5 mb-10">
            <div className="flex flex-col p-4 w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
              <div className="flex flex-row space-x-14 ml-16 mb-4 mt-10">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Country
                  </label>
                  <select
                    value={filters.country}
                    onChange={(e) =>
                      handleFilterChange("country", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value={country}>Select</option>
                    {countryOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Platform
                  </label>
                  <select
                    value={filters.platform}
                    onChange={(e) =>
                      handleFilterChange("platform", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {platformOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Vertical
                  </label>
                  <select
                    value={filters.vertical}
                    onChange={(e) =>
                      handleFilterChange("vertical", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {verticalOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-row space-x-14 ml-12 mt-8 mb-4">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Price Format
                  </label>
                  <select
                    value={filters.price_format}
                    onChange={(e) =>
                      handleFilterChange("price_format", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {priceFormatOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Status
                  </label>
                  <select
                    value={filters.status}
                    onChange={(e) =>
                      handleFilterChange("status", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {statusOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Payout
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="number"
                      placeholder="Min"
                      value={minPayout}
                      onChange={(e) => setMinPayout(e.target.value)}
                      className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                    />
                    <input
                      type="number"
                      placeholder="Max"
                      value={maxPayout}
                      onChange={(e) => setMaxPayout(e.target.value)}
                      className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                    />
                  </div>
                </div>
              </div>
              {extendedSearch && (
                <div className="flex flex-row space-x-16 ml-16 mt-8 mb-4">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-[#1F282E] text-[13px]">
                      Carrier
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        placeholder="Carrier"
                        value={filters.carrier}
                        onChange={(e) =>
                          handleFilterChange("carrier", e.target.value)
                        }
                        className="p-2 border-none bg-[#F5F5F5] w-44 rounded-md text-black"
                      />
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-[#1F282E] text-[13px]">
                      flow
                    </label>
                    <select
                      value={filters.flow}
                      onChange={(e) =>
                        handleFilterChange("flow", e.target.value)
                      }
                      className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                    >
                      <option value="">All</option>
                      {flowOptionsLabels.map((option) => (
                        <option
                          key={option}
                          className="border border-[#C9C7C7] rounded-sm"
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-[#1F282E] text-[13px]">
                      Loyalty Program
                    </label>
                    <select
                      value={filters.loyalty}
                      onChange={(e) =>
                        handleFilterChange("loyalty", e.target.value)
                      }
                      className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                    >
                      <option value="">All</option>
                      {LoyaltyOptions.map((option) => (
                        <option
                          key={option}
                          className="border border-[#C9C7C7] rounded-sm"
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="flex justify-end space-x-4 mt-8 mb-8 mr-10">
                <button
                  onClick={toggleExtendedSearch}
                  className="bg-[#58165C66] flex space-x-2 bg-opacity-40 text-xs text-[#FFFFFF] p-2 items-center rounded-md w-32 h-10 shadow-gray-400 shadow-md"
                >
                  <img src={Arrows} alt="" />
                  <h1>Extended Search</h1>
                </button>
                <button className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">
                  Apply
                </button>
                <button className="bg-[#315EFF] bg-opacity-40 text-[#0B31FB] p-2 items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center w-full justify-center mt-5 mb-10">
          <div className="flex flex-col w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-14">
            <div className="mx-auto w-full">
              <div className="flex justify-between items-center mb-4 mt-4 border-b-2 border-gray-200">
                <div className="flex flex-row space-x-2 ml-5 mb-2">
                  <img
                    src={OfferIcn}
                    alt="Icn"
                    width={"40px"}
                    height={"40px"}
                  />
                  <h1 className="text-2xl font-bold text-[#000000] text-[20px] mt-2">
                    Recently Viewed
                  </h1>
                </div>
                <div className="flex items-center space-x-4 mr-5 mb-2">
                  <img src={settingIcn} alt="" width={"34px"} height={"34px"} />
                  <img src={categIcn} alt="" width={"34px"} height={"34px"} />
                  <div className="relative">
                    <input
                      type="text"
                      className="border-t-[3px] border-[#58165C] rounded-full h-9 w-40 px-2 py-1 border-b-2 border-b-black text-[#58165C] font-semibold"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <svg
                        className="w-4 h-4 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <table className="text-xs w-11/12 ml-11 mb-8">
                <colgroup>
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.name ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.priceFormat
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.payout ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.country ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.vertical
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.platform
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.loyalty ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.flow ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.blacklistedCountry
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.restrictedMediaTypes
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.status ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.tracking_type
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.age ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.gender ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.media_types_allowed
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.creative_preapproval
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.incentivized_promotion
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.post_leads_via_api
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.wifi ? "table-cell" : "none",
                    }}
                  />
                </colgroup>
                <thead className="">
                  <tr className="text-center">
                    {columnVisibility.name && (
                      <th className="w-56 h-8 bg-[#58165C66] border-r-[2px] border-[#681d64] text-black rounded-l-full">
                        Name
                      </th>
                    )}
                    {columnVisibility.priceFormat && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Price Format
                      </th>
                    )}
                    {columnVisibility.payout && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Payout
                      </th>
                    )}
                    {columnVisibility.cpa_rate && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        CPA Rate
                      </th>
                    )}
                    {columnVisibility.country && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Country
                      </th>
                    )}
                    {columnVisibility.vertical && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Vertical
                      </th>
                    )}
                    {columnVisibility.platform && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Platform
                      </th>
                    )}
                    {columnVisibility.loyalty && (
                      <th className="p-[2px] bg-[#58165C66] text-black rounded-r-full">
                        Loyalty
                      </th>
                    )}
                    {columnVisibility.flow && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Flow
                      </th>
                    )}
                    {columnVisibility.blacklistedCountry && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Blacklisted Country
                      </th>
                    )}
                    {columnVisibility.restrictedMediaTypes && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Restricted Media Types
                      </th>
                    )}
                    {columnVisibility.status && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Status
                      </th>
                    )}
                    {columnVisibility.tracking_type && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Tracking Type
                      </th>
                    )}
                    {columnVisibility.age && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Age
                      </th>
                    )}
                    {columnVisibility.gender && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Gender
                      </th>
                    )}
                    {columnVisibility.media_types_allowed && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Media Types Allowed
                      </th>
                    )}
                    {columnVisibility.creative_preapproval && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Creative PreApproval
                      </th>
                    )}
                    {columnVisibility.incentivized_promotion && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Incentivized Promotion
                      </th>
                    )}
                    {columnVisibility.post_leads_via_api && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Posts Leads Via API
                      </th>
                    )}
                    {columnVisibility.wifi && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black rounded-r-full">
                        3G / WiFi
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {searchOffers.map((offer) => (
                    <tr
                      key={offer.id}
                      className="text-center shadow-xl rounded-r-xl rounded-l-xl h-20"
                    >
                      {columnVisibility.name && (
                        <td
                          className="p-3 text-blue-700 cursor-pointer underline  border-r-2 border-gray-200"
                          onClick={() => handleRowClick(offer)}
                        >
                          {offer.campaign_name}
                        </td>
                      )}
                      {columnVisibility.priceFormat && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.price_format}
                        </td>
                      )}
                      {columnVisibility.payout && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.price_format === "CPA"
                            ? `$ ${offer.payout}`
                            : " "}
                        </td>
                      )}
                      {columnVisibility.country && (
                        <td
                          className="relative   text-black border-r-2 border-gray-200"
                          onMouseEnter={() => handleMouseEnter(offer.id)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {(() => {
                            const urls = mapCountryFlags(offer.country);
                            const displayedUrls = urls.slice(0, 3);
                            const remainingCount =
                              urls.length - displayedUrls.length;

                            return (
                              <>
                                <div>
                                  <div>
                                    {displayedUrls.map((url, index) =>
                                      url ? (
                                        <img
                                          key={index}
                                          src={url}
                                          alt="country flag"
                                          className="inline-block mx-1"
                                        />
                                      ) : null
                                    )}
                                  </div>
                                  <div>
                                    {remainingCount > 0 && (
                                      <span className="cursor-pointer">
                                        +{remainingCount} more
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {hoveredRow === offer.id && (
                                  <div className="absolute left-0 mt-2 p-2 bg-white border border-gray-300 shadow-lg z-10">
                                    {urls.map((url, index) =>
                                      url ? (
                                        <img
                                          key={index}
                                          src={url}
                                          alt="country flag"
                                          className="inline-block mx-1"
                                        />
                                      ) : null
                                    )}
                                  </div>
                                )}
                              </>
                            );
                          })()}
                        </td>
                      )}
                      {columnVisibility.vertical && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.vertical}
                        </td>
                      )}
                      {columnVisibility.platform && (
                        <td className="  text-black pl-3 border-r-2 border-gray-200">
                          {offer.platform
                            ? renderPlatformIcons(offer.platform.split(","))
                            : null}
                        </td>
                      )}
                      {columnVisibility.loyalty && (
                        <td className="p-3 text-black">{offer.loyalty}</td>
                      )}
                      {columnVisibility.flow && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.flow}
                        </td>
                      )}
                      {columnVisibility.blacklistedCountry && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.blacklisted_country}
                        </td>
                      )}
                      {columnVisibility.restrictedMediaTypes && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.media_types_restricted}
                        </td>
                      )}
                      {columnVisibility.status && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.status}
                        </td>
                      )}
                      {columnVisibility.tracking_type && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.tracking_type}
                        </td>
                      )}
                      {columnVisibility.age && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.age}
                        </td>
                      )}
                      {columnVisibility.gender && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.gender}
                        </td>
                      )}
                      {columnVisibility.media_types_allowed && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.media_types_allowed}
                        </td>
                      )}
                      {columnVisibility.creative_preapproval && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.creative_preapproval}
                        </td>
                      )}
                      {columnVisibility.incentivized_promotion && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.incentivized_promotion}
                        </td>
                      )}
                      {columnVisibility.post_leads_via_api && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.post_leads_via_api}
                        </td>
                      )}
                      {columnVisibility.wifi && (
                        <td className="p-3 text-black border-r-2 border-gray-200">
                          {offer.g_wifi}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {selectedOffer && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full h-auto max-h-[95vh] overflow-auto">
              <div className="flex flex-row space-x-3">
                <div className="w-2/5 border-r-2 border-gray-100">
                  <h2 className="text-xl font-bold mb-4 text-black">
                    {selectedOffer.campaign_name}
                  </h2>
                  <div>
                    <img
                      className="images w-60 h-60"
                      src={selectedOffer.imageUrl}
                      alt="img"
                    />
                  </div>
                  <div className="space-y-2">
                    <p className="text-black mt-2">
                      <strong>Payout:</strong> {selectedOffer.payout}
                    </p>
                    <p className="text-black">
                      <strong>Price Format:</strong>{" "}
                      {selectedOffer.price_format}
                    </p>
                    <p className="text-black">
                      <strong>Vertical:</strong> {selectedOffer.vertical}
                    </p>
                    <p className="text-black">
                      <strong>Platforms:</strong> {selectedOffer.platform}
                    </p>
                    <p className="text-black">
                      <strong>Offer capped:</strong> {selectedOffer.status}
                    </p>

                    {/* Handle Allowed Countries */}
                    <p className="text-black">
                      <strong>Allowed countries:</strong>
                      {selectedOffer.country
                        ? selectedOffer.country
                            .split(",")
                            .slice(0, 10)
                            .map((country) => country.trim()) // To handle extra spaces
                            .join(", ") +
                          (selectedOffer.country.split(",").length > 10
                            ? ", ...more"
                            : "")
                        : "N/A"}
                    </p>
                  </div>
                </div>{" "}
                <div className="w-7/12 flex flex-col space-y-1">
                  <div className="mt-10 space-y-6 border-b-2 border-gray-100">
                    <p className="text-black">
                      <strong>Description : </strong> {selectedOffer.info_share}{" "}
                    </p>

                    {/* Handle Media Types Restricted */}
                    <p className="text-black underline">
                      <strong>Restrictions</strong>
                    </p>
                    <p className="text-black">
                      <span className="font-semibold">
                        Media Types Restricted:{" "}
                      </span>
                      {selectedOffer.media_types_restricted
                        ? selectedOffer.media_types_restricted
                            .split(",")
                            .slice(0, 10)
                            .map((type) => type.trim())
                            .join(", ") +
                          (selectedOffer.media_types_restricted.split(",")
                            .length > 10
                            ? ", ...more"
                            : "")
                        : "N/A"}
                    </p>

                    {/* Handle Not Allowed Countries */}
                    <p className="text-black underline">
                      <strong>Not Allowed</strong>
                    </p>
                    <p className="text-black">
                      <span className="font-semibold">Countries: </span>
                      {selectedOffer.blacklisted_country
                        ? selectedOffer.blacklisted_country
                            .split(",")
                            .slice(0, 10)
                            .map((country) => country.trim())
                            .join(", ") +
                          (selectedOffer.blacklisted_country.split(",").length >
                          10
                            ? ", ...more"
                            : "")
                        : "N/A"}
                    </p>
                    <p className="text-black">
                      <strong>Loyalty : </strong>
                      {selectedOffer.loyalty}
                    </p>
                    <br />
                    <br />
                  </div>
                  {field === "Affiliate" && (
                    <div>
                      <p className="text-black text-xs ">
                        By checking the box below, you agree to promote the
                        offer in accordance with the restrictions above.
                      </p>
                      <div className="flex flex-row mt-3 space-x-1">
                        <input
                          type="checkbox"
                          className="h-3 w-3 mt-1"
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <p className="text-black"> I Agree</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr className="mt-4 border-b-[1px] border-gray-100" />
              <div className="flex justify-end space-x-7">
                <button
                  onClick={closeModal}
                  className="mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600"
                >
                  Close
                </button>
                {field === "Affiliate" && (
                  <button
                    onClick={(e) => {
                      handleApplySubmit(
                        e,
                        selectedOffer.id,
                        selectedOffer.userid,
                        selectedOffer.campaign_name,
                        selectedOffer.userField
                      );
                    }}
                    disabled={!isChecked}
                    className={`mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600 ${
                      !isChecked ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  userFields: state.user.userFields,
});

export default connect(mapStateToProps)(RecentlyViewed);
