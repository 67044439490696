import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';

const MyDomains = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const [domains, setDomains] = useState([
        { name: 'test.offer.com', status: 'Active', ssl: 'Yes', facebookVer: 'No', cnameConf: 'No' },
        { name: 'testmydomain.com', status: 'Active', ssl: 'Yes', facebookVer: 'No', cnameConf: 'No' },
        { name: 'microfinet.com', status: 'Active', ssl: 'Yes', facebookVer: 'No', cnameConf: 'No' },
    ]);

    const addDomain = (newDomain) => {
        setDomains([...domains, newDomain]);
    };


    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl"> 
                <TopBar />

                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>MY DOMAINS</h1>
                    </div>

                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">

                            <p className="mt-2 ml-2 mr-2 p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700">
                                In this section, you can add your own redirect domains to be used in your tracking links. To link your own subdomain, create a CNAME (Alias) record and point it to
                                <span className="font-mono font-bold"> cdpxy.cdtechbox.link</span>. NOTE: if you want to use HTTPS with the subdomain you added to your account, you need to enable the "SSL" checkbox when adding the domain.
                            </p>

                            <button className="w-32 mt-6 bg-blue-500 text-white py-1 rounded-md mb-4" onClick={() => addDomain({ name: 'newdomain.com', status: 'Active', ssl: 'Yes', facebookVer: 'No', cnameConf: 'No' })}>Add Domain</button>
                            <table className="bg-white border border-gray-200">
                                <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b border-r text-black">Domain Name</th>
                                        <th className="py-2 px-4 border-b border-r text-black">Status</th>
                                        <th className="py-2 px-4 border-b border-r text-black">SSL</th>
                                        <th className="py-2 px-4 border-b border-r text-black">Facebook Ver</th>
                                        <th className="py-2 px-4 border-b border-r text-black">CNAME Conf</th>
                                        <th className="py-2 px-4 border-b text-black">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {domains.map((domain, index) => (
                                        <tr key={index}>
                                            <td className="py-2 px-4 border-b border-r text-black">{domain.name}</td>
                                            <td className="py-2 px-4 border-b border-r text-black">{domain.status}</td>
                                            <td className="py-2 px-4 border-b border-r text-black">{domain.ssl}</td>
                                            <td className="py-2 px-4 border-b border-r text-black">{domain.facebookVer}</td>
                                            <td className="py-2 px-4 border-b border-r text-black">{domain.cnameConf}</td>
                                            <td className="py-2 px-4 border-b">
                                                <button className="text-blue-500 hover:underline mr-2">Edit</button>
                                                <button className="text-red-500 hover:underline">Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(MyDomains)