import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';

const ConversionApi = ({ userId, userFields }) => {
    
    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;


    const [openForm, setOpenForm] = useState(false)

    const [campaignName, setCampaignName] = useState('')
    const [campaignId, setCampaignId] = useState()
    const [visits, setVisits] = useState()
    const [totalVisits, setTotalVisits] = useState()
    const [clicks, setClicks] = useState()
    const [totalclicks, setTotalClicks] = useState()
    const [conversions, setConversions] = useState()
    const [events, setEvents] = useState()
    const [revenue, setRevenue] = useState()
    const [cR, setCR] = useState()
    const [ePC, setEPC] = useState()

  return (
        <>
    <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span className="sr-only">Open sidebar</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
    </button>

    <Sidebar />

    <div className="sm:ml-64 p-3 bg-">
        <TopBar />

        <button onClick={() => { setOpenForm(true) }} className='text-black'>Request Report</button>
    </div>


    {openForm === true && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg w-7/12">
                <h2 className="text-xl font-bold mb-4 text-black">Request Offers Form</h2>
                <form>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Campaign Name</label>
                        <input type="text" value={campaignName} onChange={(e) => setCampaignName(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Campaign Name' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>visits</label>
                        <input type="text" value={visits} onChange={(e) => setVisits(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Visits' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Total Visits</label>
                        <input type="text" value={totalVisits} onChange={(e) => setTotalVisits(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Total Visits' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Clicks</label>
                        <input type="text" value={clicks} onChange={(e) => setClicks(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Clicks' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Total Clicks</label>
                        <input type="text" value={totalclicks} onChange={(e) => setTotalClicks(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Total Clicks' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Conversions</label>
                        <input type="text" value={conversions} onChange={(e) => setConversions(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Conversions' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Events</label>
                        <input type="text" value={events} onChange={(e) => setEvents(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Events' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>Revenue</label>
                        <input type="text" value={revenue} onChange={(e) => setRevenue(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter Revenue' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>CR</label>
                        <input type="text" value={cR} onChange={(e) => setCR(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter CR' required />
                    </div>
                    <div className='w-full flex  justify-between mt-4'>
                        <label className='text-xs text-gray-500'>EPC</label>
                        <input type="text" value={ePC} onChange={(e) => setEPC(e.target.value)} className='w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2' placeholder='Enter EPC' required />
                    </div>
                    <div className="w-full flex  justify-between">
                        <button onClick={() => { setOpenForm(false) }} className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">Close</button>
                        <button type="submit" className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )}
</>
  )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(ConversionApi)