import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { connect } from 'react-redux';


const SocialTable = ({ userId, userFields }) => {

    // const uid = userId
    // const field = userFields.name

const storedData = localStorage.getItem('userData');
const parsedData = JSON.parse(storedData);
const uid = parsedData.id;
const field = parsedData.setUserFields.name;

    const time = new Date().toISOString().slice(0, 19).replace('T', ' ')
    const [requestOffers, setRequestOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null);


    const handleRowOfferClick = (offer) => {
        setSelectedOffer(offer);
    };

    const closeModal = () => {
        setSelectedOffer(null);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://apidealer.devcir.co/api/social-networks');
                // const response = await fetch('https://apidealer.devcir.co/api/social-networks');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // const filteredData = data.filter(item => item.status === "Approved");
                setRequestOffers(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleRowClick = async (offer, id, status) => {
        setSelectedOffer(offer);
        console.log('Clicked row ID :', id);
        console.log('Time :', time);
        console.log('UserId :', uid);
        console.log('User Field :', field);
        console.log('Status :', status);

    };

    const [columnVisibility, setColumnVisibility] = useState({

        campaign_name: true,
        countries: true,
        blacklist_countries: true,
        platform: true,
        gender: false,
        incentivized_promotion: false,
        adult_traffic: false,
        eCPM: true,
        media_types: true,
        restricted_media_types: true,
        age: false,
        loyalty_program: false,
        wifi_3g: false,
        status: false,
        action: false
    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };

    return (
        <>
            <button
                data-drawer-target="sidebar-multi-level-sidebar"
                data-drawer-toggle="sidebar-multi-level-sidebar"
                aria-controls="sidebar-multi-level-sidebar"
                type="button"
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">Open sidebar</span>
                <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        clipRule="evenodd"
                        fillRule="evenodd"
                        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 p-3 bg-">
                <TopBar />
                <div className="mt-6 bg-white">
                    <label className="text-xl text-gray-500 tracking-wider">NEW OFFERS</label>
                </div>
                <div className="mt-6 bg-white">
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div>
                        <div className="overflow-x-auto">
                            <table className="text-xs">
                                <colgroup>
                                    <col className="text-[10px]" style={{ display: columnVisibility.User_id ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.User_field ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.campaign_name ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.countries ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.blacklist_countries ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.platform ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.gender ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.loyalty_program ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.incentivized_promotion ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.adult_traffic ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.eCPM ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.media_types ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.restricted_media_types ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.age ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.loyalty ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.wifi_3g ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.status ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.action ? 'table-cell' : 'none' }} />
                                </colgroup>
                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.campaign_name && <th className="p-3 text-black">Name</th>}
                                        {columnVisibility.priceFormat && <th className="p-3 text-black">Price Format</th>}
                                        {columnVisibility.countries && <th className="p-3 text-black">Country</th>}
                                        {columnVisibility.adult_traffic && <th className="p-3 text-black">Adult Traffic</th>}
                                        {columnVisibility.platform && <th className="p-3 text-black">Platform</th>}
                                        {columnVisibility.loyalty_program && <th className="p-3 text-black">Loyalty</th>}
                                        {columnVisibility.flow && <th className="p-3 text-black">Flow</th>}
                                        {columnVisibility.blacklist_countries && <th className="p-3 text-black">Blacklisted Country</th>}
                                        {columnVisibility.restricted_media_types && <th className="p-3 text-black">Restricted Media Types</th>}
                                        {columnVisibility.status && <th className="p-3 text-black">Status</th>}
                                        {columnVisibility.tracking_type && <th className="p-3 text-black">Tracking Type</th>}
                                        {columnVisibility.age && <th className="p-3 text-black">Age</th>}
                                        {columnVisibility.gender && <th className="p-3 text-black">Gender</th>}
                                        {columnVisibility.media_types && <th className="p-3 text-black">Media Types Allowed</th>}
                                        {columnVisibility.creative_preapproval && <th className="p-3 text-black">Creative PreApproval</th>}
                                        {columnVisibility.incentivized_promotion && <th className="p-3 text-black">Incentivized Promotion</th>}
                                        {columnVisibility.eCPM && <th className="p-3 text-black">ECPM</th>}
                                        {columnVisibility.wifi_3g && <th className="p-3 text-black">3G / WiFi</th>}
                                        {columnVisibility.action && <th className="p-3 text-black">Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {requestOffers.map((offer) => (
                                        <tr
                                            key={offer.id}
                                            className="border-b border-opacity-20 "
                                        >
                                            {columnVisibility.campaign_name && <td className="p-3 text-blue-700 cursor-pointer underline" onClick={() => handleRowClick(offer, offer.id, offer.status)}>{offer.campaign_name}</td>}
                                            {columnVisibility.payout && <td className="p-3 text-black">{offer.payout}</td>}
                                            {columnVisibility.countries && <td className="p-3 text-black">{offer.countries}</td>}
                                            {columnVisibility.adult_traffic && <td className="p-3 text-black">{offer.adult_traffic}</td>}
                                            {columnVisibility.platform && <td className="p-3 text-black">{offer.platform}</td>}
                                            {columnVisibility.loyalty_program && <td className="p-3 text-black">{offer.loyalty_program}</td>}
                                            {columnVisibility.flow && <td className="p-3 text-black">{offer.flow}</td>}
                                            {columnVisibility.blacklist_countries && <td className="p-3 text-black">{offer.blacklist_countries}</td>}
                                            {columnVisibility.restricted_media_types && <td className="p-3 text-black">{offer.restricted_media_types}</td>}
                                            {columnVisibility.status && <td className="p-3 text-black">{offer.status}</td>}
                                            {columnVisibility.tracking_type && <td className="p-3 text-black">{offer.tracking_type}</td>}
                                            {columnVisibility.age && <td className="p-3 text-black">{offer.age}</td>}
                                            {columnVisibility.gender && <td className="p-3 text-black">{offer.gender}</td>}
                                            {columnVisibility.media_types && <td className="p-3 text-black">{offer.media_types}</td>}
                                            {columnVisibility.creative_preapproval && <td className="p-3 text-black">{offer.creative_preapproval}</td>}
                                            {columnVisibility.incentivized_promotion && <td className="p-3 text-black">{offer.incentivized_promotion}</td>}
                                            {columnVisibility.eCPM && <td className="p-3 text-black">{offer.eCPM}</td>}
                                            {columnVisibility.wifi_3g && <td className="p-3 text-black">{offer.wifi_3g}</td>}
                                            {columnVisibility.action && <td className="p-3 text-black">{offer.action}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                {/* {selectedOffer && (
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white flex flex-col p-8 rounded-lg w-7/12">
                            <div className='flex flex-row space-x-3'>
                                <div className='w-2/5 border-r-2 border-gray-100'>
                                    <h2 className="text-xl font-bold mb-4 text-black">{selectedOffer.campaign_name}</h2>
                                    <div>
                                        <img className='images w-60 h-60' src={selectedOffer.imageUrl} alt="img" />
                                    </div>
                                    <div className='space-y-2'>
                                        <p className='text-black mt-2'><strong>Payout:</strong> {selectedOffer.payout}</p>
                                        <p className='text-black'><strong>Price Format:</strong> {selectedOffer.price_format}</p>
                                        <p className='text-black'><strong>Vertical:</strong> {selectedOffer.vertical}</p>
                                        <p className='text-black'><strong>Platforms:</strong> {selectedOffer.platform}</p>
                                        <p className='text-black'><strong>Offer capped:</strong> {selectedOffer.status}</p>
                                        <p className='text-black'><strong>Allowed countries:</strong> {selectedOffer.country}</p>
                                    </div>
                                </div>
                                <div className='w-7/12 flex flex-col space-y-1'>
                                    <div className='mt-10 space-y-6 border-b-2 border-gray-100'>
                                        <p className='text-black'><strong>Description : </strong> {selectedOffer.info_share} </p>
                                        <p className='text-black underline'><strong>Restrictions</strong></p>
                                        <p className='text-black'><span className='font-semibold'>Media Types Restricted : </span> {selectedOffer.media_types_restricted} </p>
                                        <p className='text-black underline'><strong>Not Allowed</strong></p>
                                        <p className='text-black'><span className='font-semibold'>Countries </span> {selectedOffer.blacklisted_country} </p>
                                        <p className='text-black'><strong>Loyalty : </strong>{selectedOffer.loyalty}</p>
                                        <br />
                                        <br />
                                    </div>
                                    <div>
                                        <p className='text-black text-xs '>By checking the box below, you agree to promote the offer in accordance with the restrictions above.</p>
                                        <div className='flex flex-row mt-3 space-x-1'>
                                            <input type="checkbox" className='h-3 w-3 mt-1' />
                                            <p className='text-black'> I Agree</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='mt-4 border-b-[1px] border-gray-100' />
                            <div className='flex justify-end space-x-7'>
                                <button onClick={closeModal} className="mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600">Close</button>
                                <button onClick={closeModal} className="mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600">Apply</button>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(SocialTable);