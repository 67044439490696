import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";
import axios from 'axios';
import LZString from 'lz-string';

const PieChart = () => {

  const [pieData, setPieData] = useState([]);

  const [requestOffers, setRequestOffers] = useState([]);
  const [additionalData, setAdditionalData] = useState({});

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`https://apidealer.devcir.co/api/request-offers`);
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       const filteredData = data.filter(item => item.status === "Approved");
  //       setRequestOffers(filteredData);

  //       const additionalDataPromises = filteredData.map(async (report) => {
  //         const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
  //         return { id: report.id, data: response.data };
  //       });

  //       const additionalDataResults = await Promise.all(additionalDataPromises);
  //       const additionalDataMap = additionalDataResults.reduce((acc, { id, data }) => {
  //         acc[id] = data;
  //         return acc;
  //       }, {});

  //       console.log("Payout API: ", additionalDataMap);
  //       setAdditionalData(additionalDataMap);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedRequestOffers = LZString.decompress(localStorage.getItem('requestOffers'));
        const requestOffers = JSON.parse(storedRequestOffers);
        setRequestOffers(requestOffers)

        const storedAdditionalData = LZString.decompress(localStorage.getItem('additionalData'));
        const additionalData = JSON.parse(storedAdditionalData);
        setAdditionalData(additionalData)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const chartData = requestOffers.map(item => {
    const itemName = item.campaign_name;
    const itemClicks = (item.payout * additionalData[item.id]?.conversion) || 0;

    return {
      id: itemName,
      label: itemName,
      value: itemClicks,
    };
  }).filter(item => item.value !== 0);

  const chartlast10 = chartData.slice(-10);

  console.log("Chart Data:", chartlast10);

  return (
    <ResponsivePie
      data={chartlast10}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: 'pink_yellowGreen' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#ffffff"
      radialLabelsLinkOffset={10}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      slicesLabelsSkipAngle={10}
      arcLinkLabelsSkipAngle={5}
      arcLinkLabelsTextColor="#ff01ff"
      arcLabelsSkipAngle={7}
      slicesLabelsTextColor="#ffffff"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={({ datum }) => (
        <div style={{ color: '#ffffff', background: 'rgba(0, 0, 0, 0.7)', padding: '10px', borderRadius: '5px' }}>
          <div>ID: {datum.id}</div>
          <div>Value: {datum.value}</div>
        </div>
      )}
      legends={[
        {
          anchor: 'bottom',
          direction: 'column',
          justify: false,
          translateX: 0,
          translateY: '200px',
          itemsSpacing: 20,
          itemWidth: 100,
          itemHeight: 20,
          itemTextColor: '#ff01ff', // Change to white for legend items
          itemDirection: 'top-to-bottom',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000' // Change to black on hover
              }
            }
          ]
        },
      ]}
      theme={{
        tooltip: {
          container: {
            background: '#333',
          },
        },
      }}
    />
  );
};

export default PieChart;
