import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';

const EcommerceToolkit = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const [offer, setOffer] = useState('');
    const [updatedSince, setUpdatedSince] = useState('');
    const [downloadAvailability, setDownloadAvailability] = useState('Both');

    const handleApply = () => {
        console.log('Filter applied with values:', {
            offer,
            updatedSince,
            downloadAvailability,
        });
    };

    
    const handleClear = () => {
        setOffer('');
        setUpdatedSince('');
        setDownloadAvailability('Both');
    };


    const sampleData = [
        { catalogId: '001', offer: 'Offer A', name: 'Catalog 1', language: 'EN', numberOfItems: 100, lastUpdated: '2023-05-01', actions: 'Download' },
        { catalogId: '002', offer: 'Offer B', name: 'Catalog 2', language: 'FR', numberOfItems: 200, lastUpdated: '2023-05-10', actions: 'Pending' },
        { catalogId: '003', offer: 'Offer C', name: 'Catalog 3', language: 'ES', numberOfItems: 150, lastUpdated: '2023-04-15', actions: 'Request' },
        { catalogId: '004', offer: 'Offer D', name: 'Catalog 4', language: 'DE', numberOfItems: 250, lastUpdated: '2023-05-20', actions: 'Download' },
        { catalogId: '005', offer: 'Offer E', name: 'Catalog 5', language: 'IT', numberOfItems: 300, lastUpdated: '2023-04-25', actions: 'Pending' },
    ];

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl mb-4"> 
                <TopBar />

                <div className='mt-6 bg-white'>
                    <label className='text-xl text-gray-500 tracking-wider'>ECOMMERCE TOOLKIT</label>
                </div>

                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>TOOLS</h1>
                    </div>

                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">

                            <p className="mt-2 ml-2 mr-2 p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700">
                                In this section, you will be able to browse through your available Product Catalogs.
                                These catalogs consist of specific products that your brands would like you to promote.
                                They offer detailed information about the items, including descriptions, prices, and stock availability.
                                Below, in the Actions column, you have the following options:
                                <ul className="list-disc pl-5 mt-2">
                                    <li><strong>Request:</strong> Click to request a catalog (processing may take 48 hours or longer).</li>
                                    <li><strong>Pending:</strong> Displayed while your request is being processed.</li>
                                    <li><strong>Download:</strong> Download the catalog in "CSV" format once available.</li>
                                </ul>
                            </p>
                            <div className="mt-6 mr-2 flex space-x-4 mb-4">
                                <div className="w-1/3 space-x-3 flex flex-row">
                                    <label className="block text-gray-700 mt-2 mb-2" htmlFor="offer">Offer</label>
                                    <select
                                        id="offer"
                                        value={offer}
                                        onChange={(e) => setOffer(e.target.value)}
                                        className="text-black w-7/12 px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                                    >
                                        <option value="">ALL</option>
                                        <option value="Offer A">Offer A</option>
                                        <option value="Offer B">Offer B</option>
                                        <option value="Offer C">Offer C</option>
                                        <option value="Offer D">Offer D</option>
                                        <option value="Offer E">Offer E</option>
                                    </select>
                                </div>
                                <div className="w-1/3 space-x-3 flex flex-row">
                                    <label className="block text-gray-700 mt-2 mb-2" htmlFor="updatedSince">Updated Since</label>
                                    <input
                                        type="date"
                                        id="updatedSince"
                                        value={updatedSince}
                                        onChange={(e) => setUpdatedSince(e.target.value)}
                                        className="text-black w-7/12 px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                                    />
                                </div>
                                <div className="w-1/3 space-x-3 flex flex-row">
                                    <label className="block text-gray-700 mt-2 mb-2" htmlFor="downloadAvailability">Download Availability</label>
                                    <select
                                        id="downloadAvailability"
                                        value={downloadAvailability}
                                        onChange={(e) => setDownloadAvailability(e.target.value)}
                                        className="text-black w-7/12 px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                                    >
                                        <option value="Both">Both</option>
                                        <option value="Available">Available</option>
                                        <option value="Not Available">Not Available</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex space-x-4 mb-4">
                                <button
                                    onClick={handleApply}
                                    className="px-4 py-2 bg-yellow-500 text-white font-semibold rounded-md hover:bg-yellow-600"
                                >
                                    Apply
                                </button>
                                <button
                                    onClick={handleClear}
                                    className="px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-md hover:bg-gray-400"
                                >
                                    Clear
                                </button>
                            </div>

                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border border-gray-300">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">CATALOG ID</th>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">OFFER</th>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">NAME</th>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">LANGUAGE</th>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">NUMBER OF ITEMS</th>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">LAST UPDATED</th>
                                            <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sampleData.map((data, index) => (
                                            <tr key={index} className="hover:bg-gray-100">
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.catalogId}</td>
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.offer}</td>
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.name}</td>
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.language}</td>
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.numberOfItems}</td>
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.lastUpdated}</td>
                                                <td className="px-4 py-2 border-b border-gray-300 text-black">{data.actions}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(EcommerceToolkit)