import React from 'react';
import { connect } from 'react-redux';

const DisplayData = ({ userId, userFields }) => {

    return (
        <div>
            <h1 className='text-black'>User ID: <span className='text-red-600'>{userId }</span></h1>
            <h1 className='text-black'>User ID: <span className='text-red-600'>{userFields.name}</span></h1>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
  });

  export default connect(mapStateToProps)(DisplayData);