import React, { useEffect, useState } from 'react';
import frame from '../../assets/images/Frame 111.svg';
import BalanceIcon from '../../assets/images/image 47.svg';
import FaqIcon from '../../assets/images/image 48.svg';
import LogoutIcon from '../../assets/images/logout.png';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

const TopBar = ({ userId, userFields }) => {
    const navigate = useNavigate(); 

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const [payouts, setPayouts] = useState([]);
    useEffect(() => {
        const fetchPayoutsByUser = (userId, userField) => {
            fetch(`https://apidealer.devcir.co/api/payouts/${userId}/${userField}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    const pendingPayouts = data.filter(payout => payout.status === 'Pending');
                    setPayouts(pendingPayouts);
                })
                .catch(error => console.log(error));
        };

        fetchPayoutsByUser(id, field);
    }, [id, field]);

    useEffect(() => {
        console.log(payouts);
    }, [payouts]);

    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            let hours = now.getHours();
            const minutes = now.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
            setCurrentTime(formattedTime);
        };

        const interval = setInterval(updateTime, 1000);

        return () => clearInterval(interval);
    }, []);

    const balanced = payouts[0]?.balance || 0;

    const handleLogout = () => {
        localStorage.removeItem('userData'); 

        navigate('/'); 
    };

    return (
        <div className="bg-white text-black flex justify-between items-center px-4 py-2 shadow-gray-400 shadow-lg rounded-tl-3xl">
            <div className="flex items-center space-x-4">
                <div className="flex items-center justify-center w-8 h-8 rounded-lg">
                    <img src={frame} alt="" />
                </div>
            </div>
            <div className="bg-white flex flex-row text-black rounded-lg px-2 py-1 border-gray-300">
                <div className="flex">
                    <img src={BalanceIcon} alt="Icon" width={'30px'} height={'30px'} />
                </div>
                <div className="flex flex-col space-y-1 ml-2">
                    <p className="text-[12px] font-medium">Balance: ${balanced ? balanced : 0}</p>
                    <p className="text-xs">Ready for withdrawal: ${balanced >= 100 ? balanced : '0.00'}</p>
                </div>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-[#230B4D] font-extrabold text-[20px] underline">{currentTime}</p>
                <p className="text-sm ml-2">Your Local Time</p>
            </div>
            <div className="flex items-center space-x-4">
                <div className="flex items-center justify-center text-gray-800">
                    <img src={FaqIcon} alt="Icon" width={'20px'} height={'20px'} />
                    <p className='text-[20px] font-normal'>FAQ</p>
                </div>
                <div className="relative">
                    <div className='flex items-center'>
                        <img
                            src={LogoutIcon} 
                            alt="Logout" 
                            height={'20px'} 
                            width={'20px'} 
                            onClick={handleLogout} 
                            className="cursor-pointer"
                        />
                        <div className='ml-3'>
                            <input type="search" className="bg-gray-100 text-black rounded-full w-[161px] h-[35px] border border-[#58165C] px-4 focus:border-none" placeholder="Search" />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg className="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(TopBar);
