import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img from '../assets/images/lock.svg';
import logo from '../assets/images/Logo.png';
import axios from 'axios';
import LZString from 'lz-string';

const AdminLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [incorrectEmail, setIncorrectEmail] = useState(false);
    const [incorrectPass, setIncorrectPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [requestOffers, setRequestOffers] = useState([]);
    const [additionalData, setAdditionalData] = useState({});

    const fetchData = async () => {
        try {
            console.log('Starting data fetch...');
            const response = await fetch(`https://apidealer.devcir.co/api/request-offers`);
            console.log('RequestOffers API Response:', response);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Raw requestOffers data:', data);

            const filteredData = data.filter(item => item.status === "Approved");
            console.log('Filtered requestOffers:', filteredData);

            setRequestOffers(filteredData);

            // Compress and store requestOffers in localStorage
            const compressedRequestOffers = LZString.compress(JSON.stringify(filteredData));
            localStorage.setItem('requestOffers', compressedRequestOffers);
            console.log('requestOffers stored in localStorage');

            const additionalDataPromises = filteredData.map(async (report) => {
                const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
                return { id: report.id, data: response.data };
            });

            const additionalDataResults = await Promise.all(additionalDataPromises);
            const additionalDataMap = additionalDataResults.reduce((acc, { id, data }) => {
                acc[id] = data;
                return acc;
            }, {});

            console.log("Final additionalDataMap:", additionalDataMap);
            setAdditionalData(additionalDataMap);

            // Compress and store additionalData in localStorage
            const compressedAdditionalData = LZString.compress(JSON.stringify(additionalDataMap));
            localStorage.setItem('additionalData', compressedAdditionalData);
            console.log('additionalData stored in localStorage');

            setLoading(false); // Stop the loader after all data is fetched and stored
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false); // Stop the loader if there's an error
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Show loader when login starts

        if (email === 'admin@gmail.com' && password === 'admin') {
            console.log('Login successful');
            await fetchData(); // Fetch data before navigating
            navigate('/admindashboard'); // Navigate only after data fetch completes
        } else {
            setIncorrectEmail(email !== 'admin@gmail.com');
            setIncorrectPass(password !== 'admin');
            setLoading(false); // Hide loader if login fails
        }
    };

    return (
        <>
            <div className='flex flex-row Login_Div justify-evenly bg-'>
                <div className='flex justify-center w-2/4'>
                    <div className='w-1/2 mt-24'>
                        <form className='mt-[180px]' onSubmit={handleLogin}>
                            <div className="relative z-0 w-[440px] group ml-[340px]">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-gray-400">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                                    </svg>
                                </div>
                                <input type="email" name="floating_email" id="floating_email"
                                    className={`w-3/4 block py-2.5 pl-10 pr-7 text-sm text-white bg-transparent border border-gray-400 focus:outline-none ${incorrectEmail ? 'focus:border-red-600 border-red-600' : 'focus:border-[#f442bc]'} focus:ring-0 peer placeholder-gray-400`}
                                    placeholder="USERNAME" required value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="relative z-0 w-[440px] group ml-[340px] mt-[20px]">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <img src={img} alt="" width={"22px"} height={"20px"} />
                                </div>
                                <input type="password" name="floating_password" id="floating_password"
                                    className={`block py-2.5 pl-10 pr-7 w-3/4 text-sm text-white bg-transparent border border-gray-400 focus:outline-none ${incorrectPass ? 'focus:border-red-600 border-red-600' : 'focus:border-[#f442bc]'} focus:ring-0 peer placeholder-gray-400`}
                                    placeholder="PASSWORD" required value={password} onChange={(e) => setPassword(e.target.value)} />
                                {incorrectPass && (
                                    <div className='text-red-600'>
                                        <p className='italic text-xs'>Incorrect Email or Password</p>
                                    </div>
                                )}
                            </div>
                            <br />
                            <br />
                            <button type='submit' className='h-12 w-[332px] mt-2 ml-[340px] bg-[#000000] text-white rounded-lg font-bold tracking-wider flex justify-center items-center'>
                                {loading ? (
                                    <div className="loader"></div> // Display the spinner while loading
                                ) : (
                                    'LOGIN'
                                )}
                            </button>
                            <p className='ml-[560px] mt-2 w-[220px]'>Forgot password?</p>
                        </form>
                    </div>
                </div>
                <div className='w-2/4'>
                    <img src={logo} alt="Logo" className='mt-24 w-[440px] ml-[-240px]' />
                </div>
            </div>

            {/* Spinner CSS */}
            <style jsx="true">{`
                .loader {
                    border: 4px solid #f3f3f3;
                    border-top: 4px solid #f442bc;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    animation: spin 1s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </>
    );
};

export default AdminLogin;
