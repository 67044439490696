import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import 'react-datepicker/dist/react-datepicker.css';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Agency_SignUp from "./pages/Agency_SignUp";
import LoginPage from "./pages/LoginPage";
import Affiliate_SignUp from "./pages/Affiliate_SignUp";
import SmartLink_SignUp from "./pages/SmartLink_SignUp";
import SmartLink_Login from "./pages/Smartlink_Login";
import UserDash from "./pages/UserDashboard/UserDash";
import OfferTables from "./pages/UserDashboard/OfferTables";
import PendingOffer from "./pages/UserDashboard/PendingOffer";
import NewOffers from "./pages/UserDashboard/NewOffers";
import DisplayData from "./pages/DisplayData";
import Dataget from "./components/dataget";
import MyCampaignTable from "./pages/UserDashboard/MyCampaignTable";
import GetReduxData from "./pages/UserDashboard/GetReduxData";
import UpdateStateData from "./pages/UserDashboard/UpdateStateData";
import RecentlyViewed from "./pages/UserDashboard/RecentlyViewed";
import { useState } from "react";
import ConfirmRequest from "./pages/UserDashboard/ConfirmRequest";
import SocialTable from "./pages/UserDashboard/SocialTable";
import IGamingTable from "./pages/UserDashboard/IGamingTable";
import SmartLinkTable from "./pages/UserDashboard/SmartLinkTable";
import DailySummary from "./pages/UserDashboard/Reports/DailySummary";
import GlobalReport from "./pages/UserDashboard/Reports/GlobalReport";
import CampaignSummary from "./pages/UserDashboard/Reports/CampaignSummary";
import EcommerceReport from "./pages/UserDashboard/Reports/EcommerceReport";
import SocialSmartLinkSummary from "./pages/UserDashboard/Reports/SocialSmartLinkSummary";
import CreativeSummary from "./pages/UserDashboard/Reports/CreativeSummary";
import SubAffiliateSummary from "./pages/UserDashboard/Reports/SubAffiliateSummary";
import RotationSummary from "./pages/UserDashboard/Reports/RotationSummary";
import EventReport from "./pages/UserDashboard/Reports/EventReport";
import ConversionReport from "./pages/UserDashboard/Reports/ConversionReport";
import ClickReport from "./pages/UserDashboard/Reports/ClickReport";
import Paymentinfo from "./pages/UserDashboard/Billing/Paymentinfo";
import PaymentReport from "./pages/UserDashboard/Billing/PaymentReport";
import ReferralPayment from "./pages/UserDashboard/Billing/ReferralPayment";
import AccountSetting from "./pages/UserDashboard/Tools/AccountSetting";
import TeamManagement from "./pages/UserDashboard/Tools/TeamManagement";
import GlobalPostback from "./pages/UserDashboard/Tools/GlobalPostback";
import Alerts from "./pages/UserDashboard/Tools/Alerts";
import MyDomains from "./pages/UserDashboard/Tools/MyDomains";
import Api from "./pages/UserDashboard/Tools/Api";
import ConversionApi from "./pages/UserDashboard/Tools/ConversionApi";
import EcommerceToolkit from "./pages/UserDashboard/Tools/EcommerceToolkit";
import ReferralProg from "./pages/UserDashboard/Tools/ReferralProg";
import Tutorails from "./pages/UserDashboard/Tools/Tutorails";
import Loyalty from "./pages/UserDashboard/Loyalty/Loyalty";
import DashboardSmart from "./pages/SmartLink/Dashboard";

import AgencyDashboard from "./pages/Agency/Dashboard";

import AgencyLogin from "./pages/AgencyLogin";
import Partners from "./pages/UserDashboard/Partners";
import OffersApplied from "./pages/UserDashboard/OffersApplied";
import AdminLogin from "./pages/AdminLogin";
import CampaignDetail from "./pages/CampaignDetail";
import PendingAppliedRequests from "./pages/UserDashboard/PendingAppliedRequests";
import { useLocation } from "react-router-dom";
import Admin_app from "./scenes/admin_app/Admin_app";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [dashboardColor, setDashboardColor] = useState('');
  const [dashboardStyle, setDashboardStyle] = useState({});
  const location = useLocation();

  const storedData = localStorage.getItem('userData');
  // const parsedData = JSON.parse(storedData);
  const parsedData = storedData ? JSON.parse(storedData) : null;
  // const field = parsedData.setUserFields.name;
  const field = parsedData?.setUserFields?.name;


  useEffect(() => {
    if (location.pathname === '/') {  
      setDashboardStyle({
        background: 'linear-gradient(140deg, #230B4D 2%, #8B216A 80%)'
      });
    } else if (location.pathname === '/AgencyLogin') {
      setDashboardStyle({
        background: 'linear-gradient(140deg, #230B4D 2%, #8B216A 80%)'
      });
    } else if (location.pathname === '/AdminLogin') {
      setDashboardStyle({
        background: 'linear-gradient(140deg, #12100E 2%, #2B4162 80%)'
      });

    } else if (field === 'Affiliate') {
      setDashboardStyle({
        background: 'linear-gradient(140deg, #230B4D 2%, #8B216A 80%)'
      });
    } else if (field === 'Agency') {
      setDashboardStyle({
        background: 'linear-gradient(140deg, #2A5470 3%, #380036 80%)'
      });
    } else {
      setDashboardStyle({
        background: 'linear-gradient(140deg, #230B4D 2%, #8B216A 80%)'
      });
      console.log('No field found');
    }
  }, [field, location.pathname]);

  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <div className="app" style={{ backgroundColor: dashboardColor }}> */}
        <div className="app" style={dashboardStyle}>
        <div className="app">
          <main className="content">
            
            <Routes>
              <Route path="/AdminDashboard" element={<Dashboard />} />
              <Route path="/AdminLogin" element={<AdminLogin />} />
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/AgencySignUp" element={<Agency_SignUp />} />
              <Route path="/AgencySignUp/:uniqueKey" element={<Agency_SignUp />} />
              <Route path="/" element={<LoginPage />} />
              <Route path="/AffiliateSignUp" element={<Affiliate_SignUp />} />
              <Route path="/AffiliateSignUp/:uniqueKey" element={<Affiliate_SignUp/>} />
              <Route path="/SmartLinkSignUp" element={<SmartLink_SignUp />} />
              <Route path="/SmartLinkLogin" element={<SmartLink_Login />} />
              <Route path="/AgencyLogin" element={<AgencyLogin />} />
              <Route path="/UserDashboard" element={<UserDash />} />
              <Route path="/AllTables" element={<OfferTables />} />
              <Route path="/PendingOffer" element={<PendingOffer />} />
              <Route path="/NewOffer" element={<NewOffers />} />
              <Route path="/MyCampaigns" element={<MyCampaignTable />} />
              <Route path="/RecentlyViewed" element={<RecentlyViewed />} />
              <Route path="/display-data" element={<DisplayData />} />
              <Route path="/getreduxdata" element={<GetReduxData />} />
              <Route path="/updatestate" element={<UpdateStateData />} />
              <Route path="/ConfirmRequest" element={<ConfirmRequest />} />
              <Route path="/Dataget" element={<Dataget />} />
              <Route path="/SocialNetwork" element={<SocialTable />} />
              <Route path="/iGamingTable" element={<IGamingTable />} />
              <Route path="/SmartLinkTable" element={<SmartLinkTable />} />
              <Route path="/DailySummary" element={<DailySummary />} />
              <Route path="/GlobalReports" element={<GlobalReport />} />
              <Route path="/CampaignSummary" element={<CampaignSummary />} />
              <Route path="/EcommerceReport" element={<EcommerceReport />} />
              <Route path="/SocialSmartLinkSummary" element={<SocialSmartLinkSummary />} />
              <Route path="/CreativeSummary" element={<CreativeSummary />} />
              <Route path="/SubAffiliateSummary" element={<SubAffiliateSummary />} />
              <Route path="/RotationSummary" element={<RotationSummary />} />
              <Route path="/EventReport" element={<EventReport />} />
              <Route path="/ConversionReport" element={<ConversionReport />} />
              <Route path="/ClickReport" element={<ClickReport />} />
              <Route path="/Paymentinfo" element={<Paymentinfo />} />
              <Route path="/PaymentReport" element={<PaymentReport />} />
              <Route path="/ReferralPayment" element={<ReferralPayment />} />
              <Route path="/AccountSetting" element={<AccountSetting />} />
              <Route path="/TeamManagement" element={<TeamManagement />} />
              <Route path="/GlobalPostback" element={<GlobalPostback />} />
              <Route path="/Alerts" element={<Alerts />} />
              <Route path="/MyDomains" element={<MyDomains />} />
              <Route path="/ApiTool" element={<Api />} />
              <Route path="/ConversionApiTool" element={<ConversionApi />} />
              <Route path="/EcommerceToolkit" element={<EcommerceToolkit />} />
              <Route path="/ReferralProg" element={<ReferralProg />} />
              <Route path="/Tutorails" element={<Tutorails />} />
              <Route path="/Loyalty" element={<Loyalty />} />
              <Route path="/SmartLinkDashboard" element={<DashboardSmart />} />
              <Route path="/AgencyDashboard" element={<AgencyDashboard />} />
              <Route path="/Partners" element={<Partners />} />
              <Route path="/OfferApplied" element={<OffersApplied />} />
              <Route path="/CampaignDetail/:offerId" element={<CampaignDetail />} />
              <Route path="/PendingAppliedOffers" element={<PendingAppliedRequests />} />
              <Route path="/Admin_app" element={<Admin_app />} />
            </Routes>
          </main>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

export default App;
