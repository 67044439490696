import React, { useEffect, useState } from "react";
import TopBar from "../UserDashboard/TopBar";
import Sidebar from "../UserDashboard/Sidebar";
import { connect } from "react-redux";
import axios from "axios";
import Arrow from "../../assets/images/Arrow 1.svg";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import format from "date-fns/format";
import calendarIcon from "../../assets/images/image 51.svg";
import statIcn from "../../assets/images/image 52.svg";
import ReportIcn from "../../assets/images/image 54.svg";
import { Line } from "react-chartjs-2";

const Dashboard = ({ userId, userFields }) => {
  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);
  const id = parsedData.id;
  const field = parsedData.setUserFields.name;
  const [requestOffers, setRequestOffers] = useState([]);
  const [additionalData, setAdditionalData] = useState({});
  const [finalData, setFinalData] = useState({});
  const [fina2lData, setFinal2Data] = useState({});
  const [totalClickss, setTotalClickss] = useState(0);
  const [conversionss, setConversionss] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [totalPayouts, setTotalPayouts] = useState(0);
  const [averageEPC, setAverageEPC] = useState(0);
  const [filterOpen, setFilterOpen] = useState(true);
  const today = new Date();
  const [clicks, setClicks] = useState();
  const [conversion, setConversion] = useState();

  useEffect(() => {
    const fetchRequestOffers = async () => {
      try {
        // const response2 = await fetch(`https://apidealer.devcir.co/api/request-offers/${item.item_id}`);
        const response2 = await fetch(
          `https://apidealer.devcir.co/api/user-request-offer/${id}/${field}`
        );
        if (!response2.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response2.json();
        setRequestOffers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRequestOffers();
  }, [id, field]);

  useEffect(() => {
    const fetchAdditionalData = async () => {
      try {
        const additionalDataPromises = requestOffers.map(async (report) => {
          // const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
          const response = await axios.get(
            `https://apidealer.devcir.co/api/price-table/host/${report.id}`
          );
          return { id: report.id, data: response.data };
        });
        const additionalDataResults = await Promise.all(additionalDataPromises);

        const additionalDataMap = additionalDataResults.reduce(
          (acc, { id, data }) => {
            acc[id] = data;
            return acc;
          },
          {}
        );
        console.log("Payout API: ", additionalDataMap);
        setAdditionalData(additionalDataMap);

        const totalClicks = Object.values(additionalDataMap).reduce(
          (acc, obj) => parseInt(acc) + (parseInt(obj.clicks) || 0),
          0
        );

        const totalConvers = Object.values(additionalDataMap).reduce(
          (acc, obj) => parseInt(acc) + (parseInt(obj.conversion) || 0),
          0
        );

        setTotalClickss(totalClicks);
        setConversionss(totalConvers);
        setTotalEvents(totalClicks + totalConvers);

        const totalSum = requestOffers.reduce((acc, report) => {
          if (additionalDataMap[report.id]) {
            const conversion =
              parseInt(additionalDataMap[report.id].conversion) || 0;
            const payout = parseFloat(report.payout) || 0;

            return acc + payout * conversion;
          }
          return acc;
        }, 0);

        setTotalPayouts(totalSum);

        const averageEPC =
          totalClicks > 0 ? parseFloat(totalSum / totalClicks) : 0;
        setAverageEPC(averageEPC);

        const updatepayload = {
          user_id: id,
          user_field: field,
          balance: totalSum,
        };

        try {
          // Send the PATCH request to the API
          const response = await axios.put(
            `https://apidealer.devcir.co/api/payouts/update`,
            updatepayload,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log(`Success: ${JSON.stringify(response.data)}`);
        } catch (error) {
          console.log(
            `Error: ${
              error.response ? error.response.data.message : error.message
            }`
          );
        }
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };
    if (requestOffers.length > 0) {
      fetchAdditionalData();
    }
  }, [requestOffers]);

  const [columnVisibility, setColumnVisibility] = useState({
    CampaignName: true,
    Visits: true,
    Consversions: true,
    Clicks: true,
    Amount: true,
    Revenue: true,
    Events: true,
    CR: true,
    Epc: true,
    FinalData: true,
  });

  const [shownBy, setShownBy] = useState("Total");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const options = ["Total", "Offers", "Smartlinks"];

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    fetch("https://apidealer.devcir.co/api/payment_reports")
      .then((response) => response.json())
      .then((data) => {
        if (!data || !Array.isArray(data)) {
          throw new Error("Invalid data format");
        }

        const clicks = requestOffers.map(
          (item) => additionalData[item.id].clicks
        );
        const conversions = requestOffers.map(
          (item) => additionalData[item.id].conversion
        );
        const events = requestOffers.map(
          (item) =>
            parseInt(additionalData[item.id].conversion) +
            parseInt(additionalData[item.id].clicks)
        );

        console.log("Clicks ", clicks);
        console.log("conversions", conversions);
        console.log("Events", events);

        setChartData({
          labels: Array.from({ length: clicks.length }, (_, i) =>
            i < 10 ? `0${i}` : `${i}`
          ),
          datasets: [
            {
              label: "Clicks",
              data: clicks,
              yAxisID: "y-axis-2",
              borderColor: "#230B4D",
              backgroundColor: "#230B4D",
              fill: false,
              pointStyle: "circle",
              pointRadius: 4,
              pointBorderColor: "#230B4D",
              pointBackgroundColor: "#230B4D",
            },
            {
              label: "Conversions",
              data: conversions,
              yAxisID: "y-axis-1",
              borderColor: "#B54993",
              backgroundColor: "#B54993",
              fill: false,
              pointStyle: "circle",
              pointRadius: 4,
              pointBorderColor: "#B54993",
              pointBackgroundColor: "#B54993",
            },
            {
              label: "Events",
              data: events,
              yAxisID: "y-axis-1",
              borderColor: "#9F3BB0",
              backgroundColor: "#9F3BB0",
              fill: false,
              pointStyle: "circle",
              pointRadius: 4,
              pointBorderColor: "#9F3BB0",
              pointBackgroundColor: "#9F3BB0",
            },
          ],
        });
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [additionalData]);

  const optionss = {
    responsive: true,
    scales: {
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          position: "left",
          ticks: {
            beginAtZero: true,
            min: -1,
            max: 11,
            stepSize: 1,
          },
          gridLines: {
            drawOnChartArea: true,
          },
        },
        {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          ticks: {
            beginAtZero: true,
            min: -1,
            max: 11,
            stepSize: 1,
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: false,
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        fontColor: "#000",
        boxWidth: 10,
        padding: 20,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
  };

  const [filters, setFilters] = useState({
    campaign_name: "",
    country: "",
    platform: "",
    vertical: "",
    price_format: "",
    payout: "",
    flow: "",
    trackingType: "",
    media_types_allowed: "",
    mediaTypesRestricted: "",
    wifi: "",
    incentivizedPromotion: "",
    postLeadsViaAPI: "",
    targetDemo: "",
    age: "",
    adult_traffic: "",
    creativePreapproval: "",
    carrier: "",
    status: "",
    loyaltyProg: "",
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: today,
      endDate: today,
      key: "selection",
    },
  ]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  useEffect(() => {
    setDateRange([
      {
        startDate: new Date(filters.startDate),
        endDate: new Date(filters.endDate),
        key: "selection",
      },
    ]);
  }, [filters.startDate, filters.endDate]);

  const removeDuplicates = (data, key) => {
    const uniqueData = Array.from(new Set(data.map((item) => item[key]))).map(
      (uniqueKey) => {
        return data.find((item) => item[key] === uniqueKey);
      }
    );
    return uniqueData;
  };

  const startOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const endOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  const isEmptyFilters = (filters) => {
    return Object.values(filters).every((value) => value === "");
  };

  const filteredOffers = removeDuplicates(
    requestOffers.filter((offer) => {
      const offerDate = new Date(offer.created_at);
      const today = new Date();

      // Initial display of offers created on the current date
      if (isEmptyFilters(filters)) {
        return (
          startOfDay(offerDate) >= startOfDay(today) &&
          endOfDay(offerDate) <= endOfDay(today)
        );
      }

      // Filter based on provided filters
      return (
        Object.entries(filters).every(([key, value]) => {
          if (key === "startDate" || key === "endDate") {
            return true;
          }
          if (value !== "") {
            const regex = new RegExp(value, "i");
            return regex.test(offer[key]);
          }
          return true;
        }) &&
        (!filters.startDate ||
          startOfDay(offerDate) >= startOfDay(new Date(filters.startDate))) &&
        (!filters.endDate ||
          endOfDay(offerDate) <= endOfDay(new Date(filters.endDate)))
      );
    }),
    "campaign_name"
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchOffers = filteredOffers.filter((offer) =>
    offer.campaign_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="ml-2">
      <button
        data-drawer-target="sidebar-multi-level-sidebar"
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <Sidebar />

      <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
        <TopBar />
        <div className="mt-6 bg-white flex justify-between">
          <label className="text-[24px] text-[#000000] font-semibold tracking-wider ml-5">
            DASHBOARD
          </label>
          <button
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
            className="bg-[#EFEFEF] w-24 h-6 rounded-full mr-5 flex flex-row space-x-1 shadow-gray-400 shadow-md"
          >
            <img src={Arrow} alt="" className="mt-[6px] ml-6" />
            <span className="text-black text-[14px] font-semibold">
              {!filterOpen ? "Filter" : "Hide"}
            </span>
          </button>
        </div>

        {filterOpen && (
          <div className="flex items-center w-full justify-center mt-5">
            <div className="flex justify-around items-center p-4 w-11/12 h-48 bg-white shadow-2xl rounded-2xl space-x-4 border-t-4 border-[#C9C7C7]">
              <div className="flex items-center space-x-2 relative">
                <label className="font-semibold text-[#1F282E] text-[16px]">
                  Period
                </label>
                <img
                  src={calendarIcon}
                  alt="Icon"
                  width={"34px"}
                  height={"34px"}
                />
                <input
                  type="text"
                  value={`${format(
                    new Date(filters.startDate),
                    "MM/dd/yyyy"
                  )} - ${format(new Date(filters.endDate), "MM/dd/yyyy")}`}
                  readOnly
                  className="border-none h-9 bg-[#F5F5F5] rounded-md text-black"
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                />
                {isDatePickerOpen && (
                  <div className="absolute top-full mt-2 z-50">
                    <DateRangePicker
                      ranges={dateRange}
                      onChange={(item) => {
                        const { startDate, endDate } = item.selection;
                        handleFilterChange("startDate", startDate);
                        handleFilterChange("endDate", endDate);
                      }}
                      moveRangeOnFirstSelection={false}
                      className="shadow-lg text-black"
                    />
                  </div>
                )}
              </div>
              {/* <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[16px]">Shown by</label>
                  <select
                    value={shownBy}
                    onChange={(e) => setShownBy(e.target.value)}
                    className="p-2 border-none bg-[#F5F5F5] w-28 rounded-md text-black"
                  >
                    {options.map((option) => (
                      <option key={option} className='border border-[#C9C7C7] rounded-sm' value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div> */}
              <div className="flex space-x-3">
                <button className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">
                  Apply
                </button>
                <button className="bg-[#315EFF] bg-opacity-40 text-[#0B31FB] p-2 items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center w-full justify-center mt-14">
          <div className="flex flex-col items-center w-11/12 h-52 bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
            <div className="flex items-center space-x-2 border-b border-gray-300 w-full p-3">
              <img src={statIcn} alt="Icon" width={"40px"} height={"40px"} />
              <h2 className="text-xl font-bold text-black">Top Metrics</h2>
            </div>
            <div className="w-11/12 mt-6">
              <div className="flex justify-between p-4 bg-white border border-[#230B4D] rounded-3xl">
                <div className="text-center">
                  <p className="text-sm font-medium text-black">CLICKS</p>
                  <p className="mt-1 text-lg font-semibold text-black">
                    {totalClickss}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm font-medium text-black">CONVERSIONS</p>
                  <p className="mt-1 text-lg font-semibold text-black">
                    {conversionss}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm font-medium text-black">EVENTS</p>
                  <p className="mt-1 text-lg font-semibold text-black">
                    {totalEvents}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm font-medium text-black">REVENUE</p>
                  <p className="mt-1 text-lg font-semibold text-black">
                    ${totalPayouts}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-sm font-medium text-black ">AVG EPC</p>
                  <p className="mt-1 text-lg font-semibold text-black">
                    ${averageEPC.toFixed(3)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full justify-center mt-14">
          <div className="flex flex-col items-center w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-10">
            <div className="flex items-center space-x-2 border-b border-gray-300 w-full p-3 mb-7">
              <img src={ReportIcn} alt="Icon" width={"40px"} height={"40px"} />
              <h2 className="text-xl font-bold text-black">Overview</h2>
            </div>
            {chartData ? (
              <Line data={chartData} options={optionss} />
            ) : (
              <p>Loading...</p>
            )}
            <div className="w-11/12 mt-6 mb-12">
              <div className="flex justify-between p-4 bg-white border border-[#230B4D] rounded-3xl">
                <div className="mt-4">
                  <h2 className="text-xl font-bold text-black">Campaigns</h2>
                </div>
                <div className="overflow-x-auto flex">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: "0 10px" }}
                  >
                    <colgroup>
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.CampaignName
                            ? "table-cell"
                            : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Visits
                            ? "table-cell"
                            : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Clicks
                            ? "table-cell"
                            : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Consversions
                            ? "table-cell"
                            : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Amount
                            ? "table-cell"
                            : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Events
                            ? "table-cell"
                            : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.CR ? "table-cell" : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Epc ? "table-cell" : "none",
                        }}
                      />
                      <col
                        className="text-[10px]"
                        style={{
                          display: columnVisibility.Revenue
                            ? "table-cell"
                            : "none",
                        }}
                      />
                    </colgroup>
                    <thead className="">
                      <tr className="text-center">
                        {columnVisibility.CampaignName && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black rounded-l-lg">
                            Campaign Name
                          </th>
                        )}
                        {columnVisibility.Visits && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            Visits
                          </th>
                        )}
                        {columnVisibility.Clicks && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            Clicks
                          </th>
                        )}
                        {columnVisibility.Consversions && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            Consversions
                          </th>
                        )}
                        {columnVisibility.Amount && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            Amount
                          </th>
                        )}
                        {columnVisibility.Events && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            Events
                          </th>
                        )}
                        {columnVisibility.CR && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            CR
                          </th>
                        )}
                        {columnVisibility.Epc && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black">
                            EPC
                          </th>
                        )}
                        {columnVisibility.Revenue && (
                          <th className="p-3 text-left bg-gray-100 border-r border-b border-gray-200 text-black rounded-r-lg">
                            Revenue
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {searchOffers.map((report) => {
                        return (
                          <tr
                            key={report.id}
                            className="border-b border-opacity-20 text-center"
                          >
                            {columnVisibility.CampaignName && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {report.campaign_name}
                              </td>
                            )}
                            {columnVisibility.Visits && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? additionalData[report.id].visits || 0
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.Clicks && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? additionalData[report.id].clicks || 0
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.Consversions && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? additionalData[report.id].conversion || 0
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.Amount && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? report.payout === "CPA"
                                    ? isNaN(
                                        report.payout *
                                          additionalData[report.id].conversion
                                      )
                                      ? "$ 0"
                                      : `$ ${
                                          report.payout *
                                          additionalData[report.id].conversion
                                        }`
                                    : isNaN(
                                        report.payout *
                                          additionalData[report.id].conversion
                                      )
                                    ? "$ 0"
                                    : `$ ${
                                        report.payout *
                                        additionalData[report.id].conversion
                                      }`
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.Events && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? isNaN(
                                      parseInt(
                                        additionalData[report.id].conversion
                                      ) +
                                        parseInt(
                                          additionalData[report.id].clicks
                                        )
                                    )
                                    ? 0
                                    : parseInt(
                                        additionalData[report.id].conversion
                                      ) +
                                      parseInt(additionalData[report.id].clicks)
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.CR && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? isNaN(
                                      additionalData[report.id].conversion /
                                        additionalData[report.id].clicks
                                    )
                                    ? `0 %`
                                    : `${
                                        (additionalData[report.id].conversion /
                                          additionalData[report.id].clicks) *
                                        100
                                      } %`
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.Epc && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? isNaN(
                                      (report.payout *
                                        additionalData[report.id].conversion) /
                                        additionalData[report.id].clicks
                                    )
                                    ? `$ 0`
                                    : `$ ${`${
                                        (report.payout *
                                          additionalData[report.id]
                                            .conversion) /
                                        additionalData[report.id].clicks
                                      }`}`
                                  : "Loading..."}
                              </td>
                            )}
                            {columnVisibility.Revenue && (
                              <td className="p-3 border-r border-b border-gray-200 text-black">
                                {additionalData[report.id]
                                  ? report.price_format === "CPA"
                                    ? isNaN(
                                        report.payout *
                                          additionalData[report.id].conversion
                                      )
                                      ? "$ 0"
                                      : `$ ${
                                          report.payout *
                                          additionalData[report.id].conversion
                                        }`
                                    : isNaN(
                                        report.payout *
                                          additionalData[report.id].conversion *
                                          (report.percent / 100)
                                      )
                                    ? "$ 0"
                                    : `$ ${
                                        report.payout *
                                        additionalData[report.id].conversion *
                                        (report.percent / 100)
                                      }`
                                  : "Loading..."}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <hr className="bg-gray-200 w-full h-[2px]" />
            {searchOffers.length == 0 && (
              <div className="flex w-full pl-10 mt-4 mb-8">
                <h2 className="text-[13px] font-normal text-[#8E8E8E]">
                  No data available - Please choose a campaign for the report or
                  decrease a report period
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  userFields: state.user.userFields,
});

export default connect(mapStateToProps)(Dashboard);
