import React from 'react';
import { connect } from 'react-redux';

const UpdatedDataChecker = ({ userId, userFields }) => {
  return (
    <div>
      <h2 className='text-black'>Updated Data</h2>
      <p className='text-black'>User ID: {userId}</p>
      <p className='text-black'>Name: {userFields.name}</p> {/* Accessing 'name' property of userFields */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  userFields: state.user.userFields,
});

export default connect(mapStateToProps)(UpdatedDataChecker);

