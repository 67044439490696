import { combineReducers } from 'redux';

const initialState = {
  userId: null,
  userFields: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_ID':
      return { ...state, userId: action.payload };
    case 'SET_USER_FIELDS':
      return { ...state, userFields: action.payload };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  // Add other reducers if needed
});

export default rootReducer;
