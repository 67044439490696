import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import currencyCodes from 'currency-codes';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import bcrypt from "bcryptjs";
import axios from 'axios';

const AccountSetting = ({ userId, userFields }) => {

    // const id = userId;
    // const field = userFields.name;

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');

    const timezones = moment.tz.names();
    const currencies = currencyCodes.codes();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiUrl = field === "Affiliate" 
                    ? `https://apidealer.devcir.co/api/affiliate-registrations/${id}`
                    : `https://apidealer.devcir.co/api/agency-registrations/${id}`;
                
                const response = await axios.get(apiUrl);
                const { password, timeZone, currency } = response.data;

                const match = await bcrypt.compare(oldPassword, password);
                if (!match) {
                    console.log("Old password does not match.");
                    return;
                }

                setSelectedTimezone(timeZone);
                setSelectedCurrency(currency);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [id, field, oldPassword]);

    const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSave = async () => {
        if (newPassword !== confirmPassword) {
            console.log("Passwords do not match.");
            return;
        }
        try {
            const hashedPassword = await bcrypt.hash(confirmPassword, 7);
            const saveItems = {
                password: hashedPassword,
            };

            const apiUrl = field === "Affiliate" 
                ? `https://apidealer.devcir.co/api/affiliate-registrations/update/${id}`
                : `https://apidealer.devcir.co/api/agency-registration/${id}`;
            
            await axios.put(apiUrl, saveItems);

            alert("Password Updated successfully")
            setOldPassword("")
            setNewPassword("")
            setConfirmPassword("")

        } catch (error) {
            console.error("Error updating data:", error);
        }
    };


    const verticalOptions = [
        { value: 1, label: "Astrology, Clairvoyance" },
        { value: 2, label: "Binary options" },
        { value: 3, label: "Bingo" },
        { value: 4, label: "Business Opportunity" },
        { value: 5, label: "Brain Health" },
        { value: 6, label: "Bathroom" },
        { value: 7, label: "Casual Dating" },
        { value: 8, label: "CBD" },
        { value: 9, label: "Car insurance" },
        { value: 10, label: "Credit Repair" },
        { value: 11, label: "Download" },
        { value: 12, label: "Diet & Weight Loss" },
        { value: 13, label: "Debt Consolidation" },
        { value: 14, label: "Debt Relief" },
        { value: 15, label: "Education" },
        { value: 16, label: "Ecommerce" },
        { value: 17, label: "Financial" },
        { value: 18, label: "Financial-" },
        { value: 19, label: "Forex" },
        { value: 20, label: "Finance (Pay-Per-Call)" },
        { value: 21, label: "Gaming" },
        { value: 22, label: "Home Improvement" },
        { value: 23, label: "Hair Loss" },
        { value: 24, label: "GamingHealth insurance" },
        { value: 25, label: "Home Improvement (Pay-Per-Call)" },
        { value: 26, label: "HVAC" },
        { value: 27, label: "iGaming" },
        { value: 28, label: "Insurance (other)" },
        { value: 29, label: "Insurance (Pay-Per-Call)" },
        { value: 30, label: "Irresponsible <lending></lending>" },
        { value: 31, label: "Life insurance" },
        { value: 32, label: "Loans" },
        { value: 33, label: "Legal services (home improvement)" },
        { value: 34, label: "Mobile app, Social Networking" },
        { value: 35, label: "Mainstream dating" },
        { value: 36, label: "Mobile content" },
        { value: 37, label: "Mobile app, Download" },
        { value: 38, label: "mobile content, Carrier Billing" },
        { value: 39, label: "Male Enhancement" },
        { value: 40, label: "Muscle Gain" },
        { value: 41, label: "Mass Tort" },
        { value: 42, label: "Nutra (Other)" },
        { value: 43, label: "Penny Auction" },
        { value: 44, label: "Other" },
        { value: 45, label: "Push Subscriptions" },
        { value: 46, label: "Property insurance" },
        { value: 47, label: "Pest control" },
        { value: 48, label: "Personal Injury" },
        { value: 49, label: "Real Estate" },
        { value: 50, label: "Roofing" },
        { value: 51, label: "Real estate insurance" },
        { value: 52, label: "Refinance/Mortgages" },
        { value: 53, label: "Residential services" },
        { value: 54, label: "Reverse Mortgage" },
        { value: 55, label: "Streaming_" },
        { value: 56, label: "Streaming" },
        { value: 57, label: "Sweepstakes" },
        { value: 58, label: "Smartlink" },
        { value: 59, label: "Sale" },
        { value: 60, label: "Skincare" },
        { value: 61, label: "Solar" },
        { value: 62, label: "Services" },
        { value: 63, label: "Survey" },
        { value: 64, label: "Student Debt" },
        { value: 65, label: "Training vertical" },
        { value: 66, label: "Travel" },
        { value: 67, label: "Training" },
        { value: 68, label: "Travel (Pay-Per-Call)" },
        { value: 69, label: "Vouchers/Leadgen" },
        { value: 70, label: "Windows" },
        { value: 71, label: "_Gaming" },
    ];

    return (
        <div className='ml-2'>

            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 min-h-screen bg-white mt-7 rounded-tl-3xl">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <label className='ml-4 text-xl text-gray-500 tracking-wider'>ACCOUNT SETTINGS</label>
                </div>

                <div className="mt-6 bg-black">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>EDIT ACCOUNT SETTINGS</h1>
                    </div>
                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="flex flex-row">
                            <h1 className='text-gray-600 w-1/4 font-light text-lg mt-3 mb-3 ml-4'>Password settings</h1>
                        </div>
                        {/* <div className="flex flex-row items-center">
                            <label htmlFor="timezoneSelect" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                TimeZone
                            </label>
                            <select
                                id="timezoneSelect"
                                className='ml-4 h-10 w-1/3 p-1 border border-gray-300 rounded mt-3 mb-3 text-black'
                                value={selectedTimezone}
                                onChange={(e) => setSelectedTimezone(e.target.value)}
                            >
                                {timezones.map((timezone) => {
                                    const offset = moment.tz(timezone).format('Z');
                                    return (
                                        <option key={timezone} value={timezone}>
                                            (UTC{offset}) {timezone}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="flex flex-row items-center mb-4">
                            <label htmlFor="currencySelect" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                Currency
                            </label>
                            <select
                                id="currencySelect"
                                className='ml-6 h-10 w-1/3 p-1 border border-gray-300 rounded mt-3 mb-3 text-black'
                                value={selectedCurrency}
                                onChange={(e) => setSelectedCurrency(e.target.value)}
                            >
                                {currencies.map((currency) => (
                                    <option key={currency} value={currency}>
                                        {currency}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        <div className="flex flex-row">
                            <div className="flex flex-row items-center">
                                <label htmlFor="oldPassword" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                    Old Password
                                </label>
                                <div className="relative ml-6 mt-3 mb-3">
                                    <input
                                        type={showOldPassword ? "text" : "password"}
                                        id="oldPassword"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        className='h-10 w-10/12 p-1 border border-gray-300 rounded text-black'
                                    />
                                    <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer text-gray-600">
                                        <button type="button" onClick={toggleShowOldPassword}>
                                            {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div className="flex flex-row items-center">
                                <label htmlFor="newPassword" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                    New Password
                                </label>
                                <div className="relative ml-6 mt-3 mb-3">
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        id="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className='h-10 w-10/12 p-1 border border-gray-300 rounded text-black'
                                    />
                                    <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer text-gray-600">
                                        <button type="button" onClick={toggleShowNewPassword}>
                                            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row items-center">
                                <label htmlFor="confirmPassword" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                    Confirm Password
                                </label>
                                <div className="relative ml-6 mt-3 mb-3">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className='h-10 w-10/12 p-1 border border-gray-300 rounded text-black'
                                    />
                                    <div className="absolute inset-y-0 right-10 flex items-center cursor-pointer text-gray-600">
                                        <button type="button" onClick={toggleShowConfirmPassword}>
                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 
                        <hr className='border-1' />
                        <div className="flex flex-row mt-2">
                            <h1 className='text-gray-600 w-1/4 font-light text-lg mt-3 mb-3 ml-4'>Choose Preferred Verticals</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-4 ml-4">
                            {verticalOptions.map((vertical) => (
                                <div key={vertical.value} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id={`vertical-${vertical.value}`}
                                        value={vertical.value}
                                        className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    />
                                    <label htmlFor={`vertical-${vertical.value}`} className="ml-2 text-gray-600 text-sm">
                                        {vertical.label}
                                    </label>
                                </div>
                            ))}
                        </div> */}
                        <hr className='border-1 mt-3 mb-5' />

                        <div className="mt-6 mb-6 ml-4 flex justify-start space-x-4">
                            <button
                                className="bg-orange-400 text-white px-4 py-2 rounded"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(AccountSetting)