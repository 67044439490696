import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';

const Alerts = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const alerts = [
        { name: 'Offer approaching conversion cap (75%)', subscribed: 'No', type: 'email', entities: 'All' },
        { name: 'Offer reached conversion cap', subscribed: 'Yes', type: 'email', entities: 'All' },
        { name: 'Campaign approaching conversion cap (75%)', subscribed: 'No', type: 'email', entities: 'All' },
        { name: 'Campaign reached conversion cap', subscribed: 'No', type: 'email', entities: 'All' },
        { name: 'Advertiser approaching conversion cap (75%)', subscribed: 'No', type: 'both', entities: 'All' },
        { name: 'Advertiser reached conversion cap', subscribed: 'Yes', type: 'email', entities: 'All' },
        // Add more rows as needed
    ];

    const [filter, setFilter] = useState('both');

    const filteredAlerts = alerts.filter(alert => {
        if (filter === 'both') return true;
        return alert.subscribed.toLowerCase() === filter;
    });

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl mb-7">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <label className='text-xl text-gray-500 tracking-wider'>ALERTS</label>
                </div>

                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>EDIT ACCOUNT SETTINGS</h1>
                    </div>

                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">
                            <div className='mt-6 ml-3 flex space-x-3'>
                                <label htmlFor="subscriptionFilter" className="block text-sm mt-2 font-medium text-gray-700">
                                    Subscribed
                                </label>
                                <select
                                    id="subscriptionFilter"
                                    className="block w-1/5 pl-3 pr-10 py-2 text-base border-2 text-black border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <option value="both">Both</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            <div className="flex space-x-6 ml-3 mt-4 mb-4">
                                <button
                                    className="px-5 py-1 bg-yellow-500 text-white font-semibold rounded-md hover:bg-yellow-600"
                                    onClick={() => setFilter(filter)}
                                >
                                    Apply
                                </button>
                                <button
                                    className="px-5 py-1 bg-gray-300 text-gray-700 font-semibold rounded-md hover:bg-gray-400"
                                    onClick={() => setFilter('both')}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Alert Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscribed</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscription Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entities</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredAlerts.map((alert, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{alert.name}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{alert.subscribed}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{alert.type}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{alert.entities}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                <button className="text-indigo-600 hover:text-indigo-900">Edit</button>
                                                <button className="ml-2 text-red-600 hover:text-red-900">Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(Alerts)