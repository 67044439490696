import React, { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import axios from 'axios';
import LZString from 'lz-string';

const BarChart = () => {
    const [requestOffers, setRequestOffers] = useState([]);
    const [additionalData, setAdditionalData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedRequestOffers = LZString.decompress(localStorage.getItem('requestOffers'));
                const requestOffers = JSON.parse(storedRequestOffers);
                setRequestOffers(requestOffers)
                
                const storedAdditionalData = LZString.decompress(localStorage.getItem('additionalData'));
                const additionalData = JSON.parse(storedAdditionalData);
                setAdditionalData(additionalData)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Adjust chartData to use the full data array
    const chartData = requestOffers.map(item => {
        const itemDate = new Date(item.created_at).toLocaleString(); 
        const itemClicks = additionalData[item.id]?.clicks || 0;
        const itemConversions = additionalData[item.id]?.conversion || 0;

        return {
            date: itemDate,
            clicks: itemClicks,
            conversions: itemConversions,
        };
    });

    const chartlast10 = chartData.slice(-10);

    console.log("Chart Data:", chartData);

    return (
        <ResponsiveBar
            data={chartlast10}
            keys={['clicks', 'conversions']}
            indexBy="date"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.2}  // Adjusted padding to ensure all bars are visible
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'pink_yellowGreen'}}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#ffffff',
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#ffffff',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'date',
                legendPosition: 'middle',
                legendOffset: 32,
                format: value => value.split(',')[0], // Ensure date only
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'value',
                legendPosition: 'middle',
                legendOffset: -40,
            }}
            enableLabel={true}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="#ffffff"
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fill: '#ffffff',
                        },
                    }
                },
                legends: {
                    text: {
                        fill: '#ffffff',
                    },
                },
            }}
            role="application"
            barAriaLabel={function (e) {
                return `${e.id}: ${e.formattedValue} in date: ${e.indexValue}`;
            }}
        />
    );
};

export default BarChart;
