import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LZString from 'lz-string';

const LineChart = () => {

  const [requestOffers, setRequestOffers] = useState([]);
  const [additionalData, setAdditionalData] = useState({});

  //   useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const response = await fetch(`https://apidealer.devcir.co/api/request-offers`);
  //             if (!response.ok) {
  //                 throw new Error('Network response was not ok');
  //             }
  //             const data = await response.json();
  //             const filteredData = data.filter(item => item.status === "Approved");
  //             setRequestOffers(filteredData);

  //             const additionalDataPromises = filteredData.map(async (report) => {
  //                 const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
  //                 return { id: report.id, data: response.data };
  //             });

  //             const additionalDataResults = await Promise.all(additionalDataPromises);
  //             const additionalDataMap = additionalDataResults.reduce((acc, { id, data }) => {
  //                 acc[id] = data;
  //                 return acc;
  //             }, {});

  //             console.log("Payout API: ", additionalDataMap);
  //             setAdditionalData(additionalDataMap);
  //         } catch (error) {
  //             console.error('Error fetching data:', error);
  //         }
  //     };

  //     fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedRequestOffers = LZString.decompress(localStorage.getItem('requestOffers'));
        const requestOffers = JSON.parse(storedRequestOffers);
        setRequestOffers(requestOffers)

        const storedAdditionalData = LZString.decompress(localStorage.getItem('additionalData'));
        const additionalData = JSON.parse(storedAdditionalData);
        setAdditionalData(additionalData)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const prepareChartData = () => {
    const labels = requestOffers.map(item => item.campaign_name);
    const clicksData = requestOffers.map(item => {
      return additionalData[item.id]?.conversion || 0;
    });
    const totalClicksData = requestOffers.map(item => {
      return additionalData[item.id]?.visits || 0;
    });

    const chartData = labels.map((label, index) => ({
      campaign_name: label,
      cr: totalClicksData[index],
      epc: clicksData[index],
    }));

    const filteredChartData = chartData.filter(item => item.cr !== 0 || item.epc !== 0);

    const last10ChartData = filteredChartData.slice(-10);

    return [
      {
        id: 'CR',
        data: last10ChartData.map(item => ({
          x: item.campaign_name,
          y: item.cr,
        })),
      },
      {
        id: 'EPC',
        data: last10ChartData.map(item => ({
          x: item.campaign_name,
          y: item.epc,
        })),
      },
    ];
  };

  console.log("Line chart data", prepareChartData());


  return (
    <ResponsiveLine
      data={prepareChartData()}
      margin={{ top: 150, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      axisTop={{
        orient: 'top',
        tickSize: 20,
        tickPadding: 5,
        tickRotation: -26,
        legend: '',
        legendOffset: 5,
        truncateTickAt: 0
      }}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'value',
        legendOffset: -40,
        legendPosition: 'middle',
        legendTextColor: '#00000',
      }}
      colors={{ scheme: 'pink_yellowGreen' }}
      pointSize={10}
      lineWidth={5}
      pointColor="#ffffff"
      pointBorderColor="black"
      labelTextColor="#ffffff"
      pointBorderWidth={2}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          textColor: 'white',
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        },
      ]}

      theme={{

        legends: {
          text: {
            fill: '#ffffff',
          },
        },
        axis: {
          "legend": {
            "text": {
              "fontSize": 14,
              "fill": "#ff01ff",
              "outlineWidth": 0,
              "outlineColor": "transparent"
            }
          },
          ticks: {
            text: {
              fill: '#ff01ff',
            },
          }
        },
        "tooltip": {
          "wrapper": {},
          "container": {
            "background": "#ff01ff",
            "color": "white",
            "fontSize": 12
          },
        },

      }}
    />
  );
};

export default LineChart;
