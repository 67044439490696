import React from 'react';
import { connect } from 'react-redux';
import { setUserId, setUserFields } from '../../actions/userActions';
import { useNavigate } from 'react-router-dom';
import GetReduxData from './GetReduxData';
import DisplayData from '../DisplayData';

const UpdateStateData = ({ setUserId, setUserFields }) => {
    const naviate = useNavigate()
    const handleClick = () => {
        setUserId(4);
        setUserFields({ name: 'Agency' });
        naviate('/display-data')
    };

    return (
        <div>
            <h2 className='text-black'>Update State Data</h2>
            <button className='text-black' onClick={handleClick}>Update User Data</button>
            <GetReduxData />
            <DisplayData />
        </div>
    );
};

export default connect(null, { setUserId, setUserFields })(UpdateStateData);