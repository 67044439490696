import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import logo from "../../assets/images/Logo.png";
import UserIcon from "../../assets/images/Ellipse 1.png";
import DashboardIcon from "../../assets/images/Dashboard Layout.svg";
import OfferIcon from "../../assets/images/image 2.svg";
import ReportsIcon from "../../assets/images/image 3.svg";
import BillingIcon from "../../assets/images/image 6.svg";
import ToolsIcon from "../../assets/images/image 7.svg";

const Sidebar = ({ userId, userFields }) => {
  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);
  const id = parsedData.id;
  const field = parsedData.setUserFields.name;
  const [numberForm, setNumberForm] = useState(0);
  const [newForm, setNewForms] = useState(false);
  const [socialNewForm, setSocialNewForms] = useState(0);
  const [iGamingNewForm, setIGamingNewForms] = useState(0);
  const [eCPM, setECPM] = useState(0);
  const [loading, setLoading] = useState(false);
  // States of Second Form

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedBlacklist, setSelectedBlacklist] = useState([]);
  const [platform, setPlatform] = useState([]);

  const filteredCountryOptions = options.filter(
    (option) =>
      !selectedBlacklist.some(
        (blacklistItem) => blacklistItem.value === option.value
      )
  );

  const filteredBlacklistOptions = options.filter(
    (option) =>
      !selected.some((selectedItem) => selectedItem.value === option.value)
  );

  const platformOptions = [
    { value: 1, label: "Desktop (pc)" },
    { value: 2, label: "Desktop (mac)" },
    { value: 3, label: "Mobile (android)" },
    { value: 4, label: "Mobile (ios)" },
    { value: 5, label: "Mobile (other)" },
    { value: 6, label: "Tablet (android)" },
    { value: 7, label: "Tablet (ios)" },
    { value: 8, label: "Tablet (other)" },
  ];

  const [selectedVertical, setSelectedVertical] = useState(null);
  const verticalOptions = [
    { value: 1, label: "Astrology, Clairvoyance" },
    { value: 2, label: "Binary options" },
    { value: 3, label: "Bingo" },
    { value: 4, label: "Business Opportunity" },
    { value: 5, label: "Brain Health" },
    { value: 6, label: "Bathroom" },
    { value: 7, label: "Casual Dating" },
    { value: 8, label: "CBD" },
    { value: 9, label: "Car insurance" },
    { value: 10, label: "Credit Repair" },
    { value: 11, label: "Download" },
    { value: 12, label: "Diet & Weight Loss" },
    { value: 13, label: "Debt Consolidation" },
    { value: 14, label: "Debt Relief" },
    { value: 15, label: "Education" },
    { value: 16, label: "Ecommerce" },
    { value: 17, label: "Financial" },
    { value: 18, label: "Financial-" },
    { value: 19, label: "Forex" },
    { value: 20, label: "Finance (Pay-Per-Call)" },
    { value: 21, label: "Gaming" },
    { value: 22, label: "Home Improvement" },
    { value: 23, label: "Hair Loss" },
    { value: 24, label: "GamingHealth insurance" },
    { value: 25, label: "Home Improvement (Pay-Per-Call)" },
    { value: 26, label: "HVAC" },
    { value: 27, label: "iGaming" },
    { value: 28, label: "Insurance (other)" },
    { value: 29, label: "Insurance (Pay-Per-Call)" },
    { value: 30, label: "Irresponsible <lending></lending>" },
    { value: 31, label: "Life insurance" },
    { value: 32, label: "Loans" },
    { value: 33, label: "Legal services (home improvement)" },
    { value: 34, label: "Mobile app, Social Networking" },
    { value: 35, label: "Mainstream dating" },
    { value: 36, label: "Mobile content" },
    { value: 37, label: "Mobile app, Download" },
    { value: 38, label: "mobile content, Carrier Billing" },
    { value: 39, label: "Male Enhancement" },
    { value: 40, label: "Muscle Gain" },
    { value: 41, label: "Mass Tort" },
    { value: 42, label: "Nutra (Other)" },
    { value: 43, label: "Penny Auction" },
    { value: 43, label: "Other" },
    { value: 43, label: "Push Subscriptions" },
    { value: 43, label: "Push Subscriptions" },
    { value: 43, label: "Property insurance" },
    { value: 43, label: "Pest control" },
    { value: 43, label: "Personal Injury" },
    { value: 43, label: "Real Estate" },
    { value: 43, label: "Roofing" },
    { value: 43, label: "Real estate insurance" },
    { value: 43, label: "Refinance/Mortgages" },
    { value: 43, label: "Residential services" },
    { value: 43, label: "Reverse Mortgage" },
    { value: 43, label: "Streaming_" },
    { value: 43, label: "Streaming" },
    { value: 43, label: "Sweepstakes" },
    { value: 43, label: "Smartlink" },
    { value: 43, label: "Sale" },
    { value: 43, label: "Skincare" },
    { value: 43, label: "Solar" },
    { value: 43, label: "Services" },
    { value: 43, label: "Survey" },
    { value: 43, label: "Student Debt" },
    { value: 43, label: "Training vertical" },
    { value: 43, label: "Travel" },
    { value: 43, label: "Training" },
    { value: 43, label: "Travel (Pay-Per-Call)" },
    { value: 43, label: "Vouchers/Leadgen" },
    { value: 43, label: "Windows" },
    { value: 43, label: "_Gaming" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "6px",
      fontSize: "16px",
      lineHeight: "1.5",
      minHeight: "45px",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#white"
        : state.isFocused
        ? "#e0e0e0"
        : "white",
      color: state.isSelected ? "#000" : "#333",
      padding: "10px 20px",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#e0e0e0",
        color: "#000",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#333",
      "&:hover": {
        color: "#007bff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "auto",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#888",
    }),
  };

  const handleVerticalChange = (selectedOption) => {
    setSelectedVertical(selectedOption);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://trial.mobiscroll.com/content/countries.json"
        );
        const data = await response.json();
        const countryOptions = data.map((country) => ({
          label: country.text,
          value: country.value,
          icon: `https://img.mobiscroll.com/demos/flags/${country.value}.png`,
        }));
        setOptions(countryOptions);
        console.log(options);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const [priceFormat, setPriceFormat] = useState("");
  const priceOption = [
    { value: 1, label: "CPA" },
    { value: 2, label: "RevShare" },
  ];

  const handleFormatPriceChange = (selectedOption) => {
    setPayout()
    setPercent()
    setPriceFormat(selectedOption);
  };

  const [payout, setPayout] = useState();
  const [percent, setPercent] = useState();

  //Form 2

  const [flow, setFlow] = useState([]);
  const flowOptions = [
    { value: 1, label: "CPC" },
    { value: 2, label: "CPI" },
    { value: 3, label: "CPI (APK)" },
    { value: 4, label: "CPE" },
    { value: 5, label: "CPL (SOI/Email Submit)" },
    { value: 6, label: "CPL (DOI)" },
    { value: 7, label: "CPS (Straight Sale)" },
    { value: 8, label: "CPS (First-Time Deposit)" },
    { value: 9, label: "CPS (Cash on Delivery)" },
    { value: 10, label: "Free Trial" },
    { value: 11, label: "CC Submit" },
    { value: 12, label: "DCB (1-click flow)" },
    { value: 13, label: "DCB (2-click flow)" },
    { value: 14, label: "Pin submit (MO flow)" },
    { value: 15, label: "Pin submit (MT flow)" },
    { value: 16, label: "Pin submit (Click2Sms)" },
    { value: 17, label: "Pay-per-call" },
    { value: 18, label: "Dynamic (Smartlink)" },
    { value: 19, label: "Click2Call" },
  ];

  const [mediaTypes, setMediaTypes] = useState([]);
  const [restrictedMediaTypes, setRestrictedMediaTypes] = useState([]);
  const mediaTypesOptions = [
    { value: 1, label: "SEO/SEM" },
    { value: 2, label: "Social Media (Facebook)" },
    { value: 3, label: "Native" },
    { value: 4, label: "Coupon sites" },
    { value: 5, label: "SMS" },
    { value: 6, label: "Instagram" },
    { value: 7, label: "Contextual" },
    { value: 8, label: "Retargeting" },
    { value: 9, label: "Review Site/Blog" },
    { value: 10, label: "Snapchat" },
    { value: 11, label: "Content lock" },
    { value: 12, label: "PPC/Search" },
    { value: 13, label: "TikTok" },
    { value: 14, label: "Email" },
    { value: 15, label: "Cashback services" },
    { value: 16, label: "Inapp" },
    { value: 17, label: "Streaming" },
    { value: 18, label: "YouTube" },
    { value: 19, label: "Adware" },
    { value: 20, label: "Banner/Display" },
    { value: 21, label: "Network" },
    { value: 22, label: "Push" },
    { value: 23, label: "Incentivized" },
    { value: 24, label: "Messengers" },
    { value: 25, label: "Social Media (other than Facebook)" },
    { value: 26, label: "Pop/Redirect" },
    { value: 27, label: "Other" },
  ];

  const filteredMediaTypesOptions = mediaTypesOptions.filter(
    (option) =>
      !restrictedMediaTypes.some(
        (restrictedItem) => restrictedItem.value === option.value
      )
  );

  // Filter function for restricted media types
  const filteredRestrictedMediaTypesOptions = mediaTypesOptions.filter(
    (option) =>
      !mediaTypes.some((allowedItem) => allowedItem.value === option.value)
  );

  const handleMediaTypesChange = (selected) => {
    setMediaTypes(selected);
    setRestrictedMediaTypes((prev) =>
      prev.filter((item) => !selected.some((s) => s.value === item.value))
    );
  };

  const handleRestrictedMediaTypesChange = (selected) => {
    setRestrictedMediaTypes(selected);
    setMediaTypes((prev) =>
      prev.filter((item) => !selected.some((s) => s.value === item.value))
    );
  };

  const [gender, setGender] = useState("");
  const handleOptionChange = (event) => {
    event.preventDefault();
    setGender(event.target.value);
  };

  const [trackingType, setTrackingType] = useState("");
  const handleTrackingChange = (event) => {
    event.preventDefault();
    setTrackingType(event.target.value);
  };

  const [adultTraffic, setAdultTraffic] = useState("");
  const handleAdultTraffic = (event) => {
    event.preventDefault();
    setAdultTraffic(event.target.value);
  };

  const [age, setAge] = useState("");
  const handleAgeChange = (event) => {
    event.preventDefault();
    setAge(event.target.value);
  };

  const [creativePreapproval, setCreativePreapproval] = useState("");
  const handleCreativePreapproval = (event) => {
    event.preventDefault();
    setCreativePreapproval(event.target.value);
  };

  const [incentivizedPromotion, setIncentivizedPromotion] = useState("");
  const handleIncentivizedPromotion = (event) => {
    event.preventDefault();
    setIncentivizedPromotion(event.target.value);
  };

  const [apiLeads, setApiLeads] = useState("");
  const handleApiLeads = (event) => {
    event.preventDefault();
    setApiLeads(event.target.value);
  };

  const [loyaltyProgram, setLoyaltyProgram] = useState("");
  const handleLoyalty = (event) => {
    event.preventDefault();
    setLoyaltyProgram(event.target.value);
  };

  const [productCatalog, setProductCatalog] = useState("");
  const handleProductCatalog = (event) => {
    event.preventDefault();
    setProductCatalog(event.target.value);
  };

  const [wifi3G, setWifi3G] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setWifi3G([...wifi3G, value]);
    } else {
      setWifi3G(wifi3G.filter((item) => item !== value));
    }
  };

  const [carrier, setCarrier] = useState("");

  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpenTwo, setIsDropdownOpenTwo] = useState(false);
  const toggleDropdownTwo = () => {
    setIsDropdownOpenTwo(!isDropdownOpenTwo);
  };

  const [isDropdownOpenThree, setIsDropdownOpenThree] = useState(false);
  const toggleDropdownThree = () => {
    setIsDropdownOpenThree(!isDropdownOpenThree);
  };

  const [isDropdownOpenFour, setIsDropdownOpenFour] = useState(false);
  const toggleDropdownFour = () => {
    setIsDropdownOpenFour(!isDropdownOpenFour);
  };

  const [isDropdownOpenFive, setIsDropdownOpenFive] = useState(false);
  const toggleDropdownFive = () => {
    setIsDropdownOpenFive(!isDropdownOpenFive);
  };

  const openForm = () => {
    setNumberForm(1);
  };
  const openForm1 = () => {
    setNewForms(true);
  };
  const openSoicalForm = () => {
    setSocialNewForms(1);
  };
  const openIGamingForm = () => {
    setIGamingNewForms(1);
  };

  // States Of first form

  const [campaignName, setCampaignName] = useState("");
  const [landingPage, setLandingPage] = useState("");
  const [domain, setDomain] = useState("");
  // const [redirect_URL, setRedirect_URL] = useState("");
  const [cpaRate, setCpaRate] = useState();
  const [runningSomewhere, setRunningSomewhere] = useState("");
  const [network, setNetwork] = useState("");
  const [switchRate, setSwitchRate] = useState(0);
  const [leadsPerDay, setLeadsPerDay] = useState();
  const [goesOffer, setGoesOffer] = useState("");
  const [trafficUse, setTrafficUse] = useState("");
  const [infoShare, setInfoShare] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    let newErrors = {};

    if (campaignName.trim() === "") {
      newErrors.campaignName = "Campaign name is required";
    }

    if (!landingPage.match(/^(https?:\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/i)) {
      newErrors.landingPage = "Enter a valid URL";
    }

    // if (!redirect_URL.match(/^(https?:\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/i)) {
    //   newErrors.redirect_URL = "Enter a valid Redirect URL";
    // }

    if (domain === "") {
      newErrors.domain = "Enter a valid Domain";
    }

    if (cpaRate <= 0 || isNaN(cpaRate)) {
      newErrors.cpaRate = "CPA rate must be a positive number";
    }

    if (runningSomewhere === "") {
      newErrors.runningSomewhere = "Please select an option";
    }

    if (runningSomewhere === "Yes" && network.trim() === "") {
      newErrors.network = "Network is required";
    }

    if (runningSomewhere === "Yes" && (switchRate <= 0 || isNaN(switchRate))) {
      newErrors.switchRate = "Switch rate must be a positive number";
    }

    if (leadsPerDay <= 0 || isNaN(leadsPerDay)) {
      newErrors.leadsPerDay = "Leads per day must be a positive number";
    }

    if (goesOffer.trim() === "") {
      newErrors.goesOffer = "Geos offer is required";
    }

    if (trafficUse.trim() === "") {
      newErrors.trafficUse = "Traffic use is required";
    }

    if (infoShare.trim() === "") {
      newErrors.infoShare = "Additional info is required";
    }

    if (imageFile === null) {
      newErrors.imageFile = "Please upload an image";
    }

    setErrors(newErrors);

    // If no errors, form is valid
    if (Object.keys(newErrors).length === 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  // Call validateForm whenever a field changes
  useEffect(() => {
    validateForm();
  }, [
    campaignName,
    landingPage,
    domain,
    // redirect_URL,
    cpaRate,
    runningSomewhere,
    network,
    switchRate,
    leadsPerDay,
    goesOffer,
    trafficUse,
    infoShare,
    imageFile,
  ]);

  const allFieldsFilled = () => {
    if (priceFormat.label === "RevShare") {
      return (
        (selected.length > 0 ||
        selectedBlacklist.length > 0) &&
        platform.length > 0 &&
        selectedVertical !== null &&
        (payout != "" && payout != 0 && payout != null)  &&
        (percent != "" && percent !=0 && percent != null) &&
        gender !== "" &&
        trackingType !== "" &&
        adultTraffic !== ""
      );
    } else if (priceFormat.label === "CPA") {
      return (
        (selected.length > 0 ||
        selectedBlacklist.length > 0) &&
        platform.length > 0 &&
        selectedVertical !== null &&
        (payout != "" && payout != 0 && payout != null) &&
        gender !== "" &&
        trackingType !== "" &&
        adultTraffic !== ""
      );
    } else {
      return (
        selected.length > 0 &&
        selectedBlacklist.length > 0 &&
        platform.length > 0 &&
        selectedVertical !== null &&
        gender !== "" &&
        trackingType !== "" &&
        adultTraffic !== ""
      );
    }
  };

  const isFormLastValid = () => {
    return (
      flow.length > 0 &&
      (mediaTypes.length > 0 ||
      restrictedMediaTypes.length > 0) &&
      creativePreapproval !== "" &&
      incentivizedPromotion !== "" &&
      adultTraffic !== "" &&
      age !== "" &&
      apiLeads !== "" &&
      loyaltyProgram !== "" &&
      network !== "null" && 
      productCatalog !== "" &&
      wifi3G.length > 0 &&
      carrier.trim() !== ""
    );
  };

  const handleSubmitFirst = async (e) => {
    e.preventDefault();
    console.log(
      `------------->First Form Submitted Successfully<-------------`
    );
    setNumberForm(2);
  };

  const handleSubmitSecond = async (e) => {
    e.preventDefault();
    console.log(
      `------------->Second Form Submitted Successfully<-------------`
    );
    setNumberForm(3);
  };

  const closeForm = () => {
    setNumberForm(0);
  };
  const closeForm1 = () => {
    setNewForms(false);
  };
  const closeSocialForm = () => {
    setSocialNewForms(0);
  };
  const closeIGamingForm = () => {
    setIGamingNewForms(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // First
    console.log(campaignName);
    console.log(landingPage);
    console.log(domain);
    // console.log(redirect_URL);
    console.log(cpaRate);
    console.log(runningSomewhere);
    console.log(leadsPerDay);
    console.log(goesOffer);
    console.log(trafficUse);
    console.log(infoShare);

    // Second
    const country = selected.map((option) => option.label).join(",");
    const blackListCountry = selectedBlacklist
      .map((option) => option.label)
      .join(",");
    const platformstr = platform.map((option) => option.label).join(",");

    console.log(country);
    console.log(blackListCountry);
    console.log(platformstr);
    console.log(selectedVertical);
    console.log(priceFormat);
    console.log(payout);
    console.log(gender);
    console.log(trackingType);
    console.log(adultTraffic);

    // Third
    const flowstr = flow.map((option) => option.label).join(",");
    const mediaTypeStr = mediaTypes.map((option) => option.label).join(",");
    const restrictedMediaTypeStr = restrictedMediaTypes
      .map((option) => option.label)
      .join(",");
    const wifi3Gstr = wifi3G.join(",");

    console.log(flowstr);
    console.log(mediaTypeStr);
    console.log(restrictedMediaTypeStr);
    console.log(creativePreapproval);
    console.log(incentivizedPromotion);
    console.log(age);
    console.log(apiLeads);
    console.log(loyaltyProgram);
    console.log(wifi3Gstr);
    console.log(carrier);

    try {
      // Create FormData object for multipart form-data
      const formData = new FormData();

      formData.append("userid", id);
      formData.append("campaign_name", campaignName);
      formData.append("landing_page", landingPage);
      formData.append("domain", domain);
      formData.append("cpa_rate", cpaRate);
      // formData.append("Redirect_URL", redirect_URL);
      formData.append("running_somewhere", runningSomewhere);
      formData.append("network", network);
      formData.append("switch_rate", switchRate);
      formData.append("leads_per_day", leadsPerDay);
      formData.append("goes_offer", goesOffer);
      formData.append("traffic_use", trafficUse);
      formData.append("info_share", infoShare);
      formData.append("userField", field);
      formData.append("price_format", priceFormat.label);
      formData.append("payout", payout);
      formData.append("percent", percent ? percent : 0);
      formData.append("country", country);
      formData.append("vertical", selectedVertical.label);
      formData.append("platform", platformstr);
      formData.append("blacklisted_country", blackListCountry);
      formData.append("flow", flowstr);
      formData.append("tracking_type", trackingType);
      formData.append("age", age);
      formData.append("gender", gender);
      formData.append("media_types_allowed", mediaTypeStr);
      formData.append("media_types_restricted", restrictedMediaTypeStr);
      formData.append("creative_preapproval", creativePreapproval);
      formData.append("loyalty", loyaltyProgram);
      formData.append("incentivized_promotion", incentivizedPromotion);
      formData.append("3g_wifi", wifi3Gstr);
      formData.append("adult_traffic", adultTraffic);
      formData.append("carrier", carrier);
      formData.append("post_leads_via_api", apiLeads);
      formData.append("product_catalogs", productCatalog);

      if (imageFile) {
        formData.append("imageUrl", imageFile);
      }

      // Send form data via POST request
      const response = await axios.post(
        "https://apidealer.devcir.co/api/request-offers",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response) {
        setCampaignName("");
        setLandingPage("");
        setDomain("");
        // setRedirect_URL("");
        setCpaRate("");
        setRunningSomewhere("");
        setLeadsPerDay("");
        setGoesOffer("");
        setTrafficUse("");
        setInfoShare("");
        setSelected([]);
        setSelectedBlacklist([]);
        setPlatform([]);
        setSelectedVertical(null);
        setPriceFormat("");
        setPayout("");
        setGender("");
        setTrackingType("");
        setAdultTraffic("");
        setFlow([]);
        setMediaTypes([]);
        setRestrictedMediaTypes([]);
        setCreativePreapproval("");
        setIncentivizedPromotion("");
        setAge("");
        setApiLeads("");
        setLoyaltyProgram("");
        setWifi3G([]);
        setCarrier("");
        setProductCatalog("");
        setImageFile(null);
      }
      console.log("Registration successful:", response.data);

      alert("Registration successful!");
      closeForm();
    } catch (error) {
      console.error("Error registering user:", error);
      alert("Error Registering!");
    } finally {
      setLoading(false);
    }
  };

  const handleNewOfferRequest = async (e) => {
    e.preventDefault();

    //First

    console.log(userId);
    console.log(userFields.name);
    console.log(campaignName);
    console.log(landingPage);
    console.log(cpaRate);
    console.log(runningSomewhere);
    console.log(leadsPerDay);
    console.log(goesOffer);
    console.log(trafficUse);
    console.log(infoShare);

    try {
      const formData = {
        user_id: id,
        user_field: field,
        campaign_name: campaignName,
        landing_page: landingPage,
        cpa_rate: cpaRate,
        running_somewhere: runningSomewhere,
        network: network,
        switch_rate: switchRate,
        leads_per_day: leadsPerDay,
        goes_offer: goesOffer,
        traffic_use: trafficUse,
        info_share: infoShare,
      };

      const response = await axios.post(
        "https://apidealer.devcir.co/api/request-new-offers",
        formData
      );
      // const response = await axios.post("https://apidealer.devcir.co/api/request-new-offers", formData);

      console.log("Registration successful:", response.data);

      // Show an alert
      alert("Registration successful!");
      closeForm1();
    } catch (error) {
      console.error("Error registering user:", error);
      alert("Error Registering!");
      closeForm1();
    }
  };

  const handleRunningChange = (event) => {
    setRunningSomewhere(event.target.value);
  };

  const handleSocialSubmit = async (e) => {
    const country = selected.map((option) => option.label).join(",");
    const blackListCountry = selectedBlacklist
      .map((option) => option.label)
      .join(",");
    const platformstr = platform.map((option) => option.label).join(",");
    const mediaTypeStr = mediaTypes.map((option) => option.label).join(",");
    const restrictedMediaTypeStr = restrictedMediaTypes
      .map((option) => option.label)
      .join(",");
    const wifi3Gstr = wifi3G.join(",");

    e.preventDefault();
    console.log("First Form DATA");
    console.log(campaignName);
    console.log(country);
    console.log(blackListCountry);
    console.log(platformstr);
    console.log(selectedVertical.label);
    console.log(gender);
    console.log(incentivizedPromotion);
    console.log(adultTraffic);
    console.log("Second Form DATA");
    console.log(eCPM);
    console.log(mediaTypeStr);
    console.log(restrictedMediaTypeStr);
    console.log(age);
    console.log(loyaltyProgram);
    console.log(wifi3Gstr);

    try {
      const formData = {
        campaign_name: campaignName,
        countries: country,
        blacklist_countries: blackListCountry,
        platform: platformstr,
        vertical: selectedVertical.label,
        gender: gender,
        incentivized_promotion: incentivizedPromotion,
        adult_traffic: adultTraffic,
        eCPM: eCPM,
        media_types: mediaTypeStr,
        restricted_media_types: restrictedMediaTypeStr,
        age: age,
        loyalty_program: loyaltyProgram,
        wifi_3g: wifi3Gstr,
        User_id: id,
        User_field: field,
      };

      const response = await axios.post(
        "https://apidealer.devcir.co/api/social-networks",
        formData
      );
      // const response = await axios.post("https://apidealer.devcir.co/api/social-networks", formData);

      console.log("Registration successful:", response.data);

      // Show an alert
      alert("Registration successful!");
      closeSocialForm();
    } catch (error) {
      console.error("Error registering user:", error);
      alert("Error Registering!");
      closeSocialForm();
    }
  };

  const handleiGamingSubmit = async (e) => {
    e.preventDefault();

    const country = selected.map((option) => option.label).join(",");
    const blackListCountry = selectedBlacklist
      .map((option) => option.label)
      .join(",");
    const platformstr = platform.map((option) => option.label).join(",");
    const mediaTypeStr = mediaTypes.map((option) => option.label).join(",");
    const restrictedMediaTypeStr = restrictedMediaTypes
      .map((option) => option.label)
      .join(",");
    const wifi3Gstr = wifi3G.join(",");

    e.preventDefault();
    console.log("First Form DATA");
    console.log(campaignName);
    console.log(country);
    console.log(blackListCountry);
    console.log(platformstr);
    console.log(selectedVertical.label);
    console.log(gender);
    console.log(incentivizedPromotion);
    console.log(adultTraffic);
    console.log("Second Form DATA");
    console.log(eCPM);
    console.log(mediaTypeStr);
    console.log(restrictedMediaTypeStr);
    console.log(age);
    console.log(loyaltyProgram);
    console.log(wifi3Gstr);

    try {
      const formData = {
        User_id: id,
        User_field: field,
        campaign_name: campaignName,
        countries: country,
        blacklist_countries: blackListCountry,
        platform: platformstr,
        vertical: selectedVertical.label,
        gender: gender,
        incentivized_promotion: incentivizedPromotion,
        adult_traffic: adultTraffic,
        eCPM: eCPM,
        media_types: mediaTypeStr,
        restricted_media_types: restrictedMediaTypeStr,
        age: age,
        loyalty_program: loyaltyProgram,
        wifi_3g: wifi3Gstr,
      };

      const response = await axios.post(
        "https://apidealer.devcir.co/api/igaming",
        formData
      );
      // const response = await axios.post("https://apidealer.devcir.co/api/igaming", formData);

      console.log("Registration successful:", response.data);

      // Show an alert
      alert("Registration successful!");
      closeIGamingForm();
    } catch (error) {
      console.error("Error registering user:", error);
      alert("Error Registering!");
      closeIGamingForm();
    }
  };

  const handleNavigateChange = () => {
    if (field === "Agency") {
      navigate("/AgencyDashboard");
    } else if (field === "Affiliate") {
      navigate("/UserDashboard");
    } else if (field === "SmartLink") {
      navigate("/SmartLinkDashboard");
    }
  };

  return (
    <>
      <aside
        id="sidebar-multi-level-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 "
        aria-label="Sidebar"
      >
        <div
          className={`h-full py-4 overflow-y-auto ${
            field === "Agency" ? "bg-transparent" : "bg-transparent"
          }`}
        >
          <img src={logo} alt="Logo" />
          <div className="w-full flex justify-center">
            <img
              src={UserIcon}
              alt="UserProfile"
              width={"80px"}
              className="mt-5"
            />
          </div>
          <div className="w-full flex justify-center mt-2">
            <h1>{parsedData.firstname}</h1>
          </div>
          <ul className="space-y-8 font-medium mt-8">
            <li>
              <button
                onClick={handleNavigateChange}
                className="flex w-full items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-gray-700 group"
              >
                <img
                  src={DashboardIcon}
                  alt="DashIcon"
                  width={"30px"}
                  height={"30px"}
                  className="ml-6"
                />
                <span className="ms-3 text-[20px] font-medium">Dashboard</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-text-white transition duration-75 rounded-lg group  dark:text-white hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={toggleDropdown}
              >
                <img
                  src={OfferIcon}
                  alt="DashIcon"
                  width={"30px"}
                  height={"30px"}
                  className="ml-6"
                />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-[20px] font-medium">
                  Offers
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg "
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={` space-y-2 bg-[#000000] bg-opacity-30 ${
                  isDropdownOpen ? "" : "hidden"
                }`}
              >
                <li>
                  <button
                    onClick={() => {
                      navigate("/AllTables");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4">Browse</span>
                  </button>
                </li>
                {field !== "Affiliate" && (
                  <>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/MyCampaigns");
                        }}
                        className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                      >
                        <span className="ml-4">My Campaigns</span>{" "}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/PendingOffer");
                        }}
                        className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                      >
                        <span className="ml-4">Pending Campaigns</span>{" "}
                      </button>
                    </li>
                  </>
                )}
                <li>
                  <button
                    onClick={() => {
                      navigate("/NewOffer");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4">New Offers</span>{" "}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      navigate("/RecentlyViewed");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4">Recently Viewed</span>{" "}
                  </button>
                </li>
                {field === "Affiliate" && (
                  <>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/PendingAppliedOffers");
                        }}
                        className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                      >
                        <span className="ml-4">pending offers</span>{" "}
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          navigate("/OfferApplied");
                        }}
                        className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                      >
                        <span className="ml-4">My Campaigns</span>{" "}
                      </button>
                    </li>
                  </>
                )}
                {/* <li>
                                    <a href="#" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group  dark:text-white hover:bg-gray-700">Rotations</a>
                                </li> */}
                {field !== "Affiliate" && (
                  <>
                    <li>
                      <button
                        onClick={openForm}
                        className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"
                      >
                        <span className="ml-4">Request New Offers</span>{" "}
                      </button>
                    </li>
                    {/* <li>
                                            <button onClick={openForm1} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group  dark:text-gray-300 hover:bg-gray-700 font-medium text-[20px]"><span className='ml-4'>Request New Offer</span> </button>
                                        </li> */}
                  </>
                )}
              </ul>
            </li>
            {/* <li>
                            <button
                                type="button"
                                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group  dark:text-white hover:bg-gray-700"
                                aria-controls="dropdown-example"
                                data-collapse-toggle="dropdown-example"
                                onClick={toggleDropdownTwo}
                            >
                                <img src={SmartLinkIcon} alt="DashIcon" width={"30px"} height={"30px"} className='ml-6'/>
                                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-[20px] font-medium ">SmartLinks</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                </svg>
                            </button>
                            <ul id="dropdown-example" className={`space-y-2 bg-[#000000] bg-opacity-30 ${isDropdownOpenTwo ? '' : 'hidden'}`}>
                                <li>
                                    <button onClick={() => { navigate('/SocialNetwork') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"><span className='ml-4'>Social Networking</span></button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/iGamingTable') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"><span className='ml-4'>iGaming</span></button>
                                </li>
                                {field !== "Affiliate" && (
                                    <li>
                                        <button onClick={() => { navigate('/SmartLinkTable') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"><span className='ml-4'>My Smartlinks</span></button>
                                    </li>
                                )}
                                {field !== "Affiliate" && (
                                    <>
                                        <li>
                                            <button onClick={openSoicalForm} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"><span className='ml-4'>Social Request</span></button>
                                        </li>
                                        <li>
                                            <button onClick={openIGamingForm} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"><span className='ml-4'>iGaming Request</span></button>
                                        </li>
                                    </>)}
                            </ul>
                        </li> */}
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group  dark:text-white hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={toggleDropdownThree}
              >
                <img
                  src={ReportsIcon}
                  alt="DashIcon"
                  width={"30px"}
                  height={"30px"}
                  className="ml-6"
                />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-[20px] font-medium">
                  Reports
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={` space-y-2 bg-[#000000] bg-opacity-30 ${
                  isDropdownOpenThree ? "" : "hidden"
                }`}
              >
                <li>
                  <button
                    onClick={() => {
                      navigate("/GlobalReports");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4"></span> Global Report
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      navigate("/DailySummary");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4"></span> Daily Summary
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      navigate("/CampaignSummary");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4"></span> Campaign summary
                  </button>
                </li>
                {/* <li>
                                    <button onClick={() => { navigate('/SocialSmartLinkSummary') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"><span className="ml-4"></span> Smartlink summary</button>
                                </li> 
                                <li>
                                    <button onClick={() => { navigate('/CreativeSummary') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">
                                        <span className="ml-4"></span> Creative summary</button>
                                </li>*/}
                <li>
                  <button
                    onClick={() => {
                      navigate("/SubAffiliateSummary");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[18px]"
                  >
                    <span className="ml-4"></span>SubAffiliate summary
                  </button>
                </li>
                {/* <li>
                                    <button onClick={() => { navigate('/RotationSummary') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">
                                        <span className="ml-4"></span> Rotation summary</button>
                                </li> */}
                <li>
                  <button
                    onClick={() => {
                      navigate("/EventReport");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4"></span> Event report
                  </button>
                </li>
                {/* <li>
                                    <button onClick={() => { navigate('/ConversionReport') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">
                                        <span className="ml-4"></span> Conversion report</button>
                                </li>    */}
                {/* <li>
                                    <button onClick={() => { navigate('/ClickReport') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">
                                        <span className="ml-4"></span> Click report</button>
                                </li> */}
              </ul>
            </li>
            {field !== "Affiliate" && (
              <li>
                <button
                  onClick={() => {
                    navigate("/ConfirmRequest");
                  }}
                  className="flex w-full items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-gray-700 group"
                >
                  <svg
                    className="flex-shrink-0 w-[30px] h-[30px] ml-6 text-white transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="ms-3 text-[20px] font-medium">
                    Confirm Requests
                  </span>
                </button>
              </li>
            )}
            {/* {field === "Affiliate" && (
                            <>
                                <li>
                                    <button onClick={() => { navigate('/OfferApplied') }} className="flex w-full items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-gray-700 group">
                                        <svg className="flex-shrink-0 w-[30px] h-[30px] ml-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span className="ms-3 text-[20px] font-medium">Offers Applied</span>
                                    </button>
                                </li>
                            </>)} */}
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group  dark:text-white hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={toggleDropdownFour}
              >
                <img
                  src={BillingIcon}
                  alt="DashIcon"
                  width={"30px"}
                  height={"30px"}
                  className="ml-6"
                />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-[20px] font-medium ">
                  Billing
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`space-y-2 bg-[#000000] bg-opacity-30 ${
                  isDropdownOpenFour ? "" : "hidden"
                }`}
              >
                <li>
                  <button
                    onClick={() => {
                      navigate("/PaymentReport");
                    }}
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group  dark:text-white hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4"></span> Payout Report
                  </button>
                </li>
                {/* <li>
                                    <button onClick={() => { navigate('/ReferralPayment') }} className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group  dark:text-white hover:bg-gray-700 font-medium text-[20px]"><span className="ml-4"></span> Refferal Payemnt</button>
                                </li> */}
                <li>
                  <button
                    onClick={() => {
                      navigate("/Paymentinfo");
                    }}
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group  dark:text-white hover:bg-gray-700 font-medium text-[20px]"
                  >
                    <span className="ml-4"></span> Payment Info
                  </button>
                </li>
              </ul>
            </li>
            {/* <li>
                            <button onClick={() => { navigate('/Loyalty') }} className="flex w-full items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-gray-700 group">
                                <img src={LoyaltyIcon} alt="DashIcon" width={"30px"} height={"30px"} className='ml-6'/>
                                <span className="ms-3 text-[20px] font-medium">Loyalty</span>
                            </button>
                        </li> */}
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group  dark:text-white hover:bg-gray-700"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
                onClick={toggleDropdownFive}
              >
                <img
                  src={ToolsIcon}
                  alt="DashIcon"
                  width={"30px"}
                  height={"30px"}
                  className="ml-6"
                />
                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-[20px] font-medium ">
                  Tools
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`space-y-2 bg-[#000000] bg-opacity-30 ${
                  isDropdownOpenFive ? "" : "hidden"
                }`}
              >
                <li>
                  <button
                    onClick={() => {
                      navigate("/AccountSetting");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"
                  >
                    Account Setting
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      navigate("/ReferralProg");
                    }}
                    className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]"
                  >
                    Referral Program
                  </button>
                </li>
              </ul>
            </li>
            {/*
                                <li>
                                    <button onClick={() => { navigate('/TeamManagement') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">Team Management</button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/GlobalPostback') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">Global Postback</button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/Alerts') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">Alerts</button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/MyDomains') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">My Domains</button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/EcommerceToolkit') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700 font-medium text-[20px]">Ecommerce Toolkit</button>
                                </li>
                                 <li>
                                    <button onClick={() => { navigate('/ApiTool') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">API</button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/ConversionApiTool') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">Conversion API Integration</button>
                                </li>
                                <li>
                                    <button onClick={() => { navigate('/Tutorails') }} className="flex items-center w-full p-2 text-gray-300 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">Tutorials</button>
                                </li> 
                            </ul>
                        </li> 
                        <li>
                            <button onClick={() => { navigate('/Partners') }} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white  hover:bg-gray-700 group">
                            <img src={PartnersIcon} alt="DashIcon" width={"30px"} height={"30px"} className='ml-6'/>
                                <span className="ms-3 text-[20px] font-medium">Partners</span>
                            </button>
                        </li>*/}
          </ul>
        </div>
      </aside>

      {numberForm === 1 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full h-auto max-h-[98vh] overflow-auto">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Offers Form
            </h2>
            <form>
              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Campaign Name</label>
                <input
                  type="text"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  className="w-3/5 border border-gray-300 text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  placeholder="Example: Summer Campaign 2024"
                  title="Enter a unique name for your campaign, like Summer Campaign 2024 or Holiday Sale 2024."
                  required
                />
              </div>
              {errors.campaignName && (
                <p className="text-red-500 text-xs">{errors.campaignName}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">
                Redirect URL
                </label>
                <input
                  type="text"
                  value={landingPage}
                  onChange={(e) => setLandingPage(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: https://www.example.com"
                  title="Enter a Url on which you want the Traffic to be Redirected."
                  required
                />
              </div>
              {errors.landingPage && (
                <p className="text-red-500 text-xs">{errors.landingPage}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Domain</label>
                <input
                  type="text"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: DevcirCo"
                  title="Enter Your Domain."
                  required
                />
              </div>
              {errors.domain && (
                <p className="text-red-500 text-xs">{errors.domain}</p>
              )}

              {/* <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Redirect URL</label>
                <input
                  type="text"
                  value={redirect_URL}
                  onChange={(e) => setRedirect_URL(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: https://www.example.com"
                  required
                />
              </div>
              {errors.redirect_URL && (
                <p className="text-red-500 text-xs">{errors.redirect_URL}</p>
              )} */}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">CPA rate</label>
                <input
                  type="number"
                  onChange={(e) => setCpaRate(Number(e.target.value))}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: 15"
                  title="CPA (Cost Per Action) rates across multiple verticals, such as eCommerce, dating, mobile, gaming, and social media."
                  value={cpaRate}
                  required
                />
              </div>
              {errors.cpaRate && (
                <p className="text-red-500 text-xs">{errors.cpaRate}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">
                  Running Somewhere
                </label>
                <select
                  value={runningSomewhere}
                  onChange={(e) => setRunningSomewhere(e.target.value)}
                  className="w-3/5 border border-gray-300 text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  title="Wheather Running The Offer Somewhere or not"
                >
                  <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              {errors.runningSomewhere && (
                <p className="text-red-500 text-xs">
                  {errors.runningSomewhere}
                </p>
              )}

              {runningSomewhere === "Yes" && (
                <>
                  <div className="w-full flex justify-between mt-4">
                    <label className="text-xs text-gray-500">Network</label>
                    <input
                      type="text"
                      value={network}
                      onChange={(e) => setNetwork(e.target.value)}
                      className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                      placeholder="Example: Google Ads"
                      title="Enter a Which network is being used."
                      required
                    />
                  </div>
                  {errors.network && (
                    <p className="text-red-500 text-xs">{errors.network}</p>
                  )}

                  <div className="w-full flex justify-between mt-4">
                    <label className="text-xs text-gray-500">Switch Rate</label>
                    <input
                      type="number"
                      onChange={(e) => setSwitchRate(Number(e.target.value))}
                      className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                      placeholder="Example: 20"
                      title="Number of traffic that gets redirected from the used network."
                      value={switchRate}
                      required
                    />
                  </div>
                  {errors.switchRate && (
                    <p className="text-red-500 text-xs">{errors.switchRate}</p>
                  )}
                </>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Leads per Day</label>
                <input
                  type="number"
                  onChange={(e) => setLeadsPerDay(Number(e.target.value))}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: 50"
                  value={leadsPerDay}
                  title="Enter The Number of Leads Generated per day..."
                  required
                />
              </div>
              {errors.leadsPerDay && (
                <p className="text-red-500 text-xs">{errors.leadsPerDay}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Geos Offer</label>
                <input
                  type="text"
                  value={goesOffer}
                  onChange={(e) => setGoesOffer(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: Summer GOES Offer 2024"
                  title="Enter the name of the GOES offer for guaranteed exposure"
                  required
                />
              </div>
              {errors.goesOffer && (
                <p className="text-red-500 text-xs">{errors.goesOffer}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Traffic Use</label>
                <input
                  type="text"
                  value={trafficUse}
                  onChange={(e) => setTrafficUse(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: Search, Display"
                  title="Enter the Traffic Use."
                  required
                />
              </div>
              {errors.trafficUse && (
                <p className="text-red-500 text-xs">{errors.trafficUse}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Additional Info</label>
                <input
                  type="text"
                  value={infoShare}
                  onChange={(e) => setInfoShare(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Example: Special terms or conditions"
                  required
                />
              </div>
              {errors.infoShare && (
                <p className="text-red-500 text-xs">{errors.infoShare}</p>
              )}

              <div className="w-full flex justify-between mt-4">
                <label className="text-xs text-gray-500">Image Path</label>
                <div className="w-3/5 relative">
                  {/* Hidden default file input */}
                  <input
                    className="absolute inset-0 opacity-0 w-full cursor-pointer"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleImageUpload}
                  />

                  {/* Custom file input style */}
                  <div className="w-full px-2 py-1 text-black border border-gray-300 bg-white flex items-center justify-between">
                    <span className="text-sm">
                      {imageFile ? imageFile.name : "No file chosen"}
                    </span>
                    <button className="text-sm text-blue-500">Browse</button>
                  </div>
                </div>
              </div>
              {errors.imageFile && (
                <p className="text-red-500 text-xs">{errors.imageFile}</p>
              )}

              <div className="w-full flex justify-between">
                <button
                  onClick={closeForm}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Close
                </button>
                {isFormValid && (
                  <button
                    onClick={handleSubmitFirst}
                    type="submit"
                    className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {numberForm === 2 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full h-auto max-h-[95vh] overflow-auto">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Offers Form
            </h2>
            <form>
              <div style={{ color: "black" }}>
                <h1>Select Countries</h1>
                <MultiSelect
                  options={filteredCountryOptions}
                  value={selected}
                  onChange={setSelected}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Blacklisted Countries</h1>
                <MultiSelect
                  options={filteredBlacklistOptions}
                  value={selectedBlacklist}
                  onChange={setSelectedBlacklist}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Platform</h1>
                <MultiSelect
                  options={platformOptions}
                  value={platform}
                  onChange={setPlatform}
                  style={{ color: "black" }}
                />
              </div>
              <div>
                <h1 className="text-black">Select Vertical</h1>
                <Select
                  options={verticalOptions}
                  value={selectedVertical}
                  onChange={handleVerticalChange}
                  styles={customStyles}
                  placeholder="Select Vertical..."
                  isSearchable={true}
                  menuPlacement="auto"
                  menuShouldScrollIntoView={false}
                />
              </div>

              <div>
                <h1 className="text-black">Select Price Format</h1>
                <Select
                  options={priceOption}
                  value={priceFormat}
                  onChange={handleFormatPriceChange}
                  styles={customStyles}
                  placeholder="Select Price Format..."
                />
              </div>
              {priceFormat.label === "CPA" && (
                <div>
                  <label>Payout</label>
                  <input
                    type="number"
                    value={payout}
                    onChange={(e) => setPayout(e.target.value)}
                    className="w-full p-2 rounded-md text-black border-2 border-gray-300"
                    placeholder="Enter Payout Amount"
                  />
                </div>
              )}
              {priceFormat.label === "RevShare" && (
                <div className="flex flex-row justify-between">
                  <div>
                    <label>Payout</label>
                    <input
                      type="number"
                      onChange={(e) => setPayout(e.target.value)}
                      value={payout}
                      className="w-full p-2 rounded-md border-2 border-gray-300 text-black"
                      placeholder="Enter Payout Amount"
                    />
                  </div>
                  <div>
                    <label>Percent</label>
                    <input
                      type="number"
                      onChange={(e) => setPercent(e.target.value)}
                      value={percent}
                      className="w-full p-2 rounded-md border-2 border-gray-300 text-black"
                      placeholder="Enter Percent Share"
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-row space-x-20 mt-4">
                <div>
                  <h1 className="text-black">Target Demo</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="gender"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={(e) => setGender(e.target.value)} 
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Male
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="gender"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={(e) => setGender(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Female
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Tracking Type</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="trackingType"
                      value="Cookie (img/ifrm/js)"
                      checked={trackingType === "Cookie (img/ifrm/js)"}
                      onChange={(e) => setTrackingType(e.target.value)}
                      title="Track the data using cookie"
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Cookie (img/ifrm/js)
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="trackingType"
                      value="Postback/s2s"
                      checked={trackingType === "Postback/s2s"}
                      title="track the data using Postback"
                      onChange={(e) => setTrackingType(e.target.value)} // Update state directly
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Postback/s2s
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Adult Traffic</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="adultTraffic"
                      value="Yes"
                      checked={adultTraffic === "Yes"}
                      onChange={(e) => setAdultTraffic(e.target.value)} // Update state directly
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="adultTraffic"
                      value="No"
                      checked={adultTraffic === "No"}
                      onChange={(e) => setAdultTraffic(e.target.value)} // Update state directly
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="w-full flex justify-between">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setNumberForm(1);
                  }}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Back
                </button>
                {allFieldsFilled() && (
                  <button
                    type="submit"
                    onClick={handleSubmitSecond}
                    className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {numberForm === 3 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full h-auto max-h-[95vh] overflow-auto">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Offers Form
            </h2>
            <form onSubmit={handleSubmit}>
              <div style={{ color: "black" }}>
                <h1>Select Flow</h1>
                <MultiSelect
                  options={flowOptions}
                  value={flow}
                  onChange={setFlow}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Media Type Allowed</h1>
                <MultiSelect
                  options={filteredMediaTypesOptions}
                  value={mediaTypes}
                  onChange={setMediaTypes}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Restricted Media Type</h1>
                <MultiSelect
                  options={filteredRestrictedMediaTypesOptions}
                  value={restrictedMediaTypes}
                  onChange={setRestrictedMediaTypes}
                  style={{ color: "black" }}
                />
              </div>
              <div className="flex flex-row space-x-20 mt-4">
                {/* Creative Preapproval */}
                <div>
                  <h1 className="text-black">Creative Preapproval</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="creativePreapproval"
                      value="Yes"
                      checked={creativePreapproval === "Yes"}
                      onChange={(e) => setCreativePreapproval(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="creativePreapproval"
                      value="No"
                      checked={creativePreapproval === "No"}
                      onChange={(e) => setCreativePreapproval(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>

                {/* Incentivized Promotion */}
                <div>
                  <h1 className="text-black">Incentivized Promotion</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="incentivizedPromotion"
                      value="Yes"
                      checked={incentivizedPromotion === "Yes"}
                      onChange={(e) => setIncentivizedPromotion(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="incentivizedPromotion"
                      value="No"
                      checked={incentivizedPromotion === "No"}
                      onChange={(e) => setIncentivizedPromotion(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>

                {/* Age */}
                <div>
                  <h1 className="text-black">Age</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="age"
                      value="18+"
                      checked={age === "18+"}
                      onChange={(e) => setAge(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      18+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="age"
                      value="21+"
                      checked={age === "21+"}
                      onChange={(e) => setAge(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      21+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="age"
                      value="25+"
                      checked={age === "25+"}
                      onChange={(e) => setAge(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      25+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="age"
                      value="30+"
                      checked={age === "30+"}
                      onChange={(e) => setAge(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      30+
                    </span>
                  </label>
                </div>
              </div>

              <div className="flex flex-row space-x-24 mt-4">
                {/* Post Leads Via Api */}
                <div>
                  <h1 className="text-black">Post Leads Via Api</h1>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="apiLeads"
                      value="Yes"
                      checked={apiLeads === "Yes"}
                      onChange={(e) => setApiLeads(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="apiLeads"
                      value="No"
                      checked={apiLeads === "No"}
                      onChange={(e) => setApiLeads(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>

                {/* Include Loyalty Program */}
                <div>
                  <h1 className="text-black">Include Loyalty Program</h1>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="loyaltyProgram"
                      value="Yes"
                      checked={loyaltyProgram === "Yes"}
                      onChange={(e) => setLoyaltyProgram(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="loyaltyProgram"
                      value="No"
                      checked={loyaltyProgram === "No"}
                      onChange={(e) => setLoyaltyProgram(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>

                <div>
                  <h1 className="text-black">3G / WIFI</h1>
                  <div className="flex space-x-2">
                    <div className="flex">
                      <input
                        type="checkbox"
                        value="3G"
                        onChange={handleCheckboxChange}
                        checked={wifi3G.includes('3G')}
                      />
                      <label>3G</label>
                    </div>

                    <div className="flex">
                      <input
                        type="checkbox"
                        value="Wifi"
                        onChange={handleCheckboxChange}
                        checked={wifi3G.includes('Wifi')}
                      />
                      <label>WIFI</label>
                    </div>
                  </div>
                </div>

                {/* Product Catalogs */}
                <div>
                  <h1 className="text-black">Product Catalogs</h1>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="productCatalog"
                      value="Yes"
                      checked={productCatalog === "Yes"}
                      onChange={(e) => setProductCatalog(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="productCatalog"
                      value="No"
                      checked={productCatalog === "No"}
                      onChange={(e) => setProductCatalog(e.target.value)}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="flex flex-row space-x-20">
                <div className="w-full flex mt-4">
                  <label className="text-[14px] text-gray-500">Carrier</label>
                  <input
                    type="text"
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                    className="w-1/3 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2 ml-5"
                    placeholder="Enter Carrier"
                    required
                  />
                </div>
              </div>
              <div className="w-full flex  justify-between">
                <button
                  onClick={() => {
                    setNumberForm(2);
                  }}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Back
                </button>

                {isFormLastValid() && (
                  <>
                    <button
                      type="submit"
                      className={`mt-3 ${
                        loading
                          ? "bg-gray-300"
                          : "bg-gray-500 hover:bg-gray-600"
                      } text-white px-4 py-2 rounded-lg`}
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {newForm && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40">
          <div className="bg-white p-8 rounded-lg w-7/12">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Offers Form
            </h2>
            <form>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">Campaign Name</label>
                <input
                  type="text"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  placeholder="Enter Campaign Name"
                  required
                />
              </div>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">
                  Landing page URL
                </label>
                <input
                  type="text"
                  value={landingPage}
                  onChange={(e) => setLandingPage(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Enter URL"
                  required
                />
              </div>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">CPA rate</label>
                <input
                  type="text"
                  onChange={(e) => setCpaRate(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Enter CPA Rate"
                  required
                />
              </div>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">
                  Are you currently running it elsewhere?
                </label>
                <div className="w-3/5 ">
                  <input
                    type="radio"
                    className="form-radio text-blue-600"
                    name="radio"
                    value="Yes"
                    checked={runningSomewhere === "Yes"}
                    onChange={handleRunningChange}
                    required
                  />
                  <span className="ml-2 text-base text-gray-400 font-light">
                    Yes
                  </span>
                  <input
                    type="radio"
                    className="form-radio text-blue-600 ml-4"
                    name="radio"
                    value="No"
                    checked={runningSomewhere === "No"}
                    onChange={handleRunningChange}
                    required
                  />
                  <span className="ml-2 text-base text-gray-400 font-light">
                    No
                  </span>
                </div>
              </div>
              {runningSomewhere === "No" ? (
                <>
                  <div className="w-full  hidden justify-between mt-4">
                    <label className="text-xs hidden text-gray-500">
                      Which network?
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setNetwork(e.target.value)}
                      className=" hidden w-3/5 border border-gray-300"
                      required
                    />
                  </div>
                  <div className="w-full hidden justify-between mt-4">
                    <label className="text-xs hidden text-gray-500">
                      What rate do you need from us to switch networks?
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setSwitchRate(e.target.value)}
                      className="hidden w-3/5 border border-gray-300"
                      required
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full flex  justify-between mt-4">
                    <label className="text-xs text-gray-500">
                      Which network?
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setNetwork(e.target.value)}
                      className="w-3/5 border px-2 text-black border-gray-300"
                      required
                    />
                  </div>
                  <div className="w-full flex  justify-between mt-4">
                    <label className="text-xs text-gray-500">
                      What rate do you need from us to switch networks?
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setSwitchRate(e.target.value)}
                      className="w-3/5 px-2 text-black border border-gray-300"
                      required
                    />
                  </div>
                </>
              )}
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">
                  How many leads per day do you generate?{" "}
                </label>
                <input
                  type="text"
                  onChange={(e) => setLeadsPerDay(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Enter Number Of Leads Per Day"
                  required
                />
              </div>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">
                  What geos do you need the offer for?
                </label>
                <input
                  type="text"
                  value={goesOffer}
                  onChange={(e) => setGoesOffer(e.target.value)}
                  className="w-3/5 border border-gray-300 placeholder:text-xs text-black placeholder:text-gray-400 px-2"
                  placeholder="Enter Goes"
                  required
                />
              </div>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">
                  Which traffic types will you be using?
                </label>
                <input
                  type="text"
                  value={trafficUse}
                  onChange={(e) => setTrafficUse(e.target.value)}
                  className="w-3/5 px-2  text-black border border-gray-300"
                  required
                />
              </div>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-xs text-gray-500">
                  Any other info you'd like to share
                </label>
                <input
                  type="text"
                  value={infoShare}
                  onChange={(e) => setInfoShare(e.target.value)}
                  className="w-3/5 px-2  text-black border border-gray-300"
                  required
                />
              </div>
              <div className="w-full flex  justify-between">
                <button
                  onClick={closeForm1}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Close
                </button>
                <button
                  onClick={handleNewOfferRequest}
                  type="submit"
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {socialNewForm === 1 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg w-7/12">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Smartlinks
            </h2>
            <form>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-sm text-gray-500">Enter Name</label>
                <input
                  type="text"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  className="w-5/6 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  placeholder="Enter Campaign Name"
                  required
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Countries</h1>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Blacklisted Countries</h1>
                <MultiSelect
                  options={options}
                  value={selectedBlacklist}
                  onChange={setSelectedBlacklist}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Platform</h1>
                <MultiSelect
                  options={platformOptions}
                  value={platform}
                  onChange={setPlatform}
                  style={{ color: "black" }}
                />
              </div>
              <div>
                <h1 className="text-black">Select Vertical</h1>
                <Select
                  options={verticalOptions}
                  value={selectedVertical}
                  onChange={handleVerticalChange}
                  styles={customStyles}
                  placeholder="Select Vertical..."
                />
              </div>
              <div className="flex flex-row justify-evenly space-x-10 mt-4 ">
                <div>
                  <h1 className="text-black">Target Demo</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radio"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={handleOptionChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Male
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radios"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={handleOptionChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Female
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Incentivized Promotion</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radiobtt"
                      value="Yes"
                      checked={incentivizedPromotion === "Yes"}
                      onChange={handleIncentivizedPromotion}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radiosbt5"
                      value="No"
                      checked={incentivizedPromotion === "No"}
                      onChange={handleIncentivizedPromotion}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Adult Traffic</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radiob"
                      value="Yes"
                      checked={adultTraffic === "Yes"}
                      onChange={handleAdultTraffic}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radiosbt3"
                      value="No"
                      checked={adultTraffic === "No"}
                      onChange={handleAdultTraffic}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="w-full flex  justify-between">
                <button
                  onClick={closeSocialForm}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Back
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    setSocialNewForms(2);
                  }}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {socialNewForm === 2 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg w-7/12">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Smartlinks
            </h2>
            <form>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-sm text-gray-500">
                  ECPM (UNIQUE/TOTAL)
                </label>
                <input
                  type="text"
                  value={eCPM}
                  onChange={(e) => setECPM(e.target.value)}
                  className="w-5/6 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  placeholder="Enter Campaign Name"
                  required
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Media Type Allowed</h1>
                <MultiSelect
                  options={mediaTypesOptions}
                  value={mediaTypes}
                  onChange={setMediaTypes}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Restricted Media Type</h1>
                <MultiSelect
                  options={mediaTypesOptions}
                  value={restrictedMediaTypes}
                  onChange={setRestrictedMediaTypes}
                  style={{ color: "black" }}
                />
              </div>
              <div className="flex flex-row space-x-10 mt-4 justify-evenly">
                <div>
                  <h1 className="text-black">Age</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radioag"
                      value="18+"
                      checked={age === "18+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      18+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radioag2"
                      value="21+"
                      checked={age === "21+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      21+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radioag3"
                      value="25+"
                      checked={age === "25+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      25+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radioag4"
                      value="30+"
                      checked={age === "30+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      30+
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Include Loyalty Program</h1>
                  <label className="inline-flex items-center ">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radiobtn8"
                      value="Yes"
                      checked={loyaltyProgram === "Yes"}
                      onChange={handleLoyalty}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radiosbttn7"
                      value="No"
                      checked={loyaltyProgram === "No"}
                      onChange={handleLoyalty}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">3G / WIFI</h1>
                  <div className="flex space-x-2 mt-3">
                    <div className="flex">
                      <input
                        type="checkbox"
                        value="3G"
                        onChange={handleCheckboxChange}
                      />
                      <label>3G</label>
                    </div>

                    <div className="flex">
                      <input
                        type="checkbox"
                        value="Wifi"
                        onChange={handleCheckboxChange}
                      />
                      <label>WIFI</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex  justify-between">
                <button
                  onClick={() => {
                    setSocialNewForms(1);
                  }}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Back
                </button>
                <button
                  type="submit"
                  onClick={handleSocialSubmit}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {iGamingNewForm === 1 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg w-7/12">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request iGaming
            </h2>
            <form>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-sm text-gray-500">Enter Name</label>
                <input
                  type="text"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  className="w-5/6 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  placeholder="Enter Campaign Name"
                  required
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Countries</h1>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Blacklisted Countries</h1>
                <MultiSelect
                  options={options}
                  value={selectedBlacklist}
                  onChange={setSelectedBlacklist}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Select Platform</h1>
                <MultiSelect
                  options={platformOptions}
                  value={platform}
                  onChange={setPlatform}
                  style={{ color: "black" }}
                />
              </div>
              <div>
                <h1 className="text-black">Select Vertical</h1>
                <Select
                  options={verticalOptions}
                  value={selectedVertical}
                  onChange={handleVerticalChange}
                  styles={customStyles}
                  placeholder="Select Vertical..."
                />
              </div>
              <div className="flex flex-row justify-evenly space-x-10 mt-4 ">
                <div>
                  <h1 className="text-black">Target Demo</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radio"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={handleOptionChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Male
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radios"
                      value="Female"
                      checked={gender === "Female"}
                      onChange={handleOptionChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Female
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Incentivized Promotion</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radiobtt"
                      value="Yes"
                      checked={incentivizedPromotion === "Yes"}
                      onChange={handleIncentivizedPromotion}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radiosbt5"
                      value="No"
                      checked={incentivizedPromotion === "No"}
                      onChange={handleIncentivizedPromotion}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Adult Traffic</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radiob"
                      value="Yes"
                      checked={adultTraffic === "Yes"}
                      onChange={handleAdultTraffic}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radiosbt3"
                      value="No"
                      checked={adultTraffic === "No"}
                      onChange={handleAdultTraffic}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
              </div>
              <div className="w-full flex  justify-between">
                <button
                  onClick={closeIGamingForm}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Back
                </button>
                <button
                  type="submit"
                  onClick={(e) => {
                    setIGamingNewForms(2);
                  }}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {iGamingNewForm === 2 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg w-7/12">
            <h2 className="text-xl font-bold mb-4 text-black">
              Request Offers Form
            </h2>
            <form>
              <div className="w-full flex  justify-between mt-4">
                <label className="text-sm text-gray-500">
                  ECPM (UNIQUE/TOTAL)
                </label>
                <input
                  type="text"
                  value={eCPM}
                  onChange={(e) => setECPM(e.target.value)}
                  className="w-5/6 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                  placeholder="Enter Campaign Name"
                  required
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Media Type Allowed</h1>
                <MultiSelect
                  options={mediaTypesOptions}
                  value={mediaTypes}
                  onChange={setMediaTypes}
                  style={{ color: "black" }}
                />
              </div>
              <div style={{ color: "black" }}>
                <h1>Restricted Media Type</h1>
                <MultiSelect
                  options={mediaTypesOptions}
                  value={restrictedMediaTypes}
                  onChange={setRestrictedMediaTypes}
                  style={{ color: "black" }}
                />
              </div>
              <div className="flex flex-row space-x-10 mt-4 justify-evenly">
                <div>
                  <h1 className="text-black">Age</h1>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radioag"
                      value="18+"
                      checked={age === "18+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      18+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radioag2"
                      value="21+"
                      checked={age === "21+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      21+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radioag3"
                      value="25+"
                      checked={age === "25+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      25+
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radioag4"
                      value="30+"
                      checked={age === "30+"}
                      onChange={handleAgeChange}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      30+
                    </span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">Include Loyalty Program</h1>
                  <label className="inline-flex items-center ">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio"
                      name="radiobtn8"
                      value="Yes"
                      checked={loyaltyProgram === "Yes"}
                      onChange={handleLoyalty}
                    />
                    <span className="ml-2 font-semibold text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 form-radio ml-2"
                      name="radiosbttn7"
                      value="No"
                      checked={loyaltyProgram === "No"}
                      onChange={handleLoyalty}
                    />
                    <span className="ml-2 font-semibold text-gray-700">No</span>
                  </label>
                </div>
                <div>
                  <h1 className="text-black">3G / WIFI</h1>
                  <div className="flex space-x-2 mt-3">
                    <div className="flex">
                      <input
                        type="checkbox"
                        value="3G"
                        onChange={handleCheckboxChange}
                      />
                      <label>3G</label>
                    </div>

                    <div className="flex">
                      <input
                        type="checkbox"
                        value="Wifi"
                        onChange={handleCheckboxChange}
                      />
                      <label>WIFI</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex  justify-between">
                <button
                  onClick={() => {
                    setIGamingNewForms(1);
                  }}
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Back
                </button>
                <button
                  onClick={handleiGamingSubmit}
                  type="submit"
                  className="mt-3 bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-lg"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  userFields: state.user.userFields,
});

export default connect(mapStateToProps)(Sidebar);
