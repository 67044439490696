import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import axios from "axios";
import { connect } from "react-redux";
import Select from "react-select";
import Arrow from "../../assets/images/Arrow 1.svg";
import Arrows from "../../assets/images/Arrow 7.svg";
import OfferIcn from "../../assets/images/image 17.svg";
import settingIcn from "../../assets/images/image 55.svg";
import categIcn from "../../assets/images/image 56.svg";

const ConfirmRequest = ({ userId, userFields }) => {
  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);
  const user_id = parsedData.id;
  const user_field = parsedData.setUserFields.name;

  const [requestOffers, setRequestOffers] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [userNames, setUserNames] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [domains, setDomains] = useState("");
  const [domainName, setDomainName] = useState("");
  const [mainOfferId, setMainOfferId] = useState(null);
  const [filterOpen, setFilterOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLinkId, setSelectedLinkId] = useState(null);

  // Fetch data from the API and store it in the linkData state variable
  useEffect(() => {
    const fetchLinkData = async () => {
      try {
        const response = await axios.get("https://apidealer.devcir.co/api/links");
        setLinkData(response.data);
      } catch (error) {
        console.error("Error fetching link data:", error);
      }
    };

    fetchLinkData();
  }, []);

  const generateRandomLink = async (item_id) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
    let urlKey = "";
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      urlKey += characters[randomIndex];
    }
    try {
      const response = await axios.get(
        `https://apidealer.devcir.co/api/request-offers/${item_id}`
      );
      console.log(item_id);
      const landingPageUrl = response.data.landing_page;
      const domainUrl = new URL(landingPageUrl);
      const domain = domainUrl.hostname;

      const url = `https://DevCir.com/link/${domain}/${urlKey}`;
      setDomains(url);
      setDomainName(domain);

      console.log("Domain:", domain);
      console.log("Response:", response);
      console.log("Generated Link:");
      console.log("Domain Name:", domainName);

      const result = JSON.stringify({ domain, url, urlKey });

      return result;
    } catch (error) {
      console.error("Error fetching link data:", error);
    }
  };

  const [response, setResponse] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://apidealer.devcir.co/api/applied_offers_user/${user_id}`
      );
      // const response = await fetch(`https://apidealer.devcir.co/api/applied_offers_user/${user_id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const filteredData = data.filter((item) => item.status == "Pending");
      setRequestOffers(data);
      fetchUserNames(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchUserNames = async (data) => {
    try {
      const userDetailsPromises = data.map(async (item) => {
        // const userResponse = await fetch(`https://apidealer.devcir.co/api/affiliate-registrations/${item.user_id}`);
        const userResponse = await fetch(
          `https://apidealer.devcir.co/api/affiliate-registrations/${item.user_id}`
        );
        if (!userResponse.ok) {
          throw new Error("Network response was not ok");
        }
        return userResponse.json();
      });

      const userDetailsArray = await Promise.all(userDetailsPromises);
      setUserDetails(userDetailsArray);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleStatusChange = async () => {
    const generateRandomCode = async () => {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-";
      let result = "";
      for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    };

    console.log(newStatus);
    console.log(selectedOfferId);

    try {
      // await axios.put(`https://apidealer.devcir.co/api/applied_offers/${selectedOfferId}`, { status: newStatus });
      await axios.put(
        `https://apidealer.devcir.co/api/applied_offers/${selectedOfferId}`,
        { status: newStatus }
      );

      const updatedRequestOffers = requestOffers.map(async (offer) => {
        if (offer.id == selectedOfferId) {
          if (newStatus == "Approved") {
            const uniqueCode = await generateRandomCode();
            console.log("Generated Unique Code:", uniqueCode);
            console.log("Offer id :", offer.item_id);
            try {
              // const res = axios.post('https://apidealer.devcir.co/api/links', {
              const res = axios.post("https://apidealer.devcir.co/api/links", {
                applied_id: offer.id,
                applied_offers_id: offer.item_id,
                url: uniqueCode,
              });
              setResponse(res.data);
              console.log(response);
              const newLink = {
                applied_id: offer.id,
                applied_offers_id: offer.item_id,
                url: uniqueCode,
                status: "Active",
              };
              setLinkData((prevLinkData) => [...prevLinkData, newLink]);
            } catch (error) {
              console.error("There was an error creating the link!", error);
            }

            const jsonResult = await generateRandomLink(mainOfferId);
            const resultObject = JSON.parse(jsonResult);
            try {
              console.log(`data Of Domain generated: ${jsonResult}`);

              const response = axios.post(
                "https://apidealer.devcir.co/api/tracking-urls",
                {
                  domain: resultObject.domain,
                  url: resultObject.url,
                  urlKey: uniqueCode,
                }
              );
            } catch (error) {
              console.error("Error creating tracking URL:", error);
            }
          }
          return { ...offer, status: newStatus };
        }
        return offer;
      });
      setRequestOffers(updatedRequestOffers);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleStatusChanges = async (offers) => {
    try {
      // const response = await axios.put(`https://apidealer.devcir.co/api/links/${offers}/status`, {
      const response = await axios.put(
        `https://apidealer.devcir.co/api/links/${offers}/status`,
        {
          status: newAction,
        }
      );
      console.log(
        `Status updated successfully: ${JSON.stringify(response.data)}`
      );
      const updatedLinkData = linkData.map((link) => {
        if (link.applied_id == offers) {
          return { ...link, status: newAction };
        }
        return link;
      });
      setLinkData(updatedLinkData);
    } catch (error) {
      console.log(
        `Error updating status: ${
          error.response ? error.response.data.message : error.message
        }`
      );
    }
  };

  const statusOptions = [
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  const handleRowClick = (selectedOption, offer) => {
    setSelectedOfferId(offer.id);
    setNewStatus(selectedOption.value);
    setMainOfferId(offer.item_id);
  };

  useEffect(() => {
    if (newStatus !== null && selectedOfferId !== null) {
      console.log(newStatus);
      console.log(selectedOfferId);
    }
  }, [newStatus, selectedOfferId]);

  const [newAction, setNewAction] = useState(null);
  const [selectedActionOfferId, setSelectedActionOfferId] = useState(null);

  const actionOptions = [
    { value: "Active", label: "Activate" },
    { value: "Deactivate", label: "Deactivate" },
  ];

  const handleActionChange = (selectedOption, offer) => {
    setSelectedActionOfferId(offer.id);
    setNewAction(selectedOption.value);
  };

  useEffect(() => {
    if (newAction !== null && selectedActionOfferId !== null) {
      console.log("Action:", newAction);
      console.log("Action Offer ID:", selectedActionOfferId);
    }
  }, [newAction, selectedActionOfferId]);

  const getUserDetail = (user_id) => {
    console.log(user_id);
    console.log(userDetails);
    const userDetail = userDetails.find((detail) => detail.id == user_id);
    console.log(userDetail);
    return userDetail ? userDetail.firstname : "Loading...";
  };

  const handleUse = () => {
    console.log("Use button clicked");
    setIsOpen(false);
  };

  return (
    <div className="ml-2">
      <button
        data-drawer-target="sidebar-multi-level-sidebar"
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <Sidebar />

      <div className="sm:ml-64 bg-white min-h-screen mt-7 rounded-tl-3xl">
        <TopBar />
        <div className="mt-6 bg-white flex justify-between">
          <label className="text-[24px] text-[#000000] font-semibold tracking-wider ml-5">
            OFFERS APPLIED
          </label>
        </div>
        <div className="flex items-center w-full justify-center mt-5 mb-10">
          <div className="flex flex-col w-[95%] h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-14">
            <div className="mx-auto w-full">
              <div className="flex justify-between items-center mb-4 mt-4 border-b-2 border-gray-200">
                <div className="flex flex-row space-x-2 ml-5 mb-2">
                  <img
                    src={OfferIcn}
                    alt="Icn"
                    width={"40px"}
                    height={"40px"}
                  />
                  <h1 className="text-2xl font-bold text-[#000000] text-[20px] mt-2">
                    Offers
                  </h1>
                </div>
                <div className="flex items-center space-x-4 mr-5 mb-2">
                  <img src={settingIcn} alt="" width={"34px"} height={"34px"} />
                  <img src={categIcn} alt="" width={"34px"} height={"34px"} />
                  <div className="relative">
                    <input
                      type="text"
                      className="border-t-[3px] border-[#58165C] rounded-full h-9 w-40 px-2 py-1 border-b-2 border-b-black"
                      placeholder="Search"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <svg
                        className="w-4 h-4 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <table className="text-xs w-11/12 ml-4 mb-4">
                <thead>
                  <tr className="text-center">
                    <th className="p-[2px] w-28 bg-[#58165C66] border-r-[2px] border-[#681d64] text-black rounded-l-full">
                      Offer Name
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Name
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Function
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Status
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Admin Approval
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Change Status
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Update
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Link Status
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                      Action
                    </th>
                    <th className="p-[2px] border-r-[2px] border-[#681d64 bg-[#58165C66] text-black rounded-r-full">
                      Update link status
                    </th>
                  </tr>
                </thead>

                {/* <tbody>
                                    {requestOffers.map((offer) => {
                                        const matchingLink = linkData.find(link => link.applied_id == offer.id);
                                        return (
                                            <tr key={offer.id} className="text-center p-3 shadow-xl mb-3 rounded-r-xl rounded-l-xl">
                                                <td className="p-3 text-black border-r-2 border-gray-200 text-xs">{offer.offer_Name}</td>
                                                <td className="p-3 text-black border-r-2 border-gray-200 text-xs">{getUserDetail(offer.user_id, offer.user_field)}</td>
<td className="p-3 text-black border-r-2 border-gray-200 text-xs">

<button 
    onClick={() => {
        if (offer.status !== 'Approved') {
            alert("Kindly update the status");
        } else {
            if (matchingLink) {
                console.log('Matching Link ID:', matchingLink.id); 
                setSelectedLinkId(matchingLink.id);  
            } else {
                console.log('No matching link found.');
                setSelectedLinkId(null);  
            }
            setIsOpen(true);
        }
    }}
    className="text-black w-[56px] bg-blue-500 p-1 rounded-md hover:text-white hover:bg-blue-700 text-xs"
>
    Function
</button>

{isOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 border-6 border-red-300 ">
    <div className="bg-white p-6 rounded-lg shadow-xl w-[800px] h-[300px] overflow-auto bg-gray-400">
      <h1 className="text-xl font-bold mb-4">Function Details</h1>
      <h3 className='text-xl font-bold mb-4'>
        Id: {selectedLinkId !== null ? selectedLinkId : 'No ID found'}
      </h3>
      <pre className="mb-6 text-xs text-left whitespace-pre-wrap">
        {`
<script>

  async function performPutRequest() {
      const apiUrl = 'https://apidealer.devcir.co/api/price-table/${selectedLinkId !== null ? selectedLinkId : '56'}/addConversion';

      try {
          const response = await fetch(apiUrl, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              }
          });

          if (!response.ok) {
              throw new Error(\`HTTP error! Status: \${response.status}\`);
          }

          const data = await response.json();
          console.log('PUT request successful:', data);

          sessionStorage.setItem('hasMadeApiRequest', 'true');
      } catch (error) {
          console.error('Error:', error);
      }
  }

  async function visitsAdded() {
      const apiUrl = 'https://apidealer.devcir.co/api/price-table/${selectedLinkId !== null ? selectedLinkId : '56'}/addVists';

      try {
          const response = await fetch(apiUrl, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json'
              }
          });

          if (!response.ok) {
              throw new Error(\`HTTP error! Status: \${response.status}\`);
          }

          const data = await response.json();
          console.log('PUT request successful:', data);

          sessionStorage.setItem('hasMadeApiRequest', 'true');
      } catch (error) {
          console.error('Error:', error);
      }
  }

  window.addEventListener('load', function () {
      function getCookie(name) {
          let value = "; " + document.cookie;
          let parts = value.split("; " + name + "=");
          if (parts.length == 2) return parts.pop().split(";").shift();
      }

      if (getCookie('LinkRedirection') === 'Completed') {
          alert("Redirected From Link");
          visitsAdded()
          console.log('User was successfully redirected.')

          let alertTriggered = false;
          document.querySelectorAll('button').forEach(function (button) {
              button.addEventListener('click', function () {
                  if (!alertTriggered) {
                      alert('Button clicked after redirection');
                      alertTriggered = true;
                      performPutRequest();
                  }
              });
          });
          
          document.cookie = 'LinkRedirection=; Max-Age=0; path=/';
      } else {
          console.log('No redirection detected.');
      }
  });

</script>
        `}
      </pre>
      <div className="flex justify-end space-x-4">
        <button onClick={() => setIsOpen(false)} className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md font-bold">
          Close
        </button>
      </div>
    </div>
  </div>
)}
</td>

<td className="p-3 text-black border-r-2 border-gray-200 text-xs">{offer.status}</td>
<td className="p-3 text-black border-r-2 border-gray-200 text-xs">{offer.admin_approval}</td>

                                                <td className="p-3 text-black border-r-2 border-gray-200-3">
                                                    {offer.status !== 'Approved' ? (
                                                        <Select
                                                            className="text-pink text-xs"
                                                            options={statusOptions}
                                                            onChange={(selectedOption) => handleRowClick(selectedOption, offer)}
                                                            value={statusOptions.find(option => option.label == offer.status)}
                                                        />
                                                    ) : (
                                                        <span className="text-black">Status Updated</span>
                                                    )}
                                                </td>
<td className="p-3 text-black border-r-2 border-gray-200-3">
    {offer.status !== 'Approved' ? (
        <button 
            onClick={async () => {
                try {
                    await handleStatusChange();
                    window.location.reload();
                } catch (error) {
                    console.error("Error updating status:", error);
                    alert("Failed to update status. Please try again.");
                }
            }} 
            className="text-black w-[56px] bg-blue-500 p-2 rounded-md hover:text-white hover:bg-blue-700 text-xs"
        >
            Update 
        </button>
    ) : (
        <span className="text-black text-xs">Status Updated</span>
    )}
</td>
                                                <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                                                    {offer.status === 'Approved' && matchingLink ? matchingLink.status : ''}
                                                </td>
                                                <td className="p-3 text-black border-r-2 border-gray-200">
                                                    {offer.status === 'Approved' && matchingLink ? (
                                                        <Select
                                                            className="text-black text-xs"
                                                            options={actionOptions}
                                                            onChange={(selectedOption) => handleActionChange(selectedOption, offer)}
                                                            value={actionOptions.find(option => option.label == offer.action)}
                                                        />
                                                    ) : ''}
                                                </td>
                                                <td className="p-3 text-black">
                                                    {offer.status === 'Approved' && matchingLink ? (
                                                        <button onClick={() => handleStatusChanges(offer.id)} className="text-black w-[56px] bg-blue-500 p-1 rounded-md hover:text-white hover:bg-blue-700 text-xs">
                                                            Update 
                                                        </button>
                                                    ) : ''}
                                                    
                                                </td>
                                                
                                            </tr>
                                        );
                                    })}
                                </tbody> */}

                <tbody>
                  {requestOffers.length === 0 ? (
                    <tr>
                      <td
                        colSpan="10"
                        className="mt-2 text-center p-4 text-2xl font-bold text-gray-500"
                      >
                        No data is available
                      </td>
                    </tr>
                  ) : (
                    requestOffers.map((offer) => {
                      const matchingLink = linkData.find(
                        (link) => link.applied_id == offer.id
                      );
                      return (
                        <tr
                          key={offer.id}
                          className="text-center p-3 shadow-xl mb-3 rounded-r-xl rounded-l-xl"
                        >
                          <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                            {offer.offer_Name}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                            {getUserDetail(offer.user_id, offer.user_field)}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                            <button
                              onClick={() => {
                                if (offer.status !== "Approved") {
                                  alert("Kindly update the status");
                                } else {
                                  if (matchingLink) {
                                    console.log(
                                      "Matching Link ID:",
                                      matchingLink.id
                                    );
                                    setSelectedLinkId(matchingLink.id);
                                  } else {
                                    console.log("No matching link found.");
                                    setSelectedLinkId(null);
                                  }
                                  setIsOpen(true);
                                }
                              }}
                              className="text-black w-[56px] bg-blue-500 p-1 rounded-md hover:text-white hover:bg-blue-700 text-xs"
                            >
                              Function
                            </button>

                            {isOpen && (
                              <div
                                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                                onClick={() => setIsOpen(false)}
                              >
                                <div
                                  className="bg-white p-6 rounded-lg shadow-xl w-[800px] h-[300px] overflow-auto relative border-6 border-red-300"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  
                                  <button
                                    onClick={() => setIsOpen(false)}
                                    className="absolute top-2 right-2 text-red-500 hover:text-red-700 font-bold text-4xl"
                                    aria-label="Close"
                                  >
                                    &times;
                                  </button>
                                  <h1 className="text-xl font-bold mb-4">
                                    Function Details
                                  </h1>
                                  <h3 className="text-xl font-bold mb-4">
                                    Id:{" "}
                                    {selectedLinkId !== null
                                      ? selectedLinkId
                                      : "No ID found"}
                                  </h3>
                                  <pre className="mb-6 text-xs text-left whitespace-pre-wrap">
                                    {`
<script>
    // Function to get query parameters from the URL
    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    async function checkRecordExists(id, rn) {
        const apiUrl = \`https://apidealer.devcir.co/api/delete-record/\${id}/\${rn}\`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return response.status; // Return the status code
        } catch (error) {
            console.error('Error:', error);
            return null; // Return null on error
        }
    }

    async function performPutRequest(id) {
        const apiUrl = \`https://apidealer.devcir.co/api/price-table/\${id}/addConversion\`;

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(\`HTTP error! Status: \${response.status}\`);
            }

            const data = await response.json();
            console.log('PUT request successful:', data);

            sessionStorage.setItem('hasMadeApiRequest', 'true');
        } catch (error) {
            console.error('Error:', error);
        }
    }

    async function visitsAdded(id) {
        const apiUrl = \`https://apidealer.devcir.co/api/price-table/\${id}/addVists\`;

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(\`HTTP error! Status: \${response.status}\`);
            }

            const data = await response.json();
            console.log('Visits added successfully:', data);

            sessionStorage.setItem('hasMadeApiRequest', 'true');
        } catch (error) {
            console.error('Error:', error);
        }
    }

    window.addEventListener('load', async function () {
        const id = getQueryParam('id');
        const rn = getQueryParam('rn');

        if (id && rn) {
            const status = await checkRecordExists(id, rn);

            if (status === 200) {
                alert("Record found. Proceeding with API requests.");

                visitsAdded(id);
                console.log('User was successfully redirected with ID:', id);

                let alertTriggered = false;
                document.querySelectorAll('button').forEach(function (button) {
                    button.addEventListener('click', function () {
                        if (!alertTriggered) {
                            alert('Button clicked after redirection');
                            alertTriggered = true;
                            performPutRequest(id);
                        }
                    });
                });
            } else {
                console.log('Record not found or API returned an unexpected status:', status);
            }
        } else {
            console.log('No ID or RN found in query parameters.');
        }
    });
</script>
                `}
                                  </pre>
                                  <div className="flex justify-end space-x-4">
                                    <button
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          document.querySelector("pre")
                                            .innerText
                                        );
                                        alert("Code copied to clipboard!");
                                      }}
                                      className="bg-gradient-to-r from-[#6E3CBC] to-[#9B59B6] text-white items-center rounded-md w-32 h-10 shadow-gray-400 shadow-md font-bold"
                                    >
                                      Copy Code
                                    </button>
                                    <button
                                      onClick={() => setIsOpen(false)}
                                      className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md font-bold"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                            {offer.status}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                            {offer.admin_approval}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200-3">
                            {offer.status !== "Approved" ? (
                              <Select
                                className="text-pink text-xs"
                                options={statusOptions}
                                onChange={(selectedOption) =>
                                  handleRowClick(selectedOption, offer)
                                }
                                value={statusOptions.find(
                                  (option) => option.label == offer.status
                                )}
                              />
                            ) : (
                              <span className="text-black">Status Updated</span>
                            )}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200-3">
                            {offer.status !== "Approved" ? (
                              <button
                                onClick={async () => {
                                  try {
                                    await handleStatusChange();
                                    window.location.reload();
                                  } catch (error) {
                                    console.error(
                                      "Error updating status:",
                                      error
                                    );
                                    alert(
                                      "Failed to update status. Please try again."
                                    );
                                  }
                                }}
                                className="text-black w-[56px] bg-blue-500 p-2 rounded-md hover:text-white hover:bg-blue-700 text-xs"
                              >
                                Update
                              </button>
                            ) : (
                              <span className="text-black text-xs">
                                Status Updated
                              </span>
                            )}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200 text-xs">
                            {offer.status === "Approved" && matchingLink
                              ? matchingLink.status
                              : ""}
                          </td>
                          <td className="p-3 text-black border-r-2 border-gray-200">
                            {offer.status === "Approved" && matchingLink ? (
                              <Select
                                className="text-black text-xs"
                                options={actionOptions}
                                onChange={(selectedOption) =>
                                  handleActionChange(selectedOption, offer)
                                }
                                value={actionOptions.find(
                                  (option) => option.label == offer.action
                                )}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="p-3 text-black">
                            {offer.status === "Approved" && matchingLink ? (
                              <button
                                onClick={() => handleStatusChanges(offer.id)}
                                className="text-black w-[56px] bg-blue-500 p-1 rounded-md hover:text-white hover:bg-blue-700 text-xs"
                              >
                                Update
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  userFields: state.user.userFields,
});

export default connect(mapStateToProps)(ConfirmRequest);
