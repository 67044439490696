import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import img from '../assets/images/Login_Image.png'
import logo from '../assets/images/Login_Logo.png'
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import { connect } from 'react-redux';
import { setUserId, setUserFields } from '../actions/userActions';

export const Smartlink_Login = ({ setUserId, setUserFields }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [id, setId] = useState(null);
    const [incorrectPass, setIncorrectPass] = useState(false)
    const [incorrectEmail, setIncorrectEmail] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://apidealer.devcir.co/api/registrations/login', {
            // const response = await axios.post('https://apidealer.devcir.co/api/registrations/login', {
                email: email,
            });
            console.log('User logged in:', response.data);
            const hashedPassword = response.data.password; // Assuming the password is returned in the response
            const isPasswordCorrect = await bcrypt.compare(password, hashedPassword);

            if (isPasswordCorrect) {
                console.log('User logged in:', response.data.id);
                console.log('Correct Password');
                setId(response.data.id);
                setUserId(response.data.id);
                console.log(response.data.id)
                setUserFields({ name: 'SmartLink' });
                navigate(`/SmartLinkDashboard`);
            } else {
                console.log('Incorrect password');
                setIncorrectPass(true)
                setIncorrectEmail(true)
            }


        } catch (error) {
            setIncorrectEmail(true)
            setIncorrectPass(true)
            console.error('Error logging in:', error);
        }
    };

    return (
        <>
            <div className='flex flex-row Login_Div justify-evenly'>
                <div className='flex justify-center w-2/4'>
                    <div className='w-1/2 mt-24'>
                        <label className='font-semibold text-[32px] text-black'>SMARTLINK LOGIN</label>
                        <hr className='w-[204px] h-1 bg-[#3ac67b]' />
                        <form className='mt-16' onSubmit={handleLogin}>
                            {incorrectEmail ? (
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute mt-4'>
                                    <path d="M4.46504 5.6655C5.52819 5.6655 6.39004 4.62109 6.39004 3.33275C6.39004 2.04441 5.52819 1 4.46504 1C3.40189 1 2.54004 2.04441 2.54004 3.33275C2.54004 4.62109 3.40189 5.6655 4.46504 5.6655Z" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 12.1973H7.93V11.6914C7.92387 10.9801 7.76909 10.2824 7.4801 9.66342C7.19111 9.04424 6.77736 8.52409 6.27756 8.15132C5.77769 7.77856 5.20797 7.56538 4.62175 7.53177C4.56942 7.52872 4.51717 7.52719 4.465 7.5271C4.41283 7.52719 4.36058 7.52872 4.30833 7.53177C3.72203 7.56538 3.15231 7.77856 2.65251 8.15132C2.15264 8.52409 1.73889 9.04424 1.4499 9.66342C1.16091 10.2824 1.00613 10.9801 1 11.6914V12.1973Z" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.54492 5.6655C8.60807 5.6655 9.46992 4.62109 9.46992 3.33275C9.46992 2.04441 8.60807 1 7.54492 1" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.46994 12.1972H11.0099V11.6913C11.0038 10.98 10.849 10.2823 10.56 9.66328C10.2711 9.0441 9.8573 8.52395 9.35743 8.15118C9.03434 7.9102 8.68203 7.73595 8.31494 7.63379" stroke="#FF0000" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            ) : (
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute mt-4'>
                                    <path d="M4.46504 5.6655C5.52819 5.6655 6.39004 4.62109 6.39004 3.33275C6.39004 2.04441 5.52819 1 4.46504 1C3.40189 1 2.54004 2.04441 2.54004 3.33275C2.54004 4.62109 3.40189 5.6655 4.46504 5.6655Z" stroke="#3AC67B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 12.1973H7.93V11.6914C7.92387 10.9801 7.76909 10.2824 7.4801 9.66342C7.19111 9.04424 6.77736 8.52409 6.27756 8.15132C5.77769 7.77856 5.20797 7.56538 4.62175 7.53177C4.56942 7.52872 4.51717 7.52719 4.465 7.5271C4.41283 7.52719 4.36058 7.52872 4.30833 7.53177C3.72203 7.56538 3.15231 7.77856 2.65251 8.15132C2.15264 8.52409 1.73889 9.04424 1.4499 9.66342C1.16091 10.2824 1.00613 10.9801 1 11.6914V12.1973Z" stroke="#3AC67B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.54492 5.6655C8.60807 5.6655 9.46992 4.62109 9.46992 3.33275C9.46992 2.04441 8.60807 1 7.54492 1" stroke="#3AC67B" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.46994 12.1972H11.0099V11.6913C11.0038 10.98 10.849 10.2823 10.56 9.66328C10.2711 9.0441 9.8573 8.52395 9.35743 8.15118C9.03434 7.9102 8.68203 7.73595 8.31494 7.63379" stroke="#3AC67B" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            )}
                            <div className="relative z-0 w-full mb-5 group">
                                <input type="email" name="floating_email" id="floating_email" className={`w-3/4 block py-2.5 px-7 text-sm text-gray-900 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0  ${incorrectEmail ? 'focus:border-red-600 border-red-600' : 'focus:border-[#3AC67B] border-gray-400'} peer`} placeholder=" " required value={email} onChange={(e) => setEmail(e.target.value)} />
                                <label htmlFor="floating_email" className={`ml-6 peer-focus:font-medium absolute text-sm  italic duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto ${incorrectEmail ? 'text-red-600 peer-focus:text-red-600' : 'text-[#3AC67B] peer-focus:text-[#3AC67B]'}  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}>Email address</label>
                            </div>
                            {!incorrectPass ? (
                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute mt-8'>
                                    <g clipPath="url(#clip0_108_20)">
                                        <path d="M9.25551 5.39697H2.9698C2.53586 5.39697 2.18408 5.82493 2.18408 6.35283V12.088C2.18408 12.6159 2.53586 13.0438 2.9698 13.0438H9.25551C9.68946 13.0438 10.0412 12.6159 10.0412 12.088V6.35283C10.0412 5.82493 9.68946 5.39697 9.25551 5.39697Z" stroke="#3AC67B" strokeWidth="1.0039" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.86279 5.39697V3.96318C8.86279 3.0759 8.5731 2.22495 8.05733 1.59756C7.54161 0.970148 6.84214 0.617676 6.11279 0.617676C5.38345 0.617676 4.68397 0.970148 4.16825 1.59756C3.65253 2.22495 3.36279 3.0759 3.36279 3.96318V5.39697" stroke="#3AC67B" strokeWidth="1.0039" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.11258 9.69829C6.32955 9.69829 6.50544 9.48431 6.50544 9.22036C6.50544 8.95641 6.32955 8.74243 6.11258 8.74243C5.89562 8.74243 5.71973 8.95641 5.71973 9.22036C5.71973 9.48431 5.89562 9.69829 6.11258 9.69829Z" stroke="#3AC67B" strokeWidth="1.0039" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_108_20">
                                            <rect width="11" height="13.382" fill="white" transform="translate(0.612793 0.139648)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            ) : (
                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute mt-8'>
                                    <g clipPath="url(#clip0_108_20)">
                                        <path d="M9.25551 5.39697H2.9698C2.53586 5.39697 2.18408 5.82493 2.18408 6.35283V12.088C2.18408 12.6159 2.53586 13.0438 2.9698 13.0438H9.25551C9.68946 13.0438 10.0412 12.6159 10.0412 12.088V6.35283C10.0412 5.82493 9.68946 5.39697 9.25551 5.39697Z" stroke="#FF0000" strokeWidth="1.0039" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.86279 5.39697V3.96318C8.86279 3.0759 8.5731 2.22495 8.05733 1.59756C7.54161 0.970148 6.84214 0.617676 6.11279 0.617676C5.38345 0.617676 4.68397 0.970148 4.16825 1.59756C3.65253 2.22495 3.36279 3.0759 3.36279 3.96318V5.39697" stroke="#FF0000" strokeWidth="1.0039" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.11258 9.69829C6.32955 9.69829 6.50544 9.48431 6.50544 9.22036C6.50544 8.95641 6.32955 8.74243 6.11258 8.74243C5.89562 8.74243 5.71973 8.95641 5.71973 9.22036C5.71973 9.48431 5.89562 9.69829 6.11258 9.69829Z" stroke="#FF0000" strokeWidth="1.0039" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_108_20">
                                            <rect width="11" height="13.382" fill="white" transform="translate(0.612793 0.139648)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            )}

                            <div className="relative z-0 w-full mb-5 mt-9 group">
                                <input type="text" name="floating_email" id="floating_email" className={`block py-2.5 px-7 w-3/4 text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0  peer ${incorrectPass ? 'focus:border-red-600 border-red-600' : 'focus:border-[#3AC67B] border-gray-400'}`} placeholder=" " required value={password}
                                    onChange={(e) => setPassword(e.target.value)} />
                                <label htmlFor="floating_email" className={`ml-6 peer-focus:font-medium absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto ${incorrectPass ? 'peer-focus:text-red-600 text-red-600' : 'peer-focus:text-[#3AC67B] text-[#3AC67B]'} peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`}>Password</label>
                                {incorrectPass ? (
                                    <div className='text-red-600'>
                                        <p className='italic text-xs'>Incorrect Email or Password</p>
                                    </div>
                                ) : (<></>)}
                            </div>
                            <br />
                            <input type="checkbox" className='mt-10' />
                            <span className='absolute mt-[37px] ml-3 text-sm text-black'>Remember me</span>
                            <br />
                            <button type='submit' className='h-12 w-32 mt-10 bg-[#3AC67B] text-white rounded-lg font-bold tracking-wider'>LOGIN</button>
                            <button onClick={() => { navigate('/SmartLinkSignUp') }} className='h-12 w-32 ml-3 mt-10 bg-[#3AC67B] text-white rounded-lg font-bold tracking-wider'>Register</button>
                        </form>
                        <div className='flex flex-row space-x-3 mt-14'>
                            <label className='mt-2 text-sm font-bold text-black'>Or Login With</label>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256" className='w-9'>
                                <defs><linearGradient x1="6.228" y1="4.896" x2="42.077" y2="43.432" gradientUnits="userSpaceOnUse" id="color-1_yGcWL8copNNQ_gr1"><stop offset="0" stopColor="#4dadff"></stop><stop offset="1" stopColor="#16528c"></stop></linearGradient></defs><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(5.33333,5.33333)"><path d="M42,40c0,1.105 -0.895,2 -2,2h-32c-1.105,0 -2,-0.895 -2,-2v-32c0,-1.105 0.895,-2 2,-2h32c1.105,0 2,0.895 2,2z" fill="url(#color-1_yGcWL8copNNQ_gr1)"></path><path d="M25,38v-11h-4v-6h4v-2.138c0,-5.042 2.666,-7.818 7.505,-7.818c1.995,0 3.077,0.14 3.598,0.208l0.858,0.111l0.039,0.861v4.776h-3.635c-1.128,0 -1.365,1.378 -1.365,2.535v1.465h4.723l-0.928,6h-3.795v11z" fill="#000000" opacity="0.05"></path><path d="M25.5,37.5v-11h-4v-5h4v-2.638c0,-4.788 2.422,-7.318 7.005,-7.318c1.971,0 3.03,0.138 3.54,0.204l0.436,0.057l0.02,0.442v4.253h-3.135c-1.623,0 -1.865,1.901 -1.865,3.035v1.965h4.64l-0.773,5h-3.868v11z" fill="#000000" opacity="0.07"></path><path d="M33.365,16h2.635v-3.754c-0.492,-0.064 -1.531,-0.203 -3.495,-0.203c-4.101,0 -6.505,2.08 -6.505,6.819v3.138h-4v4h4v11h5v-11h3.938l0.618,-4h-4.556v-2.465c0,-1.874 0.612,-3.535 2.365,-3.535z" fill="#ffffff"></path></g></g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256" className='w-7'>
                                <g fill="#ff0000" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><path d="M0,256v-256h256v256z" id="bgRectangle"></path></g><g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" ><g transform="scale(5.33333,5.33333)"><path d="M23,21.5v5c0,0.828 0.671,1.5 1.5,1.5h10.809c-0.499,1.416 -1.256,2.698 -2.205,3.805l6.033,5.229c3.022,-3.505 4.863,-8.054 4.863,-13.034c0,-0.828 -0.064,-1.688 -0.202,-2.702c-0.101,-0.743 -0.736,-1.298 -1.486,-1.298h-17.812c-0.829,0 -1.5,0.672 -1.5,1.5zM12.612,27.761c-0.392,-1.184 -0.612,-2.447 -0.612,-3.761c0,-1.314 0.22,-2.577 0.612,-3.761l-6.557,-5.014c-1.303,2.653 -2.055,5.624 -2.055,8.775c0,3.151 0.752,6.122 2.056,8.775zM30.865,33.835c-1.959,1.369 -4.333,2.165 -6.865,2.165c-4.212,0 -7.917,-2.186 -10.059,-5.478l-6.362,4.865c3.616,5.198 9.623,8.613 16.421,8.613c4.968,0 9.508,-1.832 13.009,-4.84zM37.515,9.297c-3.702,-3.416 -8.502,-5.297 -13.515,-5.297c-6.798,0 -12.805,3.415 -16.421,8.614l6.362,4.865c2.142,-3.293 5.847,-5.479 10.059,-5.479c2.944,0 5.776,1.081 7.974,3.043c0.593,0.53 1.498,0.504 2.06,-0.059l3.525,-3.524c0.289,-0.288 0.447,-0.683 0.439,-1.091c-0.008,-0.408 -0.183,-0.795 -0.483,-1.072z"></path></g></g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0,0,256,256" className='w-7'>
                                <g fill="#66b5ff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><path d="M0,256v-256h256v256z" id="bgRectangle"></path></g><g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(5.33333,5.33333)"><path d="M42,12.429c-1.323,0.586 -2.746,0.977 -4.247,1.162c1.526,-0.906 2.7,-2.351 3.251,-4.058c-1.428,0.837 -3.01,1.452 -4.693,1.776c-1.344,-1.425 -3.261,-2.309 -5.385,-2.309c-4.08,0 -7.387,3.278 -7.387,7.32c0,0.572 0.067,1.129 0.193,1.67c-6.138,-0.308 -11.582,-3.226 -15.224,-7.654c-0.64,1.082 -1,2.349 -1,3.686c0,2.541 1.301,4.778 3.285,6.096c-1.211,-0.037 -2.351,-0.374 -3.349,-0.914c0,0.022 0,0.055 0,0.086c0,3.551 2.547,6.508 5.923,7.181c-0.617,0.169 -1.269,0.263 -1.941,0.263c-0.477,0 -0.942,-0.054 -1.392,-0.135c0.94,2.902 3.667,5.023 6.898,5.086c-2.528,1.96 -5.712,3.134 -9.174,3.134c-0.598,0 -1.183,-0.034 -1.761,-0.104c3.271,2.071 7.155,3.285 11.324,3.285c13.585,0 21.017,-11.156 21.017,-20.834c0,-0.317 -0.01,-0.633 -0.025,-0.945c1.45,-1.024 2.7,-2.316 3.687,-3.792"></path></g></g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='w-2/4'>
                    <img src={logo} alt="Logo" className='mt-3 ml-[470px]' />
                    <img src={img} alt="Image" className='mt-4 ml-40' />
                    <button onClick={() => { navigate('/AgencyLogin') }} className='absolute text-sm font-bold border-b-2 shadow-lg ml-80 mt-9 text-white cursor-pointer'>Login As Agency User</button>
                    <button onClick={() => { navigate('/') }} className='absolute text-sm font-bold border-b-2 shadow-lg ml-80 mt-16 text-white cursor-pointer'>Login As Affiliate User</button>
                </div>
            </div>
        </>
    )
}

export default connect(null, { setUserId, setUserFields })(Smartlink_Login);