import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { connect } from 'react-redux';

const Partners = ({ userId, userFields }) => {

// const storedData = localStorage.getItem('userData');
// const parsedData = JSON.parse(storedData);
// const userId = parsedData.id;
// const userFields = parsedData.setUserFields.name;

    const categories = [
        "Advertising Network", "Spy Tool", "Tracking Tool", "Affiliate Service", "Hosting provider", "Cloaking tool"
    ];

    const discounts = [
        {
            category: "Advertising Network",
            title: "AdsKeeper",
            description: "AdsKeeper is a fast-growing ad recommendation platform that adjusts native ads to the needs of direct advertisers, media agencies and affiliates.",
            discount: "Sign up using our referral link and get a 25% bonus on your first payment. Deposit at least $500 and get an additional $125 added to your account, scaling up all the way up to $1250 for $5000.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "New"
        },
        {
            category: "Advertising Network",
            title: "EZmob",
            description: "Ezmob is a self-serve DSP platform, serving all verticals and worldwide traffic on ad-formats such as Push notification, Pop, Native and Display ads.",
            discount: "Simply use the code - CLICK50, and get a $50 bonus on your first deposit of $150",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "New"
        },
        {
            category: "Spy Tool",
            title: "Adplexity",
            description: "AdPlexity places ads from the most popular mobile sources right at your fingertips. Data is gathered from over 75 countries, and even includes in-app and publicly hidden mobile carrier campaigns.",
            discount: "Sign up using our landing page and receive a 30% lifetime discount for Adplexity Products.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "Exclusive"
        },
        {
            category: "Tracking Tool",
            title: "AdsBridge",
            description: "AdsBridge is a cloud-based distribution tracking system designed for high-precision ad campaign optimization.",
            discount: "Use the promo code CronoNetwork and get up to 65% on any subscription plan.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "Hot"
        },
        {
            category: "Affiliate Service",
            title: "Banners & Landers",
            description: "Banners and Landers was created by iAmAttila out of straight necessity. Many new affiliates, and experienced ones alike are running into a problem.",
            discount: "Use the promo code CronoNetworkDISCOUNT when placing your order to receive a custom discount per service.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "Exclusive"
        },
        {
            category: "Affiliate Service",
            title: "TheOptimizer",
            description: "TheOptimizer is a leading campaign management & automation platform that helps advertisers boost their performance while reducing the workload.",
            discount: "For CronoNetwork users, we are offering 15 days extra FREE Trial. After signing up you will need to reach out to TheOptimizer support team and they will add 15 days to your trial period.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "New"
        },
        {
            category: "Hosting provider",
            title: "Bluehost",
            description: "Bluehost is one of the largest website hosting providers and powers millions of websites.",
            discount: "Sign up using our referral link and get a 60% discount on your first year of hosting.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "New"
        },
        {
            category: "Hosting provider",
            title: "HostGator",
            description: "HostGator is a leading provider of secure and affordable web hosting services.",
            discount: "Use the promo code HOSTINGDEAL to get 45% off on all hosting plans.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "Hot"
        },
        {
            category: "Cloaking tool",
            title: "Cloaker.xyz",
            description: "Cloaker.xyz provides advanced cloaking solutions for digital marketers.",
            discount: "Get a 14-day free trial and 25% off your first month using the promo code CLOAK25.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "Exclusive"
        },
        {
            category: "Cloaking tool",
            title: "NoIPFraud",
            description: "NoIPFraud offers robust cloaking services to protect your ad campaigns from click fraud.",
            discount: "Sign up now and get 30% off your first three months with the code NOIP30.",
            validTill: "Lifetime",
            logo: "https://via.placeholder.com/100",
            badge: "New"
        }
    ];

    const [activeCategory, setActiveCategory] = useState("Advertising Network");


    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl mb-7">    
                <TopBar />

                <div className='mt-6 bg-white'>
                    <label className='text-xl text-gray-500 tracking-wider'>PARTNERS</label>
                </div>


                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>CATEGORIES</h1>
                    </div>

                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">

                            <div className="flex space-x-4 ml-2 mb-4 mt-4">
                                <button
                                    className={`px-4 py-2 ${activeCategory === "" ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} rounded`}
                                    onClick={() => setActiveCategory("")}
                                >
                                    All
                                </button>
                                {categories.map(category => (
                                    <button
                                        key={category}
                                        className={`px-4 py-2 ${activeCategory === category ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} rounded`}
                                        onClick={() => setActiveCategory(category)}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div>
                        <h2 className="text-2xl font-bold mb-4 text-gray-500">{activeCategory}</h2>
                        {discounts.filter(discount => activeCategory === "" || discount.category === activeCategory).map((discount, index) => (
                            <div key={index} className="border p-4 mb-4 rounded-lg shadow-md">
                                <div className="flex">
                                    <img src={discount.logo} alt={discount.title} className="h-24 w-24 object-cover rounded-lg mr-4" />
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-bold text-black ">{discount.title}</h3>
                                        <p className="text-gray-600 ">Category: {discount.category}</p>
                                        <p className="mt-2 text-black ">{discount.description}</p>
                                        <p className="mt-2 text-black "><strong>How to use the discount:</strong> {discount.discount}</p>
                                        <p className="mt-2 text-black "><strong>Valid till:</strong> {discount.validTill}</p>
                                    </div>
                                    <div className="flex items-center">
                                        {discount.badge && <span className=" text-black ml-4 px-2 py-1 bg-green-500 text-xs rounded">{discount.badge}</span>}
                                    </div>
                                </div>
                                <button className="mt-4 px-4 py-2 bg-orange-500 text-white rounded">Use discount</button>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(Partners)