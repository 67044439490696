import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import axios from 'axios';

const ConversionReport = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;


    const [showlessdata, setShowlessdata] = useState(true);
    const [newOffers, setNewOffers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get('https://apidealer.devcir.co/api/conversion-reports');
                const response = await axios.get('https://apidealer.devcir.co/api/conversion-reports');
                setNewOffers(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [filters, setFilters] = useState({
        country: '',
        platform: '',
        vertical: '',
        priceFormat: '',
        payout: '',
        flow: '',
        trackingType: '',
        mediaTypesAllowed: '',
        mediaTypesRestricted: '',
        wifi: '',
        incentivizedPromotion: '',
        postLeadsViaAPI: '',
    });


    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    const [columnVisibility, setColumnVisibility] = useState({
        Date: true,
        Conversion_Id: true,
        Transaction_Id: true,
        Rotation: true,
        SmartLink: true,
        Campaign: true,
        Contact: true,
        Revenue: true,
        Payout: true,
        Currency: true,
        Payout_Convert: false,
        Currency_Payout: false,
        Order_Total: false,
        Total_Order_Convert: false,
        Creative: false,
        Event_Id: false,
        Event_Name: false,
        Event_Type: false,
        Sub_Id1: false,
        Sub_Id2: false,
        Sub_Id3: false,
        Sub_Id4: false,
        Sub_Id5: false,
        Country: false,
        Device: false,
        Os: false,
        Browser: false,
        Isp: false,
        Status: false,
        Disposition: false,
        Click_Id: false,

    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };

    return (
        <>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 p-3 bg-">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <h1 className='text-2xl font-semibold text-gray-500 tracking-wider'>CONVERSION REPORT</h1>
                </div>

                {/* {showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-6 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Campaign:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <label class="block text-black mb-2">Contact:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.vertical} onChange={e => handleFilterChange('vertical', e.target.value)} placeholder="Contact" />

                        <label class="block text-black mb-2">SmartLink:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.priceFormat} onChange={e => handleFilterChange('priceFormat', e.target.value)} placeholder="SmartLink" />

                        <label class="block text-black mb-2">Group By:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.payout} onChange={e => handleFilterChange('payout', e.target.value)} placeholder="Group By" />

                        <label class="block text-black mb-2">Country:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.flow} onChange={e => handleFilterChange('flow', e.target.value)} placeholder="Country" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600' onClick={() => { setShowlessdata(false) }}>Show More</button>
                    </div>
                )}

                {!showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-6 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Campaign:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <label class="block text-black mb-2">Contact:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.vertical} onChange={e => handleFilterChange('vertical', e.target.value)} placeholder="Contact" />

                        <label class="block text-black mb-2">SmartLink:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.priceFormat} onChange={e => handleFilterChange('priceFormat', e.target.value)} placeholder="SmartLink" />

                        <label class="block text-black mb-2">Group By:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.payout} onChange={e => handleFilterChange('payout', e.target.value)} placeholder="Group By" />

                        <label class="block text-black mb-2">Country:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.flow} onChange={e => handleFilterChange('flow', e.target.value)} placeholder="Country" />

                        <label class="block text-black mb-2">Device Type:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.trackingType} onChange={e => handleFilterChange('trackingType', e.target.value)} placeholder="Device Type" />

                        <label class="block text-black mb-2">Connection:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.mediaTypesAllowed} onChange={e => handleFilterChange('mediaTypesAllowed', e.target.value)} placeholder="Connection" />

                        <label class="block text-black mb-2">OS:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.mediaTypesRestricted} onChange={e => handleFilterChange('mediaTypesRestricted', e.target.value)} placeholder="OS" />

                        <label class="block text-black mb-2">Browser:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.wifi} onChange={e => handleFilterChange('wifi', e.target.value)} placeholder="Browser" />

                        <label class="block text-black mb-2">Media Type:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.incentivizedPromotion} onChange={e => handleFilterChange('incentivizedPromotion', e.target.value)} placeholder="Media Type" />

                        <label class="block text-black mb-2">SubAffiliates:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.postLeadsViaAPI} onChange={e => handleFilterChange('postLeadsViaAPI', e.target.value)} placeholder="SubAffiliates" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600' onClick={() => { setShowlessdata(true) }}>Show Less</button>
                    </div>
                )} */}



                <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className='mt-4'>
                    <div>
                        <div className="overflow-x-auto">
                            <table className="text-xs text-center w-full">
                                <colgroup>
                                    <col className="text-[10px]" style={{ display: columnVisibility.Date ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Conversion_Id ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Transaction_Id ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Rotation ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.SmartLink ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Campaign ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Contact ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Revenue ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Payout ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Epc ? 'table-cell' : 'none' }} />
                                </colgroup>

                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.Date && <th className="p-3 text-black">Date</th>}
                                        {columnVisibility.Conversion_Id && <th className="p-3 text-black">Conversion Id</th>}
                                        {columnVisibility.Transaction_Id && <th className="p-3 text-black">Transaction Id</th>}
                                        {columnVisibility.Rotation && <th className="p-3 text-black">Rotation</th>}
                                        {columnVisibility.SmartLink && <th className="p-3 text-black">Smartlink</th>}
                                        {columnVisibility.Campaign && <th className="p-3 text-black">Campaign</th>}
                                        {columnVisibility.Contact && <th className="p-3 text-black">Contact</th>}
                                        {columnVisibility.Revenue && <th className="p-3 text-black">Revenue</th>}
                                        {columnVisibility.Payout && <th className="p-3 text-black">Payout</th>}
                                        {columnVisibility.Currency && <th className="p-3 text-black">Currency</th>}
                                        {columnVisibility.Payout_Convert && <th className="p-3 text-black">Payout (Convert)</th>}
                                        {columnVisibility.Currency_Payout && <th className="p-3 text-black">Currency</th>}
                                        {columnVisibility.Order_Total && <th className="p-3 text-black">Order Total</th>}
                                        {columnVisibility.Total_Order_Convert && <th className="p-3 text-black">Order Total (Convert)</th>}
                                        {columnVisibility.Creative && <th className="p-3 text-black">Creative</th>}
                                        {columnVisibility.Event_Id && <th className="p-3 text-black">Event Id</th>}
                                        {columnVisibility.Event_Name && <th className="p-3 text-black">Event Name</th>}
                                        {columnVisibility.Event_Type && <th className="p-3 text-black">Event Type</th>}
                                        {columnVisibility.Sub_Id1 && <th className="p-3 text-black">Sub Id 1</th>}
                                        {columnVisibility.Sub_Id2 && <th className="p-3 text-black">Sub Id 2</th>}
                                        {columnVisibility.Sub_Id3 && <th className="p-3 text-black">Sub Id 3</th>}
                                        {columnVisibility.Sub_Id4 && <th className="p-3 text-black">Sub Id 4</th>}
                                        {columnVisibility.Sub_Id5 && <th className="p-3 text-black">Sub Id 5</th>}
                                        {columnVisibility.Country && <th className="p-3 text-black">Country</th>}
                                        {columnVisibility.Device && <th className="p-3 text-black">Device</th>}
                                        {columnVisibility.Os && <th className="p-3 text-black">OS</th>}
                                        {columnVisibility.Browser && <th className="p-3 text-black">Browser</th>}
                                        {columnVisibility.Isp && <th className="p-3 text-black">ISP/Carrier</th>}
                                        {columnVisibility.Status && <th className="p-3 text-black">Status</th>}
                                        {columnVisibility.Disposition && <th className="p-3 text-black">Disposition</th>}
                                        {columnVisibility.Click_Id && <th className="p-3 text-black">Click Id</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {newOffers.map((offer) => (
                                        <tr
                                            key={offer.id}
                                            className="border-b border-opacity-20 text-center"
                                        >
                                            {columnVisibility.Date && <td className="p-3 text-black">{offer.date}</td>}
                                            {columnVisibility.Conversion_Id && <td className="p-3 text-black">{offer.conversion_id}</td>}
                                            {columnVisibility.Transaction_Id && <td className="p-3 text-black">{offer.transaction_id}</td>}
                                            {columnVisibility.Rotation && <td className="p-3 text-black">{offer.rotation}</td>}
                                            {columnVisibility.SmartLink && <td className="p-3 text-black">{offer.smart_link}</td>}
                                            {columnVisibility.Campaign && <td className="p-3 text-black">{offer.campaign}</td>}
                                            {columnVisibility.Contact && <td className="p-3 text-black">{offer.contact}</td>}
                                            {columnVisibility.Revenue && <td className="p-3 text-black">${offer.revenue}</td>}
                                            {columnVisibility.Payout && <td className="p-3 text-black">${offer.payout}</td>}
                                            {columnVisibility.Currency && <td className="p-3 text-black">{offer.currency}</td>}
                                            {columnVisibility.Payout_Convert && <td className="p-3 text-black">${offer.payout_convert}</td>}
                                            {columnVisibility.Currency_Payout && <td className="p-3 text-black">{offer.currency_payout}</td>}
                                            {columnVisibility.Order_Total && <td className="p-3 text-black">${offer.order_total}</td>}
                                            {columnVisibility.Total_Order_Convert && <td className="p-3 text-black">€{offer.total_order_convert}</td>}
                                            {columnVisibility.Creative && <td className="p-3 text-black">{offer.creative}</td>}
                                            {columnVisibility.Event_Id && <td className="p-3 text-black">{offer.event_id}</td>}
                                            {columnVisibility.Event_Name && <td className="p-3 text-black">{offer.event_name}</td>}
                                            {columnVisibility.Event_Type && <td className="p-3 text-black">{offer.event_type}</td>}
                                            {columnVisibility.Sub_Id1 && <td className="p-3 text-black">{offer.sub_id1}</td>}
                                            {columnVisibility.Sub_Id2 && <td className="p-3 text-black">{offer.sub_id2}</td>}
                                            {columnVisibility.Sub_Id3 && <td className="p-3 text-black">{offer.sub_id3}</td>}
                                            {columnVisibility.Sub_Id4 && <td className="p-3 text-black">{offer.sub_id4}</td>}
                                            {columnVisibility.Sub_Id5 && <td className="p-3 text-black">{offer.sub_id5}</td>}
                                            {columnVisibility.Country && <td className="p-3 text-black">{offer.country}</td>}
                                            {columnVisibility.Device && <td className="p-3 text-black">{offer.device}</td>}
                                            {columnVisibility.Os && <td className="p-3 text-black">{offer.os}</td>}
                                            {columnVisibility.Browser && <td className="p-3 text-black">{offer.browser}</td>}
                                            {columnVisibility.Isp && <td className="p-3 text-black">{offer.isp}</td>}
                                            {columnVisibility.Status && <td className="p-3 text-black">{offer.status}</td>}
                                            {columnVisibility.Disposition && <td className="p-3 text-black">{offer.disposition}</td>}
                                            {columnVisibility.Click_Id && <td className="p-3 text-black">{offer.click_id}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(ConversionReport)