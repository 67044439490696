import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import axios from "axios";

const Team = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState({}); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://apidealer.devcir.co/api/agency_registrations");
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://apidealer.devcir.co/api/affiliate-registrations");
        const result = await response.json();
        setData2(result);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading2(false);
      }
    };

    fetchData();
  }, []);

  const handleStatusSelectChange = (event, id) => {
    const newStatus = event.target.value;
    setSelectedStatus(prevStatus => ({ ...prevStatus, [id]: newStatus }));
  };

  const handleStatusSelectChange2 = (event, id) => {
    const newStatus = event.target.value;
    setSelectedStatus(prevStatus => ({ ...prevStatus, [id]: newStatus }));
  };

  const handleStatusChange = async (id) => {
    const newStatus = selectedStatus[id];
    try {
      await axios.put(`https://apidealer.devcir.co/api/agency-registrations/${id}/status`, { status: newStatus });
      setData(prevOffers => {
        return prevOffers.map(offer => {
          if (offer.id === id) {
            return { ...offer, status: newStatus };
          }
          return offer;
        });
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleStatusChange2 = async (id) => {
    const newStatus = selectedStatus[id];
    try {
      await axios.put(`https://apidealer.devcir.co/api/affiliate-registrations/${id}/status`, { status: newStatus });
      setData2(prevOffers => {
        return prevOffers.map(offer => {
          if (offer.id === id) {
            return { ...offer, status: newStatus };
          }
          return offer;
        });
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    { field: 'firstname', headerName: 'Name', flex: 1, align: 'center', headerAlign: 'center'  },
    { field: 'company_name', headerName: 'Company Name', flex: 1, align: 'center', headerAlign: 'center'  },
    { field: 'company_website', headerName: 'Company Website', flex: 1, align: 'center', headerAlign: 'center'  },
    { field: 'selected_budget', headerName: 'Budget', flex: 1, align: 'center', headerAlign: 'center'  },
    { field: 'status', headerName: 'status', flex: 1, align: 'center', headerAlign: 'center'  },
    {
      field: 'changeStatus',
      headerName: 'Change Status',
      flex: 1, align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            className="h-7"
            value={selectedStatus[params.row.id] || params.row.status}
            onChange={(event) => handleStatusSelectChange(event, params.row.id)}
            label="Status"
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="InActive">InActive</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'update',
      headerName: 'Update',
      flex: 1, align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <Button
          onClick={() => handleStatusChange(params.row.id)}
          variant="contained"
          color="primary"
        >
          Update Status
        </Button>
      ),
    },
  ];

  const columns2 = [
    { field: 'accountname', headerName: 'Account Name', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'currentCPA', headerName: 'Current CPA', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'monthlyRevenue', headerName: 'Monthly Revenue', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'Specialization', headerName: 'Specialization', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'status', headerName: 'status', flex: 1, align: 'center', headerAlign: 'center', },
    {
      field: 'changeStatus',
      headerName: 'Change Status',
      flex: 1, align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            className="h-7"
            value={selectedStatus[params.row.id] || params.row.status}
            onChange={(event) => handleStatusSelectChange2(event, params.row.id)}
            label="Status"
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="InActive">InActive</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'update',
      headerName: 'Update',
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => handleStatusChange2(params.row.id)}
          variant="contained"
          color="primary"
        >
          Update Status
        </Button>
      ),
    },
  ];

  return (
    <div className="app">
      <Sidebar />
      <main className="content">
        <Topbar />
        
        <Box m="20px">
          <Header title="Agency Users" subtitle="Manage Agency Users" />
          <Box m="40px 0">
            <DataGrid
              // rows={data}
              rows={data.filter((row) => row.status === 'Active')}
              columns={columns}
              pageSize={10}
              autoHeight
              loading={loading}
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  color: 'white',
                },
                '& .MuiInputBase-root': {
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFormControl-root': {
                  color: 'white',
                },
                '& .MuiButtonBase-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />
          </Box>
        </Box>

        <Box m="20px">
          <Box m="40px 0">
            <DataGrid
              // rows={data}
              rows={data.filter((row) => row.status === 'InActive')}
              columns={columns}
              pageSize={10}
              autoHeight
              loading={loading}
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  color: 'white',
                },
                '& .MuiInputBase-root': {
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFormControl-root': {
                  color: 'white',
                },
                '& .MuiButtonBase-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />
          </Box>
        </Box>


        <Box m="20px">
          <Header title="Affiliate Users" subtitle="Manage Affiliate Users" />
          <Box m="40px 0">
            <DataGrid
              // rows={data2}
              rows={data2.filter((row) => row.status === 'Active')}
              columns={columns2}
              pageSize={10}
              autoHeight
              loading={loading2}
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  color: 'white',
                },
                '& .MuiInputBase-root': {
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFormControl-root': {
                  color: 'white',
                },
                '& .MuiButtonBase-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />
          </Box>
        </Box>

        <Box m="20px">
          <Box m="40px 0">
            <DataGrid
              // rows={data2}
              rows={data2.filter((row) => row.status === 'InActive')}
              columns={columns2}
              pageSize={10}
              autoHeight
              loading={loading2}
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  color: 'white',
                },
                '& .MuiInputBase-root': {
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFormControl-root': {
                  color: 'white',
                },
                '& .MuiButtonBase-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />
          </Box>
        </Box>


      </main>
    </div>
  );
};

export default Team;
