import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import axios from 'axios';
import Arrow from '../../../assets/images/Arrow 1.svg'
import Arrows from '../../../assets/images/Arrow 7.svg'
import OfferIcn from '../../../assets/images/image 17.svg'
import settingIcn from '../../../assets/images/image 55.svg'
import categIcn from '../../../assets/images/image 56.svg'

const ReferralPayment = ({ userId, userFields }) => {

    const id = userId
    const field = userFields.name
    const [newOffers, setNewOffers] = useState([]);
    const [filterOpen, setFilterOpen] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get('https://apidealer.devcir.co/api/refferal-payments');
                const response = await axios.get('https://apidealer.devcir.co/api/refferal-payments');
                setNewOffers(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const [columnVisibility, setColumnVisibility] = useState({
        Date: true,
        COMMISSION: true,
        PAYMENTS: true,
        BiLL_Id: true,
        Balance: true
    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
                <TopBar />
                <div className='mt-6 bg-white flex justify-between'>
                    <label className='text-[24px] text-[#000000] font-semibold tracking-wider ml-5'>PAYOUT REPORT</label>
                    <button onClick={() => { setFilterOpen(!filterOpen) }} className='bg-[#EFEFEF] w-24 h-6 rounded-full mr-5 flex flex-row space-x-1 shadow-gray-400 shadow-md'>
                        <img src={Arrow} alt="" className='mt-[6px] ml-6' />
                        <span className="text-black text-[14px] font-semibold">{(!filterOpen) ? "Filter" : "Hide"}</span>
                    </button>
                </div>


                {/* 
                <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}


                <div className="flex items-center w-full justify-center mt-5 mb-10">
                    <div className="flex flex-col w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-14">
                        <div className="mx-auto w-full">
                            <div className="flex justify-between items-center mb-4 mt-4 border-b-2 border-gray-200">
                                <div className="flex flex-row space-x-2 ml-5 mb-2">
                                    <img src={OfferIcn} alt="Icn" width={'40px'} height={'40px'} />
                                    <h1 className="text-2xl font-bold text-[#000000] text-[20px] mt-2">Offers</h1>
                                </div>
                                <div className="flex items-center space-x-4 mr-5 mb-2">
                                    <img src={settingIcn} alt="" width={'34px'} height={'34px'} />
                                    <img src={categIcn} alt="" width={'34px'} height={'34px'} />
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="border-t-[3px] border-[#58165C] rounded-full h-9 w-40 px-2 py-1 border-b-2 border-b-black"
                                            placeholder="Search"
                                        />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                            <svg className="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="text-xs w-11/12 ml-11 mb-8">
                                <colgroup>
                                    <col className="text-[10px]" style={{ display: columnVisibility.Date ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.COMMISSION ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.PAYMENTS ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.BiLL_Id ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Balance ? 'table-cell' : 'none' }} />
                                </colgroup>

                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.Date && <th className="p-[2px] w-28 bg-[#58165C66] border-r-[2px] border-[#681d64] text-black rounded-l-full">Date</th>}
                                        {columnVisibility.COMMISSION && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">COMMISSION, $</th>}
                                        {columnVisibility.PAYMENTS && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">PAYMENTS, $</th>}
                                        {columnVisibility.BiLL_Id && <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">BILL ID</th>}
                                        {columnVisibility.Balance && <th className="p-[2px] bg-[#58165C66] text-black rounded-r-full">BALANCE</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {newOffers.map((offer) => (
                                        <tr
                                            key={offer.id}
                                            className="text-center shadow-xl rounded-r-xl rounded-l-xl h-20"
                                        >
                                            {columnVisibility.Date && <td className="p-3 text-black border-r-2 border-gray-200">{offer.Date}</td>}
                                            {columnVisibility.COMMISSION && <td className="p-3 text-black border-r-2 border-gray-200">${offer.COMMISSION}</td>}
                                            {columnVisibility.PAYMENTS && <td className="p-3 text-black border-r-2 border-gray-200">${offer.PAYMENTS}</td>}
                                            {columnVisibility.BiLL_Id && <td className="p-3 text-black border-r-2 border-gray-200">{offer.BiLL_Id}</td>}
                                            {columnVisibility.Balance && <td className="p-3 text-black border-r-2 border-gray-200">${offer.Balance}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(ReferralPayment)