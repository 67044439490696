import React, { useEffect, useState } from 'react';
import TopBar from '../UserDashboard/TopBar';
import Sidebar from '../UserDashboard/Sidebar';
import { connect } from 'react-redux';
import axios from 'axios';

const Dashboard = ({ userId, userFields }) => {
    const user_id = userId
    const user_field = userFields.name
    const [requestOffers, setRequestOffers] = useState([]);
    const [newOffers, setNewOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [showlessdata, setShowlessdata] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://apidealer.devcir.co/api/request-offers');
                // const response = await fetch('https://apidealer.devcir.co/api/request-offers');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const filteredData = data.filter(item => item.status === 1);
                setRequestOffers(filteredData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://apidealer.devcir.co/api/request-offers');
                // const response = await fetch('https://apidealer.devcir.co/api/request-offers');
                const jsonData = await response.json();
                const filteredData = jsonData.filter(item => item.status === "Approved");
                const sortedData = filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setNewOffers(sortedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleRowClick = (offer) => {
        setSelectedOffer(offer);
    };

    const closeModal = () => {
        setSelectedOffer(null);
    };

    const [columnVisibility, setColumnVisibility] = useState({
        name: true,
        priceFormat: true,
        payout: true,
        country: true,
        vertical: true,
        platform: true,
        loyalty: true,
        flow: false,
        blacklistedCountry: false,
        restrictedMediaTypes: false,
        status: false,
        tracking_type: false,
        age: false,
        gender: false,
        media_types_allowed: false,
        creative_preapproval: false,
        incentivized_promotion: false,
        post_leads_via_api: false,
        wifi: false
    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };

    const [filters, setFilters] = useState({
        country: '',
        platform: '',
        vertical: '',
        priceFormat: '',
        payout: '',
        flow: '',
        trackingType: '',
        mediaTypesAllowed: '',
        mediaTypesRestricted: '',
        wifi: '',
        incentivizedPromotion: '',
        postLeadsViaAPI: '',
        targetDemo: '',
        age: '',
        adultTraffic: '',
        creativePreapproval: '',
        carrier: '',
        loyaltyProg: ''
    });


    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };


    const filteredOffers = newOffers.filter(offer => {
        return Object.entries(filters).every(([key, value]) => {
            if (value !== '') {
                const regex = new RegExp(value, 'i');
                return regex.test(offer[key]);
            }
            return true;
        });
    });

    const handleApplySubmit = async (e, itemId, userID, Name, field) => {
        e.preventDefault();
        console.log(itemId)
        console.log(user_id)
        try {
            const response = await axios.post('https://apidealer.devcir.co/api/applied_offers', {
                // const response = await axios.post('https://apidealer.devcir.co/api/applied_offers', {
                    "item_id": itemId,
                    "user_id": user_id,
                    "user_field": user_field,
                    "userOfferId": userID,
                    "userOfferField": field,
                    "offer_Name": Name
                  });
            console.log('Offer created:', response.data);
            alert("Applied Successfully!!");
            closeModal();

        } catch (error) {
            console.error('Error creating offer:', error);
        }
    };

    return (
        <>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 p-3 bg-">
                <TopBar />
                <div className='mt-6 bg-white'>
                    <label className='text-xl text-gray-500 tracking-wider'>NEW OFFERS</label>
                </div>


                {showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-4 gap-4">
                        <label class="block text-black mb-2">Country:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Country" />

                        <label class="block text-black mb-2">Platform:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Platform" />

                        <label class="block text-black mb-2">Vertical:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.vertical} onChange={e => handleFilterChange('vertical', e.target.value)} placeholder="Vertical" />

                        <label class="block text-black mb-2">Price Format:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.priceFormat} onChange={e => handleFilterChange('priceFormat', e.target.value)} placeholder="Price Format" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600' onClick={() => { setShowlessdata(false) }}>Show More</button>
                    </div>
                )}


                {!showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-4 gap-4">
                        <label class="block text-black mb-2">Country:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Country" />

                        <label class="block text-black mb-2">Platform:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Platform" />

                        <label class="block text-black mb-2">Vertical:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.vertical} onChange={e => handleFilterChange('vertical', e.target.value)} placeholder="Vertical" />

                        <label class="block text-black mb-2">Price Format:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.priceFormat} onChange={e => handleFilterChange('priceFormat', e.target.value)} placeholder="Price Format" />

                        <label class="block text-black mb-2">Payout:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.payout} onChange={e => handleFilterChange('payout', e.target.value)} placeholder="Payout" />

                        <label class="block text-black mb-2">Flow:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.flow} onChange={e => handleFilterChange('flow', e.target.value)} placeholder="Flow" />

                        <label class="block text-black mb-2">Tracking Type:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.trackingType} onChange={e => handleFilterChange('trackingType', e.target.value)} placeholder="Tracking Type" />

                        <label class="block text-black mb-2">Media Types Allowed:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.mediaTypesAllowed} onChange={e => handleFilterChange('mediaTypesAllowed', e.target.value)} placeholder="Media Types Allowed" />

                        <label class="block text-black mb-2">Media Types Restricted:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.mediaTypesRestricted} onChange={e => handleFilterChange('mediaTypesRestricted', e.target.value)} placeholder="Media Types Restricted" />

                        <label class="block text-black mb-2">3G/wifi:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.wifi} onChange={e => handleFilterChange('wifi', e.target.value)} placeholder="WiFi" />

                        <label class="block text-black mb-2">Incentivized Promotion:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.incentivizedPromotion} onChange={e => handleFilterChange('incentivizedPromotion', e.target.value)} placeholder="Incentivized Promotion" />

                        <label class="block text-black mb-2">Post Lead Via Api:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.postLeadsViaAPI} onChange={e => handleFilterChange('postLeadsViaAPI', e.target.value)} placeholder="Post Leads Via API" />

                        <label class="block text-black mb-2">Target Demo:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.targetDemo} onChange={e => handleFilterChange('targetDemo', e.target.value)} placeholder="Target Demo" />

                        <label class="block text-black mb-2">Age:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.age} onChange={e => handleFilterChange('age', e.target.value)} placeholder="Age" />

                        <label class="block text-black mb-2">Adult Traffic:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.adultTraffic} onChange={e => handleFilterChange('adultTraffic', e.target.value)} placeholder="Adult Traffic" />

                        <label class="block text-black mb-2">Creative Preapproval:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.creativePreapproval} onChange={e => handleFilterChange('creativePreapproval', e.target.value)} placeholder="Creative Preapproval" />

                        <label class="block text-black mb-2">Carrier:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.carrier} onChange={e => handleFilterChange('carrier', e.target.value)} placeholder="Carrier" />

                        <label class="block text-black mb-2">Loyalty Program:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.loyaltyProg} onChange={e => handleFilterChange('loyaltyProg', e.target.value)} placeholder="Loyalty Program" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600' onClick={() => { setShowlessdata(true) }}>Show Less</button>
                    </div>
                )}


                <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='mt-4'>
                    <div>
                        <div className="overflow-x-auto">
                            <table className="text-xs w-full">
                                <colgroup>
                                    <col className="text-[10px]" style={{ display: columnVisibility.name ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.priceFormat ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.payout ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.country ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.vertical ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.platform ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.loyalty ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.flow ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.blacklistedCountry ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.restrictedMediaTypes ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.status ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.tracking_type ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.age ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.gender ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.media_types_allowed ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.creative_preapproval ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.incentivized_promotion ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.post_leads_via_api ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.wifi ? 'table-cell' : 'none' }} />
                                </colgroup>
                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.name && <th className="p-3 text-black">Name</th>}
                                        {columnVisibility.priceFormat && <th className="p-3 text-black">Price Format</th>}
                                        {columnVisibility.payout && <th className="p-3 text-black">Payout</th>}
                                        {columnVisibility.country && <th className="p-3 text-black">Country</th>}
                                        {columnVisibility.vertical && <th className="p-3 text-black">Vertical</th>}
                                        {columnVisibility.platform && <th className="p-3 text-black">Platform</th>}
                                        {columnVisibility.loyalty && <th className="p-3 text-black">Loyalty</th>}
                                        {columnVisibility.flow && <th className="p-3 text-black">Flow</th>}
                                        {columnVisibility.blacklistedCountry && <th className="p-3 text-black">Blacklisted Country</th>}
                                        {columnVisibility.restrictedMediaTypes && <th className="p-3 text-black">Restricted Media Types</th>}
                                        {columnVisibility.status && <th className="p-3 text-black">Status</th>}
                                        {columnVisibility.tracking_type && <th className="p-3 text-black">Tracking Type</th>}
                                        {columnVisibility.age && <th className="p-3 text-black">Age</th>}
                                        {columnVisibility.gender && <th className="p-3 text-black">Gender</th>}
                                        {columnVisibility.media_types_allowed && <th className="p-3 text-black">Media Types Allowed</th>}
                                        {columnVisibility.creative_preapproval && <th className="p-3 text-black">Creative PreApproval</th>}
                                        {columnVisibility.incentivized_promotion && <th className="p-3 text-black">Incentivized Promotion</th>}
                                        {columnVisibility.post_leads_via_api && <th className="p-3 text-black">Posts Leads Via API</th>}
                                        {columnVisibility.wifi && <th className="p-3 text-black">3G / WiFi</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredOffers.map((offer) => (
                                        <tr
                                            key={offer.id}
                                            className="border-b border-opacity-20 text-center"
                                        >
                                            {columnVisibility.name && <td className="p-3 text-blue-700 cursor-pointer underline" onClick={() => handleRowClick(offer)}>{offer.campaign_name}</td>}
                                            {columnVisibility.priceFormat && <td className="p-3 text-black">{offer.price_format}</td>}
                                            {columnVisibility.payout && <td className="p-3 text-black">{offer.payout}</td>}
                                            {columnVisibility.country && <td className="p-3 text-black">{offer.country}</td>}
                                            {columnVisibility.vertical && <td className="p-3 text-black">{offer.vertical}</td>}
                                            {columnVisibility.platform && <td className="p-3 text-black">{offer.platform}</td>}
                                            {columnVisibility.loyalty && <td className="p-3 text-black">{offer.loyalty}</td>}
                                            {columnVisibility.flow && <td className="p-3 text-black">{offer.flow}</td>}
                                            {columnVisibility.blacklistedCountry && <td className="p-3 text-black">{offer.blacklisted_country}</td>}
                                            {columnVisibility.restrictedMediaTypes && <td className="p-3 text-black">{offer.media_types_restricted}</td>}
                                            {columnVisibility.status && <td className="p-3 text-black">{offer.status}</td>}
                                            {columnVisibility.tracking_type && <td className="p-3 text-black">{offer.tracking_type}</td>}
                                            {columnVisibility.age && <td className="p-3 text-black">{offer.age}</td>}
                                            {columnVisibility.gender && <td className="p-3 text-black">{offer.gender}</td>}
                                            {columnVisibility.media_types_allowed && <td className="p-3 text-black">{offer.media_types_allowed}</td>}
                                            {columnVisibility.creative_preapproval && <td className="p-3 text-black">{offer.creative_preapproval}</td>}
                                            {columnVisibility.incentivized_promotion && <td className="p-3 text-black">{offer.incentivized_promotion}</td>}
                                            {columnVisibility.post_leads_via_api && <td className="p-3 text-black">{offer.post_leads_via_api}</td>}
                                            {columnVisibility.wifi && <td className="p-3 text-black">{offer.g_wifi}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {selectedOffer && (
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white flex flex-col p-8 rounded-lg w-7/12">
                            <div className='flex flex-row space-x-3'>
                                <div className='w-2/5 border-r-2 border-gray-100'>
                                    <h2 className="text-xl font-bold mb-4 text-black">{selectedOffer.campaign_name}</h2>
                                    <div>
                                        <img className='images w-60 h-60' src={selectedOffer.imageUrl} alt="img" />
                                    </div>
                                    <div className='space-y-2'>
                                        <p className='text-black mt-2'><strong>Payout:</strong> {selectedOffer.payout}</p>
                                        <p className='text-black'><strong>Price Format:</strong> {selectedOffer.price_format}</p>
                                        <p className='text-black'><strong>Vertical:</strong> {selectedOffer.vertical}</p>
                                        <p className='text-black'><strong>Platforms:</strong> {selectedOffer.platform}</p>
                                        <p className='text-black'><strong>Offer capped:</strong> {selectedOffer.status}</p>
                                        <p className='text-black'><strong>Allowed countries:</strong> {selectedOffer.country}</p>
                                    </div>
                                </div>
                                <div className='w-7/12 flex flex-col space-y-1'>
                                    <div className='mt-10 space-y-6 border-b-2 border-gray-100'>
                                        <p className='text-black'><strong>Description : </strong> {selectedOffer.info_share} </p>
                                        <p className='text-black underline'><strong>Restrictions</strong></p>
                                        <p className='text-black'><span className='font-semibold'>Media Types Restricted : </span> {selectedOffer.media_types_restricted} </p>
                                        <p className='text-black underline'><strong>Not Allowed</strong></p>
                                        <p className='text-black'><span className='font-semibold'>Countries </span> {selectedOffer.blacklisted_country} </p>
                                        <p className='text-black'><strong>Loyalty : </strong>{selectedOffer.loyalty}</p>
                                        <br />
                                        <br />
                                    </div>
                                    <div>
                                        <p className='text-black text-xs '>By checking the box below, you agree to promote the offer in accordance with the restrictions above.</p>
                                        <div className='flex flex-row mt-3 space-x-1'>
                                            <input
                                                type="checkbox"
                                                className='h-3 w-3 mt-1'
                                                checked={isChecked}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            />
                                            <p className='text-black'> I Agree</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='mt-4 border-b-[1px] border-gray-100' />
                            <div className='flex justify-end space-x-7'>
                                <button onClick={closeModal} className="mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600">Close</button>
                                <button
                                    onClick={(e) => { handleApplySubmit(e, selectedOffer.id, selectedOffer.userid, selectedOffer.campaign_name, selectedOffer.userField) }}
                                    disabled={!isChecked}
                                    className={`mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600 ${!isChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(Dashboard);