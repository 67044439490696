import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';

const ReferralProg = ({ userId, userFields }) => {

    const id = userId
    const field = userFields.name

    const [referrerName, setReferrerName] = useState('Affiliate');
    const [agencyReferrerName, setAgencyReferrerName] = useState('Agency');


    const randomData = [
    ];

    const storedData = localStorage.getItem('userData');
    const parsedData = storedData ? JSON.parse(storedData) : {};
    const setUserFields = localStorage.getItem('setUserFields');
    const userField = parsedData.setUserFields.name;



    let refCode_aff = '';
    let refCode_agn = '';

    if (userField === 'Affiliate') {
        refCode_aff = parsedData.Ref_code;  
      } else if (userField === 'Agency') {
        refCode_agn = parsedData.code;  
      }

      console.log('code of agency: ', refCode_agn);

      let referralLink = '';

      if (userField === 'Affiliate') {
        referralLink = `https://apidealer.devcir.co/AffiliateSignUp/${refCode_aff}`;
      } else if (userField === 'Agency') {
        referralLink = `https://apidealer.devcir.co/AgencySignUp/${refCode_agn}`;
      }


      useEffect(() => {
        const fetchReferrerData = async () => {
            try {
                const response = await fetch('https://apidealer.devcir.co/api/affiliate-registrations');
                const data = await response.json();
                const referrer = data.find(user => user.Referrer === refCode_aff);
                
                if (referrer) {
                    setReferrerName(referrer.firstname);
                } else {
                    setReferrerName('No referrer found');
                }
            } catch (error) {
                console.error('Error fetching referrer data:', error);
                setReferrerName('Error fetching data');
            }
        };

        if (refCode_aff) {
            fetchReferrerData();
        }
    }, [refCode_aff]);

    useEffect(() => {
        const fetchAgencyReferrerData = async () => {
            try {
                const response = await fetch('https://apidealer.devcir.co/api/agency_registrations');
                const data = await response.json();
                const referrer = data.find(user => user.referral === refCode_agn);
                
                if (referrer) {
                    setAgencyReferrerName(referrer.firstname);  
                } else {
                    setAgencyReferrerName('No referrer found');
                }
            } catch (error) {
                console.error('Error fetching agency referrer data:', error);
                setAgencyReferrerName('Error fetching data');
            }
        };

        if (refCode_agn) {
            fetchAgencyReferrerData();
        }
    }, [refCode_agn]);


          
    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl mb-7">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <label className='text-xl text-gray-500 tracking-wider'>REFERAL PROGRAM</label>
                </div>


                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>REFERAL TERMS</h1>
                    </div>

                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">

                            <p className="mt-2 ml-2 mr-2 p-4 bg-blue-100 border-l-4 border-blue-500 text-blue-700">
                                Referral Commission is calculated as a percentage of your Referred Affiliates' paid earnings. This means your Referral Commission starts to accrue once your Referred Affiliates' bills get paid and not on traffic origin, so as to include final amounts with potential adjustments.
                            </p>
                            <div className="mt-6 bg-black">
                                <div className="w-full bg-white border-gray-300">
                                    <div className="flex flex-row">
                                        <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Percentage</h1>
                                        <h1 className='text-gray-400 font-bold text-sm mt-3 mb-3 ml-4'>2.00%</h1>
                                    </div>
                                    <div className="flex flex-row">
                                        <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Duration</h1>
                                        <h1 className='text-gray-400 font-bold text-sm mt-3 mb-3 ml-4'>6 months</h1>
                                    </div>
                                    <div className="flex flex-row">
                                        <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Min. payment threshold</h1>
                                        <h1 className='text-gray-400 font-bold text-sm mt-3 mb-3 ml-4'>$0.00</h1>
                                    </div>
                                    <div className="flex flex-row">
                                        <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Referral page</h1>
                                        <select className="mt-1 p-2 text-black border rounded-md w-1/3">
                                            <option>Sign up (ENG)</option>
                                        </select>
                                    </div>
                                    <div className="flex flex-row">
                                        <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Referral link</h1>
                                        <input
                                            type="text"
                                            value={referralLink}
                                            className="mt-1 p-2 text-black border rounded-md w-1/3"
                                            readOnly
                                        />
                                    </div>
<div>
        {userField === 'Affiliate' && (
            <div className="flex flex-row">
                <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Referrer Affiliate</h1>
                <input
                    type="text"
                    value={referrerName}
                    className="mt-1 p-2 text-black border rounded-md w-1/3"
                    readOnly
                />
            </div>
        )}
        
        {userField === 'Agency' && (
            <div className="flex flex-row">
                <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Referrer Agency</h1>
                <input
                    type="text"
                    value={agencyReferrerName}
                    className="mt-1 p-2 text-black border rounded-md w-1/3"
                    readOnly
                />
            </div>
        )}
    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>REFERRAL COMMISSIONS</h1>
                    </div>

                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="mb-4 flex flex-col space-x-2">

                            <div className="mt-6 bg-black">
                                <div className="w-full bg-white border-gray-300">

                                    <div className="flex flex-row">
                                        <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Referral</h1>
                                        <select className="mt-1 p-2 text-black border rounded-md w-1/3">
                                            <option>ALL</option>
                                        </select>
                                    </div>
                                    <div className="ml-4 col-span-1 flex items-center">
                                        <label className="mt-4 block font-medium mr-2 text-gray-500">Group by referrals</label>
                                        <input type="checkbox" className="mt-4 mr-4" />
                                        <label className="mt-4 block font-medium mr-2 text-gray-500">Group by date</label>
                                        <input type="checkbox" className="mt-4 mr-4" />
                                        <label className="mt-4 block font-medium mr-2 text-gray-500">Group by Bill ID</label>
                                        <input type="checkbox" className='mt-4' />
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-5 mt-6 ml-2">
                                <button className="px-5 py-1 bg-yellow-500 text-white rounded-md">Apply</button>
                                <button className="px-5 py-1 bg-gray-200 rounded-md">Clear</button>
                            </div>

                            <table className="bg-white mt-6 mr-2">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="py-2 px-4 border-b text-black">DATE</th>
                                        <th className="py-2 px-4 border-b text-black">REFERRAL</th>
                                        <th className="py-2 px-4 border-b text-black">COMMISSION</th>
                                        <th className="py-2 px-4 border-b text-black">BILL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {randomData.length > 0 ? (
                                        randomData.map((row, index) => (
                                            <tr key={index}>
                                                <td className="py-2 px-4 border-b text-black">{row.date}</td>
                                                <td className="py-2 px-4 border-b text-black">{row.referral}</td>
                                                <td className="py-2 px-4 border-b text-black">{row.commission}</td>
                                                <td className="py-2 px-4 border-b text-black">{row.bill}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="py-2 px-4 border-b text-center text-black" colSpan="4">
                                                No data available in table
                                            </td>
                                        </tr>
                                    )}
                                    <tr className="bg-blue-100">
                                        <td className="py-2 px-4 border-b font-semibold text-black">TOTAL</td>
                                        <td className="py-2 px-4 border-b text-right font-semibold text-black" colSpan="3">
                                            $0.00
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(ReferralProg)