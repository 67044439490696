import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import TopBar from "../TopBar";
import { connect } from "react-redux";
import axios from "axios";
import Chart from "chart.js";
import Arrow from "../../../assets/images/Arrow 1.svg";
import Arrows from "../../../assets/images/Arrow 7.svg";
import OfferIcn from "../../../assets/images/image 17.svg";
import settingIcn from "../../../assets/images/image 55.svg";
import categIcn from "../../../assets/images/image 56.svg";
import calendarIcon from "../../../assets/images/image 51.svg";
import format from "date-fns/format";
import { DateRangePicker } from "react-date-range";

const EventReport = ({ userId, userFields }) => {
  const storedData = localStorage.getItem("userData");
  const parsedData = JSON.parse(storedData);
  const id = parsedData.id;
  const field = parsedData.setUserFields.name;

  const [openForm, setOpenForm] = useState(false);
  const [showlessdata, setShowlessdata] = useState(true);
  const [newOffers, setNewOffers] = useState([]);
  const [additionalData, setAdditionalData] = useState({});
  const [requestOffers, setRequestOffers] = useState([]);
  const [detailedData, setDetailedData] = useState({});
  const [fina2lData, setFinal2Data] = useState({});
  const [filterOpen, setFilterOpen] = useState(true);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://apidealer.devcir.co/api/request-offers"
        );
        // const response = await fetch('https://apidealer.devcir.co/api/request-offers');
        const jsonData = await response.json();
        const filteredData = jsonData.filter(
          (item) => item.status === "Approved"
        );
        const sortedData = filteredData.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setNewOffers(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [columnVisibility, setColumnVisibility] = useState({
    Date: true,
    CampaignName: true,
    Clicks: true,
    TotalClicks: true,
    Consversions: true,
    Events: true,
    Revenue: true,
    CR: true,
    Epc: true,
  });

  const toggleColumnVisibility = (columnName) => {
    setColumnVisibility({
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName],
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching data for id:", id, "and field:", field);
      if (field === "Agency") {
        try {
          // const response = await fetch(`https://apidealer.devcir.co/api/user-request-offer/${id}/${field}`);
          const response = await fetch(
            `https://apidealer.devcir.co/api/user-request-offer/${id}/${field}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          const filteredData = data.filter(
            (item) => item.status === "Approved"
          );
          setRequestOffers(filteredData);
          console.log("Filtered request offers:", filteredData);

          const additionalDataPromises = filteredData.map(async (report) => {
            // const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
            const response = await axios.get(
              `https://apidealer.devcir.co/api/price-table/host/${report.id}`
            );
            return { id: report.id, data: response.data };
          });
          const additionalDataResults = await Promise.all(
            additionalDataPromises
          );
          const additionalDataMap = additionalDataResults.reduce(
            (acc, { id, data }) => {
              acc[id] = data;
              return acc;
            },
            {}
          );
          console.log("Payout API: ", additionalDataMap);
          setAdditionalData(additionalDataMap);

          const labels = filteredData.map((item) =>
            new Date(item.created_at).toLocaleDateString()
          );
          const clicksData = filteredData.map(
            (item) => additionalDataMap[item.id].clicks
          );
          const totalClicksData = filteredData.map(
            (item) => additionalDataMap[item.id].conversion
          );

          // // Fetch detailed data for each request offer
          // const detailedDataPromises = filteredData.map(async item => {
          //     // const response = await fetch(`https://apidealer.devcir.co/api/prices/countHost/${item.id}`);
          //     const response = await fetch(`https://apidealer.devcir.co/api/prices/countHost/${item.id}`);

          //     // const response2 = await fetch(`https://apidealer.devcir.co/api/count-complete-host/${item.id}`);
          //     const response2 = await fetch(`https://apidealer.devcir.co/api/count-complete-host/${item.id}`);
          //     if (!response.ok || !response2.ok) {
          //         throw new Error(`Failed to fetch details for id: ${item.id}`);
          //     }
          //     const detailedData = await response.json();
          //     const detailedData2 = await response2.json();
          //     return { id: item.id, details: detailedData, details1: detailedData2 };
          // });

          // const detailedDataArray = await Promise.all(detailedDataPromises);
          // const detailedDataMap = detailedDataArray.reduce((acc, item) => {
          //     acc[item.id] = item.details;
          //     return acc;
          // }, {});

          // const final2DataMap = detailedDataArray.reduce((acc, item) => {
          //     acc[item.id] = item.details1;
          //     return acc;
          // }, {});

          // setDetailedData(detailedDataMap);
          // setFinal2Data(final2DataMap);
          // console.log('Detailed data:', detailedDataMap);
          // console.log('Final2 data:', final2DataMap);

          // // Prepare data for the chart
          // const labels = filteredData.map(item => item.campaign_name);
          // const clicksData = filteredData.map(item => {
          //     const completeCount = final2DataMap[item.id]?.completeCount || 0;
          //     const count = detailedDataMap[item.id]?.count || 1; // Avoid division by zero
          //     return (completeCount / count) * 100;
          // });
          // const totalClicksData = filteredData.map(item => {
          //     const count = detailedDataMap[item.id]?.count || 0;
          //     return item.payout * count;
          // });

          // console.log('Labels:', labels);
          // console.log('Clicks Data:', clicksData);
          // console.log('Total Clicks Data:', totalClicksData);

          const config = {
            type: "line",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "CR",
                  backgroundColor: "#3182ce",
                  borderColor: "#3182ce",
                  data: clicksData,
                  fill: false,
                },
                {
                  label: "EPC",
                  fill: false,
                  backgroundColor: "#ed64a6",
                  borderColor: "#ed64a6",
                  data: totalClicksData,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              title: {
                display: false,
                text: "Daily Summary Charts",
                fontColor: "black",
              },
              legend: {
                labels: {
                  fontColor: "black",
                },
                align: "end",
                position: "bottom",
              },
              tooltips: {
                mode: "index",
                intersect: false,
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                    },
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "Date",
                      fontColor: "black",
                    },
                    gridLines: {
                      display: false,
                      borderDash: [2],
                      borderDashOffset: [2],
                      color: "rgba(33, 37, 41, 0.3)",
                      zeroLineColor: "rgba(0, 0, 0, 0)",
                      zeroLineBorderDash: [2],
                      zeroLineBorderDashOffset: [2],
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                    },
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "Value",
                      fontColor: "black",
                    },
                    gridLines: {
                      borderDash: [3],
                      borderDashOffset: [3],
                      drawBorder: false,
                      color: "black",
                      zeroLineColor: "black",
                      zeroLineBorderDash: [2],
                      zeroLineBorderDashOffset: [2],
                    },
                  },
                ],
              },
            },
          };

          const ctx = document.getElementById("line-chart").getContext("2d");
          new Chart(ctx, config);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      if (field === "Affiliate") {
        try {
          const response1 = await fetch(
            `https://apidealer.devcir.co/api/applied-offers/${id}/${field}`
          );
          if (!response1.ok) {
            throw new Error("Network response was not ok for the first API");
          }
          const data1 = await response1.json();
          const filteredData = data1.filter(
            (item) => item.status === "Approved"
          );
          console.log("response 1 completed successfully");

          // Use filtered data to fetch from the second API
          const secondApiPromises = filteredData.map(async (item) => {
            const response2 = await fetch(
              `https://apidealer.devcir.co/api/request-offers/${item.item_id}`
            );
            if (!response2.ok) {
              throw new Error("Network response was not ok for the second API");
            }
            return response2.json();
          });
          const secondApiData = await Promise.all(secondApiPromises);
          console.log("response 2 completed successfully");

          setRequestOffers(secondApiData);

          const additionalDataPromises = secondApiData.map(async (report) => {
            // const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
            const response = await axios.get(
              `https://apidealer.devcir.co/api/price-table/host/${report.id}`
            );
            return { id: report.id, data: response.data };
          });
          const additionalDataResults = await Promise.all(
            additionalDataPromises
          );
          const additionalDataMap = additionalDataResults.reduce(
            (acc, { id, data }) => {
              acc[id] = data;
              return acc;
            },
            {}
          );
          console.log("Payout API: ", additionalDataMap);
          setAdditionalData(additionalDataMap);

          // Fetch detailed data for each request offer
          // const detailedDataPromises = secondApiData.map(async item => {
          //     const response3 = await fetch(`https://apidealer.devcir.co/api/prices/countHost/${item.id}`);
          //     const response4 = await fetch(`https://apidealer.devcir.co/api/count-complete-host/${item.id}`);
          //     if (!response3.ok || !response4.ok) {
          //         throw new Error(`Failed to fetch details for id: ${item.id}`);
          //     }
          //     const detailedData = await response3.json();
          //     const detailedData2 = await response4.json();
          //     return { id: item.id, details: detailedData, details1: detailedData2 };
          // });

          // const detailedDataArray = await Promise.all(detailedDataPromises);
          // console.log('response 4 completed successfully');

          // const detailedDataMap = detailedDataArray.reduce((acc, item) => {
          //     acc[item.id] = item.details;
          //     return acc;
          // }, {});

          // const final2DataMap = detailedDataArray.reduce((acc, item) => {
          //     acc[item.id] = item.details1;
          //     return acc;
          // }, {});

          // setFinal2Data(final2DataMap);
          // setDetailedData(detailedDataMap);

          // setDetailedData(detailedDataMap);
          // setFinal2Data(final2DataMap);
          // console.log('Detailed data:', detailedDataMap);
          // console.log('Final2 data:', final2DataMap);

          // // Prepare data for the chart
          // const labels = secondApiData.map(item => item.campaign_name);
          // const clicksData = secondApiData.map(item => {
          //     const completeCount = final2DataMap[item.id]?.completeCount || 0;
          //     const count = detailedDataMap[item.id]?.count || 1; // Avoid division by zero
          //     return (completeCount / count) * 100;
          // });
          // const totalClicksData = secondApiData.map(item => {
          //     const count = detailedDataMap[item.id]?.count || 0;
          //     return item.payout * count;
          // });

          // console.log('Labels:', labels);
          // console.log('Clicks Data:', clicksData);
          // console.log('Total Clicks Data:', totalClicksData);

          const labels = secondApiData.map((item) => item.campaign_name);
          const clicksData = secondApiData.map(
            (item) =>
              (additionalDataMap[item.id].conversion /
                additionalDataMap[item.id].clicks) *
              100
          );
          const totalClicksData = secondApiData.map(
            (item) =>
              (item.payout * additionalDataMap[item.id].conversion) /
              additionalDataMap[item.id].clicks
          );

          const config = {
            type: "line",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "CR",
                  backgroundColor: "#3182ce",
                  borderColor: "#3182ce",
                  data: clicksData,
                  fill: false,
                },
                {
                  label: "EPC",
                  fill: false,
                  backgroundColor: "#ed64a6",
                  borderColor: "#ed64a6",
                  data: totalClicksData,
                },
              ],
            },
            options: {
              maintainAspectRatio: false,
              responsive: true,
              title: {
                display: false,
                text: "Daily Summary Charts",
                fontColor: "black",
              },
              legend: {
                labels: {
                  fontColor: "black",
                },
                align: "end",
                position: "bottom",
              },
              tooltips: {
                mode: "index",
                intersect: false,
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                    },
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "Date",
                      fontColor: "black",
                    },
                    gridLines: {
                      display: false,
                      borderDash: [2],
                      borderDashOffset: [2],
                      color: "rgba(33, 37, 41, 0.3)",
                      zeroLineColor: "rgba(0, 0, 0, 0)",
                      zeroLineBorderDash: [2],
                      zeroLineBorderDashOffset: [2],
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                    },
                    display: true,
                    scaleLabel: {
                      display: false,
                      labelString: "Value",
                      fontColor: "black",
                    },
                    gridLines: {
                      borderDash: [3],
                      borderDashOffset: [3],
                      drawBorder: false,
                      color: "black",
                      zeroLineColor: "black",
                      zeroLineBorderDash: [2],
                      zeroLineBorderDashOffset: [2],
                    },
                  },
                ],
              },
            },
          };

          const ctx = document.getElementById("line-chart").getContext("2d");
          new Chart(ctx, config);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [id, field]);

  const countryOptions = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const platformOptions = ["Desktop", "Mobile", "Tablet"];

  const verticalOptions = [
    "Astrology, Clairvoyance",
    "Binary options",
    "Bingo",
    "Business Opportunity",
    "Brain Health",
    "Bathroom",
    "Casual Dating",
    "CBD",
    "Car insurance",
    "Credit Repair",
    "Download",
    "Diet & Weight Loss",
    "Debt Consolidation",
    "Debt Relief",
    "Education",
    "Ecommerce",
    "Financial",
    "Financial-",
    "Forex",
    "Finance (Pay-Per-Call)",
    "Gaming",
    "Home Improvement",
    "Hair Loss",
    "GamingHealth insurance",
    "Home Improvement (Pay-Per-Call)",
    "iGaming",
    "Insurance (other)",
    "Insurance (Pay-Per-Call)",
    "Irresponsible",
    "Life insurance",
    "Loans",
    "Legal services (home improvement)",
    "Mobile app, Social Networking",
    "Mainstream dating",
    "Mobile content",
    "Mobile app, Download",
    "mobile content, Carrier Billing",
    "Male Enhancement",
    "Muscle Gain",
    "Mass Tort",
    "Nutra (Other)",
    "Penny Auction",
    "Other",
    "Push Subscriptions",
    "Property insurance",
    "Pest control",
    "Personal Injury",
    "Real Estate",
    "Roofing",
    "Real estate insurance",
    "Refinance/Mortgages",
    "Residential services",
    "Reverse Mortgage",
    "Streaming",
    "Sweepstakes",
    "Smartlink",
    "Sale",
    "Skincare",
    "Solar",
    "Services",
    "Survey",
    "Student Debt",
    "Training vertical",
    "Travel",
    "Training",
    "Travel (Pay-Per-Call)",
    "Vouchers/Leadgen",
    "Windows",
    "_Gaming",
  ];

  const priceFormatOptions = ["CPA", "RevShare"];
  const connectionOptions = ["WIFI", "3G"];

  const statusOptions = ["Approved", "Pending"];

  const flowOptionsLabels = [
    "CPC",
    "CPI",
    "CPI (APK)",
    "CPE",
    "CPL (SOI/Email Submit)",
    "CPL (DOI)",
    "CPS (Straight Sale)",
    "CPS (First-Time Deposit)",
    "CPS (Cash on Delivery)",
    "Free Trial",
    "CC Submit",
    "DCB (1-click flow)",
    "DCB (2-click flow)",
    "Pin submit (MO flow)",
    "Pin submit (MT flow)",
    "Pin submit (Click2Sms)",
    "Pay-per-call",
    "Dynamic (Smartlink)",
    "Click2Call",
  ];
  const LoyaltyOptions = ["Yes", "No"];
  const adult_trafficOptions = ["Yes", "No"];
  const mediaTypesOptions = [
    "SEO/SEM",
    "Social Media (Facebook)",
    "Native",
    "Coupon sites",
    "SMS",
    "Instagram",
    "Contextual",
    "Retargeting",
    "Review Site/Blog",
    "Snapchat",
    "Content lock",
    "PPC/Search",
    "TikTok",
    "Email",
    "Cashback services",
    "Inapp",
    "Streaming",
    "YouTube",
    "Adware",
    "Banner/Display",
    "Network",
    "Push",
    "Incentivized",
    "Messengers",
    "Social Media (other than Facebook)",
    "Pop/Redirect",
    "Other",
  ];

  const [filters, setFilters] = useState({
    campaign_name: "",
    country: "",
    platform: "",
    vertical: "",
    price_format: "",
    payout: "",
    flow: "",
    trackingType: "",
    media_types_allowed: "",
    mediaTypesRestricted: "",
    wifi: "",
    incentivizedPromotion: "",
    postLeadsViaAPI: "",
    targetDemo: "",
    age: "",
    adult_traffic: "",
    creativePreapproval: "",
    carrier: "",
    status: "",
    loyaltyProg: "",
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  useEffect(() => {
    setDateRange([
      {
        startDate: new Date(filters.startDate),
        endDate: new Date(filters.endDate),
        key: "selection",
      },
    ]);
  }, [filters.startDate, filters.endDate]);

  const removeDuplicates = (data, key) => {
    const uniqueData = Array.from(new Set(data.map((item) => item[key]))).map(
      (uniqueKey) => {
        return data.find((item) => item[key] === uniqueKey);
      }
    );
    return uniqueData;
  };

  const startOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const endOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  const isEmptyFilters = (filters) => {
    return Object.values(filters).every((value) => value === "");
  };

  const filteredOffers = removeDuplicates(
    requestOffers.filter((offer) => {
      const offerDate = new Date(offer.created_at);
      const today = new Date();

      // Initial display of offers created on the current date
      if (isEmptyFilters(filters)) {
        return (
          startOfDay(offerDate) >= startOfDay(today) &&
          endOfDay(offerDate) <= endOfDay(today)
        );
      }

      // Filter based on provided filters
      return (
        Object.entries(filters).every(([key, value]) => {
          if (key === "startDate" || key === "endDate") {
            return true;
          }
          if (value !== "") {
            const regex = new RegExp(value, "i");
            return regex.test(offer[key]);
          }
          return true;
        }) &&
        (!filters.startDate ||
          startOfDay(offerDate) >= startOfDay(new Date(filters.startDate))) &&
        (!filters.endDate ||
          endOfDay(offerDate) <= endOfDay(new Date(filters.endDate)))
      );
    }),
    "campaign_name"
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchOffers = filteredOffers.filter((offer) =>
    offer.campaign_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="ml-2">
      <button
        data-drawer-target="sidebar-multi-level-sidebar"
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <Sidebar />

      <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
        <TopBar />
        <div className="mt-6 bg-white flex justify-between">
          <label className="text-[24px] text-[#000000] font-semibold tracking-wider ml-5">
            EVENT REPORT
          </label>
          <button
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
            className="bg-[#EFEFEF] w-24 h-6 rounded-full mr-5 flex flex-row space-x-1 shadow-gray-400 shadow-md"
          >
            <img src={Arrow} alt="" className="mt-[6px] ml-6" />
            <span className="text-black text-[14px] font-semibold">
              {!filterOpen ? "Filter" : "Hide"}
            </span>
          </button>
        </div>

        {/* {showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-6 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Campaign:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600'>Apply</button>
                    </div>
                )} */}

        {filterOpen && (
          <div className="flex items-center w-full justify-center mt-5 mb-10">
            <div className="flex flex-col p-4 w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
              <div className="flex flex-row space-x-7 ml-0 mb-4 mt-10">
                <div className="flex items-center space-x-2 relative">
                  <label className="font-semibold text-[#1F282E] text-[16px]">
                    Period
                  </label>
                  <img
                    src={calendarIcon}
                    alt="Icon"
                    width={"34px"}
                    height={"34px"}
                  />
                  <input
                    type="text"
                    value={`${format(
                      new Date(filters.startDate),
                      "MM/dd/yyyy"
                    )} - ${format(new Date(filters.endDate), "MM/dd/yyyy")}`}
                    readOnly
                    className="border-none h-9 bg-[#F5F5F5] rounded-md text-black"
                    onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                  />
                  {isDatePickerOpen && (
                    <div className="absolute top-full mt-2 z-50">
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={(item) => {
                          const { startDate, endDate } = item.selection;
                          handleFilterChange("startDate", startDate);
                          handleFilterChange("endDate", endDate);
                        }}
                        moveRangeOnFirstSelection={false}
                        className="shadow-lg text-black"
                      />
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Campaign
                  </label>
                  <select
                    value={filters.campaign_name}
                    onChange={(e) =>
                      handleFilterChange("campaign_name", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">Select</option>
                    {requestOffers.map((offer) => (
                      <option
                        key={offer.id}
                        value={offer.campaign_name}
                        className="border border-[#C9C7C7] rounded-sm"
                      >
                        {offer.campaign_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Loyalty
                  </label>
                  <select
                    value={filters.loyalty}
                    onChange={(e) =>
                      handleFilterChange("loyalty", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {LoyaltyOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Vertical
                  </label>
                  <select
                    value={filters.vertical}
                    onChange={(e) =>
                      handleFilterChange("vertical", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {verticalOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-row space-x-6 ml-1 mt-14 mb-4">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Country
                  </label>
                  <select
                    value={filters.country}
                    onChange={(e) =>
                      handleFilterChange("country", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">Select</option>
                    {countryOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Device Type
                  </label>
                  <select
                    value={filters.platform}
                    onChange={(e) =>
                      handleFilterChange("platform", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {platformOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Connection
                  </label>
                  <select
                    name="3g_wifi"
                    value={filters.wifi}
                    onChange={(e) =>
                      handleFilterChange("3g_wifi", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">Select</option>
                    {connectionOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Status
                  </label>
                  <select
                    value={filters.status}
                    onChange={(e) =>
                      handleFilterChange("status", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {statusOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-row space-x-6 ml-1 mt-14 mb-4">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Media Type
                  </label>
                  <select
                    value={filters.media_types_allowed}
                    onChange={(e) =>
                      handleFilterChange("media_types_allowed", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {mediaTypesOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-[#1F282E] text-[13px]">
                    Adult Traffic
                  </label>
                  <select
                    value={filters.adult_traffic}
                    onChange={(e) =>
                      handleFilterChange("adult_traffic", e.target.value)
                    }
                    className="p-2 border-none bg-[#F5F5F5] w-40 rounded-md text-black"
                  >
                    <option value="">All</option>
                    {adult_trafficOptions.map((option) => (
                      <option
                        key={option}
                        className="border border-[#C9C7C7] rounded-sm"
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex justify-end space-x-4 mt-8 mb-8 mr-10">
                <button className="bg-gradient-to-r from-[#FFBD3E] to-[#EE854F] text-white items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">
                  Apply
                </button>
                <button className="bg-[#315EFF] bg-opacity-40 text-[#0B31FB] p-2 items-center rounded-md w-24 h-10 shadow-gray-400 shadow-md">
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex items-center w-full justify-center mt-5 mb-10">
          <div className="flex flex-col p-4 w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7]">
            <div className="rounded-t mb-0 px-14 py-3 bg-transparent">
              <div className="flex flex-wrap items-center">
                <div className="w-full max-w-full flex-grow flex-1">
                  <h6 className="uppercase text-Gray-100 mb-1 text-xs text-black font-semibold">
                    Overview
                  </h6>
                  <h2 className="text-black text-xl font-semibold">Report</h2>
                </div>
              </div>
            </div>
            <div className="p-4 flex-auto">
              {/* Chart */}
              <div className="h-56">
                <canvas id="line-chart"></canvas>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}

        <div className="flex items-center w-full justify-center mt-5 mb-10">
          <div className="flex flex-col w-11/12 h-fit bg-white shadow-2xl rounded-2xl border-t-4 border-[#C9C7C7] mb-14">
            <div className="mx-auto w-full">
              <div className="flex justify-between items-center mb-4 mt-4 border-b-2 border-gray-200">
                <div className="flex flex-row space-x-2 ml-5 mb-2">
                  <img
                    src={OfferIcn}
                    alt="Icn"
                    width={"40px"}
                    height={"40px"}
                  />
                  <h1 className="text-2xl font-bold text-[#000000] text-[20px] mt-2">
                    Offers
                  </h1>
                </div>
                <div className="flex items-center space-x-4 mr-5 mb-2">
                  <img src={settingIcn} alt="" width={"34px"} height={"34px"} />
                  <img src={categIcn} alt="" width={"34px"} height={"34px"} />
                  <div className="relative">
                    <input
                      type="text"
                      className="border-t-[3px] border-[#58165C] rounded-full h-9 w-40 px-2 py-1 border-b-2 border-b-black text-[#58165C] font-semibold"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <svg
                        className="w-4 h-4 text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-4.35-4.35M11 19a8 8 0 100-16 8 8 0 000 16z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <table className="text-xs w-11/12 ml-11 mb-8">
                <colgroup>
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.Date ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.CampaignName
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.Clicks ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.TotalClicks
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.Consversions
                        ? "table-cell"
                        : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.Events ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.Revenue ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.CR ? "table-cell" : "none",
                    }}
                  />
                  <col
                    className="text-[10px]"
                    style={{
                      display: columnVisibility.Epc ? "table-cell" : "none",
                    }}
                  />
                </colgroup>

                <thead className="">
                  <tr className="text-center">
                    {columnVisibility.Date && (
                      <th className="p-[2px] w-28 bg-[#58165C66] border-r-[2px] border-[#681d64] text-black rounded-l-full">
                        Date
                      </th>
                    )}
                    {columnVisibility.CampaignName && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Campaigns
                      </th>
                    )}
                    {columnVisibility.Clicks && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Clicks
                      </th>
                    )}
                    {columnVisibility.TotalClicks && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Total Clicks
                      </th>
                    )}
                    {columnVisibility.Consversions && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Conversions
                      </th>
                    )}
                    {columnVisibility.Events && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Events
                      </th>
                    )}
                    {columnVisibility.Revenue && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        Revenue
                      </th>
                    )}
                    {columnVisibility.CR && (
                      <th className="p-[2px] border-r-[2px] border-[#681d64]  bg-[#58165C66] text-black">
                        CR (Unique/Total),%
                      </th>
                    )}
                    {columnVisibility.Epc && (
                      <th className="p-[2px] bg-[#58165C66] text-black rounded-r-full">
                        EPC (Unique/Total)
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {searchOffers.length === 0 ? (
                    <tr>
                      <td
                        className="mt-2 text-center p-4 text-2xl font-bold text-gray-500"
                        colSpan={
                          Object.values(columnVisibility).filter(Boolean).length
                        }
                      >
                        No data available
                      </td>
                    </tr>
                  ) : (
                    searchOffers.map((summary) => {
                      // Parse switch_rate and count as floats and store them in variables
                      const parsedSwitchRate = parseFloat(summary.payout) || 0;
                      const parsedCount = detailedData[summary.id]
                        ? parseFloat(detailedData[summary.id].count)
                        : 0;
                      const percentage = parseFloat(summary.percent) || 0; // Parse percentage and provide a default value
                      const revCount = (
                        (percentage / 100) *
                        parsedSwitchRate *
                        parsedCount
                      ).toFixed(3);
                      const newCount =
                        parsedCount * (parsedSwitchRate / 1000).toFixed(3);
                      // const total = (newCount / 1000) * parsedCount;

                      return (
                        <tr
                          key={summary.id}
                          className="text-center shadow-xl rounded-r-xl rounded-l-xl h-20"
                        >
                          {columnVisibility.Date && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {new Date(
                                summary.created_at
                              ).toLocaleDateString()}
                            </td>
                          )}
                          {columnVisibility.CampaignName && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {summary.campaign_name}
                            </td>
                          )}
                          {additionalData[summary.id] && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {additionalData[summary.id]
                                ? additionalData[summary.id].clicks || 0
                                : "Loading..."}
                            </td>
                          )}
                          {additionalData[summary.id] && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {additionalData[summary.id]
                                ? additionalData[summary.id].clicks || 0
                                : "Loading..."}
                            </td>
                          )}
                          <td className="p-3 text-black border-r-2 border-gray-200">
                            {additionalData[summary.id]
                              ? additionalData[summary.id].conversion || 0
                              : "Loading..."}
                          </td>
                          {additionalData[summary.id] && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {additionalData[summary.id]
                                ? (() => {
                                    const conversion =
                                      parseInt(
                                        additionalData[summary.id].conversion
                                      ) || 0;
                                    const clicks =
                                      parseInt(
                                        additionalData[summary.id].clicks
                                      ) || 0;
                                    const result = conversion + clicks;
                                    return isNaN(result) ? 0 : result;
                                  })()
                                : "Loading..."}
                            </td>
                          )}
                          {additionalData[summary.id] && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {additionalData[summary.id]
                                ? summary.price_format === "CPA"
                                  ? (() => {
                                      const payout = summary.payout || 0;
                                      const conversion =
                                        parseInt(
                                          additionalData[summary.id].conversion
                                        ) || 0;
                                      const result = payout * conversion;
                                      return isNaN(result)
                                        ? "$0"
                                        : `$ ${result}`;
                                    })()
                                  : (() => {
                                      const payout = summary.payout || 0;
                                      const conversion =
                                        parseInt(
                                          additionalData[summary.id].conversion
                                        ) || 0;
                                      const percent = summary.percent || 0;
                                      const result =
                                        payout * conversion * (percent / 100);
                                      return isNaN(result)
                                        ? "$0"
                                        : `$ ${result}`;
                                    })()
                                : "Loading..."}
                            </td>
                          )}
                          {additionalData[summary.id] && (
                            <td className="p-3 text-black border-r-2 border-gray-200">
                              {additionalData[summary.id]
                                ? isNaN(
                                    additionalData[summary.id].conversion /
                                      additionalData[summary.id].clicks
                                  )
                                  ? `0 %`
                                  : `${
                                      (additionalData[summary.id].conversion /
                                        additionalData[summary.id].clicks) *
                                      100
                                    } %`
                                : "Loading..."}
                            </td>
                          )}
                          {additionalData[summary.id] && (
                            <td className="p-3 text-black">
                              {additionalData[summary.id]
                                ? isNaN(
                                    (summary.payout *
                                      additionalData[summary.id].conversion) /
                                      additionalData[summary.id].clicks
                                  )
                                  ? `$ 0`
                                  : `$ ${`${
                                      (summary.payout *
                                        additionalData[summary.id].conversion) /
                                      additionalData[summary.id].clicks
                                    }`}`
                                : "Loading..."}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  userFields: state.user.userFields,
});

export default connect(mapStateToProps)(EventReport);
