import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import axios from 'axios';

const ClickReport = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;


    const [showlessdata, setShowlessdata] = useState(true);
    const [newOffers, setNewOffers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get('https://apidealer.devcir.co/api/click-reports');
                const response = await axios.get('https://apidealer.devcir.co/api/click-reports');
                setNewOffers(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const [filters, setFilters] = useState({
        country: '',
        platform: '',
        vertical: '',
        priceFormat: '',
        payout: '',
        flow: '',
        trackingType: '',
        mediaTypesAllowed: '',
        mediaTypesRestricted: '',
        wifi: '',
        incentivizedPromotion: '',
        postLeadsViaAPI: '',
    });


    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    const [columnVisibility, setColumnVisibility] = useState({
        Click_Id: true,
        Click_Date: true,
        Rotation: true,
        SmartLink: true,
        Campaign: true,
        Contact: true,
        Sub_Id1: true,
        Sub_Id2: true,
        Sub_Id3: true,
        Sub_Id4: true,
        Sub_Id5: true,
        Ip_Address: true,
        Is_unique: true,
        Redirect_Type: true,
    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };

    return (
        <>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 p-3 bg-">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <h1 className='text-2xl font-semibold text-gray-500 tracking-wider'>CLICK REPORT</h1>
                </div>


                {/* {showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-6 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Campaign:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <label class="block text-black mb-2">Contact:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.vertical} onChange={e => handleFilterChange('vertical', e.target.value)} placeholder="Contact" />

                        <label class="block text-black mb-2">SmartLink:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.priceFormat} onChange={e => handleFilterChange('priceFormat', e.target.value)} placeholder="SmartLink" />

                        <label class="block text-black mb-2">Group By:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.payout} onChange={e => handleFilterChange('payout', e.target.value)} placeholder="Group By" />

                        <label class="block text-black mb-2">Country:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.flow} onChange={e => handleFilterChange('flow', e.target.value)} placeholder="Country" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600' onClick={() => { setShowlessdata(false) }}>Show More</button>
                    </div>
                )}

                {!showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-6 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Campaign:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <label class="block text-black mb-2">Contact:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.vertical} onChange={e => handleFilterChange('vertical', e.target.value)} placeholder="Contact" />

                        <label class="block text-black mb-2">SmartLink:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.priceFormat} onChange={e => handleFilterChange('priceFormat', e.target.value)} placeholder="SmartLink" />

                        <label class="block text-black mb-2">Group By:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.payout} onChange={e => handleFilterChange('payout', e.target.value)} placeholder="Group By" />

                        <label class="block text-black mb-2">Country:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.flow} onChange={e => handleFilterChange('flow', e.target.value)} placeholder="Country" />

                        <label class="block text-black mb-2">Device Type:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.trackingType} onChange={e => handleFilterChange('trackingType', e.target.value)} placeholder="Device Type" />

                        <label class="block text-black mb-2">Connection:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.mediaTypesAllowed} onChange={e => handleFilterChange('mediaTypesAllowed', e.target.value)} placeholder="Connection" />

                        <label class="block text-black mb-2">OS:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.mediaTypesRestricted} onChange={e => handleFilterChange('mediaTypesRestricted', e.target.value)} placeholder="OS" />

                        <label class="block text-black mb-2">Browser:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.wifi} onChange={e => handleFilterChange('wifi', e.target.value)} placeholder="Browser" />

                        <label class="block text-black mb-2">Media Type:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.incentivizedPromotion} onChange={e => handleFilterChange('incentivizedPromotion', e.target.value)} placeholder="Media Type" />

                        <label class="block text-black mb-2">SubAffiliates:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.postLeadsViaAPI} onChange={e => handleFilterChange('postLeadsViaAPI', e.target.value)} placeholder="SubAffiliates" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600' onClick={() => { setShowlessdata(true) }}>Show Less</button>
                    </div>
                )} */}



                <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className='mt-4'>
                    <div>
                        <div className="overflow-x-auto">
                            <table className="text-xs w-full text-center">

                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.Click_Id && <th className="p-3 text-black">Click ID</th>}
                                        {columnVisibility.Click_Date && <th className="p-3 text-black">CLick Date</th>}
                                        {columnVisibility.Rotation && <th className="p-3 text-black">Rotation</th>}
                                        {columnVisibility.SmartLink && <th className="p-3 text-black">Smartlink</th>}
                                        {columnVisibility.Campaign && <th className="p-3 text-black">Campaign</th>}
                                        {columnVisibility.Contact && <th className="p-3 text-black">Contact</th>}
                                        {columnVisibility.Sub_Id1 && <th className="p-3 text-black">Sub Id 1</th>}
                                        {columnVisibility.Sub_Id2 && <th className="p-3 text-black">Sub Id 2</th>}
                                        {columnVisibility.Sub_Id3 && <th className="p-3 text-black">Sub Id 3</th>}
                                        {columnVisibility.Sub_Id4 && <th className="p-3 text-black">Sub Id 4</th>}
                                        {columnVisibility.Sub_Id5 && <th className="p-3 text-black">Sub Id 5</th>}
                                        {columnVisibility.Ip_Address && <th className="p-3 text-black">IP Address</th>}
                                        {columnVisibility.Is_unique && <th className="p-3 text-black">Is Unique</th>}
                                        {columnVisibility.Redirect_Type && <th className="p-3 text-black">Redirect Type</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/*
                                            {columnVisibility.CampaignName && <td className="p-3 text-blue-700 cursor-pointer underline">{offer.campaign_name}</td>}
                                            {columnVisibility.Visits && <td className="p-3 text-black">{offer.price_format}</td>}
                                            {columnVisibility.TotalVisits && <td className="p-3 text-black">{offer.Is_unique}</td>}
                                            {columnVisibility.Clicks && <td className="p-3 text-black">{offer.country}</td>}
                                            {columnVisibility.TotalClicks && <td className="p-3 text-black">{offer.vertical}</td>}
                                            {columnVisibility.Consversions && <td className="p-3 text-black">{offer.platform}</td>}
                                            {columnVisibility.Events && <td className="p-3 text-black">{offer.loyalty}</td>}
                                            {columnVisibility.Ip_Address && <td className="p-3 text-black">{offer.flow}</td>}
                                            {columnVisibility.CR && <td className="p-3 text-black">{offer.blacklisted_country}</td>}
                                            {columnVisibility.Epc && <td className="p-3 text-black">{offer.media_types_restricted}</td>}
                                        </tr>
                                     */}

                                    {newOffers.map((offer) => (
                                        <tr
                                            key={offer.id}
                                            className="border-b border-opacity-20 "
                                        >
                                            {columnVisibility.Click_Id && <td className="p-3 text-black">{offer.Click_Id}</td>}
                                            {columnVisibility.Click_Date && <td className="p-3 text-black">{offer.Click_Date}</td>}
                                            {columnVisibility.Rotation && <td className="p-3 text-black">{offer.Rotation}</td>}
                                            {columnVisibility.SmartLink && <td className="p-3 text-black">{offer.SmartLink}</td>}
                                            {columnVisibility.Campaign && <td className="p-3 text-black">{offer.Campaign}</td>}
                                            {columnVisibility.Contact && <td className="p-3 text-black">{offer.Contact}</td>}
                                            {columnVisibility.Sub_Id1 && <td className="p-3 text-black">{offer.Sub_Id1}</td>}
                                            {columnVisibility.Sub_Id2 && <td className="p-3 text-black">{offer.Sub_Id2}</td>}
                                            {columnVisibility.Sub_Id3 && <td className="p-3 text-black">{offer.Sub_Id3}</td>}
                                            {columnVisibility.Sub_Id4 && <td className="p-3 text-black">{offer.Sub_Id4}</td>}
                                            {columnVisibility.Sub_Id5 && <td className="p-3 text-black">{offer.Sub_Id5}</td>}
                                            {columnVisibility.Ip_Address && <td className="p-3 text-black">{offer.Ip_Address}</td>}
                                            {columnVisibility.Is_unique && <td className="p-3 text-black">{offer.Is_unique}</td>}
                                            {columnVisibility.Redirect_Type && <td className="p-3 text-black">{offer.Redirect_Type}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(ClickReport)