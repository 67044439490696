import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';

const GlobalPostback = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const [s2sPostbackURL, setS2sPostbackURL] = useState('');
    const [htmlPixel, setHtmlPixel] = useState('');

    const tokenData = [
        { tokenName: 'Lead ID', placeholder: '#leadid#' },
        { tokenName: 'Affiliate ID', placeholder: '#affid#' },
        { tokenName: 'Offer ID', placeholder: '#oid#' },
        { tokenName: 'Campaign ID', placeholder: '#campid#' },
        { tokenName: 'Creative ID', placeholder: '#cid#' },
        { tokenName: 'Transaction ID', placeholder: '#tid#' },
        { tokenName: 'Sub ID 1', placeholder: '#s1#' },
        { tokenName: 'Sub ID 2', placeholder: '#s2#' },
        { tokenName: 'Sub ID 3', placeholder: '#s3#' },
        { tokenName: 'Sub ID 4', placeholder: '#s4#' },
        { tokenName: 'Sub ID 5', placeholder: '#s5#' },
        { tokenName: 'Payout', placeholder: '#price#' },
        { tokenName: 'UDID', placeholder: '#udid#' },
        { tokenName: 'Currency', placeholder: '#currency#' },
        { tokenName: 'Price in USD', placeholder: '#price_usd#' },
        { tokenName: 'Disposition type', placeholder: '#disposition#' },
        { tokenName: 'Conversion unix timestamp', placeholder: '#utcunixtime#' },
        { tokenName: 'Conversion datetime', placeholder: '#sourcedate#' },
        { tokenName: 'Facebook click identifier', placeholder: '#fbclid#' },
        { tokenName: 'TikTok click identifier', placeholder: '#ttclid#' },
        { tokenName: 'Action type', placeholder: '#action_type#' },
        { tokenName: 'External Affiliate ID', placeholder: '#ext_affid#' },
        { tokenName: 'External Advertiser ID', placeholder: '#ext_advid#' },
        { tokenName: 'External Offer ID', placeholder: '#ext_oid#' },
        { tokenName: 'External Creative ID', placeholder: '#ext_cid#' },
        { tokenName: 'External Campaign ID', placeholder: '#ext_campid#' },
        { tokenName: 'External Offer Contract ID', placeholder: '#ext_ocid#' },
    ];


    const handleSave = () => {
        // Handle save action
        console.log('S2S Postback URL:', s2sPostbackURL);
        console.log('HTML Pixel:', htmlPixel);
    };

    const handleCancel = () => {
        // Handle cancel action
        setS2sPostbackURL('');
        setHtmlPixel('');
    };


    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <label className='text-xl text-gray-500 tracking-wider'>GLOBAL POSTBACK</label>
                </div>
                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>EDIT GLOBAL POSTBACK </h1>
                    </div>
                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="flex flex-row">
                            <h1 className='text-gray-600 w-1/4 font-light text-lg mt-3 mb-3 ml-4'>Reporting settings</h1>
                        </div>
                        <div className="flex flex-row space-x-10 items-center">
                            <label htmlFor="timezoneSelect" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                Global S2S Postback URL
                            </label>
                            <input
                                type="text"
                                id="s2sPostbackURL"
                                value={s2sPostbackURL}
                                onChange={(e) => setS2sPostbackURL(e.target.value)}
                                className="w-2/5 px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            />
                        </div>
                        <div className="flex flex-row space-x-20 items-center mb-4">
                            <label htmlFor="currencySelect" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                Global HTML Pixel
                            </label>
                            <input
                                type="text"
                                id="htmlPixel"
                                value={htmlPixel}
                                onChange={(e) => setHtmlPixel(e.target.value)}
                                className="w-2/5 px-3 py-2  border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                            />
                        </div>

                        <hr className='border-1' />

                        <div className="flex space-x-6 ml-3 mt-4 mb-4">
                            <button
                                onClick={handleSave}
                                className="px-5 py-1 bg-yellow-500 text-white font-semibold rounded-md hover:bg-yellow-600"
                            >
                                Save
                            </button>
                            <button
                                onClick={handleCancel}
                                className="px-5 py-1 bg-gray-300 text-gray-700 font-semibold rounded-md hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-6">
                    <div className="w-full bg-gray-100 border-2 border-gray-300">
                        <h1 className='text-black font-light text-base ml-4'>TOKENS DESCRIPTION </h1>
                    </div>
                    <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-300">
                                <thead>
                                    <tr>
                                        <th className="px-2 py-2 border-b-2 border-gray-300 text-left text-black">TOKEN NAME</th>
                                        <th className="px-4 py-2 border-b-2 border-gray-300 text-left text-black">PLACEHOLDER</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tokenData.map((token, index) => (
                                        <tr key={index} className="hover:bg-gray-100">
                                            <td className="px-2 py-2 border-b border-r border-gray-300 text-black">{token.tokenName}</td>
                                            <td className="px-4 py-2 border-b border-r border-gray-300 text-black">{token.placeholder}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}


const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(GlobalPostback)