import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { Box, Button, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";

export default function Admin_app() {
    const [appliedOffers, setAppliedOffers] = useState([]);
    const [affiliateData, setAffiliateData] = useState([]);
    const [agencyData, setAgencyData] = useState([]); 
    const [loading, setLoading] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState({});
  const [requestOffers, setRequestOffers] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://apidealer.devcir.co/api/applied_offers');
          setAppliedOffers(response.data.reverse());
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    // Fetch affiliate data
    useEffect(() => {
      const fetchAffiliateData = async () => {
        try {
          const response = await axios.get('https://apidealer.devcir.co/api/affiliate-registrations');
          setAffiliateData(response.data);
        } catch (error) {
          console.error('Error fetching affiliate data:', error);
        }
      };
  
      fetchAffiliateData();
    }, []);
  
    // Fetch agency data
    useEffect(() => {
      const fetchAgencyData = async () => {
        try {
          const response = await axios.get('https://apidealer.devcir.co/api/agency_registrations');
          setAgencyData(response.data);
        } catch (error) {
          console.error('Error fetching agency data:', error);
        }
      };
  
      fetchAgencyData();
    }, []);

    const handleStatusChange = async (id) => {
        const newStatus = selectedStatus[id];
        try {
          await axios.put(`https://apidealer.devcir.co/api/applied_offers_admin_approval/${id}`, { admin_approval: newStatus });
          setRequestOffers((prevOffers) => {
            return prevOffers.map((offer) => {
              if (offer.id === id) {
                return { ...offer, admin_approval: newStatus };
              }
              return offer;
            });
          });
          window.location.reload();
        } catch (error) {
          console.error('Error updating status:', error);
        }
      };

      const handleStatusSelectChange = (event, id) => {
        const newStatus = event.target.value;
        setSelectedStatus((prevStatus) => ({ ...prevStatus, [id]: newStatus }));
      };
  

    const columns = [
      // { field: 'user_id', headerName: 'User ID', flex: 1, align: 'center', headerAlign: 'center' },
      {
        field: 'affiliate_name',
        headerName: 'Affiliate Name',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => {
          const matchingAffiliate = affiliateData.find(affiliate => affiliate.id === params.row.user_id);
          return matchingAffiliate ? matchingAffiliate.firstname : 'NA';
        }
      },
      {
        field: 'agency_name',
        headerName: 'Agency Name',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: (params) => {
          const matchingAgency = agencyData.find(agency => agency.id === params.row.userOfferId);
          return matchingAgency ? matchingAgency.firstname : 'NA';
        }
      },
      { field: 'offer_Name', headerName: 'Offer Name', flex: 1, align: 'center', headerAlign: 'center' },
      { field: 'admin_approval', headerName: 'Admin Approval', flex: 1, align: 'center', headerAlign: 'center' },
      {
        field: 'changeStatus',
        headerName: 'Change Status',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select className="h-8" 
            label="Status"
            value={selectedStatus[params.row.id] || ''} 
            onChange={(event) => handleStatusSelectChange(event, params.row.id)}
            >
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        field: 'update',
        headerName: 'Update',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleStatusChange(params.row.id)}
          >
            Update Status
          </Button>
        ),
      },
    ];
  

  
    return (
      <div className="app">
        <Sidebar />
        <main className="content">
          <Box m="20px">
            <Typography variant="h1" align="left" className="font-extrabold">
              Applied Offers
            </Typography>
            <Box m="40px 0">
              <DataGrid
                rows={appliedOffers.filter((row) => row.admin_approval === 'Approved')}
                columns={columns}
                pageSize={10}
                autoHeight
                loading={loading}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                  '& .MuiDataGrid-cell': {
                    color: 'white',
                  },
                  '& .MuiInputBase-root': {
                    color: 'white',
                  },
                  '& .MuiSelect-icon': {
                    color: 'white',
                  },
                }}
              />
            </Box>
          </Box>
  
          <Box m="20px">
            <Box m="40px 0">
              <DataGrid
                rows={appliedOffers.filter((row) => row.admin_approval === 'Pending')}
                columns={columns}
                pageSize={10}
                autoHeight
                loading={loading}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                  '& .MuiDataGrid-cell': {
                    color: 'white',
                  },
                  '& .MuiInputBase-root': {
                    color: 'white',
                  },
                  '& .MuiSelect-icon': {
                    color: 'white',
                  },
                }}
              />
            </Box>
          </Box>
        </main>
      </div>
    );
  }