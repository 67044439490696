import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import axios from 'axios';

const CreativeSummary = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;


    const [showlessdata, setShowlessdata] = useState(true);
    const [newOffers, setNewOffers] = useState([]);

    useEffect(() => {
        fetchReports();
    }, []);

    const fetchReports = async () => {
        try {
            // const response = await axios.get('https://apidealer.devcir.co/api/creative-summaries');
            const response = await axios.get('https://apidealer.devcir.co/api/creative-summaries');
            setNewOffers(response.data);
        } catch (error) {
            console.error('Error fetching the reports:', error);
        }
    };

    const [filters, setFilters] = useState({
        country: '',
        platform: '',
        vertical: '',
        priceFormat: '',
        payout: '',
        flow: '',
        trackingType: '',
        mediaTypesAllowed: '',
        mediaTypesRestricted: '',
        wifi: '',
        incentivizedPromotion: '',
        postLeadsViaAPI: '',
    });


    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    const [columnVisibility, setColumnVisibility] = useState({
        CampaignName: true,
        Creatives: true,
        Clicks: true,
        TotalClicks: true,
        Consversions: true,
        Events: true,
        Revenue: true,
        CR: true,
        Epc: true,
    });

    const toggleColumnVisibility = (columnName) => {
        setColumnVisibility({
            ...columnVisibility,
            [columnName]: !columnVisibility[columnName]
        });
    };

    return (
        <>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 p-3 bg-">
                <TopBar />

                <div className='mt-6 bg-white'>
                    <h1 className='text-2xl font-semibold text-gray-500 tracking-wider'>CREATIVE SUMMARY</h1>
                </div>


                {/* {showlessdata && (
                    <div class="bg-white rounded-lg shadow-md p-6 w-full mx-auto grid grid-cols-4 gap-4">
                        <label class="block text-black mb-2">Period:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.country} onChange={e => handleFilterChange('country', e.target.value)} placeholder="Period" />

                        <label class="block text-black mb-2">Campaign:</label>
                        <input class="border-2 border-gray-300 rounded-sm text-black w-full py-2 px-3 mb-3 focus:outline-none focus:border-blue-500" type="text" value={filters.platform} onChange={e => handleFilterChange('platform', e.target.value)} placeholder="Campaign" />

                        <button className='text-black bg-gray-400 py-1 px-4 rounded hover:bg-gray-600'>Apply</button>
                    </div>
                )} */}


                <div className='mt-6 bg-white'>
                    <div className="TableDropdown">
                        <label className='text-black'>Show/Hide Columns</label>
                        <div className="DivDropDown">
                            {Object.keys(columnVisibility).map((columnName, index) => (
                                <div key={index}>
                                    <label className='text-black'>
                                        <input
                                            type="checkbox"
                                            checked={columnVisibility[columnName]}
                                            onChange={() => toggleColumnVisibility(columnName)}
                                        />
                                        {columnName}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className='mt-4'>
                    <div>
                        <div className="overflow-x-auto">
                            <table className="text-xs w-full">
                                <colgroup>
                                    <col className="text-[10px]" style={{ display: columnVisibility.CampaignName ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Creatives ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Clicks ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.TotalClicks ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Consversions ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Events ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Revenue ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.CR ? 'table-cell' : 'none' }} />
                                    <col className="text-[10px]" style={{ display: columnVisibility.Epc ? 'table-cell' : 'none' }} />
                                </colgroup>

                                <thead className="">
                                    <tr className="text-center">
                                        {columnVisibility.Creatives && <th className="p-3 text-black">Creatives</th>}
                                        {columnVisibility.CampaignName && <th className="p-3 text-black">Campaign Name</th>}
                                        {columnVisibility.Clicks && <th className="p-3 text-black">Clicks</th>}
                                        {columnVisibility.TotalClicks && <th className="p-3 text-black">Total Clicks</th>}
                                        {columnVisibility.Consversions && <th className="p-3 text-black">Conversions</th>}
                                        {columnVisibility.Events && <th className="p-3 text-black">Events</th>}
                                        {columnVisibility.Revenue && <th className="p-3 text-black">Revenue, €</th>}
                                        {columnVisibility.CR && <th className="p-3 text-black">CR (Unique/Total),%</th>}
                                        {columnVisibility.Epc && <th className="p-3 text-black">EPC (Unique/Total),€</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {newOffers.map((summary) => (
                                        <tr
                                            key={summary.id}
                                            className="border-b border-opacity-20 text-center"
                                        >
                                            {columnVisibility.Creatives && <td className="p-3 text-black">{summary.Creatives}</td>}
                                            {columnVisibility.CampaignName && <td className="p-3 text-black">{summary.CampaignName}</td>}
                                            {columnVisibility.Clicks && <td className="p-3 text-black">{summary.Clicks}</td>}
                                            {columnVisibility.TotalClicks && <td className="p-3 text-black">{summary.TotalClicks}</td>}
                                            {columnVisibility.Consversions && <td className="p-3 text-black">{summary.Conversions}</td>}
                                            {columnVisibility.Events && <td className="p-3 text-black">{summary.Events}</td>}
                                            {columnVisibility.Revenue && <td className="p-3 text-black">{summary.Revenue}</td>}
                                            {columnVisibility.CR && <td className="p-3 text-black">{summary.CR}</td>}
                                            {columnVisibility.Epc && <td className="p-3 text-black">{summary.Epc}</td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(CreativeSummary)