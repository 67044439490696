import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TeamManagement = ({ userId, userFields }) => {

    // const id = userId
    // const field = userFields.name

    const storedData = localStorage.getItem('userData');
    const parsedData = JSON.parse(storedData);
    const id = parsedData.id;
    const field = parsedData.setUserFields.name;

    const [openForm, setOpenForm] = useState(false)

    const [activeTab, setActiveTab] = useState('Manage Team');

    return (
        <div className='ml-2'>
            <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <Sidebar />

            <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
                <TopBar />

                <div>
                    <div className="flex border-b border-gray-300">
                        <button
                            className={`px-4 py-2 ${activeTab === 'Manage Team' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                                }`}
                            onClick={() => setActiveTab('Manage Team')}
                        >
                            Manage Team
                        </button>
                        <button
                            className={`px-4 py-2 ${activeTab === 'Access Log' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                                }`}
                            onClick={() => setActiveTab('Access Log')}
                        >
                            Access Log
                        </button>
                        <button
                            className={`px-4 py-2 ${activeTab === 'Action Log' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                                }`}
                            onClick={() => setActiveTab('Action Log')}
                        >
                            Action Log
                        </button>
                    </div>
                    <div className="mt-4">
                        {activeTab === 'Manage Team' && <ManageTeamTable />}
                        {activeTab === 'Access Log' && <AccessLog />}
                        {activeTab === 'Action Log' && <ActionLog />}
                    </div>
                </div>
            </div>


        </div>
    )
}

const ManageTeamTable = () => {
    const [data, setData] = useState([
        {
            id: 33915,
            username: 'david_bcn997',
            name: 'david_bcn997 (default)',
            email: 'david_bcn997@hotmail.com',
            phone: '123456789',
            contactType: 'Account Manager',
            dateCreated: '2018-02-12 05:58:18',
            canLogin: true,
            lastLoginDate: '2024-05-20 08:55:25',
            lastLoginIP: '103.244.176.32',
            Actions: '',
        },
    ]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        affiliate: 'david_bcn997',
        firstName: '',
        lastName: '',
        email: '',
        contactType: '',
        imType: '',
        imName: '',
        phone: '',
        timezone: '',
        dateOfBirth: '',
        canLogin: true,
        sendLoginDetails: true,
    });

    const permissionsList = [
        { label: "Dashboard", checked: true },
        { label: "Offer", checked: true },
        { label: "Apply for offer", checked: true },
        { label: "Add rotation", checked: true },
        { label: "Smartlink", checked: true },
        { label: "Apply for smartlink", checked: true },
        { label: "Set pixel & postback", checked: true },
        { label: "Reports", checked: true },
        { label: "Loyalty", checked: false },
        { label: "Tools", checked: true },
        { label: "Manage custom domains", checked: false },
        { label: "View Tools - API", checked: true },
        { label: "Show account balance", checked: true },
        { label: "Billing", checked: false },
        { label: "Change payment details", checked: false },
        { label: "Request referral payments", checked: false },
        { label: "Team management", checked: false },
        { label: "Can add contact", checked: false },
        { label: "Can edit contact", checked: false },
        { label: "Can delete contact", checked: false },
        { label: "Can reset password", checked: false },
    ];
    const permissionsListSecond = [
        { label: "Billing", checked: false },
        { label: "Change payment details", checked: false },
        { label: "Request referral payments", checked: false },
        { label: "Team management", checked: false },
        { label: "Can add contact", checked: false },
        { label: "Can edit contact", checked: false },
        { label: "Can delete contact", checked: false },
        { label: "Can reset password", checked: false },
        { label: "Can edit account details", checked: false },
        { label: "Change global postback", checked: false },
        { label: "Manage FB and TikTok integrations", checked: false },
        { label: "Show own statistics", checked: false },
        { label: "Upload creative for approval", checked: false },
        { label: "Upload creative for approval while applying", checked: false },
    ];

    const [permissions, setPermissions] = useState(permissionsList);
    const [permissionsSecond, setPermissionsSecond] = useState(permissionsListSecond);

    const handleCheckboxChange = (index) => {
        const newPermissions = [...permissions];
        newPermissions[index].checked = !newPermissions[index].checked;
        setPermissions(newPermissions);
    };
    const handleCheckboxChangeSecond = (indexSecond) => {
        const newPermissionsSecond = [...permissionsSecond];
        newPermissionsSecond[indexSecond].checked = !newPermissionsSecond[indexSecond].checked;
        setPermissionsSecond(newPermissionsSecond);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleNext = () => {
        setStep(2);
    };

    const handlePrev = () => {
        setStep(1);
    };

    const handleAddContact = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        console.log("Permissions submitted: ", permissions);
        onClose();
    };

    const onClose = (e) => {
        setIsModalOpen(false)
    }

    return (
        <div className="overflow-x-auto">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Manage Team</h2>
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="px-4 py-2 bg-blue-500 text-black rounded hover:bg-blue-600"
                >
                    Add Contact
                </button>
            </div>
            <table className="min-w-full bg-white border border-gray-300 mb-10">
                <thead>
                    <tr>
                        <th className="px-4 py-2 border-2 text-black">Name</th>
                        <th className="px-4 py-2 border-2 text-black">Email</th>
                        <th className="px-4 py-2 border-2 text-black">Phone</th>
                        <th className="px-4 py-2 border-2 text-black">Contact Type</th>
                        <th className="px-4 py-2 border-2 text-black">Date Created</th>
                        <th className="px-4 py-2 border-2 text-black">Can Log In</th>
                        <th className="px-4 py-2 border-2 text-black">Last Login Date</th>
                        <th className="px-4 py-2 border-2 text-black">Last Login IP</th>
                        <th className="px-4 py-2 border-2 text-black">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td className="px-4 py-2 border-2 text-black">{`(${item.id}) ${item.username} ${item.name}`}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.email}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.phone}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.contactType}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.dateCreated}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.canLogin ? 'Yes' : 'No'}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.lastLoginDate}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.lastLoginIP}</td>
                            <td className="px-4 py-2 border-2 text-black">{item.Actions}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && (
                <div className="overflow-y-auto fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg w-7/12">
                        <h3 className="text-lg mb-4 text-black">Add new contact</h3>
                        <div className="flex border-b mb-4">
                            <button
                                className={`flex-1 py-2 ${step === 1 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                onClick={() => setStep(1)}
                            >
                                Info
                            </button>
                            <button
                                className={`flex-1 py-2 ${step === 2 ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                onClick={() => setStep(2)}
                            >
                                Permissions
                            </button>
                        </div>
                        {step === 1 && (
                            <div className="grid grid-cols-1 overflow-y-auto">
                                <div className='grid grid-cols-2'>
                                    <label className=" text-gray-700">Affiliate</label>
                                    <p className="text-gray-500">{formData.affiliate}</p>
                                </div>
                                <form></form>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">First name *</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                        required
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Last name *</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                        required
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Email *</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                        required
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Contact type *</label>
                                    <select
                                        name="contactType"
                                        value={formData.contactType}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                        required
                                    >
                                        <option value="">Select</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Employee">Employee</option>
                                        <option value="Contractor">Contractor</option>
                                    </select>
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">IM type</label>
                                    <select
                                        name="imType"
                                        value={formData.imType}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    >
                                        <option value="">Select</option>
                                        <option value="Skype">Skype</option>
                                        <option value="WhatsApp">WhatsApp</option>
                                        <option value="Telegram">Telegram</option>
                                    </select>
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">IM name</label>
                                    <input
                                        type="text"
                                        name="imName"
                                        value={formData.imName}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Phone</label>
                                    <input
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Timezone</label>
                                    <select
                                        name="timezone"
                                        value={formData.timezone}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    >
                                        <option value="">Select</option>
                                        <option value="PST">PST</option>
                                        <option value="EST">EST</option>
                                        <option value="CET">CET</option>
                                    </select>
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Date of birth</label>
                                    <input
                                        type="date"
                                        name="dateOfBirth"
                                        value={formData.dateOfBirth}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Can log in</label>
                                    <input
                                        type="checkbox"
                                        name="canLogin"
                                        checked={formData.canLogin}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    />
                                </div>
                                <div className='w-full flex  justify-between mt-4'>
                                    <label className="text-xs text-gray-500">Send login details</label>
                                    <input
                                        type="checkbox"
                                        name="sendLoginDetails"
                                        checked={formData.sendLoginDetails}
                                        onChange={handleInputChange}
                                        className="w-3/5 border border-gray-300  text-black placeholder:text-xs placeholder:text-gray-400 px-2"
                                    />
                                </div>
                                <div className='flex justify-between  mt-1 border-gray-100'>
                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleNext}
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                        {step === 2 && (
                            <div className="grid grid-cols-1 overflow-y-auto h-[500px]">
                                <div>
                                    <h2 className="text-xs font-semibold mb-2 text-black">Permissions</h2>
                                    {permissions.map((permission, index) => (
                                        <div key={index} className="grid grid-cols-4 overflow-y-auto items-center mb-2 text-black">
                                            <input
                                                type="checkbox"
                                                id={`permission-${index}`}
                                                checked={permission.checked}
                                                onChange={() => handleCheckboxChange(index)}
                                                className="mr-2 text-gray-500"
                                            />
                                            <label className='text-gray-500 text-xs' htmlFor={`permission-${index}`}>{permission.label}</label>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <p className="text-xs font-light mb-2 text-gray-500">Please pay attention to these permissions. If you enable them, your contacts can potentially gain access to the features that might threaten the security of your account. Enable them only if you are absolutely sure about it.</p>
                                    {permissionsSecond.map((permissionsSecond, indexsecond) => (
                                        <div key={indexsecond} className="grid grid-cols-4 overflow-y-auto items-center mb-2 text-gray-500">
                                            <input
                                                type="checkbox"
                                                id={`permissionSecond-${indexsecond}`}
                                                checked={permissionsSecond.checked}
                                                onChange={() => handleCheckboxChangeSecond(indexsecond)}
                                                className="mr-2 text-black"
                                            />
                                            <label className='text-gray-500 text-xs' htmlFor={`permissionSecond-${indexsecond}`}>{permissionsSecond.label}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className='flex justify-between  mt-1 border-gray-100'>

                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="button"
                                        onClick={handlePrev}
                                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                    >
                                        Previous
                                    </button>

                                    <button
                                        type="button"
                                        onClick={handleAddContact}
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            )}
        </div>
    );
};

const AccessLog = () => {
    const initialData = [
        { contact: 'david_bcn997', date: '2024-05-20 18:26:29', ip: '103.156.136.187', action: 'Authentication success' },
        { contact: 'david_bcn997', date: '2024-05-20 08:55:25', ip: '103.244.176.32', action: 'Authentication failure' },
        { contact: 'david_bcn997', date: '2024-05-20 06:12:45', ip: '103.244.176.32', action: 'Password change' },
    ];
    const actions = ['All', 'Authentication success', 'Authentication failure', 'Password change'];
    const [startDate, setStartDate] = useState(new Date('2024-05-20T00:00:00'));
    const [endDate, setEndDate] = useState(new Date('2024-05-20T23:59:59'));
    const [contactFilter, setContactFilter] = useState('');
    const [actionFilter, setActionFilter] = useState('All');

    const handleFilter = () => {
        const filteredData = initialData.filter(item => {
            const itemDate = new Date(item.date);
            const isWithinDateRange = itemDate >= startDate && itemDate <= endDate;
            const matchesContact = contactFilter ? item.contact.includes(contactFilter) : true;
            const matchesAction = actionFilter === 'All' ? true : item.action === actionFilter;
            return isWithinDateRange && matchesContact && matchesAction;
        });
        return filteredData;
    };

    const filteredData = handleFilter();

    return (
        <div className="p-4 bg-white shadow-md rounded-lg max-w-full mx-auto">
            <div className="flex items-center mb-4 space-x-4">
                <div className="flex flex-col">
                    <label className="text-sm text-black">Period</label>
                    <div className="flex space-x-2">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="border rounded p-2 text-black"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="border rounded p-2 text-black"
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <label className="text-sm text-black">Contact</label>
                    <input
                        type="text"
                        placeholder="Search by Contact name"
                        value={contactFilter}
                        onChange={(e) => setContactFilter(e.target.value)}
                        className="border rounded p-2 text-black"
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-sm text-black">Action</label>
                    <select
                        value={actionFilter}
                        onChange={(e) => setActionFilter(e.target.value)}
                        className="border rounded p-2 text-black"
                    >
                        {actions.map(action => (
                            <option key={action} value={action}>{action}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="flex space-x-8">
                <button
                    className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-1 px-6 rounded"
                    onClick={() => handleFilter()}
                >
                    Apply
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-6 rounded"
                    onClick={() => {
                        setStartDate(new Date('2024-05-20T00:00:00'));
                        setEndDate(new Date('2024-05-20T23:59:59'));
                        setContactFilter('');
                        setActionFilter('All');
                    }}
                >
                    Clear
                </button>
            </div>
            <h2 className="text-lg font-semibold mb-2 mt-3 text-black">History</h2>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b text-black">Contact</th>
                        <th className="py-2 px-4 border-b text-black">Date</th>
                        <th className="py-2 px-4 border-b text-black">IP</th>
                        <th className="py-2 px-4 border-b text-black">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((item, index) => (
                            <tr key={index}>
                                <td className="py-2 px-4 border-b text-black">{item.contact}</td>
                                <td className="py-2 px-4 border-b text-black">{item.date}</td>
                                <td className="py-2 px-4 border-b text-black">{item.ip}</td>
                                <td className="py-2 px-4 border-b text-black">{item.action}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="py-2 px-4 text-center text-black">No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

const ActionLog = () => {
    const initialData = [
        { contact: 'david_bcn997', date: '2024-05-20 18:26:29', ip: '103.156.136.187', action: 'Authentication success' },
        { contact: 'david_bcn997', date: '2024-05-20 08:55:25', ip: '103.244.176.32', action: 'Authentication failure' },
    ];
    const actions = ['All', 'Authentication success', 'Authentication failure', 'Password change'];
    const [startDate, setStartDate] = useState(new Date('2024-05-20T00:00:00'));
    const [endDate, setEndDate] = useState(new Date('2024-05-20T23:59:59'));
    const [contactFilter, setContactFilter] = useState('');
    const [actionFilter, setActionFilter] = useState('All');

    const handleFilter = () => {
        const filteredData = initialData.filter(item => {
            const itemDate = new Date(item.date);
            const isWithinDateRange = itemDate >= startDate && itemDate <= endDate;
            const matchesContact = contactFilter ? item.contact.includes(contactFilter) : true;
            const matchesAction = actionFilter === 'All' ? true : item.action === actionFilter;
            return isWithinDateRange && matchesContact && matchesAction;
        });
        return filteredData;
    };

    const filteredData = handleFilter();

    return (
        <div className="p-4 bg-white shadow-md rounded-lg max-w-full mx-auto">
            <div className="flex items-center mb-4 space-x-4">
                <div className="flex flex-col">
                    <label className="text-sm text-black">Period</label>
                    <div className="flex space-x-2">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="border rounded p-2 text-black"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="border rounded p-2 text-black"
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <label className="text-sm text-black">Contact</label>
                    <input
                        type="text"
                        placeholder="Search by Contact name"
                        value={contactFilter}
                        onChange={(e) => setContactFilter(e.target.value)}
                        className="border rounded p-2 text-black"
                    />
                </div>
                <div className="flex flex-col">
                    <label className="text-sm text-black">Action</label>
                    <select
                        value={actionFilter}
                        onChange={(e) => setActionFilter(e.target.value)}
                        className="border rounded p-2 text-black"
                    >
                        {actions.map(action => (
                            <option key={action} value={action}>{action}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="flex space-x-8">
                <button
                    className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-1 px-6 rounded"
                    onClick={() => handleFilter()}
                >
                    Apply
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-6 rounded"
                    onClick={() => {
                        setStartDate(new Date('2024-05-20T00:00:00'));
                        setEndDate(new Date('2024-05-20T23:59:59'));
                        setContactFilter('');
                        setActionFilter('All');
                    }}
                >
                    Clear
                </button>
            </div>
            <h2 className="text-lg font-semibold mb-2 mt-3 text-black">History</h2>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b text-black">Contact</th>
                        <th className="py-2 px-4 border-b text-black">Date</th>
                        <th className="py-2 px-4 border-b text-black">IP</th>
                        <th className="py-2 px-4 border-b text-black">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((item, index) => (
                            <tr key={index}>
                                <td className="py-2 px-4 border-b text-black">{item.contact}</td>
                                <td className="py-2 px-4 border-b text-black">{item.date}</td>
                                <td className="py-2 px-4 border-b text-black">{item.ip}</td>
                                <td className="py-2 px-4 border-b text-black">{item.action}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="py-2 px-4 text-center text-black">No data available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.userId,
    userFields: state.user.userFields,
});

export default connect(mapStateToProps)(TeamManagement)