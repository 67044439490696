import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'gray',
                        },
                        '&:hover fieldset': {
                            borderColor: 'gray',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'gray',
                        },
                    },
                },
            },
        },
    },
});

const Paymentinfo = ({ userId, userFields }) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

    const handleChangePaymentMethod = (method) => {
        setSelectedPaymentMethod(selectedPaymentMethod === method ? '' : method);
    };

    const paymentMethods = [
        { id: 'Wire', label: 'Wire/ACH (Tipalti)', placeholder: 'Enter email address that you use in Wire/ACH' },
        { id: 'Payoneer', label: 'Payoneer', placeholder: 'Enter email address that you use in Payoneer' },
        { id: 'PayPal', label: 'PayPal', placeholder: 'Enter email address that you use in PayPal' },
        { id: 'Paxum', label: 'Paxum', placeholder: 'Enter email address that you use in Paxum' },
        { id: 'Crypto', label: 'Crypto (BTC/USDT)', placeholder: 'Btc Address', hasSelect: true, selectOptions: ['BTC', 'USDT'] },
        { id: 'Dana', label: 'DANA', placeholder: 'Enter email address that you use in Dana' },
        { id: 'Other', label: 'Other', placeholder: 'Enter Payment Details' },
    ];

    return (
        <div className='ml-2'>
            <ThemeProvider theme={theme}>
                <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>

                <Sidebar />

                <div className="sm:ml-64 bg-white mt-7 rounded-tl-3xl">
                    <TopBar />

                    <div className='mt-6 bg-white'>
                        <label className='text-xl text-gray-500 tracking-wider'>Payment Information</label>
                    </div>

                    <div className="mt-6 bg-black">
                        <div className="w-full bg-gray-100 border-2 border-gray-300">
                            <h1 className='text-black font-light text-base ml-4'>PAYMENT TERMS</h1>
                        </div>
                        <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                            <div className="flex flex-row">
                                <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Billing Cycle</h1>
                                <h1 className='text-gray-400 font-bold text-sm mt-3 mb-3 ml-4'>Monthly</h1>
                            </div>
                            <div className="flex flex-row">
                                <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Payment Terms</h1>
                                <h1 className='text-gray-400 font-bold text-sm mt-3 mb-3 ml-4'>NET 15</h1>
                            </div>
                            <div className="flex flex-row">
                                <h1 className='text-gray-400 w-1/4 font-light text-sm mt-3 mb-3 ml-4'>Payment threshold (for selected payment type)</h1>
                                <h1 className='text-gray-400 font-bold text-sm mt-3 mb-3 ml-4'>$ 500</h1>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 bg-black">
                        <div className="w-full bg-gray-100 border-2 border-gray-300">
                            <h1 className='text-black font-light text-base ml-4'>PAYMENT DETAILS</h1>
                        </div>
                        <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                            {paymentMethods.map((paymentMethod) => (
                                <div key={paymentMethod.id} className="flex flex-col border-b-2 border-gray-300">
                                    <div className="flex flex-row items-center">
                                        <input
                                            type="checkbox"
                                            id={`myCheckbox${paymentMethod.id}`}
                                            checked={selectedPaymentMethod === paymentMethod.id}
                                            onChange={() => handleChangePaymentMethod(paymentMethod.id)}
                                            className='ml-5 h-5 w-5 mt-3 mb-3'
                                        />
                                        <label htmlFor={`myCheckbox${paymentMethod.id}`} className='text-gray-400 font-light text-xl ml-4 mt-2'>
                                            {paymentMethod.label}
                                        </label>
                                        {selectedPaymentMethod === paymentMethod.id && (
                                            <span className="text-gray-300 font-light text-xl ml-4 mt-2">{paymentMethod.placeholder}</span>
                                        )}
                                    </div>
                                    {selectedPaymentMethod === paymentMethod.id && (
                                        <div className="flex flex-col">
                                            <div className="flex flex-row mt-4 mb-5 ml-5 space-x-28">
                                                <h1 className="text-gray-400 text-base">{paymentMethod.placeholder}</h1>
                                                <input
                                                    type="text"
                                                    className="w-1/3 p-1 border border-gray-300 rounded"
                                                    placeholder={paymentMethod.placeholder}
                                                />
                                            </div>
                                            {paymentMethod.hasSelect && (
                                                <div className="flex flex-row mt-4 mb-5 ml-5 space-x-28">
                                                    <h1 className="text-gray-400 text-base">Wallet Type</h1>
                                                    <select className="w-1/3 p-1 border text-black border-gray-300 rounded">
                                                        <option value="">Select an option</option>
                                                        {paymentMethod.selectOptions.map((option) => (
                                                            <option key={option} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mt-6 bg-black">
                        <div className="w-full bg-gray-100 border-2 border-gray-300">
                            <h1 className='text-black font-light text-base ml-4'>PAYMENT SETTING</h1>
                        </div>
                        <div className="w-full bg-white border-b-2 border-r-2 border-l-2 border-gray-300">
                            <div className="flex flex-row items-center">
                                <label htmlFor="billingCycleCheckbox" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                    Hold My Payments
                                </label>
                                <input
                                    type="checkbox"
                                    id="billingCycleCheckbox"
                                    className='ml-[90px] h-5 w-5 mt-3 mb-3'
                                />
                            </div>
                            <div className="flex flex-row items-center">
                                <label htmlFor="billingCycleSelect" className='text-gray-400 font-light text-sm mt-3 mb-3 ml-4'>
                                    Current cycle payment method
                                </label>
                                <select
                                    id="billingCycleSelect"
                                    className='ml-4 h-10 w-1/3 p-1 border border-gray-300 rounded mt-3 mb-3 text-black'
                                >
                                    <option value="monthly">Monthly</option>
                                    <option value="quarterly">Quarterly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                            <div className="mt-6 mb-6 ml-4 flex justify-start space-x-4">
                                <button
                                    className="bg-orange-400 text-white px-4 py-2 rounded"
                                >
                                    Save
                                </button>
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userId: state.user.id,
    userFields: state.user.fields,
});

export default connect(mapStateToProps)(Paymentinfo);
