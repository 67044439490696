// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material";
// import Header from "../../components/Header";
// import Sidebar from "../global/Sidebar";
// import Topbar from "../global/Topbar";

// const Calendar = () => {
//   const [loading, setLoading] = useState(true);
//   const [requestOffers, setRequestOffers] = useState([]);
//   const [additionalData, setAdditionalData] = useState({});
//   const [selectedRow, setSelectedRow] = useState(null); // To store selected row data
//   const [isDialogOpen, setIsDialogOpen] = useState(false); // Controls the dialog visibility

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`https://apidealer.devcir.co/api/request-offers`);
//         if (!response.ok) throw new Error('Network response was not ok');
//         const data = await response.json(); 
//         const filteredData = data.filter(item => item.status === "Approved");
//         setRequestOffers(filteredData);

//         const additionalDataPromises = filteredData.map(async (report) => {
//           const response = await axios.get(`https://apidealer.devcir.co/api/price-table/host/${report.id}`);
//           return { id: report.id, data: response.data };
//         });

//         const additionalDataResults = await Promise.all(additionalDataPromises);
//         const additionalDataMap = additionalDataResults.reduce((acc, { id, data }) => {
//           acc[id] = data;
//           return acc;
//         }, {});

//         setAdditionalData(additionalDataMap);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleRowClick = (params) => {
//     const selectedData = rows.find(row => row.id === params.id);
//     setSelectedRow(selectedData);
//     console.log("Selected row: " + selectedData.linkid)
//     setIsDialogOpen(true);
//   };

//   const handleCloseDialog = () => {
//     setIsDialogOpen(false);
//     setSelectedRow(null);
//   };

//   const handleInputChange = (e) => {
//     setSelectedRow({ ...selectedRow, [e.target.name]: e.target.value });
//   };

//   const handleSaveChanges = async () => {
//     try {
//       // Implement update API call here if needed
//       // await axios.put(`API_URL/${selectedRow.id}`, updatedData);
//       setIsDialogOpen(false);
//       setSelectedRow(null);
//     } catch (error) {
//       console.error('Error updating data:', error);
//     }
//   };

//   const columns = [
//     { field: 'linkid', headerName: 'id', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'campaign_name', headerName: 'Campaign Name', flex: 2, align: 'center', headerAlign: 'center' },
//     { field: 'visits', headerName: 'Visits', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'clicks', headerName: 'Clicks', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'conversions', headerName: 'Conversions', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'amount', headerName: 'Amount', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'events', headerName: 'Events', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'cr', headerName: 'CR', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'epc', headerName: 'EPC', flex: 1, align: 'center', headerAlign: 'center' },
//     { field: 'revenue', headerName: 'Revenue', flex: 1, align: 'center', headerAlign: 'center' },
//   ];

//   const rows = requestOffers.map((report) => ({
//     id: report.id,
//     campaign_name: report.campaign_name,
//     linkid: additionalData[report.id]?.link_id || '0',
//     visits: additionalData[report.id]?.visits || '0',
//     clicks: additionalData[report.id]?.clicks || '0',
//     conversions: additionalData[report.id]?.conversion || '0',
//     amount: report.payout || '0',
//     events: (parseInt(additionalData[report.id]?.conversion) + parseInt(additionalData[report.id]?.clicks)) || '0',
//     cr: additionalData[report.id] && additionalData[report.id]
//       ? isNaN(additionalData[report.id]?.conversion / additionalData[report.id]?.clicks)
//         ? 0
//         : ((additionalData[report.id]?.conversion / additionalData[report.id]?.clicks) * 100).toFixed(2)
//       : '0',
//     epc: additionalData[report.id] && additionalData[report.id]
//       ? isNaN((report.payout * additionalData[report.id]?.conversion) / additionalData[report.id]?.clicks)
//         ? 0
//         : ((report.payout * additionalData[report.id]?.conversion) / additionalData[report.id]?.clicks).toFixed(2)
//       : '0',
//     revenue: additionalData[report.id]
//       ? isNaN(report.payout * additionalData[report.id].conversion) ? 0 : (report.payout * additionalData[report.id].conversion) 
//       : '0',
//   }));

//   return (
//     <div className="app">
//       <Sidebar />
//       <main className="content">
//         <Topbar />
//         <Box m="20px">
//           <Header title="Campaigns" subtitle="Campaigns Info" />
//           <Box m="40px 0">
//             <DataGrid
//               rows={rows}
//               columns={columns}
//               pageSize={10}
//               autoHeight
//               loading={loading}
//               onRowClick={handleRowClick} 
//               components={{ Toolbar: GridToolbar }}
//               sx={{
//                 '& .MuiDataGrid-columnHeaders': { backgroundColor: 'black', color: 'white' },
//                 '& .MuiDataGrid-cell': { color: 'white' },
//               }}
//             />
//           </Box>
//         </Box>

//         {/* Dialog for editing row data */}
//         <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
//           <DialogTitle>Edit Campaign Data</DialogTitle>
//           <DialogContent>
//             <TextField
//               label="Campaign Name"
//               name="campaign_name"
//               value={selectedRow?.campaign_name || ''}
//               onChange={handleInputChange}
//               fullWidth
//               margin="dense"
//             />
//             <TextField
//               label="Visits"
//               name="visits"
//               value={selectedRow?.visits || ''}
//               onChange={handleInputChange}
//               fullWidth
//               margin="dense"
//             />
//             <TextField
//               label="Clicks"
//               name="clicks"
//               value={selectedRow?.clicks || ''}
//               onChange={handleInputChange}
//               fullWidth
//               margin="dense"
//             />
//             {/* Add more fields as necessary */}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
//             <Button onClick={handleSaveChanges} color="primary">Save</Button>
//           </DialogActions>
//         </Dialog>
//       </main>
//     </div>
//   );
// };

// export default Calendar;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Calendar = () => {
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Fetch data function, so it can be called again after an update
  const fetchData = async () => {
    try {
      const response = await fetch(`https://apidealer.devcir.co/api/prices`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log("Data fetched", data);
      setPrices(data.reverse());
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (params) => {
    const selectedData = rows.find(row => row.id === params.id);
    console.log("Selected Data", selectedData);
    setSelectedRow(selectedData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedRow(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) { // Accept only positive integers
      setSelectedRow({ ...selectedRow, [name]: parseInt(value) || '' });
    }
  };

  const handleSaveChanges = async () => {
    if (selectedRow) {
      try {
        await axios.put(`https://apidealer.devcir.co/api/UpdateConversion/${selectedRow.price_id}`, {
          visits: selectedRow.visits,
          clicks: selectedRow.clicks,
          conversion: selectedRow.conversion,
        });
        console.log("Updated Values:", selectedRow);
        
        // Show success toast
        toast.success("Data updated successfully!");

        // Refetch data and close the dialog
        fetchData();
        setIsDialogOpen(false);
        setSelectedRow(null);
      } catch (error) {
        console.error('Error updating data:', error);
        toast.error("Failed to update data.");
      }
    }
  };

  const columns = [
    { field: 'price_id', headerName: 'id', flex: 0.1, align: 'center', headerAlign: 'center' },
    { field: 'product_name', headerName: 'Campaign Name', flex: 2, align: 'center', headerAlign: 'center' },
    { field: 'user', headerName: 'User Name', flex: 2, align: 'center', headerAlign: 'center' },
    { field: 'visits', headerName: 'Visits', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'clicks', headerName: 'Clicks', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'conversion', headerName: 'Conversions', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'amount', headerName: 'Amount', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'events', headerName: 'Events', flex: 1, align: 'center', headerAlign: 'center' }
  ];

  const rows = prices.map((item) => ({
    id: item.id,
    price_id: item.link_id,
    product_name: item.link_table?.applied_data?.offer_Name || 'N/A',
    user: item.link_table?.applied_data?.affiliate_data?.accountname || 'N/A',
    visits: item.visits,
    clicks: item.clicks,
    conversion: item.conversion,
    amount: item.amount,
    events: (parseInt(item?.conversion) + parseInt(item?.clicks)) || '0',
    cr: isNaN(item?.conversion / item?.clicks) ? 0 : ((item?.conversion / item?.clicks) * 100).toFixed(2),
  }));

  return (
    <div className="app">
      <Sidebar />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <Header title="Prices" subtitle="Price List" />
          <Box m="40px 0">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              autoHeight
              loading={loading}
              onRowClick={handleRowClick} 
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': { backgroundColor: 'black', color: 'white' },
                '& .MuiDataGrid-cell': { color: 'white' },
              }}
            />
          </Box>
        </Box>

        {/* Dialog for editing row data */}
        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Edit Data For {selectedRow?.user}</DialogTitle>
          <DialogContent>
            <TextField
              label="Visits"
              name="visits"
              value={selectedRow?.visits || 0}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Clicks"
              name="clicks"
              value={selectedRow?.clicks || 0}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
            <TextField
              label="Conversion"
              name="conversion"
              value={selectedRow?.conversion || 0}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
            <Button onClick={handleSaveChanges} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
        <ToastContainer position="top-right" autoClose={3000} />
      </main>
    </div>
  );
};

export default Calendar;
