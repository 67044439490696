import React from 'react'
import logo from '../assets/images/Main_Logo.png'
import { useState, useEffect } from 'react';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import bcrypt from "bcryptjs";

export const SmartLink_SignUp = () => {

    const navigate = useNavigate()

    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState('');
    const validateFirstName = (value) => {
        // Regex for validating a name (letters and spaces only)
        const nameRegex = /^[a-zA-Z\s]+$/;
        return nameRegex.test(value);
    };
    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        if (validateFirstName(value)) {
            setFirstNameError('');
        } else {
            setFirstNameError('Please enter a valid first name.');
        }
        setFirstName(value);
    };

    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState('');
    const validateLastName = (value) => {
        // Regex for validating a name (letters and spaces only)
        const nameRegex = /^[a-zA-Z\s]+$/;
        return nameRegex.test(value);
    };
    const handleLastNameChange = (e) => {
        const value = e.target.value;
        if (validateLastName(value)) {
            setLastNameError('');
        } else {
            setLastNameError('Please enter a valid last name.');
        }
        setLastName(value);
    };

    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const validatePhoneNumber = (value) => {
        // Regex for validating a phone number (adjust according to your requirements)
        const phoneRegex = /^\d{10}$/; // example for a 10-digit phone number
        return phoneRegex.test(value);
    };
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (validatePhoneNumber(value)) {
            setPhoneNumberError('');
        } else {
            setPhoneNumberError('Please enter a valid phone number.');
        }
        setPhoneNumber(value);
    };

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('');
    const validateEmail = (value) => {
        // Regex for validating an email address
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };
    const handleEmailChange = (e) => {
        const value = e.target.value;
        if (validateEmail(value)) {
            setEmailError('');
        } else {
            setEmailError('Please enter a valid email address.');
        }
        setEmail(value);
    };

    const [prefferedService, setPrefferedService] = useState('')
    const [heardAbouUS, setHeardAbouUS] = useState('')
    const [imNickName, setimNickName] = useState('')
    const [password, setPassword] = useState('')
    const [sendMail, setSendMail] = useState(0)
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    const options = [
        { value: 'Skype', label: 'Skype' },
        { value: 'Telegram', label: 'Telegram' },
    ];

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setSendMail(1);
        } else {
            setSendMail(0);
        }
    };

    const handleprefferedChange = selectedOption => {
        setPrefferedService(selectedOption);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(firstName)
        console.log(lastName)
        console.log(phoneNumber)
        console.log(email)
        console.log(prefferedService)
        console.log(heardAbouUS)
        console.log(imNickName)
        console.log(password)
        console.log(sendMail)
        console.log(selectedCountry)
    }

    useEffect(() => {
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
            .then((response) => response.json())
            .then((data) => {
                setCountries(data.countries);
                setSelectedCountry(data.userSelectValue);
            });
    }, []);


    const handle_Data = async (e) => {

        e.preventDefault();

        const hashedPassword = await bcrypt.hash(password, 7);

        const response = await fetch('https://apidealer.devcir.co/api/registrations', {
        // const response = await fetch('https://apidealer.devcir.co/api/registrations', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                email: email,
                preferredService: prefferedService.label,
                heardAboutUs: heardAbouUS,
                imNickname: imNickName,
                password: hashedPassword,
                sendMail: sendMail,
                country: selectedCountry.label
            }),
        });


        if (!response.ok) {
            console.error(`Server responded with status ${response.status}: ${response.statusText}`);
            return;
        }

        try {
            const data = await response.json();
            alert('Registered successfully')
            navigate('/SmartLinkLogin')
            console.log(data);
        } catch (error) {
            alert('Error while registering user')
            console.error('Error parsing server response:', error);
            
        }
    }

    return (
        <div className='flex flex-col mt-4 mb-4'>
            <div className='flex flex-row'>
                <div>
                    <img src={logo} alt="Logo" className='mt-4 ml-6' />
                </div>
                <div className='mt-14'>
                    <label className='flex text-[32px] font-bold ml-[310px] text-black'>SmartLink Sign Up</label>
                    <hr className='ml-56 w-[472px] h-1 bg-[#3ac67b]' />
                    <p className='mt-14 ml-[325px] text-[#969494] text-[16px] font-medium'>Once Your Application is Approved,</p>
                    <p className='ml-60 text-[#969494] text-[16px] font-medium '>you will immediately gain access to our in-house smartlinks</p>
                </div>
            </div>
            <div >
                <form className='mt-8'>
                    <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
                        <div className="relative z-0 w-5/12 mb-5 group">
                            <input
                                type="text"
                                name="floating_first_name"
                                id="floating_first_name"
                                className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-black font-semibold focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer ${firstNameError ? 'border-red-500 focus:border-red-500' : 'border-gray-400 focus:border-[#3ac67b]'}`}
                                placeholder=" "
                                value={firstName}
                                required
                                onChange={handleFirstNameChange}
                            />
                            <label
                                htmlFor="floating_first_name"
                                className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${firstNameError ? 'text-red-500 peer-focus:text-red-500' : 'text-[#3ac67b] peer-focus:text-[#3ac67b]'}`}
                            >
                                First Name
                            </label>
                            {firstNameError && <p className="text-red-500 text-xs mt-1">{firstNameError}</p>}
                        </div>

                        <div className="relative z-30 w-5/12 mb-5 group">
                            <Select
                                className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10"
                                required
                                options={countries}
                                value={selectedCountry}
                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                            />
                            <label className="absolute text-lg text-[#3ac67b] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] ml-2">Country</label>
                        </div>
                    </div>
                    <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-6">
                        <div className="relative z-0 w-5/12 group ">
                            <input
                                type="text"
                                name="floating_last_name"
                                id="floating_last_name"
                                className={`block py-2.5 px-0 w-full text-sm text-black font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer ${lastNameError ? 'border-red-500 focus:border-red-500' : 'border-gray-400 focus:border-[#3ac67b]'}`}
                                placeholder=" "
                                value={lastName}
                                required
                                onChange={handleLastNameChange}
                            />
                            <label
                                htmlFor="floating_last_name"
                                className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${lastNameError ? 'text-red-500 peer-focus:text-red-500' : 'text-[#3ac67b] peer-focus:text-[#3ac67b]'}`}
                            >
                                Last Name
                            </label>
                            {lastNameError && <p className="text-red-500 text-xs mt-1">{lastNameError}</p>}
                        </div>

                        <div className="relative z-0 w-5/12 mb-3 group ">
                            <input
                                type="text"
                                name="floating_phone"
                                id="floating_phone"
                                className={`block py-2.5 px-0 w-full text-sm text-black font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer ${phoneNumberError ? 'border-red-500 focus:border-red-500' : 'border-gray-400 focus:border-[#3ac67b]'}`}
                                placeholder=" "
                                value={phoneNumber}
                                required
                                onChange={handlePhoneNumberChange}
                            />
                            <label
                                htmlFor="floating_phone"
                                className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${phoneNumberError ? 'text-red-500 peer-focus:text-red-500' : 'text-[#3ac67b] peer-focus:text-[#3ac67b]'}`}
                            >
                                Phone Number
                            </label>
                            {phoneNumberError && <p className="text-red-500 text-xs mt-1">{phoneNumberError}</p>}
                        </div>
                    </div>
                    <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-10">
                        <div className="relative z-0 w-5/12 mb-5 group">
                            <input
                                type="text"
                                name="floating_email"
                                id="floating_email"
                                className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-gray-400 appearance-none text-black font-semibold focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer ${emailError ? 'border-red-500 focus:border-red-500' : 'border-gray-400 focus:border-[#3ac67b]'}`}
                                placeholder=" "
                                value={email}
                                required
                                onChange={handleEmailChange}
                            />
                            <label
                                htmlFor="floating_email"
                                className={`peer-focus:font-medium absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ${emailError ? 'text-red-500 peer-focus:text-red-500' : 'text-[#3ac67b] peer-focus:text-[#3ac67b]'}`}
                            >
                                Email Address
                            </label>
                            {emailError && <p className="text-red-500 text-xs mt-1">{emailError}</p>}
                        </div>

                        <div className="relative z-10 w-5/12 mb-5 group">
                            <Select
                                className="text-black font-bold text-sm rounded-lg p-2.5 relative z-10" required
                                options={options}
                                value={prefferedService}
                                onChange={handleprefferedChange}
                            />
                            <label className="absolute text-lg text-[#3ac67b] duration-300 transform -translate-y-6 scale-75 top-1 -z-10 origin-[0] ml-2">Preffered IM Service</label>
                        </div>
                    </div>
                    <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-6">
                        <div className="relative z-0 w-5/12 group ">
                            <input type="text" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-black font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer" placeholder=" " required onChange={(e) => { setHeardAbouUS(e.target.value) }} />

                            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-[#3ac67b] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#3ac67b] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">How did you hear about us?</label>
                        </div>

                        <div className="relative z-0 w-5/12 mb-3 group ">
                            <input type="text" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-black font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer" placeholder=" " onChange={(e) => { setimNickName(e.target.value) }} />

                            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-[#3ac67b] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#3ac67b] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">IM Nickname</label>
                        </div>
                    </div>
                    <div className="w-7/12 flex mr-auto ml-auto flex-col sm:flex-row justify-between mt-6">
                        <div className="relative z-0 w-5/12 group ">
                            <input type="password" name="floating_email" id="floating_email" className="block py-2.5 px-0 w-full text-sm text-black font-semibold bg-transparent border-0 border-b-2 border-gray-400 appearance-none focus:outline-none focus:ring-0 focus:border-[#3ac67b] peer" placeholder=" " onChange={(e) => { setPassword(e.target.value) }} />

                            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-[#3ac67b] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#3ac67b] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                        </div>
                    </div>
                    <div className='w-7/12 flex mr-auto ml-auto flex-row mt-5'>
                        <input type='checkbox' className='' />
                        <p className='text-sm text-gray-500 ml-3'>I'm Over 18 Years old</p>
                    </div>
                    <div className='w-7/12 flex mr-auto ml-auto flex-row mt-5'>
                        <input type='checkbox' className='' />
                        <p className='text-sm text-gray-500 ml-3'>By checking this box, I confirm that I have read, understand and agree to the <span className='underline cursor-pointer text-blue-600'>Terms of Agreement</span> and <span className='underline cursor-pointer text-blue-600'>Privacy Policy</span></p>
                    </div>
                    <div className='w-7/12 flex mr-auto ml-auto flex-row mt-5'>
                        <input
                            type='checkbox'
                            className=''
                            onChange={handleCheckboxChange}
                        />
                        <p className='text-sm text-gray-500 ml-3'>I agree to receive promo emails & newsletters from CronoNetwork</p>
                    </div>
                    <button type='submit' className='mt-7 ml-[1048px] w-[130px] h-[50px] bg-[#3AC67B] text-center text-white text-[20px] font-semibold rounded-3xl tracking-widest' onClick={handle_Data}>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default SmartLink_SignUp