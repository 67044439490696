import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";

const Contacts = () => {
  const [requestOffers, setRequestOffers] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://apidealer.devcir.co/api/request-offers');
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       // const filteredData = data.filter(item => item.status === "Pending");
  //       setRequestOffers(data);
  //       fetchUserNames(data);
  //       setLoading(false)
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://apidealer.devcir.co/api/request-offers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRequestOffers(data);
        const names = await fetchUserNames(data);
        setUserNames(names);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);






  // const fetchUserNames = async (offers) => {
  //   try {
  //     const responses = await Promise.all(
  //       offers.map(offer => {
  //         if (offer.userField === "Agency") {
  //           return axios.get(`https://apidealer.devcir.co/api/agency-registrations/${offer.userid}`);
  //         }
  //         return Promise.resolve(null);
  //       })
  //     );
  //     const userNamesData = {};
  //     responses.forEach((response, index) => {
  //       if (response) {
  //         userNamesData[offers[index].userid] = response.data.firstname;
  //         console.log(userNamesData);
  //       } else {
  //         userNamesData[offers[index].userid] = "Unknown";
  //       }
  //     });
  //     setUserNames(userNamesData);
  //   } catch (error) {
  //     console.error('Error fetching user names:', error);
  //   }
  // };


  const fetchUserNames = async (offers) => {
    try {
      const userNamesData = {};
      for (const offer of offers) {
        if (offer.userField === "Agency") {
          try {
            const response = await axios.get(`https://apidealer.devcir.co/api/agency-registrations/${offer.userid}`);
            userNamesData[offer.userid] = response.data.firstname;
          } catch (error) {
            console.error(`Error fetching user name for userid ${offer.userid}:`, error);
            userNamesData[offer.userid] = "Unknown";
          }
        } else {
          userNamesData[offer.userid] = "Unknown";
        }
      }
      return userNamesData;
    } catch (error) {
      console.error('Error fetching user names:', error);
      return {};
    }
  };












  const handleStatusChange = async (id) => {
    const newStatus = selectedStatus[id];
    try {
      await axios.put(`https://apidealer.devcir.co/api/request-offers/${id}/update-status`, { status: newStatus });
      setRequestOffers(prevOffers => {
        return prevOffers.map(offer => {
          if (offer.id === id) {
            return { ...offer, status: newStatus };
          }
          return offer;
        });
      });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleStatusSelectChange = (event, id) => {
    const newStatus = event.target.value;
    setSelectedStatus(prevStatus => ({ ...prevStatus, [id]: newStatus }));
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const columns = [
    {
      field: 'campaign_name',
      headerName: 'Campaign Name',
      flex: 1, align: 'center', headerAlign: 'center' ,
      renderCell: (params) => (
        <Button onClick={() => handleRowClick(params)}>
          {params.value}
        </Button>
      ),
    },
    { field: 'userName', headerName: 'User Name', flex: 1, align: 'center', headerAlign: 'center' , },
    { field: 'userField', headerName: 'User Field', flex: 1, align: 'center', headerAlign: 'center' , },
    { field: 'status', headerName: 'Status', flex: 1, align: 'center', headerAlign: 'center' , },
    {
      field: 'changeStatus',
      headerName: 'Change Status',
      flex: 1, align: 'center', headerAlign: 'center' ,
      renderCell: (params) => (
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            className="h-8"
            value={selectedStatus[params.row.id] || params.row.status}
            onChange={(event) => handleStatusSelectChange(event, params.row.id)}
            label="Status"
          >
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Pending">Rejected</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'update',
      headerName: 'Update',
      flex: 1, align: 'center', headerAlign: 'center' ,
      renderCell: (params) => (
        <Button
          onClick={() => handleStatusChange(params.row.id)}
          variant="contained"
          color="primary"
        >
          Update Status
        </Button>
      ),
    },
  ];

  const rows = requestOffers.map(offer => ({
    ...offer,
    id: offer.id,
    userName: userNames[offer.userid] || "Unknown",
  }));
  

  return (
    <div className="app">
      <Sidebar />
      <main className="content">
        <Topbar />

     

        <Box m="20px">
          <Typography variant="h1" align="left" className="font-extrabold">
            Manage Campaigns
          </Typography>
          <Box m="40px 0">
            <DataGrid
              // rows={rows}
              rows={rows.filter((row) => row.status === 'Approved')}
              columns={columns}
              pageSize={10}
              autoHeight
              loading = {loading}
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  color: 'white',
                },
                '& .MuiInputBase-root': {
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFormControl-root': {
                  color: 'white',
                },
                '& .MuiButtonBase-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />
          </Box>
          {selectedRow && open && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
              <div className="bg-white flex flex-col p-8 rounded-lg w-7/12">
                <div className='flex flex-row space-x-3'>
                  <div className='w-2/5 border-r-2 border-gray-100'>
                    <h2 className="text-xl font-bold mb-4 text-black">{selectedRow.campaign_name}</h2>
                    <div>
                      <img className='images w-60 h-60' src={selectedRow.imageUrl} alt="img" />
                    </div>
                    <div className='space-y-2'>
                      <p className='text-black mt-2'><strong>Payout:</strong> {selectedRow.payout}</p>
                      <p className='text-black'><strong>Price Format:</strong> {selectedRow.price_format}</p>
                      <p className='text-black'><strong>Vertical:</strong> {selectedRow.vertical}</p>
                      <p className='text-black'><strong>Platforms:</strong> {selectedRow.platform}</p>
                      <p className='text-black'><strong>Offer capped:</strong> {selectedRow.status}</p>
                      <p className='text-black'><strong>Allowed countries:</strong> {selectedRow.country}</p>
                    </div>
                  </div>
                  <div className='w-7/12 flex flex-col space-y-1'>
                    <div className='mt-10 space-y-6 border-b-2 border-gray-100'>
                      <p className='text-black'><strong>Description : </strong> {selectedRow.info_share} </p>
                      <p className='text-black underline'><strong>Restrictions</strong></p>
                      <p className='text-black'><span className='font-semibold'>Media Types Restricted : </span> {selectedRow.media_types_restricted} </p>
                      <p className='text-black underline'><strong>Not Allowed</strong></p>
                      <p className='text-black'><span className='font-semibold'>Countries </span> {selectedRow.blacklisted_country} </p>
                      <p className='text-black'><strong>Loyalty : </strong>{selectedRow.loyalty}</p>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <hr className='mt-4 border-b-[1px] border-gray-100' />
                <div className='flex justify-end space-x-7'>
                  <button onClick={handleClose} className="mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600">Close</button>
                </div>
              </div>
            </div>
          )}

        </Box>

        <Box m="20px">
          <Typography variant="h1" align="left" className="font-extrabold">
          
          </Typography>
          <Box m="40px 0">
            <DataGrid
              // rows={rows}
              rows={rows.filter((row) => row.status === 'Pending')}
              columns={columns}
              pageSize={10}
              autoHeight
              loading = {loading}
              components={{ Toolbar: GridToolbar }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  color: 'white',
                },
                '& .MuiInputBase-root': {
                  color: 'white',
                },
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiFormControl-root': {
                  color: 'white',
                },
                '& .MuiButtonBase-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />
          </Box>

          
          {selectedRow && open && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-[10000]">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full h-auto max-h-[95vh] overflow-auto">
            <div className='flex flex-row space-x-3'>
                <div className='w-2/5 border-r-2 border-gray-100'>
                    <h2 className="text-xl font-bold mb-4 text-black">{selectedRow.campaign_name}</h2>
                    <div>
                        <img className='images w-60 h-60' src={selectedRow.imageUrl} alt="img" />
                    </div>
                    <div className='space-y-2'>
                        <p className='text-black mt-2'><strong>Payout:</strong> {selectedRow.payout}</p>
                        <p className='text-black'><strong>Price Format:</strong> {selectedRow.price_format}</p>
                        <p className='text-black'><strong>Vertical:</strong> {selectedRow.vertical}</p>
                        <p className='text-black'><strong>Platforms:</strong> {selectedRow.platform}</p>
                        <p className='text-black'><strong>Offer capped:</strong> {selectedRow.status}</p>

                        {/* Handle Allowed Countries */}
                        <p className='text-black'>
                            <strong>Allowed countries:</strong> 
                            {selectedRow.country ? (
                                selectedRow.country
                                    .split(',')
                                    .slice(0, 10)
                                    .map(country => country.trim()) // To handle extra spaces
                                    .join(', ') + (selectedRow.country.split(',').length > 10 ? ', ...more' : '')
                            ) : 'N/A'}
                        </p>
                    </div>
                </div>
                <div className='w-7/12 flex flex-col space-y-1'>
                    <div className='mt-10 space-y-6 border-b-2 border-gray-100'>
                        <p className='text-black'><strong>Description : </strong> {selectedRow.info_share} </p>

                        {/* Handle Media Types Restricted */}
                        <p className='text-black underline'><strong>Restrictions</strong></p>
                        <p className='text-black'>
                            <span className='font-semibold'>Media Types Restricted: </span> 
                            {selectedRow.media_types_restricted ? (
                                selectedRow.media_types_restricted
                                    .split(',')
                                    .slice(0, 10)
                                    .map(type => type.trim())
                                    .join(', ') + (selectedRow.media_types_restricted.split(',').length > 10 ? ', ...more' : '')
                            ) : 'N/A'}
                        </p>

                        {/* Handle Not Allowed Countries */}
                        <p className='text-black underline'><strong>Not Allowed</strong></p>
                        <p className='text-black'>
                            <span className='font-semibold'>Countries: </span> 
                            {selectedRow.blacklisted_country ? (
                                selectedRow.blacklisted_country
                                    .split(',')
                                    .slice(0, 10)
                                    .map(country => country.trim())
                                    .join(', ') + (selectedRow.blacklisted_country.split(',').length > 10 ? ', ...more' : '')
                            ) : 'N/A'}
                        </p>
                        <p className='text-black'><strong>Loyalty : </strong>{selectedRow.loyalty}</p>
                    </div>
                </div>
            </div>
            <hr className='mt-4 border-b-[1px] border-gray-100' />
            <div className='flex justify-end space-x-7'>
                <button onClick={handleClose} className="mt-4 bg-gray-400 text-white py-1 px-8 rounded hover:bg-gray-600">Close</button>
            </div>
        </div>
    </div>
)}

        </Box>


      </main>
    </div>
  );
};

export default Contacts;
